import { Injectable } from '@angular/core'
import { CurrentUser } from '@undock/session'

import { AccountCollection } from '@undock/user/models/account.model'
import { SavedLocation } from '@undock/locations/models/saved-location.model'
import { Memoize } from '@undock/core'

import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
} from '@typeheim/fire-rx'

import {
    distinctUntilChanged,
    shareReplay,
    switchMap,
    takeUntil,
} from 'rxjs/operators'

@Injectable()
export class LocationsManager {

    @EmitOnDestroy()
    protected readonly destroyedEvent = new DestroyEvent()

    public constructor(
        private readonly currentUser: CurrentUser,
    ) {}

    @Memoize()
    public get savedLocationsStream(): ReactiveStream<SavedLocation[]> {
        return new ReactiveStream(
            this.currentUser.uidStream.pipe(
                distinctUntilChanged(),
                switchMap(uid => {
                    return AccountCollection.one(uid)
                                            .collection(SavedLocation)
                                            .all()
                                            .stream()
                                            .emitUntil(this.destroyedEvent)
                }),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }


    public async createLocation(name: string, address: string, url: string): Promise<SavedLocation> {
        const account = await this.currentUser.accountStream

        const entity = new SavedLocation(name, address, url)

        await AccountCollection.one(account.id)
                               .collection(SavedLocation)
                               .save(entity)

        return entity
    }

    public async deleteLocation(entity: SavedLocation): Promise<void> {
        const account = await this.currentUser.accountStream


        await AccountCollection.one(account.id)
                                .collection(SavedLocation)
                                .remove(entity)
    }

    public async updateLocation(entity: SavedLocation): Promise<void> {
        const account = await this.currentUser.accountStream
        await AccountCollection.one(account.id)
                               .collection(SavedLocation).save(entity)
    }
}
