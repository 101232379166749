<header *ngIf="!(layoutStateModel.state.layoutEnabledStream | subscribe)">
    <div class="header-row">
        <div class="changing-page-content">
            <ng-content></ng-content>
        </div>
    </div>
</header>

<header *ngIf="layoutStateModel.state.layoutEnabledStream | subscribe" class="header" [ngSwitch]="(isVisitorRegularUserStream | subscribe)">
    <!--  is user authorized  /-->
    <ng-container *ngSwitchCase="true">
        <div class="header-row">
            <button  mat-button class="sidebar-button" (click)="state.toggleViewMode()">
                <app-icon-sidebar-open [width]="24" [height]="24" [color]="'#807A8E'"></app-icon-sidebar-open>
            </button>

            <div class="changing-page-content">
                <ng-content></ng-content>
            </div>

            <div class="connect-calendar-message controls-button" *ngIf="integrationsManager.noCalendarConnectedStream | subscribe"
                 (click)="onToggleConnectPopup()"
                 [matTooltip]="'Please connect a calendar'">
                <app-connect-calendar-icon [width]="24" [height]="24"></app-connect-calendar-icon>
            </div>

            <ng-container *ngIf="!isProduction && displayNavigationIcons && (isBetaUser$ | subscribe)">
                <a mat-button class="controls-button search-button" [routerLink]="['/', 'search', 'global']">
                    <app-icon-search [width]="24" [height]="24" [color]="'#807A8E'"></app-icon-search>
                </a>
            </ng-container>

            <a mat-button class="controls-button notifications" [routerLink]="['/', 'proposal', 'list']">
                <app-icon-clock></app-icon-clock>
                <ng-container *ngIf="(statusesState.incomingProposalsCountStream | subscribe) as incomingProposalsCount">
                    <span class="counter" *ngIf="incomingProposalsCount > 0">{{ incomingProposalsCount }}</span>
                </ng-container>
            </a>

            <ng-container *ngIf="displayNewEventButton">
                <app-header-new-meeting-button></app-header-new-meeting-button>
            </ng-container>
        </div>
    </ng-container>

    <!--  is user NON authorized  /-->
    <ng-container *ngSwitchCase="false">
        <div class="header-row">
            <a href="{{'https://undock.com/?ref=' + (currentlyViewedPage | stream) }}"
               target="_blank" class="undock-logo"
               *ngIf="showLogo"
            >
                <app-logo-undock [color]="logoColor"></app-logo-undock>
            </a>

            <div class="buttons-box">
                <button mat-button class="link-button -login-button" [routerLink]="'/login'">
                    <span class="title">Sign In</span>
                    <span class="mobile-title"> or Sign Up</span>
                </button>

                <button mat-button class="link-button -signup-button" [routerLink]="'/signup'"
                        [queryParams]="{ref: currentlyViewedPage | subscribe}">Get Started – it’s Free
                </button>

                <!--                <div class="compare-calendar-wrapper">-->
                <!--                    <app-compare-calendar-button></app-compare-calendar-button>-->
                <!--                </div>-->
            </div>
        </div>
    </ng-container>
</header>

<div class="popup" *ngIf="isConnectCalendarPopup.value" (click)="onToggleConnectPopup()">
    <ng-container *ngIf="(isOpenedStateStream | subscribe) || (integrationsManager.noCalendarConnectedStream | subscribe)">
        <div class="connect-calendar-button-container">

            <app-calendar-icon></app-calendar-icon>
            <h3 class="connect-title">Time to connect your calendars</h3>
            <span class="connect-subtitle">Add your calendars to sync your events to Undock</span>

            <button mat-button class="connect-calendar-button google"
                    (click)="connectGoogleCalendar()">
                <div class="icon-box">
                    <app-google-icon></app-google-icon>
                </div>
                <span class="text">Add Google Calendar</span>
            </button>

            <button mat-button class="connect-calendar-button microsoft"
                    (click)="connectMicrosoftCalendar()">
                <div class="icon-box">
                    <app-microsoft-icon></app-microsoft-icon>
                </div>
                <span class="text">Add Microsoft Calendar</span>
            </button>

            <button mat-button class="title-ask" (click)="onToggleConnectPopup()">Ask me later</button>
        </div>
    </ng-container>
</div>
