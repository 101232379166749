import {
    Collection,
    DocRef,
    Entity,
    Field,
    ID,
    Reference,
} from '@typeheim/orm-on-fire'
import { FirestoreUser } from '@undock/user'

export enum RequestStatus {
    Approved = 'approved',
    Pending = 'pending',
    Declined = 'declined',
}

export const CONNECTION_REQUEST_COLLECTION_NAME = 'connection-request'

@Entity({ collection: CONNECTION_REQUEST_COLLECTION_NAME })
export class ConnectionRequest {
    @ID()
    id: string

    @DocRef(FirestoreUser)
    requester: Reference<FirestoreUser>

    @DocRef(FirestoreUser)
    receiver: Reference<FirestoreUser>

    @Field()
    receiverID: string // simple ID string for search purposes

    @Field()
    requesterID: string // simple ID string for search purposes

    @Field()
    status: RequestStatus = RequestStatus.Pending
}

export const ConnectionRequestCollection: Collection<ConnectionRequest> = Collection.of<ConnectionRequest>(ConnectionRequest)