import {
    ChangeDetectionStrategy,
    Component,
    Input,
    Output,
    EventEmitter,
    ElementRef,
    ViewChild,
    OnInit,
} from '@angular/core'

import {
    DestroyEvent,
    EmitOnDestroy,
} from '@typeheim/fire-rx'
import { FormControl } from '@angular/forms'
import { MaterialsModule } from '@undock/common/ui-kit/materials.module'

@Component({
    selector: 'app-icon-select',
    templateUrl: 'icon-selector.component.html',
    styleUrls: ['icon-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconSelectorComponent implements OnInit {

    public searchText = new FormControl('')
    public searchTextValue = ''

    @Input() value: string = ''
    @Output() change = new EventEmitter<string>()

    @ViewChild('toggleButton') toggleButton: ElementRef
    @ViewChild('menu') menu: ElementRef

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    public isMenuOpen = false
    public paginationPage: number

    public get iconsSet() {
        return MaterialsModule.svgIconsAssetList || []
    }

    public ngOnInit() {
        if (!this.iconsSet.includes(this.value)) {
            this.value = ''
        }
    }

    public toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen
    }

    public selectIcon(icon: string) {
        if (this.value === icon) {
            icon = ''
        }
        this.value = icon
        this.change.emit(icon)
        this.isMenuOpen = false
    }

    public get icons(): string[] {
        return this.iconsSet.filter(icon => new RegExp(this.searchTextValue, 'img').test(icon))
    }

    public filterIcons(inputText) {
        this.searchTextValue = inputText
    }

}
