import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Inject,
} from '@angular/core'
import { AuthManager } from '@undock/auth'
import { SidebarState } from '@undock/common/layout/states/sidebar.state'
import { LayoutStateModel } from '@undock/common/layout/states/layout.state'
import { IS_BETA_USER } from '@undock/feature-plans/tokens/is-beta-user'
import { ReactiveStream } from '@typeheim/fire-rx'

@Component({
    selector: 'app-main-layout',
    templateUrl: 'main-layout.component.html',
    styleUrls: ['main-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLayoutComponent {

    @HostBinding('class.guest-view') isGuest = true;
    @HostBinding('class.promo-banner-displayed')
    public readonly isPromoBannedDisplayed = false

    public constructor(
        public readonly authManager: AuthManager,
        public readonly stateModel: LayoutStateModel,
        public readonly sidebarState: SidebarState,
        @Inject(IS_BETA_USER)
        public readonly isBetaUser$: ReactiveStream<boolean>,
    ) {
        this.authManager.isRegularUserStream.subscribe( isLogged => this.isGuest = !isLogged)
    }

    navigateToTestFlight() {
        window.open('https://testflight.apple.com/join/5mp3vzEX', '_blank')
    }
}
