<header
   *ngIf="{
       browserTz: state.browserTimeZoneDataStream | stream,
       selectedTz: state.selectedTimeZoneDataStream | stream,
       isOwnerMode: state.isOwnerModeStream | stream,
       isEarlyAdopter: isBetaUser$ | stream
   } as values"
>
    <div class="row">
        <div class="selected-meeting-date-box"
             *ngIf="isEventScheduleSelected$ | stream else availabilitySlotIsNotSelectedTmpl"
        >
            <div class="date-text date">
                <app-date-picker
                        [showAsModal]="true"
                        [dateFormat]="dateFormat"
                        [disabled]="!values.isOwnerMode"
                        [date]="schedule.start"
                        (onDateSelected)="handleDateSelected($event)"
                ></app-date-picker>
            </div>

            <div class="date-text time">
                <app-meet-time-slot-selector
                        [step]="timeInputStep"
                        [labelFormat]="timeFormat"
                        [selectedTime]="schedule.start"
                        (onSlotSelected)="handleStartTimeChange($event)"
                ></app-meet-time-slot-selector>
            </div>

            <span class="separator">
                to
            </span>

            <div class="date-text time">
                <app-meet-time-slot-selector
                        [step]="timeInputStep"
                        [labelFormat]="timeFormat"
                        [selectedTime]="schedule.end"
                        (onSlotSelected)="handleEndTimeChange($event)"
                ></app-meet-time-slot-selector>
            </div>
        </div>

        <ng-template #availabilitySlotIsNotSelectedTmpl>
            <ng-container [ngSwitch]="isAvailabilityLoadingStream | stream">
                <ng-container *ngSwitchCase="true">
                    <div class="selected-meeting-date-box">
                        <span class="date-text notice">Please wait. Availability is loading.</span>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="false">
                    <div class="selected-meeting-date-box">
                        <span class="date-text notice">No mutual availability found</span>
                    </div>
                </ng-container>
            </ng-container>
        </ng-template>

        <div class="toolbar-icons">
            <div class="recurring-settings-btn"
                 [matMenuTriggerFor]="recurringSettingsPopupTmpl"
            >
                <!-- repeat-icon -->
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4927 0.267786C10.805 0.539888 10.8376 1.01364 10.5655 1.32595L9.45669 2.59856C9.63477 2.58848 9.81588 2.58326 10 2.58326C14.5563 2.58326 18.25 6.27691 18.25 10.8333C18.25 15.3896 14.5563 19.0833 10 19.0833C5.44365 19.0833 1.75 15.3896 1.75 10.8333C1.75 9.13836 2.26192 7.56074 3.1397 6.2494C3.37011 5.90519 3.83594 5.81293 4.18015 6.04334C4.52437 6.27375 4.61662 6.73958 4.38621 7.08379C3.66854 8.15595 3.25 9.44459 3.25 10.8333C3.25 14.5612 6.27208 17.5833 10 17.5833C13.7279 17.5833 16.75 14.5612 16.75 10.8333C16.75 7.10534 13.7279 4.08326 10 4.08326C9.77082 4.08326 9.54763 4.09281 9.33037 4.11093L10.8591 5.22764C11.1935 5.47198 11.2666 5.94119 11.0223 6.27567C10.778 6.61014 10.3087 6.68322 9.97426 6.43888L7.05759 4.30826C6.88795 4.18433 6.77806 3.99494 6.75466 3.78616C6.73127 3.57738 6.79651 3.36835 6.93452 3.20995L9.43452 0.34058C9.70662 0.0282761 10.1804 -0.00431481 10.4927 0.267786Z" fill="#2B2733"/>
                </svg>

                <span class="pattern">{{ recurrenceLabel$ | async }}</span>

                <!-- arrow-down icon -->
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.66602 9.16675L9.99935 11.6667L13.3327 9.16675" stroke="#2B2733" stroke-width="2" stroke-linecap="round"/>
                </svg>
            </div>

            <mat-menu class="recurrence-menu" xPosition="before" #recurringSettingsPopupTmpl>
                <ng-template matMenuContent>
                    <div class="recurring-settings" (click)="$event.stopPropagation()">
                        <h5>Recurring settings</h5>

                        <mat-select udInput
                                    [value]="recurringFrequencyStream | stream"
                                    [placeholder]="recurrenceOptions[0][1]"
                                    (selectionChange)="changeRecurringFrequency($event.value)"
                        >
                            <mat-option *ngFor="let option of recurrenceOptions" [value]="option[0]"
                            >{{ option[1] }}</mat-option>
                        </mat-select>

                        <mat-slide-toggle class="limit-occurrences-number-input"
                                          [disabled]="!(isRecurrentEventStream | stream)"
                                          [checked]="limitRecurrentEventSeriesStream | stream"
                                          (toggleChange)="toggleRecurrenceLimit()"
                        >Limit number of occurrences</mat-slide-toggle>

                        <!--        <div class="input-row">-->
                        <!--            <input class="events-end-date" udInput type="number" spellcheck="false"-->
                        <!--                   *ngIf="limitRecurrentEventSeriesStream | stream"-->
                        <!--                   [ngModel]="numberOfEventOccurrencesStream | stream"-->
                        <!--                   (ngModelChange)="changeNumberOfEventOccurrences($event)"-->
                        <!--            >-->
                        <!--        </div>-->

                        <div class="input-row" *ngIf="limitRecurrentEventSeriesStream | stream">
                            <span class="label">Number of occurences</span>
                            <input class="events-limit-number" udInput type="number" spellcheck="false"
                                   [ngModel]="numberOfEventOccurrencesStream | stream"
                                   (ngModelChange)="changeNumberOfEventOccurrences($event)"
                            />
                        </div>
                    </div>
                </ng-template>
            </mat-menu>

            <app-time-time-zone-selector-2
                    [defaultSelect]="false"
                    [selectedTimeZone]="values.selectedTz"
                    [browserTimeZone]="values.browserTz"
                    (onSelected)="handleSelectedTimeZoneChange($event)"
            ></app-time-time-zone-selector-2>

            <span class="icon-box clickable"
                  *ngIf="values.isOwnerMode"
                  [ngSwitch]="isAvailabilitySelectorHidden$ | stream"
                  (click)="isAvailabilitySelectorHidden$.next(!isAvailabilitySelectorHidden$.value)"
            >
                <i class="material-icons-round" *ngSwitchCase="true">add</i>

                <!--
                  /**
                   * Icon: Sports - Collapse
                   * TODO: Replace with a component icon
                   */
                -->
                <svg *ngSwitchCase="false" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 7L12 7" stroke="#2B2733" stroke-width="2" stroke-linecap="round"/>
                    <path d="M3 12L12 12" stroke="#2B2733" stroke-width="2" stroke-linecap="round"/>
                    <path d="M3 17L12 17" stroke="#2B2733" stroke-width="2" stroke-linecap="round"/>
                    <path d="M22 17L19 15L16 17" stroke="#2B2733" stroke-width="2" stroke-linecap="round"/>
                </svg>
            </span>
        </div>
    </div>

    <ng-container *ngIf="isMeetingShouldBeRescheduled$ | stream">
        <div class="row">
            <span class="reschedule-notice">Meeting will be rescheduled</span>
            <div class="cancel-reschedule" (click)="cancelReschedule()">Cancel</div>
        </div>
    </ng-container>

    <ng-container *ngIf="isAvailabilityLoadingStream | stream">
        <div class="availability-loading-overlay"></div>
    </ng-container>
</header>

<main *ngIf="!(isAvailabilitySelectorHidden$ | stream)">
    <app-time-availability-day-browser
            [multiMode]="true"
            [availability]="displayAvailability$ | stream"
            [isAvailabilityLoading]="isAvailabilityLoadingStream | stream"
            [availabilityRangeStart]="displayAvailabilityRangeStart$ | stream"
            [selectedAvailabilityDayIndex]="selectedAvailabilityDayIndex$ | stream"
            [availabilityDaysCountToDisplay]="availabilityDaysCountToDisplay$ | stream"
            [selectedTimeZone]="state.selectedTimeZoneNameStream | stream"
            [enableDatePicker]="false"

            (onNextDaysClicked)="displayNextAvailabilityDays()"
            (onPrevDaysClicked)="displayPrevAvailabilityDays()"
            (onDayIndexSelected)="onAvailabilityDayIndexSelected($event)"
    ></app-time-availability-day-browser>

    <app-time-availability-multi-day-slot-selector
            [availabilitySet]="displayAvailability$ | stream"
            [availabilityDaysCountToDisplay]="availabilityDaysCountToDisplay$ | stream"
            [isAvailabilityLoading]="isAvailabilityLoadingStream | stream"
            [selectedAvailabilitySlot]="selectedAvailableSlotStream$ | stream"
            [selectedTimezone]="state.selectedTimeZoneNameStream | stream"

            (onAvailableSlotSelected)="selectAvailabilitySlot($event)"
    ></app-time-availability-multi-day-slot-selector>
</main>
