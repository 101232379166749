<ng-container *ngIf="isVisibleStream | subscribe">
    <div class="participant-selector-container" (click)="close()">
        <div class="participant-selector-content" (click)="$event.stopPropagation()">
            <div class="header">
                <span class="selector-title">{{ title }}</span>
                <button mat-button class="material-icons" (click)="close()">
                    <app-icon-close [color]="'#807A8E'"></app-icon-close>
                </button>
            </div>

            <div class="participants-search">
                <input name="email" autocomplete="email" #searchCriteriaInput type="text" placeholder="Enter user to assign..."/>
            </div>

            <div class="participants-list">
                <ng-container *ngIf="{
                    selectedIds: selectedIdsStream | subscribe,
                    availableParticipants: availableParticipantsStream | subscribe
                } as values">
                    <ng-container *ngFor="let participant of values.availableParticipants">
                        <div class="participant-view"
                             (click)="onParticipantSelected(participant)"
                             [class.selected]="values.selectedIds.includes(participant.id)"
                        >
                            <ng-container
                                    *ngIf="profilesProvider.getProfileById(participant.id) | subscribe as user else participantUserIsGuestTmpl"
                            >
                                <img class="user-image"
                                     [src]="user.imageUrl | imgThumbnail" [alt]="user.displayName"
                                     default="/assets/img/placeholders/user.png"
                                />

                                <div class="user-name">
                                    <span>{{ user.displayName }}</span>
                                </div>
                            </ng-container>

                            <ng-template #participantUserIsGuestTmpl>
                                <img class="user-image" src="/assets/img/placeholders/user.png"
                                     [alt]="participant.displayName || participant.email"
                                />

                                <div class="user-name">
                                    <span>{{ participant.displayName || participant.email }}</span>
                                </div>
                            </ng-template>

                            <div class="action assign">Assign</div>
                            <div class="action remove">Remove</div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="values?.availableParticipants?.length === 0">
                        <div class="no-results">
                            <span>No results</span>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>




