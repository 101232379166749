import {
    Inject,
    Injectable,
} from '@angular/core'
import { UiTimelineEvent } from '@undock/dashboard/contracts'
import {
    SnackbarManager,
    SnackbarPosition,
    SnackbarState,
} from '@undock/common/ui-kit/services/snackbar.manager'
import { RefreshStatusesEvent } from '@undock/data-store/events/refresh-statuses.event'
import { StatusesListItemType } from '@undock/data-store/contracts/statuses/statuses-list-item-type'
import { RsvpStatus } from '@undock/api/scopes/calendar/contracts'
import {
    AppEventsDispatcher,
    Config,
    ExtConnector,
    openLinkBlank,
} from '@undock/core'
import {
    TrackUserAnalyticsEvent,
    UserAnalyticsAction,
} from '@undock/integrations'
import { Api } from '@undock/api'
import {
    Router,
} from '@angular/router'
import { ConfirmPopupService } from '@undock/common/ui-kit'
import { CalendarEventsStorage } from '@undock/calendar/services/calendar-events.storage'
import { IS_BETA_USER } from '@undock/feature-plans/tokens/is-beta-user'
import { ReactiveStream } from '@typeheim/fire-rx'


@Injectable({
    providedIn: 'root',
})
export class CalendarEventsFacade {

    public constructor(
        protected api: Api,
        protected config: Config,
        protected router: Router,
        protected extConnector: ExtConnector,
        protected eventsManager: AppEventsDispatcher,
        protected snackbarManager: SnackbarManager,
        protected confirmPopupService: ConfirmPopupService,
        protected calendarEventsStorage: CalendarEventsStorage,
        @Inject(IS_BETA_USER)
        private readonly isBetaUser$: ReactiveStream<boolean>,
    ) {}

    public async deleteEvent(event: UiTimelineEvent) {
        const isConfirmed = await this.confirmPopupService.open({
            title: 'Are you sure you want to delete this event?',
            description: `This action could not be undone`,
            confirmButtonLabel: 'Yes, Delete',
            discardButtonLabel: 'Cancel',
        })

        if (isConfirmed) {
            /**
             * Removes cached event
             */
            this.calendarEventsStorage
                .deleteEventFromTheStorage(event)

            this.snackbarManager.showText(
                `Event ${event.title} deleted`,
                SnackbarState.Secondary, SnackbarPosition.BottomCenter
            )

            try {
                await this.api.calendar.events.deleteById(event.id)
            } catch (error) {
                console.warn(`Cannot delete event`, error)
                /**
                 * @TODO: Reload the range in this case
                 */
            } finally {
                /**
                 * Refreshes statuses list
                 */
                await this.eventsManager.dispatch(
                    new RefreshStatusesEvent(StatusesListItemType.TimelineEvent),
                )
            }
        }
    }

    public async acceptEvent(event: UiTimelineEvent) {
        try {
            event.state.isProcessing = true
            event.attendees.forEach(attendee => {
                if (attendee.email === event.state.relatedAttendeeEmail) {
                    attendee.status = RsvpStatus.Accepted
                }
            })

            this.calendarEventsStorage.refreshEventStatuses()

            this.snackbarManager.showText(
                `Event ${event.title} accepted`,
                SnackbarState.Secondary, SnackbarPosition.BottomCenter
            )

            await this.api.meet.requests
                      .acceptFromEvent(
                          event.id, event.state.relatedAttendeeEmail,
                      )

            event.state.isProcessing = false
            this.calendarEventsStorage.refreshEventStatuses()
        } catch (error) {
            /**
             * @TODO: Reload the range in this case
             */
            console.error(error)
        } finally {
            /**
             * Refreshes statuses list
             */
            await this.eventsManager.dispatch(
                new RefreshStatusesEvent(StatusesListItemType.TimelineEvent),
            )
        }
    }

    public async declineEvent(event: UiTimelineEvent) {
        try {
            event.state.isProcessing = true
            event.attendees.forEach(attendee => {
                if (attendee.email === event.state.relatedAttendeeEmail) {
                    attendee.status = RsvpStatus.Declined
                }
            })

            this.calendarEventsStorage.refreshEventStatuses()

            this.snackbarManager.showText(
                `Event ${event.title} declined`,
                SnackbarState.Secondary, SnackbarPosition.BottomCenter
            )

            await this.api.meet.requests
                      .declineFromEvent(
                          event.id, event.state.relatedAttendeeEmail,
                      )

            event.state.isProcessing = false
            this.calendarEventsStorage.refreshEventStatuses()
        } catch (error) {
            /**
             * @TODO: Reload the range in this case
             */
            console.error(error)
        } finally {
            /**
             * Refreshes statuses list
             */
            await this.eventsManager.dispatch(
                new RefreshStatusesEvent(StatusesListItemType.TimelineEvent),
            )
        }
    }

    public async setRsvpToEvent(
        event: UiTimelineEvent, status: RsvpStatus,
    ) {
        try {
            event.state.isProcessing = true
            event.attendees.forEach(attendee => {
                if (attendee.email === event.state.relatedAttendeeEmail) {
                    attendee.status = status
                }
            })
            this.calendarEventsStorage.refreshEventStatuses()

            await this.api.calendar.rsvp.setStatus(
                event.id, status, event.state.relatedAttendeeEmail,
            )

            event.state.isProcessing = false
            this.calendarEventsStorage.refreshEventStatuses()
        } catch (error) {
            /**
             * @TODO: Reload the range in this case
             */
        } finally {
            /**
             * Refreshes statuses list
             */
            await this.eventsManager.dispatch(
                new RefreshStatusesEvent(StatusesListItemType.TimelineEvent),
            )
        }
    }

    public async rescheduleEvent(event: UiTimelineEvent) {
        return this.extConnector.isExt()
            ? openLinkBlank(`${this.config.clientDomain}meet/edit/${event.dockKey}`)
            : this.router.navigate([
                'meet',
                (await this.isBetaUser$) ? 'edit-event' : 'edit',
                event.dockKey,
            ])
    }

    public async duplicateEvent(event: UiTimelineEvent) {
        return this.extConnector.isExt()
            ? openLinkBlank(`${this.config.clientDomain}meet/duplicate/${event.dockKey}`)
            : this.router.navigate([
                'meet', 'duplicate', event.dockKey,
            ])
    }


    public async navigateToAgenda(event: UiTimelineEvent) {
        return this.extConnector.isExt()
            ? openLinkBlank(`${this.config.clientDomain}meet/${event.dockKey}`)
            : this.router.navigate(['meet', event.dockKey])
    }

    public async navigateToTheConference(event: UiTimelineEvent) {
        const { dockId, dockKey, conferenceLink } = event

        if (conferenceLink && conferenceLink?.length > 0) {
            openLinkBlank(conferenceLink)
        } else if (dockId || dockKey) {
            this.extConnector.isExt()
                ? openLinkBlank(`${this.config.clientDomain}meet/${dockKey}/room`)
                : await this.router.navigate(['meet', dockKey, 'room'])
        }

        await this.eventsManager.dispatch(
            new TrackUserAnalyticsEvent(UserAnalyticsAction.MeetingJoinedFromTimeline)
        )
    }
}
