import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
} from '@angular/core'

import moment from 'moment/moment'
import { CalendarEvent } from 'angular-calendar'

import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'
import {
    debounceTime,
    map,
    takeUntil,
} from 'rxjs/operators'
import { combineLatest } from 'rxjs'

import { Api } from '@undock/api'
import { ConfirmPopupService } from '@undock/common/ui-kit'
import { TopicsManager } from '@undock/dock/meet/services/topics.manager'
import { EditMeetingData } from '@undock/dock/meet/contracts/edit-meeting-data.interface'
import { EventFormStateModel } from '@undock/dock/meet/services/state-models/event-form.state-model'
import { SnackbarManager } from '@undock/common/ui-kit/services/snackbar.manager'
import { CalendaringGridViewModel } from '@undock/common/calendar-grid/view-models/calendaring-grid.view-model'
import { DashboardCalendarDetailsManager } from '@undock/dashboard/services/calendar/dashboard-calendar-details.manager'
import { EventDetailsViewModel } from '@undock/dashboard/view-models/event-details.view-model'
import { MeetingsManager } from '@undock/dock/meet/services/meetings.manager'
import { AvailabilityProvider } from '@undock/time/availability/services/availability.provider'
import { AvailabilityViewModel } from '@undock/profile/public/view-models/availability.vmodel'

@Component({
    selector: 'app-dashboard-calendar-event-details-edit',
    templateUrl: 'dashboard-calendar-event-details-edit.component.html',
    styleUrls: ['dashboard-calendar-event-details-edit.component.scss'],
    providers: [
        TopicsManager,
        EventFormStateModel,
        AvailabilityProvider,
        AvailabilityViewModel,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardCalendarEventDetailsEditComponent {

    public readonly state = this.eventFormStateModel.state

    @Input()
    @HostBinding('class.full-size-mode')
    public isFullSizeMode: boolean = false

    @CompleteOnDestroy()
    public readonly isDataLoadingStream = new ValueSubject<boolean>(true)

    @CompleteOnDestroy()
    public readonly isSaveProcessingStream = new ValueSubject<boolean>(false)


    @EmitOnDestroy()
    private readonly destroyEvent = new DestroyEvent()

    public constructor(
        protected api: Api,
        protected snackbarManager: SnackbarManager,
        protected meetingsManager: MeetingsManager,
        protected eventFormStateModel: EventFormStateModel,
        protected confirmPopupService: ConfirmPopupService,
        protected availabilityProvider: AvailabilityProvider,
        protected availabilityViewModel: AvailabilityViewModel,
        protected eventDetailsViewModel: EventDetailsViewModel,
        protected dashboardCalendarViewModel: CalendaringGridViewModel,
        protected dashboardCalendarPopupManager: DashboardCalendarDetailsManager,
    ) {}

    public async ngOnInit() {
        this.initAvailability()
        this.initSubmitSubscription()
        this.initScheduleSubscription()
        this.initTimeZoneSubscription()
        this.initEventDetailsViewModel()
        this.initOnBeforeClosedCallback()

        return this.bootstrap()
    }

    protected async bootstrap() {
        const event = await this.eventDetailsViewModel.state.calendarEvent
        try {
            let eventData: EditMeetingData

            /**
             * It seems there is a new draft event
             */
            if (event.meta.payload.isDraft && !event.meta.payload.dockKey) {
                /**
                 * Creating new draft calendar event
                 */
                eventData = await this.api
                                      .meet.meetings
                                      .createDraftMeeting()

                event.meta.payload.dockId = eventData.dockId
                event.meta.payload.dockKey = eventData.dockKey
            } else {
                if (!event.meta.payload.dockKey) {
                    throw new Error(`Event has no dock key assigned`)
                }

                /**
                 * Loading existing draft or calendar event data
                 */
                eventData = await this.api
                                      .meet.meetings
                                      .getEditMeetingData(event.meta.payload.dockKey)

                if (typeof eventData.schedule?.end === 'string') {
                    eventData.schedule.end = new Date(eventData.schedule.end)
                }

                if (typeof eventData.schedule?.start === 'string') {
                    eventData.schedule.start = new Date(eventData.schedule.start)
                }
            }

            /**
             * Refreshing schedule from calendar grid tile
             */
            if (eventData.isDraft && event) {
                eventData.schedule.end = event.end
                eventData.schedule.start = event.start
                eventData.schedule.isAllDay = event.allDay
            }

            await this.eventFormStateModel.initViewModel(eventData)

            this.isDataLoadingStream.next(false)
            this.initializeCalendarEventUpdatesListener()
        } catch (error) {
            console.warn(`Cannot initialize popup edit`, error)
            this.snackbarManager.error(`An error happened. Please try later.`)
            this.dashboardCalendarPopupManager.forceClose()

            /**
             * Removing current event from the calendar grid
             */
            if (event?.meta?.payload?.isDraft) {
                this.dashboardCalendarViewModel.temporaryEvents$.next(
                    this.dashboardCalendarViewModel.temporaryEvents$.value.filter(event => {
                        return event.id !== event.id
                    })
                )
            }
        }
    }

    protected async handleFormSubmit(data: EditMeetingData) {
        // Show loader
        this.isSaveProcessingStream.next(true)

        try {
            let result: EditMeetingData
            if (data.isDraft) {
                result = await this.meetingsManager.createMeetingFromDraft(data)
            } else {
                result = await this.meetingsManager.updateMeeting(data.dockKey, data)
            }

            // Init form with updated data
            await this.eventFormStateModel.initViewModel(result)

            this.snackbarManager.success(`Event saved`)
        } catch (error) {
            console.warn(`Cannot create meeting`, error)
            this.snackbarManager.warning(`Cannot save event. Please try later`)
        } finally {
            // Hide loader
            this.isSaveProcessingStream.next(false)

            // Remove draft event from the calendar grid
            this.dashboardCalendarViewModel.temporaryEvents$.next(
                this.dashboardCalendarViewModel.temporaryEvents$.value.filter(event => {
                    return event.id !== event.id
                })
            )

            // Close the popup when event is saved
            this.dashboardCalendarPopupManager.forceClose()

            // Refresh cached events for updated ranges
            this.eventFormStateModel.reloadPossiblyMutatedDashboardRanges().catch(
                error => console.warn(`Cannot refresh events ranges`, error)
            )
        }
    }

    protected initAvailability() {
        this.availabilityProvider.initialize({
            v2: true,
            emails: this.eventFormStateModel.state.attendeesStream.pipe(
                takeUntil(this.destroyEvent),
                map(attendees => attendees.map(
                    attendee => attendee.userData.email,
                ))
            ),
            timeZone: this.eventFormStateModel.state.selectedTimeZoneNameStream,
            dateRange: this.availabilityViewModel.loadAvailabilityDatesRangeStream,
            meetingMode: this.eventFormStateModel.state.meetingModeStream,
            meetingDuration: this.eventFormStateModel.state.durationStream,

            // TODO: USE V2 availability and `bookingCode`
            rescheduleMeetingId: combineLatest([
                this.state.isDraftModeStream,
                this.state.originalEventDataStream,
            ]).pipe(
                map(([isDraft, originalData]) => {
                    return isDraft ? null : originalData.dockId
                }),
                takeUntil(this.destroyEvent),
            )
        }).catch(
            error => console.error(`Cannot initialize AvailabilityProvider`, error),
        )

        this.availabilityViewModel.setDisplayDaysCount(1)
        this.availabilityViewModel.selectAvailabilityDay(moment())
        this.availabilityViewModel.initViewModel().catch(
            error => console.error(`Cannot initialize AvailabilityViewModel`, error),
        )
    }

    protected initSubmitSubscription() {
        // Save event and update calendar grid
        this.eventFormStateModel.state.onSubmit.pipe(
            takeUntil(this.destroyEvent),
        ).subscribe((data) => this.handleFormSubmit(data))
    }

    protected initScheduleSubscription() {
        this.eventFormStateModel.state.eventScheduleStream.pipe(
            takeUntil(this.destroyEvent),
            debounceTime(100),
        ).subscribe(async schedule => {
            const gridEvent = await this.eventDetailsViewModel.state.calendarEvent

            const assignScheduleToTheEvent = (event: CalendarEvent) => {
                if (gridEvent.id === event.id) {
                    event.end = schedule.end
                    event.start = schedule.start
                    event.allDay = schedule.isAllDay
                    if (event.meta.payload) {
                        event.meta.payload.end = schedule.end
                        event.meta.payload.start = schedule.start
                    }
                }
                return event
            }

            if (!gridEvent.meta.payload.isDraft) {
                this.dashboardCalendarViewModel.calendarEvents$.next(
                    this.dashboardCalendarViewModel.calendarEvents$.value.map(assignScheduleToTheEvent)
                )
            } else {
                this.dashboardCalendarViewModel.temporaryEvents$.next(
                    this.dashboardCalendarViewModel.temporaryEvents$.value.map(assignScheduleToTheEvent)
                )
            }

            /**
             * Change viewed day due to event schedule update
             * TODO: Do not override currently edited event with original data
             * CHANGING THE VIEW DATE FORCES CALENDAR GRID TO FETCH EVENTS FROM THE STORAGE
             */
            // this.dashboardCalendarViewModel.viewDate.next(schedule.start)
        })
    }

    protected initTimeZoneSubscription() {
        // Set emulated timezone to the calendar grid
        combineLatest([
            this.state.browserTimeZoneDataStream,
            this.state.selectedTimeZoneDataStream,
        ]).pipe(
            takeUntil(this.destroyEvent),
        ).subscribe(([ browserTz, selectedTz ]) => {
            this.dashboardCalendarViewModel.emulatedTimeZone$
                .next(browserTz?.zone === selectedTz?.zone ? null : selectedTz)
        })

        // Clear emulated timezone after leaving edit mode
        this.destroyEvent.subscribe(
            () => this.dashboardCalendarViewModel.emulatedTimeZone$.next()
        )
    }

    protected initEventDetailsViewModel() {
        // Register current event form state model into details model
        this.eventDetailsViewModel.setEventFormStateModel(this.eventFormStateModel)
        this.destroyEvent.subscribe(() => {
            this.eventDetailsViewModel.setEventFormStateModel(null)
        })
    }

    protected initOnBeforeClosedCallback() {
        // Intercepts closing if event props changed
        this.eventDetailsViewModel.setOnBeforeClosedHook(async () => {
            if (await this.eventFormStateModel.isMeetingHasUnsavedChanges()) {
                const discardChanges = await this.confirmPopupService.open({
                    title: 'Are you sure you want to leave without saving your changes?',
                    description: `This action could not be undone`,

                    confirmButtonLabel: 'Discard changes',
                    discardButtonLabel: 'Back to edit',
                })

                if (discardChanges) {
                    const meetingData = await this.eventFormStateModel.getUpdatedMeetingData()
                    if (meetingData.isDraft) {
                        /**
                         * Removing current event from the calendar grid
                         */
                        this.dashboardCalendarViewModel.temporaryEvents$.next(
                            this.dashboardCalendarViewModel.temporaryEvents$.value.filter(event => {
                                return event.id !== event.id
                            })
                        )

                        this.api.meet.meetings.deleteDraftMeeting(meetingData._id).catch(error => {
                            console.warn(`Cannot delete draft meeting`)
                        })
                    } else {
                        /**
                         * Restores original version of updated event
                         */
                        this.eventFormStateModel
                            .reloadPossiblyMutatedDashboardRanges().catch(error => {
                            console.warn(`Cannot reload dashboard`, error)
                        })
                    }
                }

                return discardChanges
            }

            return true
        })
        /**
         * Removes interceptor callback after component being destroyed
         */
        this.destroyEvent.subscribe(() => this.eventDetailsViewModel.setOnBeforeClosedHook(null))
    }

    protected initializeCalendarEventUpdatesListener() {
        this.eventDetailsViewModel.state.onUpdates.pipe(
            takeUntil(this.destroyEvent),
        ).subscribe(updates => {
            if (updates.schedule) {
                this.eventFormStateModel.setEventSchedule(updates.schedule)
            }
        })
    }
}
