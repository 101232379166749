import {
    Inject,
    Injectable,
    Optional,
} from '@angular/core'

import {
    DestroyEvent,
    EmitOnDestroy,
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'
import { Observable } from 'rxjs'
import {
    remove,
    save,
} from '@typeheim/orm-on-fire'

import {
    Attachment,
    AttachmentCollectionGroup,
    FileStorage,
    StorageFile,
    UploadingFile,
} from '@undock/core/models'
import {
    AbstractAttachmentsManager,
    AttachmentSource,
} from '@undock/core/contracts/attachments.manager'
import { Memoize } from '@undock/core/decorators/memoize'
import { PathResolveStrategy } from '@undock/core/contracts/path-resolve.strategy'
import { HasAttachments } from '@undock/core/contracts/model/has-attachments.interface'
import { STORAGE_PATH_PREFIX } from '@undock/core/contracts/tokens/storage-path-prefix.token'


@Injectable()
export class AttachmentsManager extends AbstractAttachmentsManager<HasAttachments> {

    /**
     * Different kinds of executable files
     */
    public readonly restrictedExtensions = [
        'exe', 'msi', 'sys', 'sh', 'ipa', 'deb', 'rpm', 'etc', 'ko', 'js', 'py',
    ]


    @CompleteOnDestroy()
    private uploadsSubject = new ValueSubject<UploadingFile[]>([])

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()


    public constructor(
        protected fileStorage: FileStorage,
        protected pathResolveStrategy: PathResolveStrategy,
        @Optional()
        @Inject(STORAGE_PATH_PREFIX)
        protected storagePathPrefix: string = '/',
    ) { super() }


    @Memoize()
    public get uploadsStream(): Observable<UploadingFile[]> {
        return this.uploadsSubject.asObservable()
    }


    public async addAttachments(owner: HasAttachments, sources: AttachmentSource[]) {
        const storageFiles = await this.massUploadFromSource(
            sources, await this.getStoragePathForEntity(owner),
        )

        const attachments = await this.massCreateAttachmentEntity(owner, storageFiles)

        if (!owner.hasAttachments) {
            owner.hasAttachments = true
            await save(owner)
        }

        return attachments
    }

    public async removeAttachment(attachment: Attachment): Promise<void> {
        /**
         * Recursive function which deletes all parent attachments.
         */
        const deleteOriginalAttachmentOf = async (attachment: Attachment) => {
            let originalAttachment = await attachment.originalAttachment.get()

            if (originalAttachment && originalAttachment.isCopy) {
                await deleteOriginalAttachmentOf(originalAttachment)
            }

            return remove(originalAttachment)
        }

        const file = await attachment.fileStream

        if (file) {
            await this.fileStorage.removeFile(await attachment.fileStream)
        }

        await this.removeCopiesOf(attachment)

        if (attachment.isCopy) {
            await deleteOriginalAttachmentOf(attachment)
        }

        await remove(attachment)
    }

    public async generateAttachmentUrl(attachment: Attachment): Promise<string> {
        let file = await attachment.fileStream

        return this.fileStorage.getFileUrl(file)
    }

    public checkIsMediaTypeAllowed(source: AttachmentSource) {
        /**
         * Getting the last index from split array
         */
        const extension = source.name.split('.').slice(-1)[0]
        return !this.restrictedExtensions.includes(extension)
    }

    protected async createAttachmentEntity(
        owner: HasAttachments, file: StorageFile, original?: Attachment,
    ): Promise<Attachment> {
        let attachment = new Attachment()

        attachment.name = file.name
        attachment.size = file.size
        attachment.tags = file.tags
        attachment.type = file.contentType

        attachment.ownerId = owner.id
        attachment.ownerType = owner.constructor.name

        attachment.isCopy = false

        if (original) {
            /**
             * If this attachment is copy of different attachment
             */
            attachment.isCopy = true
            attachment.originalAttachment.link(original)
            attachment.originalAttachmentId = original.id
        }

        attachment.file.link(file)
        attachment.fileId = file.id

        attachment.isInitialized = true

        await owner.attachments.save(attachment)

        return attachment
    }

    protected async massCreateAttachmentEntity(owner: HasAttachments, files: StorageFile[]): Promise<Attachment[]> {
        return Promise.all(files.map(
            file => this.createAttachmentEntity(owner, file),
        ))
    }

    protected async uploadFromSource(source: AttachmentSource, storagePath: string): Promise<StorageFile> {
        let uploadingFile = await this.fileStorage.upload(
            source.target, source.name, storagePath, { ...source?.options },
        )

        let currentlyUploadingFiles = await this.uploadsSubject
        this.uploadsSubject.next([...currentlyUploadingFiles, uploadingFile])

        let storageFile = await uploadingFile.uploadedFile

        await this.removeFromUploadsList(uploadingFile)

        return storageFile
    }


    protected async massUploadFromSource(sources: AttachmentSource[], path: string): Promise<StorageFile[]> {
        return await Promise.all(sources.map(
            source => this.uploadFromSource(source, path),
        ))
    }


    protected async removeFromUploadsList(uploadingFile: UploadingFile) {
        let currentlyUploadingFiles = await this.uploadsSubject

        this.uploadsSubject.next(
            currentlyUploadingFiles.filter(file => file !== uploadingFile),
        )

        setTimeout(() => {
            /**
             * Destroying uploading file
             */
            uploadingFile.onDestroy()
        }, 1)
    }

    protected async removeCopiesOf(attachment: Attachment) {
        const copies = await AttachmentCollectionGroup.filter(
            filter => filter.isCopy.equal(true),
        ).filter(
            filter => filter.originalAttachmentId.equal(attachment.id),
        ).get()

        for (let copy of copies) {
            await remove(copy)
        }
    }
}
