import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
} from '@angular/router'
import { Injectable } from '@angular/core'

import { UserSession } from '@undock/session/models/user-session'


@Injectable()
export class AccountInitializedGuard implements CanActivate {

    /**
     * @TODO: Rework with Account usage instead of legacy User entity
     */

    public constructor(
        private userSession: UserSession,
    ) {}

    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

        /**
         * This stream emits after authentication hooks being processed
         *
         * We should wait for this moment
         */
        await this.userSession.currentUserStream

        return true
    }
}
