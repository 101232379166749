import { Router } from '@angular/router'
import { Injectable } from '@angular/core'

import * as m from 'moment'

import {
    DestroyEvent,
    EmitOnDestroy,
} from '@typeheim/fire-rx'
import { takeUntil } from 'rxjs/operators'
import { StateModel } from '@typeheim/fluent-states'

import { DateRange } from '@undock/time/availability'
import {
    RangeMs,
    getOverlapTypeForRanges,
    isRangeContainsSingleTimeStamp,
    getTotalRangeForOverlappedRanges,
} from '@undock/core/utils/ranges-overlap'
import { ArrayHelpers } from '@undock/core'
import {
    UiTimelineEvent,
    TimelineDayData,
    UpdateEventsStorageResult,
} from '@undock/dashboard/contracts'
import { CalendarEventsStorage } from '@undock/calendar/services/calendar-events.storage'
import { DashboardTimelineStore } from '@undock/dashboard/view-models/states/dashboard-timeline.state'


@Injectable()
export class DashboardTimelineViewModel<T = DashboardTimelineStore> extends StateModel<T> {

    protected daysMap = new Map<number, TimelineDayData>()
    protected timelineEvents = new Array<UiTimelineEvent>()

    /**
     * General stream storage for view model
     */
    protected readonly store = new DashboardTimelineStore()

    /**
     * Global state of the CalendarEventsStorage
     */
    protected readonly eventsStoreState = this.calendarEventsStorage.state

    /**
     * Currently displayed dates range
     */
    protected currentTimelineRange: RangeMs

    protected readonly eventsPageSize: number = 25

    @EmitOnDestroy()
    protected readonly destroyEvent = new DestroyEvent()

    public constructor(
        protected readonly router: Router,
        protected readonly calendarEventsStorage: CalendarEventsStorage,
    ) {
        super()
    }

    public initViewModel() {
        /**
         *
         */
        this.eventsStoreState.onStorageUpdated.pipe(
            takeUntil(this.destroyEvent),
        ).subscribe(
            updates => this.updateTimelineDaysState(updates)
        )
    }

    public async loadInitialRange() {
        /**
         * Should display -1 <-> +2 weeks by default
         */
        const requiredTimelineRange: DateRange = {
            start: m().startOf('day').add(-1, 'week').toDate(),
            end: m().add(2, 'weeks').endOf('day').toDate(),
        }

        /**
         * Loading events for default required timeline range
         */
        this.updateTimelineDaysState(
            await this.calendarEventsStorage
                      .getEventsForDateRange(requiredTimelineRange)
        )

        /**
         * Will allow the timeline to be rendered
         */
        this.store.isTimelineReadyStream.next(true)
    }

    public async loadFutureEventsPage(): Promise<UpdateEventsStorageResult> {
        if (this.store.canLoadFutureEventsStream.value && !this.store.isFutureLoadingStream.value) {
            this.store.isFutureLoadingStream.next(true)

            /**
             * Range to search all loaded events overlapping with future
             */
            const futureOverlapRange: RangeMs = {
                startMs: this.currentTimelineRange.endMs,
                endMs: Math.max.apply(null, this.timelineEvents.map(e => e.endMs)),
            }

            /**
             * Increasing page size if there are events in the future range
             */
            const adjustedPageSize = this.timelineEvents.reduce((size, event) => {
                return getOverlapTypeForRanges(event, futureOverlapRange) > 0 ? size + 1 : size
            }, this.eventsPageSize)

            /**
             * Loading the next page of timeline events
             */
            const result = await this.calendarEventsStorage.getFutureEventsPage(
                new Date(this.currentTimelineRange.endMs), adjustedPageSize,
            )

            if (result.loadedEvents.length < adjustedPageSize) {
                this.store.canLoadFutureEventsStream.next(false)
            }

            /**
             * Updating all days with new data
             */
            this.updateTimelineDaysState(result)

            this.store.isFutureLoadingStream.next(false)

            return result
        }
    }

    public async loadHistoryEventsPage(): Promise<UpdateEventsStorageResult> {
        if (this.store.canLoadHistoryEventsStream.value && !this.store.isHistoryLoadingStream.value) {
            this.store.isHistoryLoadingStream.next(true)

            /**
             * Range to search all loaded events overlapping with history
             */
            const historyOverlapRange: RangeMs = {
                endMs: this.currentTimelineRange.startMs,
                startMs: Math.min.apply(null, this.timelineEvents.map(e => e.startMs)),
            }

            /**
             * Increasing page size if there are events in the history range
             */
            const adjustedPageSize = this.timelineEvents.reduce((size,event) => {
                return getOverlapTypeForRanges(event, historyOverlapRange) > 0 ? size + 1 : size
            }, this.eventsPageSize)

            const result = await this.calendarEventsStorage.getHistoryEventsPage(
                new Date(this.currentTimelineRange.startMs), adjustedPageSize,
            )

            if (result.loadedEvents.length < adjustedPageSize) {
                this.store.canLoadHistoryEventsStream.next(false)
            }

            /**
             * Updating all days with new data
             */
            this.updateTimelineDaysState(result)

            this.store.isHistoryLoadingStream.next(false)

            return result
        }
    }


    public focusOnEvent(eventId: string) {
        const targetEvent = this.timelineEvents.find(e => e.id === eventId)

        /**
         * Temporary solution for lazy initialization docks
         * Should be replaced with preloader in the event tabs section
         */
        if (!targetEvent.dockId) {
            return this.router.navigate(['meet', targetEvent.dockKey])
        }

        this.store.focusedEventIdStream.next(eventId)
    }

    public getFocusedEventId(): string {
        return this.store.focusedEventIdStream.getValue()
    }


    protected prepareInitialDays() {
        const day = m().startOf('day')
        for (let i  = 0; i < 14; i++) {
            const dayTS = day.valueOf()
            if (!this.daysMap.has(dayTS)) {
                this.daysMap.set(dayTS, this.generateEmptyDayData(day))
            }
            day.add(1, 'day')
        }

        /**
         * Update days subject
         */
        this.store.daysStream.next(Array.from(this.daysMap.values()))
    }

    protected generateEmptyDayData(day: m.Moment) {
        return {
            day: day.clone(),
            broadcasts: [],
            plainEvents: [],
            allDayEvents: [],

            /**
             *
             */
            changeToken: `${day.valueOf()}${Date.now()}`,
            dayTimeStamp: day.valueOf(),
        }
    }


    protected updateTimelineDaysState(updates: UpdateEventsStorageResult) {
        /**
         * Create default 14 days cluster
         */
        if (this.store.daysStream.value.length === 0) {
            this.prepareInitialDays()
        }

        if (updates.rangeLoaded) {
            let totalTimelineRange: RangeMs
            if (this.currentTimelineRange) {
                totalTimelineRange = getTotalRangeForOverlappedRanges([
                    this.currentTimelineRange, updates.rangeLoaded,
                ])
            } else {
                totalTimelineRange = updates.rangeLoaded
            }
            this.currentTimelineRange = totalTimelineRange
        }

        /**
         * To push the updates into days
         */
        const updatedDays = new Set<number>()

        /**
         * Remove deleted events from days
         */
        if (updates.removedEvents.length > 0) {
            for (let event of updates.removedEvents) {
                for (let dayTimeStamp of event.eventDaysTs) {
                    if (this.daysMap.has(dayTimeStamp)) {
                        const targetDay = this.daysMap.get(dayTimeStamp)
                        if (!event.allDay) {
                            targetDay.plainEvents = targetDay.plainEvents.filter(
                                dayEvent => dayEvent.id !== event.id
                            )
                        } else {
                            targetDay.allDayEvents = targetDay.allDayEvents.filter(
                                dayEvent => dayEvent.id !== event.id
                            )
                        }
                        updatedDays.add(dayTimeStamp)
                    }
                }
            }
        }


        /**
         * Should remove old versions of updated events
         */
        if (updates.updatedEvents.length > 0) {
            for (let event of updates.updatedEvents) {
                const oldVersionOfEvent = this.timelineEvents.find(
                    oldEvent => oldEvent.id === event.id
                )

                /**
                 * Using previous event day timestamps to handle rescheduling
                 */
                const allDayTimestamps = ArrayHelpers.filterUnique([
                    ...event.eventDaysTs, ...(oldVersionOfEvent ? oldVersionOfEvent.eventDaysTs : []),
                ])

                for (let dayTimeStamp of allDayTimestamps) {
                    if (this.daysMap.has(dayTimeStamp)) {
                        const targetDay = this.daysMap.get(dayTimeStamp)
                        if (!event.allDay) {
                            targetDay.plainEvents = targetDay.plainEvents.filter(
                                dayEvent => dayEvent.id !== event.id
                            )
                        } else {
                            targetDay.allDayEvents = targetDay.allDayEvents.filter(
                                dayEvent => dayEvent.id !== event.id
                            )
                        }
                        updatedDays.add(dayTimeStamp)
                    }
                }
            }
        }


        /**
         * Assigns new versions of events to the days
         */
        const allEventsToAssign = ArrayHelpers.filterUniqueWithCache(
            updates.loadedEvents.concat(updates.updatedEvents), event => event.id,
        )

        if (allEventsToAssign.length > 0) {
            for (let event of allEventsToAssign) {
                for (let dayTs of event.eventDaysTs) {
                    /**
                     * Should add events only into current range days
                     */
                    if (isRangeContainsSingleTimeStamp(this.currentTimelineRange, dayTs)) {
                        let currentDay: TimelineDayData
                        if (!this.daysMap.has(dayTs)) {
                            currentDay = this.generateEmptyDayData(m(dayTs))
                            this.daysMap.set(dayTs, currentDay)
                        } else {
                            currentDay = this.daysMap.get(dayTs)
                        }

                        if (event.allDay) {
                            currentDay.allDayEvents.unshift(event)
                        } else {
                            currentDay.plainEvents.unshift(event)
                        }

                        updatedDays.add(dayTs)
                    }
                }
            }
        }


        const now = Date.now()
        for (let dayTimeStamp of updatedDays.values()) {
            const dayData = this.daysMap.get(dayTimeStamp)

            if (dayData.plainEvents.length > 0) {
                /**
                 * Filtering events to be sure there are no duplicates
                 */
                dayData.plainEvents = ArrayHelpers.filterUnique(
                    dayData.plainEvents, (a, b) => a.id === b.id,
                )

                /**
                 * Sorting events in the day
                 */
                dayData.plainEvents.sort((a, b) => {
                    return a.startMs - b.startMs
                })
            }

            if (dayData.allDayEvents.length > 0) {
                /**
                 * Filtering events to be sure there are no duplicates
                 */
                dayData.allDayEvents = ArrayHelpers.filterUnique(
                    dayData.allDayEvents, (a, b) => a.id === b.id,
                )

                /**
                 * Sorting events in the day
                 */
                dayData.allDayEvents.sort((a, b) => {
                    return a.startMs - b.startMs
                })
            }

            /**
             * Set day as updated
             */
            dayData.changeToken = `${dayTimeStamp}${now}`

            this.store.onDayUpdated.next(dayData)
        }


        /**
         * Refreshing the days subject
         */
        this.store.daysStream.next(
            Array.from(this.daysMap.values()).sort((a, b) => {
                return a.dayTimeStamp - b.dayTimeStamp
            })
        )

        /**
         * Getting the last state of the events displayed on the timeline
         */
        this.timelineEvents = this.calendarEventsStorage.getAllLoadedEvents().filter(
            event => getOverlapTypeForRanges(event, this.currentTimelineRange) > 0,
        )
    }
}

