import {
    CreatedDateField,
    DocRef,
    Entity,
    EntityFilter,
    EntityPromise,
    Field,
    ID,
    Reference,
    UpdatedDateField,
} from '@typeheim/orm-on-fire'

import { Memoize } from '@undock/core/decorators/memoize'
import { FirestoreUser } from '@undock/user/models/firestore.user'
import { StorageFile } from '@undock/core/models/storage-file.model'
import { ShouldInitialize } from '@undock/core/contracts/model/should-initialize.interface'


export enum RecordingStatus {
    Pending = 'Pending',
    Recording = 'Recording',
    Uploading = 'Uploading',
    Succeeded = 'Succeeded',

    Failed = 'Failed',
    Cancelled = 'Cancelled'
}

export class RecordingScope {
    public static failed(filter: EntityFilter<Recording>) {
        return filter.status.containAnyOf([
            RecordingStatus.Failed, RecordingStatus.Cancelled,
        ])
    }

    public static pending(filter: EntityFilter<Recording>) {
        return filter.status.equal(RecordingStatus.Pending)
    }

    public static running(filter: EntityFilter<Recording>) {
        return filter.status.equal(RecordingStatus.Recording)
    }

    public static finished(filter: EntityFilter<Recording>) {
        return filter.status.equal(RecordingStatus.Succeeded)
    }

    public static initialized(filter: EntityFilter<Recording>) {
        return filter.isInitialized.equal(true)
    }
}

export const RECORDING_COLLECTION_NAME = 'recording'

@Entity({
    collection: RECORDING_COLLECTION_NAME,
})
export class Recording implements ShouldInitialize {
    @ID() id: string

    /**
     * Recording owner id
     */
    @Field() ownerId: string

    /**
     * Recording owner Type
     */
    @Field() ownerType: string

    @Field() name: string
    @Field() size: number
    @Field() type: string

    @Field() error: string
    @Field() status: RecordingStatus

    @Field() isInitialized: boolean

    @Field() recordingStartedAt: Date
    @Field() recordingStoppedAt: Date

    @Field() fileId: string
    @DocRef(StorageFile) file: Reference<StorageFile>

    @Field() authorId: string
    @DocRef(FirestoreUser) author: Reference<FirestoreUser>

    @CreatedDateField() createdAt: Date
    @UpdatedDateField() updatedAt: Date

    @Memoize()
    public get fileStream(): EntityPromise<StorageFile> {
        return this.file.get()
    }

    @Memoize()
    public get authorStream(): EntityPromise<FirestoreUser> {
        return this.author.get()
    }
}
