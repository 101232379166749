import { environment } from '@web/environments/environment'

export const GOOGLE_CONTACT_SCOPE = 'https://www.googleapis.com/auth/contacts.readonly'
export const GOOGLE_DIRECTORY_SCOPE = 'https://www.googleapis.com/auth/directory.readonly'

export function initializeGoogleApis() {
    const initializeGapi = () => {
        const gapi = window['gapi']

        if (gapi) {
            gapi.load('client', () => {
                gapi.client.init({
                    apiKey: environment.googleApiKey,
                    clientId: environment.googleClientId,
                    discoveryDocs: [
                        'https://www.googleapis.com/discovery/v1/apis/people/v1/rest',
                    ],
                })
            })

            gapi.load('auth2', function() {
                gapi.auth2.init({
                    client_id: environment.googleClientId,
                    scope: `${GOOGLE_CONTACT_SCOPE} ${GOOGLE_DIRECTORY_SCOPE}`,
                    fetch_basic_profile: true,
                }).then(() => {
                    window.dispatchEvent(new Event('auth2init'))
                })
            })
        } else {
            console.warn('Gapi not found. Google services suspended.')
        }
    }

    const gapiFailed = () => {
        console.warn('Gapi script not loaded. Google services suspended.')
    }

    let gScript = document.createElement('script')
    gScript.type = 'text/javascript'
    gScript.async = true
    gScript.defer = true
    gScript.src = environment.googleApiUrl

    gScript.onload = initializeGapi
    gScript.onerror = gapiFailed

    document.querySelector('head')?.appendChild(gScript)
}

export const initializeGoogleMapsApi = () => {
    const gmScript = document.createElement('script')
    gmScript.async = true
    gmScript.defer = true
    gmScript.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleApiKey}&libraries=places`
    document.body.appendChild(gmScript)
}
