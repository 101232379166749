<section class="edit-attendees-container">
    <div class="heading">
        <app-user-contacts-search *ngIf="!readonly"
                                  [showBorder]="false"
                                  [placeholder]="placeholder"
                                  [disabledEmails]="allAttendeeEmails"
                                  (onSelected)="onContactSelected($event)"
        ></app-user-contacts-search>

        <app-icon-participants></app-icon-participants>
    </div>

    <ng-container *ngFor="let attendee of attendees">
        <div class="attendees-list-item"
             [ngClass]="{
                'status-declined': attendee.status === RsvpStatus.Declined,
                'status-accepted': attendee.status === RsvpStatus.Accepted,
                'status-needs-action': attendee.status  === RsvpStatus.NeedsAction
             }"
        >
            <div class="profile-image-box">
                <img [src]="attendee.userData.imageUrl | imgThumbnail"
                     [alt]="attendee.userData.displayName ?? attendee.email"
                     default="/assets/img/placeholders/user.png"
                     [matTooltip]="attendee.email"
                     [matTooltipPosition]="TooltipPosition.BOTTOM"
                >
            </div>

            <div class="display-name-box">
                <span>
                    {{ attendee.userData.displayName ?? attendee.email ?? attendee.userData.email }}
                    {{ attendee.userData.isRemoved ? '[Inactive]' : '' }}
                </span>
            </div>

            <ng-container *ngIf="!readonly && attendee.canDelete">
                <div class="actions-box">
                    <div class="delete-button" (click)="removeAttendee(attendee)">
                        <i class="material-icons">close</i>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</section>
