<ng-container [ngSwitch]="isImageProcessingStream | stream">
    <ng-container *ngSwitchCase="true">
        <spinner [size]="30" [tickness]="5" [color]="'#2B2733'"></spinner>
    </ng-container>

    <ng-container *ngSwitchCase="false">
        <div class="upload-trigger-box">
            <button class="button" mat-button (click)="fileInput.click()">
                Upload new picture
            </button>

            <input #fileInput type="file" (change)="onFileInputBeenChanged($event)">
        </div>
    </ng-container>
</ng-container>

<app-image-cropper
        [sourceImage]="imageFileStream | async"
        (onImageReady)="onProfileImageReady($event)"
></app-image-cropper>


