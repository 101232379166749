import { Injectable } from '@angular/core'
import { Dock } from '@undock/dock/meet/models/dock.model'
import {
    DockParticipant,
    DockParticipantRole,
    DockParticipantStatus,
} from '@undock/dock/meet/models/dock/dock-participant.model'
import {
    FirestoreUser,
    FirestoreUserCollection,
} from '@undock/user'
import { CurrentUser } from '@undock/session'


@Injectable()
export class DockParticipantFactory {

    public constructor(
        private currentUser: CurrentUser,
    ) {}

    /**
     * Builds a  participant from non-undock user email address
     */
    public async createFromEmail(dock: Dock, email: string): Promise<DockParticipant> {
        let [participant, currentUserProfile] = await Promise.all([
            /**
             * ID for external users should contain a email
             */
            dock.participants.new(`external|${email}`),
            FirestoreUserCollection.one(await this.currentUser.id).get(),
        ])

        participant.email = email
        participant.dockId = dock.id
        participant.isUndockUser = false

        participant.role = DockParticipantRole.Member
        participant.status = DockParticipantStatus.Pending

        participant.userData = {
            email: email,
            imageUrl: '',
            displayName: '',

            isGuest: true,
            isUndockUser: false,
            isRegularUser: false,
        }

        if (currentUserProfile) {
            participant.addedById = currentUserProfile.id
            await participant.addedBy.link(currentUserProfile)
        }

        return participant
    }

    public async createFromUser(dock: Dock, user: FirestoreUser): Promise<DockParticipant> {
        let [participant, currentUserModel] = await Promise.all([
            dock.participants.new(user.id),
            FirestoreUserCollection.one(await this.currentUser.id).get(),
        ])

        participant.userId = user.id
        participant.email = user.email
        participant.isUndockUser = true
        participant.status = DockParticipantStatus.Pending

        participant.userData = {
            isGuest: false, /** @deprecated */
            isUndockUser: true, /** @deprecated */
            isRegularUser: true,

            email: user.email,
            imageUrl: user.imageUrl,
            displayName: user.displayName,
        }

        participant.user.link(user)
        participant.addedBy.link(currentUserModel)

        participant.userId = user.id
        participant.addedById = currentUserModel.id

        /**
         * Document is filled and ready to be real-time received by subscribers
         */
        participant.isInitialized = true

        return participant
    }

}
