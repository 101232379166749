import {
    Component,
    EventEmitter,
} from '@angular/core'


export interface ConfirmPopupConfig {
    title: string,
    description: string,
    confirmButtonLabel: string,
    discardButtonLabel: string,
    highlightConfirmButton: boolean,
    dockStyleMode: string,
    hideDiscardButton?: boolean,
    discardButtonStyle?: 'button' | 'link'
    actionButtonsStyle?: 'horizontal' | 'vertical'
}

export class AbstractConfirmPopup {

    public onClosed = new EventEmitter<boolean>()

    public config: ConfirmPopupConfig = {
        title: '',
        description: '',
        confirmButtonLabel: 'Confirm',
        discardButtonLabel: 'Cancel',
        highlightConfirmButton: true,
        dockStyleMode: '',
        discardButtonStyle: 'button',
        actionButtonsStyle: 'horizontal',
    }

    public confirm() {
        this.onClosed.emit(true)
    }

    public discard() {
        this.onClosed.emit(false)
    }

    public applyConfig(config: Partial<ConfirmPopupConfig>) {
        for (let key in config) {
            if (config.hasOwnProperty(key)) {
                this.config[key] = config[key]
            }
        }
    }
}

@Component({
    selector: 'app-confirm-popup',
    templateUrl: 'confirm-popup.component.html',
    styleUrls: ['confirm-popup.component.scss'],
})
export class ConfirmPopupComponent extends AbstractConfirmPopup {}

