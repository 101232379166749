import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    NgZone,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core'

import { default as m, Moment } from 'moment'

import {
    DestroyEvent,
    EmitOnDestroy,
    StatefulSubject,
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'
import {
    debounceTime,
    distinctUntilChanged,
    takeUntil,
} from 'rxjs/operators'
import { combineLatest } from 'rxjs'

import datepicker from '@web/assets/scripts/datepicker'
import { InputAutoresizeDirective } from '@undock/common/ui-kit/ui/directives'


export interface DatePicker {

    el: HTMLElement,

    dateSelected: Date

    setMax(date: Date): void

    setMin(date: Date): void

    setDate(date: Date, moveToDate?: boolean): void

    navigate(date: Date, moveToDate?: boolean): void

    show(): void

    hide(): void

    remove(): void

    minDate?: Date,
    maxDate?: Date,
    allowedDates?: Date[]|string[]
    disabledDates?: Date[]|string[]
}

export type DatePickerPosition = 'tr' | 'tl' | 'br' | 'bl' | 'c' | 'inline'


@Component({
    selector: 'app-date-picker',
    templateUrl: 'date-picker.component.html',
    styleUrls: ['date-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent implements OnInit, AfterViewInit {

    @Output() readonly onDateSelected = new EventEmitter<Date>()
    @Output() readonly onOpenStateChange = new EventEmitter<boolean>()

    @Input() tabIndex: number
    @Input() disabled: boolean = false
    @Input() position: DatePickerPosition = 'bl'
    @Input() alwaysShow: boolean = false
    @Input() showAsModal: boolean = false
    @Input() showInvalidDateLabels: boolean = false
    @Input() displayDateTpl: TemplateRef<HTMLDivElement>

    private _date: Moment = m()
    private _minDate: Moment = m()
    private _maxDate: Moment = null
    private _dateFormat = 'ddd, MMM D'

    @CompleteOnDestroy()
    private readonly datePickerSubject = new ValueSubject<DatePicker>(null)

    @CompleteOnDestroy()
    public readonly isDatePickerShowingSubject = new ValueSubject<boolean>(false)

    @ViewChild('daySelectorInput')
    private readonly daySelectorInputElementRef: ElementRef<HTMLInputElement>

    @ViewChild(InputAutoresizeDirective)
    private readonly daySelectorAutoResizeDirective: InputAutoresizeDirective

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    @CompleteOnDestroy()
    private changeSize = new StatefulSubject()

    @HostListener('window:resize')
    public onResize() {
        this.changeSize.next(window?.innerWidth)
    }

    public constructor(
        private zone: NgZone,
    ) {}

    private _allowedDates: string[] | null = null
    @Input() set availableDates(value: (Moment | Date | string)[] ) {
        this._allowedDates = Array.isArray(value) ? value.map( item => this.toStringDate(item) ) : null;
    }

    // Temporarily disabled until it works properly
    private get allowedDates(): string[] {
        return
        // return Array.isArray(this._allowedDates) ? this._allowedDates : null
    }

    @Input() set date(value: Moment | Date | string) {
        if (value) {
            this.setDate(value)
            // To prevent issues with wrong date input size
            if (this.daySelectorAutoResizeDirective) {
                this.daySelectorAutoResizeDirective.resize()
            }
        }
    }

    @Input() set minDate(value: Moment | Date | string) {
        this._minDate = this.ensureMoment(value, true)
        this.datePickerSubject.then(dp => {
            if (dp) {
                dp.setMin(this._minDate.toDate())
            }
        })
    }

    @Input() set maxDate(value: Moment | Date | string) {
        this._maxDate = this.ensureMoment(value, true)
        this.datePickerSubject.then(dp => {
            if (dp) {
                dp.setMax(this._maxDate.toDate())
            }
        })
    }

    @Input() set dateFormat(value: string) {
        this._dateFormat = value
        this.datePickerSubject.then(dp => {
            if (dp) {
                dp && dp.setDate(dp.dateSelected)
            }
        })
    }


    public ngOnInit() {
        if (this.isMobile) {
            this.showAsModal = true
        }
        this.onResize()

        this.datePickerSubject.subscribe((picker) => {
            try {
                /**
                 * Set date to picker if exists
                 */
                if (m.isDate(this._date)) {
                    picker && picker.setDate(this._date.toDate(), true)
                }
            } catch (err) {
                /**
                 * If the error arises from browsing to a date before the picker's min date, show the month label for this date anyway
                 * NOTE: Date will not actually be selected in the picker
                 */
                if (
                    err?.message?.includes('You can\'t manually set a date that\'s disabled') &&
                    this._date.isBefore(m().startOf('day')) &&
                    this.showInvalidDateLabels
                ) {
                    this.daySelectorInputElementRef.nativeElement.value = m(this._date).format(this._dateFormat)
                } else {
                    throw err
                }
            }
        })

        this.isDatePickerShowingSubject.pipe(
            takeUntil(this.destroyedEvent),
        ).subscribe(value => this.onOpenStateChange.emit(value))
    }

    private get isMobile() {
        return window?.innerWidth <= 768
    }

    private rebuildDatePicker() {
        const dp = this.datePickerSubject.getValue()
        if (dp) {
            dp.remove()
        }
        this.datePickerSubject.next(this.buildDatePicker())
    }

    public ngAfterViewInit() {
        this.rebuildDatePicker()
        this.changeSize.next()
        combineLatest([
            this.datePickerSubject,
            this.changeSize.pipe(
                distinctUntilChanged((prev, next) => prev === next),
                debounceTime(250),
                takeUntil(this.destroyedEvent),
            ),
        ])
            .pipe(
                takeUntil(this.destroyedEvent),
            )
            .subscribe(([dp, innerWidth]) => {
                if (this.isMobile !== this.showAsModal) {
                    if (dp) {
                        dp.hide()
                    } else {
                        this.rebuildDatePicker()
                    }
                }
            })
    }

    public async show(): Promise<void> {
        if (this.isMobile) {
            this.daySelectorInputElementRef.nativeElement.focus()
        } else {
            let picker = await this.datePickerSubject
            if (picker) {
                picker.show()
            }
        }
    }

    public async hide(): Promise<void> {
        if (!this.alwaysShow) {
            let picker = await this.datePickerSubject
            if (picker) {
                picker.hide()
            }
        }
    }

    protected buildDatePicker(): DatePicker {
        /**
         * Builds the date-picker outside of Zone to prevent performance issues
         */
        return this.zone.runOutsideAngular(() => {
            const dateInputElement = this.daySelectorInputElementRef?.nativeElement
            if (dateInputElement) {
                let current = this._date && this.ensureMoment(this._date).toDate() || new Date(),
                    minDate = this._minDate ? this._minDate.clone().startOf('day').toDate() : null,
                    maxDate = this._maxDate ? this._maxDate.clone().endOf('day').toDate() : null

                const modalMode = this.isMobile || this.showAsModal
                return datepicker(dateInputElement, {
                    onSelect: (instance, date) => {
                        if (date) {
                            this.onDateSelected.emit(date)
                        } else {
                            /**
                             * Should select previous date is empty
                             */
                            this.date = this._date
                        }
                    },
                    onShow: () => {
                        this.isDatePickerShowingSubject.next(true)
                    },
                    onHide: (picker: DatePicker) => {
                        this.isDatePickerShowingSubject.next(false)
                        picker.navigate(picker.dateSelected)
                    },
                    formatter: (input, date, instance) => {
                        input.value = m(date).format(this._dateFormat)
                    },
                    customDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                    minDate: minDate,
                    maxDate: maxDate,
                    dateSelected: current,
                    disableYearOverlay: true,
                    position: modalMode ? 'c' : this.position,
                    noWeekends: false,
                    showOverlay: modalMode,
                    alwaysShow: this.alwaysShow,
                    disabler: (date) => {
                        if (date && this.allowedDates) {
                            return !this.allowedDates.includes(this.toStringDate(date))
                        }
                    }
                })
            }
        })
    }

    protected setDate(
        value: Moment | Date | string,
        allowEmpty: boolean = false,
    ) {
        this._date = this.ensureMoment(value, allowEmpty)
        const dp = this.datePickerSubject.getValue()
        if (dp) {
            const date = this._date.toDate()
            try {
                dp.setDate(date)
                dp.navigate(date)
            } catch (e) {
                // here may be error of selecting invalid date or disabled date
            }
        }
    }

    protected ensureMoment(
        value: Moment | Date | string, allowEmpty: boolean = false,
    ): Moment {
        if (allowEmpty) {
            return value ? m.isMoment(value) ? value : m(value) : null
        }
        return m.isMoment(value) ? value : m(value)
    }

    protected toStringDate( value: Moment | Date | string): string {
        return this.ensureMoment(value).format('YYYY-MM-DD')
    }
}
