import { Injectable } from '@angular/core'

import { Topic } from '@undock/dock/meet/models/topic.model'
import { CurrentUser } from '@undock/session'
import { Dock } from '@undock/dock/meet/models/dock.model'
import { AbstractFactory } from '@undock/core/models/factories/abstract.factory'
import { Model } from '@undock/core'


@Injectable()
export class TopicFactory extends AbstractFactory<Topic> {

    protected defaultProperties: Partial<Topic> = {
        text: '',
        removed: false,
        position: 99999,
        complete: false,
    }

    public constructor(
        private currentUser: CurrentUser,
    ) { super() }

    public async create(model: Model, properties: Partial<Topic>): Promise<Topic> {
        let topic = new Topic()

        this.assignDefaultData(topic, properties)

        topic.authorData = await this.currentUser.publicData

        if (!this.currentUser.isAnonymous) {
            /**
             * For regular users
             */
            let author = await this.currentUser.firestoreProfile

            topic.author.link(author)
            topic.authorId = author.id
        } else {
            /**
             * For anonymous users
             * @TODO: Do we should write guest author id to the entity?
             */
            topic.authorId = await this.currentUser.id
        }

        topic.dock.link(model)
        topic.dockId = model.id

        topic.isInitialized = true

        return topic
    }
}
