import {
    Observable,
    Subject,
} from 'rxjs'
import {
    StatefulSubject,
    ValueSubject,
} from '@typeheim/fire-rx'
import {
    StreamStore as NativeStreamStore
} from '@typeheim/fluent-states'
import { State } from '@typeheim/fluent-states/lib/contracts'

export class StreamStoreV2 extends NativeStreamStore {
    /**
     * @override
     */
    public override getState(): State<this> {
        return storeToState(this)
    }
}

/**
 * Converts store to readonly state
 */
function storeToState<T extends NativeStreamStore>(store: T): State<T> {
    let state = {} as State<T>
    for (const [key, value] of Object.entries(store)) {
        if (value instanceof StatefulSubject || value instanceof ValueSubject) {
            state[key] = value.asStream()
        }
        else if (value instanceof Subject) {
            state[key] = value.asObservable()
        }
        else if (value instanceof Observable) {
            state[key] = value
        }
    }

    /**
     * Going through all inherited methods
     */
    const prototype = Object.getPrototypeOf(store)
    if (prototype) {
        const inherited = Object.getOwnPropertyDescriptors(prototype)
        for (let [key, descriptor] of Object.entries(inherited)) {
            /**
             * Binds store getters to the state
             */
            if (typeof descriptor['get'] === 'function') {
                Object.defineProperty(state, key,{
                    get: descriptor['get'],
                    enumerable: false, configurable: true,
                })
            }
        }
    }

    return state
}
