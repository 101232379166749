<div class="available-time -container">
    <ng-container *ngIf="isSelectedDayHasAvailabilitySlotsStream | stream else noSlotsForSelectedDayTmpl">

        <div class="available-time__time-box">
            <ng-container *ngIf="isMorningSlotsAvailableStream | stream">
                <span class="day-time-label">MORNING</span>
                <div class="available-time__enabled-time-wrapper">
                    <app-time-availability-slot-view
                            *ngFor="let slot of morningAvailabilitySlotsStream | stream"
                            [slot]="slot" [selected]="isAvailableSlotSelected(slot)"
                            (click)="selectSlot(slot)" @insertSlotTrigger
                    ></app-time-availability-slot-view>
                </div>
            </ng-container>

            <div class="available-time__time-box -afternoon">
                <ng-container *ngIf="isAfternoonSlotsAvailableStream | stream">
                    <span class="day-time-label">AFTERNOON</span>
                    <div class="available-time__enabled-time-wrapper">
                        <app-time-availability-slot-view
                                *ngFor="let slot of afternoonAvailabilitySlotsStream | stream"
                                [slot]="slot" [selected]="isAvailableSlotSelected(slot)"
                                (click)="selectSlot(slot)" @insertSlotTrigger
                        ></app-time-availability-slot-view>
                    </div>
                </ng-container>

                <div class="available-time__time-box -evening">
                    <ng-container *ngIf="isEveningSlotsAvailableStream | stream">
                        <span class="day-time-label">EVENING</span>
                        <div class="available-time__enabled-time-wrapper">
                            <app-time-availability-slot-view
                                    *ngFor="let slot of eveningAvailabilitySlotsStream | stream"

                                    [slot]="slot"
                                    [selected]="isAvailableSlotSelected(slot)"
                                    (click)="selectSlot(slot)" @insertSlotTrigger
                            ></app-time-availability-slot-view>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-template #noSlotsForSelectedDayTmpl>
        <div class="available-time__time-box -empty">
            <app-icon-large-calendar-no-time class="available-time__no-free-time" [width]="'140'" [height]="'140'"></app-icon-large-calendar-no-time>
            <span style="margin-bottom: 20px; color: #2B2733;">We could't find a match on this day.</span>
        </div>
    </ng-template>

    <ng-container *ngIf="isAvailabilityLoadingStream | stream">
        <div class="availability-is-loading-overlay">
            <app-loading-overlay></app-loading-overlay>
        </div>
    </ng-container>
</div>
