import {
    Inject,
    Injectable,
} from '@angular/core'

import * as m from 'moment'

import {
    combineLatest,
    Observable,
    Subject,
} from 'rxjs'
import {
    debounceTime,
    distinctUntilChanged,
    map,
    shareReplay,
    takeUntil,
} from 'rxjs/operators'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    StatefulSubject,
    ValueSubject,
} from '@typeheim/fire-rx'

import {
    ArrayHelpers,
    Memoize,
    Moment,
} from '@undock/core'
import {
    CalendarEvent,
    CalendarEventTitleFormatter,
    CalendarView,
} from 'angular-calendar'

import {
    endOfDay,
    endOfMonth,
    endOfWeek,
    startOfDay,
    startOfMonth,
    startOfWeek,
} from 'date-fns'

import {
    BreakpointObserver,
    BreakpointState,
} from '@angular/cdk/layout'
import { GridDataSource } from '@undock/common/calendar-grid/contracts/grid-data-source'
import { CalendarSettingsStateModel } from '@undock/timeline/states/calendar.settings.state'
import { CalendarGridEvent } from '@undock/timeline/services/timeline-events.manager'
import { TimelineEventConfig } from '@undock/api/scopes/time/contracts/timeline-event'
import {
    DateRange,
    TimezoneData,
} from '@undock/time/availability'
import { applyEmulatedTimeZone } from '@undock/dock/meet/helpers/emulate-tz'

export type ViewportBreakPoint = { breakpoint: string, daysInWeek: number }
export type ViewportBreakPointMap = Record<string, ViewportBreakPoint>
export type CalendaringGridState = {
    view: string
    viewDate: Date
    viewDateEnd: Date
    daysInWeek: number
    weekStartsOn: number
    excludeDays: number[]
    hourSegments: number
    duration: number
    hideWeekends: boolean
    dayStartHour: number
    dayEndHour: number
    dayStartMinute: number
    dayEndMinute: number
}
export type CalendaringGridConfig = Record<string, TimelineEventConfig>

export type GridScrollPosition = { time: Date | m.Moment, scrollTo: ScrollLogicalPosition }

/**
 * Remove tooltip on temporary event
 */
@Injectable()
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
    weekTooltip(event: CalendarEvent, title: string) {
        if (!event.meta.tmpEvent) {
            return super.weekTooltip(event, title)
        }
    }

    dayTooltip(event: CalendarEvent, title: string) {
        if (!event.meta.tmpEvent) {
            return super.dayTooltip(event, title)
        }
    }
}

/**
 * TODO: Rename to `CalendarGridViewModel`
 */
@Injectable()
export class CalendaringGridViewModel<T extends GridDataSource = GridDataSource> {

    public DEFAULT_VIEW: any = CalendarView.Day

    public DEFAULT_EVENT_TYPE: string = 'new'

    public NEW_EVENT_CONFIG: CalendarEvent = {
        id: '',
        title: 'New event',
        start: new Date(),
        end: new Date(),
        meta: {
            tmpEvent: true,
        },
        draggable: true,
        resizable: {
            afterEnd: true,
            beforeStart: true,
        },
    }

    public readonly useCalendarSettings: boolean = true

    public readonly CALENDAR_RESPONSIVE: ViewportBreakPointMap = {
        small: {
            breakpoint: '(max-width: 480px)',
            daysInWeek: 1,
        },
        medium: {
            breakpoint: '(max-width: 768px)',
            daysInWeek: 3,
        },
        large: {
            breakpoint: '(max-width: 1024px)',
            daysInWeek: 5,
        },
    }

    @CompleteOnDestroy()
    public readonly filters$ = new ValueSubject<any>(undefined)

    @CompleteOnDestroy()
    public readonly view$ = new ValueSubject<string>(this.DEFAULT_VIEW)

    @CompleteOnDestroy()
    public readonly viewDate$ = new ValueSubject<Date>(new Date())

    @CompleteOnDestroy()
    public readonly viewDateEnd$ = new ValueSubject<Date>(new Date())

    @CompleteOnDestroy()
    public readonly hideWeekends$ = new ValueSubject<boolean>(true)

    @CompleteOnDestroy()
    public readonly daysInWeek$ = new ValueSubject<number>(7)

    @CompleteOnDestroy()
    public readonly weekStartsOn$ = new ValueSubject<number>(undefined)

    @CompleteOnDestroy()
    public readonly excludeDays$ = new ValueSubject<number[]>([])

    @CompleteOnDestroy()
    public readonly hourSegmentDuration$ = new ValueSubject<number>(15)

    @CompleteOnDestroy()
    public readonly hourSegments$ = new ValueSubject<number>(4)

    // The day start/end hours in 24 hour time. Must be 0-23
    @CompleteOnDestroy()
    public readonly dayStartHour$ = new ValueSubject<number>(0)

    @CompleteOnDestroy()
    public readonly dayEndHour$ = new ValueSubject<number>(23)

    //The day start/end minutes. Must be 0-59
    @CompleteOnDestroy()
    public readonly dayStartMinute$ = new ValueSubject<number>(0)

    @CompleteOnDestroy()
    public readonly dayEndMinute$ = new ValueSubject<number>(59)

    @CompleteOnDestroy()
    public readonly refresh$ = new Subject<void>()

    @CompleteOnDestroy()
    public readonly calendarsConfig$ = new ValueSubject<CalendaringGridConfig>({})

    @CompleteOnDestroy()
    public readonly calendarEvents$ = new ValueSubject<CalendarEvent[]>([])

    @CompleteOnDestroy()
    public readonly overriddenEvents$ = new ValueSubject<CalendarEvent[]>([])

    // TODO: Rename to customEvents
    @CompleteOnDestroy()
    public readonly temporaryEvents$ = new ValueSubject<CalendarEvent[]>([])

    @CompleteOnDestroy()
    public readonly setGridTime$ = new StatefulSubject<GridScrollPosition>()

    @CompleteOnDestroy()
    public readonly emulatedTimeZone$ = new ValueSubject<TimezoneData>(null)

    /**
     * Event emitters
     */
    @CompleteOnDestroy()
    public readonly onEventDropped = new Subject<any>()

    @CompleteOnDestroy()
    public readonly onEventDeleted = new Subject<any>()

    @CompleteOnDestroy()
    public readonly onEventClicked = new Subject<any>()

    @CompleteOnDestroy()
    public readonly onEventCreated = new Subject<any>()

    @CompleteOnDestroy()
    public readonly onWeekHeaderDayClicked = new Subject<any>()

    @CompleteOnDestroy()
    public readonly onWeekHeaderDayDoubleClicked = new Subject<any>()

    @CompleteOnDestroy()
    public readonly onMonthCellDoubleClicked = new Subject<any>()

    @CompleteOnDestroy()
    public readonly onWeekHourSegmentClicked = new Subject<any>()

    @CompleteOnDestroy()
    public readonly onWeekHourSegmentDoubleClicked = new Subject<any>()

    @CompleteOnDestroy()
    public readonly afterViewReRendered = new Subject<void>()


    @EmitOnDestroy()
    protected readonly destroyEvent = new DestroyEvent()


    public constructor(
        @Inject(GridDataSource) protected gridDataSource: T,
        protected breakpointObserver: BreakpointObserver,
        protected calendarSettingsStateModel?: CalendarSettingsStateModel,
    ) {}

    public initViewModel(): void {
        if (this.calendarSettingsStateModel) {
            this.calendarSettingsStateModel.state.settingsStream.pipe(
                takeUntil(this.destroyEvent),
            ).subscribe(async config => {
                this.hideWeekends$.next(!config.showWeekends)

                const result = {};
                (config.calendars || []).forEach(cal => {
                    result[cal.id] = {
                        icon: cal.icon,
                        color: cal.color,
                        display: cal.display,
                    };
                    (cal.subCalendars || []).forEach(subCal => {
                        result[subCal.id] = {
                            icon: subCal.icon,
                            color: subCal.color,
                            display: subCal.display,
                        }
                    })
                })
                this.calendarsConfig$.next(result)

                this.filters$.next(await this.filters$)
            })
        }

        this.gridDataSource.displayedEvents$.subscribe(
            events => this.calendarEvents$.next(events),
        )

        combineLatest([this.view$, this.viewDate$]).pipe(
            distinctUntilChanged(),
            debounceTime(100),
            takeUntil(this.destroyEvent),
        ).subscribe(([view, viewDate]) => {
            if (view && viewDate) {
                this.fetchEvents().catch(console.error)
            }
        })

        this.viewportBreakPoint.subscribe(foundBreakpoint => {
            this.daysInWeek$.next(foundBreakpoint?.daysInWeek)
        })

        this.hideWeekends$.subscribe(hide => {
            this.excludeDays$.next(hide ? [0, 6] : [])
        })

        this.daysInWeek$.subscribe(count => {
            this.weekStartsOn$.next(count >= 5 ? 1 : Moment().day())
        })

        let tmpIndex = 0
        this.onEventCreated.subscribe(event => {
            if (!event) {
                return
            }
            event.id = `tmp-event-${++tmpIndex}`
            event.meta = { type: this.DEFAULT_EVENT_TYPE }
            this.temporaryEvents$.next([...this.temporaryEvents$.getValue(), { ...event }])
        })

        this.onEventDeleted.subscribe(event => {
            if (event) {
                this.temporaryEvents$.next(this.temporaryEvents$.getValue().filter(({ id }) => event?.id !== id))
            }
        })

        /***
         * TODO: Check this code
         */
        this.onEventDropped.subscribe($event => {
            const { event } = $event || {}
            if (event) {
                let events = this.temporaryEvents$.getValue()
                let item = events.find(item => item.id === event.id)
                if (item) {
                    item = { ...event }
                    this.temporaryEvents$.next(events)
                }
            }
        })
    }

    @Memoize()
    public get viewportBreakPoint(): Observable<ViewportBreakPoint> {
        return this.breakpointObserver
                   .observe(Object.values(this.CALENDAR_RESPONSIVE).map(({ breakpoint }) => breakpoint))
                   .pipe(
                       map((state: BreakpointState) => {
                           return Object.values(this.CALENDAR_RESPONSIVE).find(
                               ({ breakpoint }) => !!state.breakpoints[breakpoint],
                           )
                       }),
                       distinctUntilChanged(),
                       takeUntil(this.destroyEvent),
                   )
    }

    @Memoize()
    public get isGridReady$(): Observable<boolean> {
        return combineLatest([
            this.gridState,
            this.calendarSettingsStateModel.state.settingsStream,
        ]).pipe(
            debounceTime(10),
            map(([state, settings]) => Boolean(state && settings)),
            distinctUntilChanged(),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get gridState(): Observable<CalendaringGridState> {
        // let iteration = 0
        return combineLatest([
            this.view$,
            this.viewDate$,
            this.viewDateEnd$,
            this.daysInWeek$,
            this.hideWeekends$,
            this.excludeDays$,
            this.hourSegments$,
            this.hourSegmentDuration$,
            this.weekStartsOn$,
            this.dayStartHour$,
            this.dayEndHour$,
            this.dayStartMinute$,
            this.dayEndMinute$,
        ])
            .pipe(
                // debounce( () => iteration < 15 ? interval(1) : interval(100)),
                debounceTime(10),
                map(
                    ([
                         view,
                         viewDate,
                         viewDateEnd,
                         daysInWeek,
                         hideWeekends,
                         excludeDays,
                         hourSegments,
                         duration,
                         weekStartsOn,
                         dayStartHour,
                         dayEndHour,
                         dayStartMinute,
                         dayEndMinute,
                     ]) => ({
                        view,
                        viewDate,
                        viewDateEnd,
                        daysInWeek,
                        hideWeekends,
                        excludeDays,
                        hourSegments,
                        duration,
                        weekStartsOn,
                        dayStartHour,
                        dayEndHour,
                        dayStartMinute,
                        dayEndMinute,
                    }),
                ),
                distinctUntilChanged(),
            )
    }

    @Memoize()
    public get viewRange$(): ReactiveStream<DateRange> {
        return new ReactiveStream(
            combineLatest([this.view$, this.viewDate$]).pipe(
                takeUntil(this.destroyEvent),
                map(([view, viewDate]) => {
                    if (view && viewDate) {
                        const getStart: any = {
                            timeline: () => null,
                            month: startOfMonth,
                            week: startOfWeek,
                            day: startOfDay,
                        }[view]
                        const getEnd: any = {
                            timeline: () => null,
                            month: endOfMonth,
                            week: endOfWeek,
                            day: endOfDay,
                        }[view]

                        if (getStart && getEnd) {
                            const start = getStart(viewDate)
                            const end = getEnd(viewDate)
                            return { start, end }
                        } else {
                            throw new Error(`View is not supported`)
                        }
                    }
                }),
            )
        )
    }

    public async fetchEvents() {
        const viewRange = await this.viewRange$
        if (viewRange) {
            return this.gridDataSource
                       .fetch(viewRange.start, viewRange.end)
        }
        return []
    }

    @Memoize()
    public get externalEvents(): Observable<any[]> {
        return combineLatest([
            this.calendarEvents$,
            this.filters$,
            this.calendarsConfig$,
        ]).pipe(
            map(([external, filters, calendarConfigMapping]) => {
                external = this.applySettingsToEvents(external, calendarConfigMapping)
                const filtersCallback = (eventItem) => {
                    let conditions = Boolean(eventItem)
                    const eventCfg = eventItem?.meta?.payload?.calendarConfig
                    if (eventCfg) {
                        const { display, color, icon } = eventCfg
                        conditions = conditions && display
                        eventItem.meta.color = color
                        eventItem.meta.icon = icon
                    }
                    if (filters) {
                        if (filters.title) {
                            const filterValue = filters.title.toUpperCase()
                            const { title = '' } = eventItem
                            conditions = conditions && title.toUpperCase().indexOf(filterValue) > -1
                        }
                    }
                    return conditions
                }
                return external.filter(filtersCallback)
            }),
        )
    }

    @Memoize()
    public get events(): Observable<CalendarEvent[]> {
        return combineLatest([
            this.externalEvents,
            this.temporaryEvents$,
            this.overriddenEvents$,
            this.emulatedTimeZone$,
        ]).pipe(
            debounceTime(100),
            map(([
                external, temporary, overridden, emulatedTz,
            ]) => {
                const allEvents = !overridden?.length
                    ? [...external, ...temporary]
                    // Replace events with overridden item
                    : ArrayHelpers.filterUniqueWithCache([
                        ...overridden, ...temporary, ...external,
                    ], e => e.id)

                return !emulatedTz
                    ? allEvents : allEvents.map(event => {
                        return {
                            ...event,
                            end: applyEmulatedTimeZone(event.end, emulatedTz.zone).toDate(),
                            start: applyEmulatedTimeZone(event.start, emulatedTz.zone).toDate(),
                        }
                    })
            }),
        )
    }

    public applySettingsToEvents(events, config?): CalendarGridEvent[] {
        if (!events || !this.useCalendarSettings || !config) {
            return events
        }
        return events.map(event => {
            const calendarId = event.meta?.payload?.calendarId
            if (calendarId) {
                event.meta.payload.calendarConfig = config[calendarId]
            }
            return event
        })
    }
}
