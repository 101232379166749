import { Injectable } from '@angular/core'

import {
    map,
    distinctUntilChanged,
    shareReplay,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators'
import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
} from '@typeheim/fire-rx'
import {
    combineLatest,
    of,
} from 'rxjs'

import {
    FirestoreUser,
    ProfilesProvider,
} from '@undock/user'
import {
    Connection,
    ConnectionScope,
    ConnectionCollection,
} from '@undock/people'
import { Memoize } from '@undock/core'
import { CurrentUser } from '@undock/session'


@Injectable({
    providedIn: 'root',
})
export class ConnectionsFacade {

    public readonly connectionUIDDelimiter = '|'

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    public constructor(
        protected user: CurrentUser,
        protected userProvider: ProfilesProvider,
    ) {}

    @Memoize()
    public get connectionsStream(): ReactiveStream<Connection[]> {
        return new ReactiveStream<Connection[]>(
            this.user.dataStream.pipe(
                distinctUntilChanged(
                    (prev, next) => prev.firebaseId === next.firebaseId,
                ),

                switchMap(user => {
                    return ConnectionCollection.filter(
                        ConnectionScope.ownConnections(user.firebaseId),
                    ).orderBy(
                        'meetingsCount', 1,
                    ).stream()
                    .emitUntil(this.destroyedEvent)
                }),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get uiConnectionsStream(): ReactiveStream<UiConnectionData[]> {
        return new ReactiveStream<UiConnectionData[]>(
            combineLatest([
                this.user.uidStream,
                this.connectionsStream,
            ]).pipe(
                switchMap(([userUId, connections]) => {

                    if (connections?.length) {
                        return combineLatest(
                            connections.map(connection => {
                                return this.userProvider.getProfileByUid(
                                    connection.getConnectedUid(userUId)
                                ).pipe(
                                    map(profile => ({
                                        profile: profile,
                                        createdAt: connection.createdAt,
                                        lastMetDate: connection.lastMetDate,
                                        connectionType: connection.connectionType,
                                    }))
                                )
                            })
                        )
                    }
                    return of([])
                }),

                /**
                 * Remove broken connections and sort by `lastMetDate`
                 */
                map(connections => {
                    return connections.filter(connection => connection.profile)
                                      .sort((a, b) => b.lastMetDate?.valueOf() - a.lastMetDate?.valueOf())
                }),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            )
        )
    }

    public generateConnectionUID(userFirebaseIds: string[]): string {
        return userFirebaseIds.sort().join(this.connectionUIDDelimiter)
    }
}

export interface UiConnectionData {
    profile: FirestoreUser
    createdAt: Date
    lastMetDate: Date
    connectionType: ConnectionType
}
