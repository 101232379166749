import {
    Input,
    Component,
    ChangeDetectionStrategy,
    TemplateRef,
    HostBinding,
    Output,
    EventEmitter,
} from '@angular/core'

import { UiTimelineEvent } from '@undock/dashboard/contracts'
import { RsvpStatus } from '@undock/api/scopes/calendar/contracts'
import { CalendarEventsFacade } from '@undock/calendar/services/calendar-events.facade'


@Component({
    selector: 'app-dashboard-plain-event-options',
    templateUrl: 'dashboard-plain-event-options.component.html',
    styleUrls: ['dashboard-plain-event-options.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardPlainEventOptionsComponent {

    public readonly RsvpStatus = RsvpStatus

    @Input() hideRsvp: boolean = false
    @Input() hideReschedule: boolean = false
    @Input() iconTemplate: TemplateRef<any>

    @Output() onAction = new EventEmitter<
        'rsvp::no' | 'rsvp::yes' | 'rsvp::maybe' | 'delete' | 'duplicate' | 'reschedule'
    >()

    @HostBinding('class.disabled')
    public isDisabled: boolean
    public canUseRsvpNo: boolean
    public canChangeRsvp: boolean
    public currentRsvpStatus: RsvpStatus
    public isDuplicateAllowed: boolean
    public isRescheduleAllowed: boolean

    private _event: UiTimelineEvent

    public constructor(
        private calendarEventsFacade: CalendarEventsFacade,
    ) {}

    public get event(): UiTimelineEvent {
        return this._event
    }

    @Input()
    public set event(event: UiTimelineEvent) {
        this._event = event

        /**
         * Updating all statuses
         */
        this.isDisabled = !event.editingAllowed
        this.canUseRsvpNo = event.state.canUseRsvpNo
        this.canChangeRsvp = event.state.canChangeRsvp
        this.currentRsvpStatus = event.state.currentRsvpStatus
        this.isDuplicateAllowed = event.state.isDuplicateAllowed
        this.isRescheduleAllowed = event.state.isRescheduleAllowed
    }

    public rsvpNo() {
        this.onAction.emit('rsvp::no')
        return this.calendarEventsFacade
                   .setRsvpToEvent(this.event, RsvpStatus.Declined)
    }

    public rsvpYes() {
        this.onAction.emit('rsvp::yes')
        return this.calendarEventsFacade
                   .setRsvpToEvent(this.event, RsvpStatus.Accepted)
    }

    public rsvpMaybe() {
        this.onAction.emit('rsvp::maybe')
        return this.calendarEventsFacade
                   .setRsvpToEvent(this.event, RsvpStatus.Tentative)
    }

    public deleteEvent() {
        this.onAction.emit('delete')
        return this.calendarEventsFacade.deleteEvent(this.event)
    }

    public duplicateEvent() {
        this.onAction.emit('duplicate')
        return this.calendarEventsFacade.duplicateEvent(this.event)
    }

    public rescheduleEvent() {
        this.onAction.emit('reschedule')
        return this.calendarEventsFacade.rescheduleEvent(this.event)
    }
}
