import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    Optional,
} from '@angular/core'
import {
    from,
    Observable,
} from 'rxjs'
import { IOrganization } from '@undock/api/scopes/organizations/contracts'
import { TooltipPosition } from '@undock/common/ui-kit/contracts/tooltip.position'
import { OrganizationsProvider } from '@undock/organizations/services/organizations.provider'


@Component({
    selector: 'app-sidebar-organizations',
    templateUrl: 'sidebar-organizations.component.html',
    styleUrls: ['sidebar-organizations.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarOrganizationsComponent {

    public readonly TooltipPosition = TooltipPosition
    public readonly organizations$: Observable<IOrganization[]>

    @Input()
    @HostBinding('class.collapsed')
    public collapsed: boolean

    public constructor(
        @Optional()
        private organizationsProvider?: OrganizationsProvider,
    ) {
        this.organizations$ = this.organizationsProvider
            ? this.organizationsProvider.own$ : from(Promise.all([]))
    }
}
