import {
    Component,
    ElementRef,
    TrackByFunction,
    ViewChild,
} from '@angular/core'

import {
    combineLatest,
    Observable,
} from 'rxjs'
import {
    debounceTime,
    map,
    shareReplay,
    startWith,
    switchMap,
    takeUntil,
    tap,
    withLatestFrom,
} from 'rxjs/operators'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'

import {
    Memoize,
    Validations,
} from '@undock/core'
import {
    FirestoreUser,
    PublicProfileData,
    ProfilesProvider,
} from '@undock/user'
import { Api } from '@undock/api'
import { CurrentUser } from '@undock/session'
import { DockFacade } from '@undock/dock/meet/services/facade/dock.facade'
import { ConnectionsFacade } from '@undock/people/services/facades/connections.facade'
import { DockParticipantsManager } from '@undock/dock/meet/services/dock/dock-participants.manager'
import { TooltipPosition } from '@undock/common/ui-kit/contracts/tooltip.position'
import {
    SnackbarManager,
    SnackbarPosition,
} from '@undock/common/ui-kit/services/snackbar.manager'


@Component({
    selector: 'app-meet-add-participants',
    templateUrl: 'add-participants.component.html',
    styleUrls: ['add-participants.component.scss'],
})
export class AddParticipantsComponent {

    public readonly TooltipPosition = TooltipPosition

    public readonly profileDataTrackByFn: TrackByFunction<PublicProfileData> = (i, p) => p.email

    public readonly participantsEmailStream: ReactiveStream<string[]>
    public readonly pendingParticipantsStream: ReactiveStream<PublicProfileData[]>


    @CompleteOnDestroy()
    public readonly isOpenedSubject = new ValueSubject<boolean>(false)

    @CompleteOnDestroy()
    public readonly isSearchingSubject = new ValueSubject<boolean>(false)

    @CompleteOnDestroy()
    public readonly isProcessingSubject = new ValueSubject<boolean>(false)

    @CompleteOnDestroy()
    public readonly searchCriteriaSubject = new ValueSubject<string>('')

    @ViewChild('searchInput')
    private searchInput: ElementRef<HTMLInputElement>

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    private readonly suggestedParticipantsCount = 4
    private readonly contactsSearchDebounceTime = 500
    private readonly maxSearchResultsCountToDisplay = 50

    public constructor(
        public profilesProvider: ProfilesProvider,
        protected api: Api,
        protected dock: DockFacade,
        protected user: CurrentUser,
        protected connections: ConnectionsFacade,
        protected snackbarManager: SnackbarManager,
        protected participantsManager: DockParticipantsManager,
    ) {
        this.participantsEmailStream = this.participantsManager.participantsEmailStream
        this.pendingParticipantsStream = this.participantsManager.pendingParticipantsStream
    }

    @Memoize()
    public get searchResultUIdsStream(): Observable<string[]> {
        return combineLatest([
            this.searchCriteriaSubject,
            this.user.isRegularUserStream,
        ]).pipe(
            debounceTime(this.contactsSearchDebounceTime),

            tap(() => this.isSearchingSubject.next(true)),

            switchMap(async sources => {
                const [criteria, isRegularUser] = sources

                /**
                 * Guest aren't able to have any contacts
                 */
                if (isRegularUser && criteria && criteria.length > 0) {
                    let userUIDs = await this.api.contacts.search.getIdsForAutocomplete(criteria)

                    return userUIDs.slice(0, this.maxSearchResultsCountToDisplay)
                }

                return []
            }),

            withLatestFrom(
                this.participantsManager.activeParticipantsStream,
                this.participantsManager.pendingParticipantsStream,
            ),
            map(sources => {
                const [connectedUIds, participants, pendingParticipants] = sources

                return connectedUIds.filter(connectedUId => {
                    /**
                     * Filtering already added participants
                     */
                    return !participants.find(participant => {
                        return participant.userUId === connectedUId
                    })
                }).filter(connectedUId => {
                    /**
                     * Filtering pending participants
                     */
                    return !pendingParticipants.find(profileData => {
                        return profileData.uid && profileData.uid === connectedUId
                    })
                })
            }),

            tap(() => this.isSearchingSubject.next(false)),

            startWith([]),

            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get pendingParticipantsCountStream(): Observable<number> {
        return this.pendingParticipantsStream.pipe(
            map(participants => participants.length),

            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get suggestedParticipantUIdsStream(): Observable<string[]> {
        return combineLatest([
            this.user.dataStream,
            this.connections.connectionsStream,
            this.participantsManager.activeParticipantsStream,
            this.participantsManager.pendingParticipantsStream,
        ]).pipe(
            map(sources => {
                const [
                    user, connections,
                    participants, pendingParticipants,
                ] = sources

                return connections.map(
                    c => c.getConnectedUid(user.firebaseId),
                ).filter(connectedUId => {
                    /**
                     * Filtering already added participants
                     */
                    return !participants.find(participant => {
                        return participant.userUId === connectedUId
                    })
                }).filter(connectedUId => {
                    /**
                     * Filtering pending participants
                     */
                    return !pendingParticipants.find(profileData => {
                        return profileData.uid && profileData.uid === connectedUId
                    })
                })
            }),

            map(userUIds => userUIds.slice(0, this.suggestedParticipantsCount)),

            startWith([]),

            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get arePendingParticipantsAddedStream(): Observable<boolean> {
        return this.pendingParticipantsStream.pipe(
            map(participants => participants && participants.length > 0),

            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }


    public open() {
        this.isOpenedSubject.next(true)
    }

    public close() {
        this.removeAllPendingParticipants()
        this.isOpenedSubject.next(false)
        this.searchCriteriaSubject.next('')
    }


    public onSearchInputKeyUp(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            return this.onEmailAddressSelected(`${this.searchInput.nativeElement.value}`)
        }

        this.searchCriteriaSubject.next((event.target as HTMLInputElement).value)
    }

    public async onProfileSelected(contact: FirestoreUser | string) {
        this.searchCriteriaSubject.next('') // clean criteria to prevent old search being left when you select user
        let profile: FirestoreUser
        if (typeof contact === 'string') {
            profile = await this.profilesProvider.getProfileByEmail(contact)
            if (!profile) {
                /**
                 * Contact isn't an Undock user
                 */
                return this.participantsManager.addParticipantByEmail(contact)
            }
        }

        profile = profile ?? contact as FirestoreUser

        /**
         * If contact is Undock user
         */
        return this.participantsManager.addParticipant(profile)
    }


    public async sendInvitesAndAddPendingParticipants(): Promise<void> {
        try {
            this.isProcessingSubject.next(true)
            await this.participantsManager.sendInvitesAndAddPendingParticipants()
        } catch (error) {
            console.error(`Unable send invites`, error)
            this.snackbarManager.error(
                `Unable invite participants. Please try later.`,
            )
        } finally {
            this.close()
            this.isProcessingSubject.next(false)
        }
    }

    public async removePendingParticipant(email: string): Promise<void> {
        return this.participantsManager.removeParticipantByEmail(email)
    }


    protected async removeAllPendingParticipants() {
        const pendingParticipants = await this.participantsManager.pendingParticipantsStream

        for (let participant of pendingParticipants) {
            await this.participantsManager.removeParticipantByEmail(participant.email)
        }
    }

    protected onEmailAddressSelected(email: string) {

        if (!email) {
            return
        }

        /**
         * Trying to get clear email address from user input
         */
        email = email.trim()
                     .toLowerCase()
                     /**
                      * Removing escaped domain host
                      */
                     .replace(/(%.+@)/, '@')
                     /**
                      * Removing additional characters
                      */
                     .replace(/(\+.+@)/, '@')
                     /**
                      * Removing comments
                      */
                     .replace(/(\(.+\))/, '')

        if (Validations.isValidEmail(email)) {
            /**
             * Emit event only if email address is correct
             */
            return this.onProfileSelected(email)
        }

        this.snackbarManager.error(`Please enter valid email address`, SnackbarPosition.BottomLeft)
    }
}
