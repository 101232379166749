import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
} from '@angular/core'
import {
    AbstractControl,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms'
import {
    ActivatedRoute,
    Router,
} from '@angular/router'
import { Title } from '@angular/platform-browser'

import {
    DestroyEvent,
    EmitOnDestroy,
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'
import { takeUntil } from 'rxjs/operators'

import { Memoize } from '@undock/core'
import { AuthManager } from '@undock/auth/services/auth.manager'
import { AuthSession } from '@undock/auth'


@Component({
    selector: 'app-signup-page',
    templateUrl: 'signup.page.html',
    styleUrls: [
        'signup.page.scss',
        '../shared-styles/login-signup.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignupPage implements OnInit {

    @CompleteOnDestroy()
    public ssoAuthErrorStream = new ValueSubject<string>('')

    @CompleteOnDestroy()
    public passwordAuthErrorStream = new ValueSubject<string>('')

    protected integrationConnectionRedirectPath: string

    protected _isPasswordHidden: boolean = true
    protected _isSignUpProcessing: boolean = false

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()


    public constructor(
        private title: Title,
        private router: Router,
        private authManager: AuthManager,
        private authSession: AuthSession,
        private activatedRoute: ActivatedRoute,
        private changeDetector: ChangeDetectorRef,
    ) {
        title.setTitle(`Sign Up | Undock`)
    }

    @Memoize()
    public get signupForm(): FormGroup {
        return new FormGroup({
            'email': new FormControl('', [
                Validators.email,
                Validators.required,
            ]),
            'password': new FormControl('', [
                Validators.required,
                Validators.minLength(8),
            ]),
        })
    }

    public get isPasswordHidden(): boolean {
        return this._isPasswordHidden
    }

    public async ngOnInit() {
        try {
            await this.authManager.getRedirectResult()
        } catch (error) {
            this.showAuthError(error)
        }

        await this.handleIntegrationConnectionRequest()

        /**
         * Will redirect if user logged in in the different tab
         */
        this.authManager.isRegularUserStream.pipe(
            takeUntil(this.destroyedEvent),
        ).subscribe(
            state => state ? this.redirectAfterSuccessfulSignUp() : null,
        )
    }

    public async signUpWithGoogle() {
        if (!this._isSignUpProcessing) {
            this.ssoAuthErrorStream.next('')
            this.passwordAuthErrorStream.next('')
            try {
                await this.authManager.signInWithGoogle()
            } catch (error) {
                this.showAuthError(error)
                this.changeDetector.detectChanges()
            }
        }
    }

    public async signUpWithMicrosoft() {
        if (!this._isSignUpProcessing) {
            this.ssoAuthErrorStream.next('')
            this.passwordAuthErrorStream.next('')
            try {
                await this.authManager.signInWithMicrosoft()
            } catch (error) {
                this.showAuthError(error)
                this.changeDetector.detectChanges()
            }
        }
    }

    protected showAuthError(error) {
        let errorMessage = 'Something went wrong, please try again!'
        if (error?.code == 'auth/cancelled-popup-request' || error?.code == 'auth/popup-blocked') {
            errorMessage = 'Sign in popup closed by browser!'
        } else if (error?.code == 'auth/popup-closed-by-user') {
            errorMessage = 'Sign in popup closed by browser!'
        } else if (error?.code == 'auth/account-exists-with-different-credential') {
            errorMessage = 'Account already exist with the same email but different credentials. Please link accounts in settings.'
        } else if (error?.code == 'auth/web-storage-unsupported') {
            errorMessage = 'Failed to finish authorization. This browser is not supported or cookies are disabled.'
        }

        this.ssoAuthErrorStream.next(errorMessage)
    }

    public async signUpWithEmailPassword() {
        if (!this._isSignUpProcessing) {
            this.ssoAuthErrorStream.next('')
            this.passwordAuthErrorStream.next('')

            if (this.signupForm.invalid) {
                return this.signupForm.markAllAsTouched()
            }

            try {
                this._isSignUpProcessing = true
                await this.authManager.signUpWithEmailAndPassword(
                    this.formControl('email').value,
                    this.formControl('password').value,
                )
                await this.redirectAfterSuccessfulSignUp()
            } catch (error) {
                const authValidationErrorCodes = [
                    'auth/invalid-email',
                    'auth/user-disabled',
                    'auth/user-not-found',
                    'auth/wrong-password',
                ]
                let errorMessage = 'Something went wrong, please try again!'
                if (error?.code == 'auth/account-exists-with-different-credential' || error?.code == 'auth/email-already-exists' || error?.code == 'auth/email-already-in-use') {
                    errorMessage = 'Account already exist with the same email but different credentials. Please link accounts in settings.'
                } else if (authValidationErrorCodes.includes(error?.code)) {
                    errorMessage = 'User does not exist or credentials are invalid!'
                }
                this.passwordAuthErrorStream.next(errorMessage)
                this.changeDetector.detectChanges()
            } finally {
                this._isSignUpProcessing = false
            }
        }
    }

    public formControl(formControlName: string): AbstractControl {
        return this.signupForm.get(formControlName) || null
    }

    public isFormControlInvalid(formControlName: string): boolean {
        const control = this.formControl(formControlName)
        return (control.dirty || control.touched) && control.invalid
    }

    public isFormControlHasError(formControlName: string, errorName: string): boolean {
        const control = this.formControl(formControlName)
        return control.errors && control.errors[errorName]
    }

    /**
     * Page should be fully reloaded after the auth state change.
     */
    protected async redirectAfterSuccessfulSignUp() {
        const isNewUser = await this.authSession.isNewUserStream
        const redirectPath = this.activatedRoute.snapshot.queryParamMap.get('redirectPath')
        if (isNewUser) {
            /**
             * After signup we should always direct users to onboarding
             *
             * NOTE: If there is an integration installation path selected, route there first with the new_user param, which will
             * ensure onboarding is reached after the integration process is finished
             */
            if (this.integrationConnectionRedirectPath) {
                return this.router.navigate(
                    [this.integrationConnectionRedirectPath],
                    { queryParams: { new_user: true } },
                )
            }
            else {
                location.href = this.router.createUrlTree([redirectPath || '/install']).toString()
            }
        } else {
            location.href = this.router.createUrlTree([redirectPath || '/timeline']).toString()
        }
    }

    protected handleIntegrationConnectionRequest = async () => {
        const integrationName = this.activatedRoute.snapshot.queryParamMap.get('install_integration')
        if (!integrationName) {
            return this.integrationConnectionRedirectPath = null
        }
        switch(integrationName) {
            case 'zoom':
                this.integrationConnectionRedirectPath = 'integrations/zoom'
                break
        }
    }
}
