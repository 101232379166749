import {
    ModuleWithProviders,
    NgModule,
} from '@angular/core'

import {
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms'

import { MatTabsModule } from '@angular/material/tabs'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { MatBadgeModule } from '@angular/material/badge'
import {
    MatNativeDateModule,
    MatRippleModule,
} from '@angular/material/core'
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatSelectModule } from '@angular/material/select'
import { MatStepperModule } from '@angular/material/stepper'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatRadioModule } from '@angular/material/radio'
import { HttpClientModule } from '@angular/common/http'
import { DomSanitizer } from '@angular/platform-browser'

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,

        // Material UI
        MatInputModule,
        MatIconModule,
        MatMenuModule,
        MatTabsModule,
        MatBadgeModule,
        MatButtonModule,
        MatGridListModule,
        MatCheckboxModule,
        MatRippleModule,
        MatSelectModule,
        MatTooltipModule,
        MatStepperModule,
        MatSnackBarModule,
        MatPaginatorModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSlideToggleModule,
        MatButtonToggleModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatRadioModule,

    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,

        MatInputModule,
        MatIconModule,
        MatMenuModule,
        MatTabsModule,
        MatBadgeModule,
        MatButtonModule,
        MatGridListModule,
        MatCheckboxModule,
        MatRippleModule,
        MatSelectModule,
        MatStepperModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatPaginatorModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatButtonToggleModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatRadioModule,
    ],
    declarations: [],
    providers: [],
})
export class MaterialsModule {

    public static readonly svgIconsAssetList = [
        'alert-triangle',
        'anchor',
        'archive',
        'award',
        'baby-carriage',
        'battery-charging',
        'bed-2',
        'bed',
        'bell',
        'book',
        'bookmark',
        'box-2',
        'box',
        'briefcase-2',
        'briefcase',
        'brush',
        'bug',
        'bulb-slash',
        'bulb',
        'cash',
        'calendar',
        'chart-column',
        'chart-line',
        'chart-pie',
        'clipboard-text',
        'clock-zzz',
        'coffee',
        'comments-2',
        'compass',
        'credit-card',
        'cut',
        'cutlery-3',
        'cutlery-4',
        'dashboard-2',
        'dollar-circle',
        'dumbell',
        'edit-3',
        'elements',
        'email',
        'emotion-neutral',
        'emotion-sad',
        'emotion-smile',
        'eye',
        'file-text',
        'film-board',
        'fire',
        'flag-2',
        'flag-3',
        'folder-photo',
        'gift',
        'glass-cocktail',
        'glass-wine',
        'globe',
        'hashtag',
        'headphones',
        'heart-slash',
        'heart',
        'history',
        'home',
        'infinity',
        'invoice',
        'kanban',
        'key',
        'keyboard',
        'laptop',
        'lifebuoy',
        'lock-2',
        'map-view',
        'megaphone',
        'moon',
        'music-library',
        'paint-tool',
        'paperclip',
        'phone',
        'pin-3',
        'pin',
        'planet',
        'plug-slash',
        'plug',
        'pos',
        'presentation-chart',
        'presentation-line',
        'rocket-2',
        'ruler',
        'search',
        'send',
        'shield',
        'shopping-bag-2',
        'shopping-cart-2',
        'shopping-cart',
        'sliders-vert',
        'speed',
        'star',
        'sun',
        'tag-2',
        'terminal',
        'thumbs-down',
        'thumbs-up',
        'ticket',
        'timer-2',
        'trash',
        'trophy',
        'truck',
        'tv',
        'umbrella',
        'user',
        'users',
        'volume-cross',
        'volume-high',
        'watch-2',
        'weighter',
        'wrench',
    ]

    public constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
    ) {
        MaterialsModule.svgIconsAssetList.forEach(iconName => {
            this.matIconRegistry.addSvgIcon(iconName, this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/mat-icons/${iconName}.svg`))
        })
    }

    static forRoot(): ModuleWithProviders<MaterialsModule> {
        return {
            ngModule: MaterialsModule,
            providers: [
                MatIconRegistry,
            ],
        }
    }

}
