import { ApiContext } from '@undock/api/contracts/api.context'
import { IndexRoute } from '@undock/api/scopes/organizations/routes/index.route'
import { OwnRoute } from '@undock/api/scopes/organizations/routes/own.route'
import { InviteRoute } from '@undock/api/scopes/organizations/routes/invite.route'

export class OrganizationsScope extends IndexRoute {

    public readonly own: OwnRoute
    public readonly invite: InviteRoute

    public constructor(
        context: ApiContext,
    ) {
        super(context)
        this.own = new OwnRoute(context)
        this.invite = new InviteRoute(context)
    }
}
