import { Injectable } from '@angular/core'

import {
    filter,
    map,
    shareReplay,
    take,
    takeUntil,
    tap,
} from 'rxjs/operators'
import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    StatefulSubject,
} from '@typeheim/fire-rx'
import { combineLatest } from 'rxjs'
import * as m from 'moment'
import {
    Memoize,
} from '@undock/core'
import { Api } from '@undock/api'
import { CurrentUser } from '@undock/session'
import {
    FeaturePlan,
    FeaturePlans,
    UserFeaturePlan,
} from '@undock/api/scopes/subscriptions/contracts/feature-plan.interface'
import { AppFeature } from '@undock/api/scopes/subscriptions/contracts/app-feature.interface'
import { StreamStoreV2 } from '@undock/core/services/stream-store-v2'
import { StateModel } from '@typeheim/fluent-states'
import { State } from '@typeheim/fluent-states/lib/contracts'
import { ConfirmPopupService } from '@undock/common/ui-kit'


export class FeaturePlansStore extends StreamStoreV2 {

    public readonly allFeatures$ = new StatefulSubject<AppFeature[]>()
    public readonly allFeaturePlans$ = new StatefulSubject<FeaturePlan[]>()
    public readonly userFeaturePlan$ = new StatefulSubject<UserFeaturePlan>()

    public constructor(
        private readonly destroyEvent: DestroyEvent,
    ) { super() }

    @Memoize()
    public get currentFeaturePlan$(): ReactiveStream<FeaturePlan> {
        return new ReactiveStream<FeaturePlan>(
            combineLatest([
                this.allFeaturePlans$, this.userFeaturePlan$,
            ]).pipe(
                map(([allPlans, userPlan]) => allPlans.find(plan => plan.type === userPlan.currentPlanType)),
                takeUntil(this.destroyEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            )
        )
    }

    @Memoize()
    public get currentPlanFeatures$(): ReactiveStream<AppFeature[]> {
        return new ReactiveStream<AppFeature[]>(
            combineLatest([
                this.allFeatures$,
                this.userFeaturePlan$,
                this.currentFeaturePlan$,
            ]).pipe(
                map(([
                    features, userFeaturePlan, currentPlan,
                ]) => {
                    return features.filter(feature => {
                        return currentPlan.type === feature.planType ||
                            currentPlan.extendsFeaturesOf.includes(feature.planType)
                    }).concat(
                        // Assign overridden features
                        features.filter(feature => {
                            return userFeaturePlan.overridePlanFeatures.includes(feature.type)
                        })
                    )
                }),
                takeUntil(this.destroyEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            )
        )
    }

    @Memoize()
    public get featurePlansToUpgrade$(): ReactiveStream<FeaturePlan[]> {
        return new ReactiveStream<FeaturePlan[]>(
            combineLatest([
                this.allFeaturePlans$,
                this.userFeaturePlan$,
            ]).pipe(
                map(([allPlans, currentPlan]) => {
                    return allPlans.filter(plan => {
                        return plan.isVisible &&
                               plan.extendsFeaturesOf
                                   .includes(currentPlan.currentPlanType)
                    })
                }),
                takeUntil(this.destroyEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            )
        )
    }

    @Memoize()
    public get nextFeaturePlanToUpgrade$(): ReactiveStream<FeaturePlan> {
        return new ReactiveStream(
            this.featurePlansToUpgrade$.pipe(
                map(featurePlans => featurePlans[0] ?? null),
                takeUntil(this.destroyEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            )
        )
    }

    @Memoize()
    public get userHasPaidFeaturePlanType$(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(
            this.userFeaturePlan$.pipe(
                map(plan =>
                    !!plan && [FeaturePlans.Premium, FeaturePlans.Unlimited].includes(plan.currentPlanType)
                ),
                takeUntil(this.destroyEvent),
                shareReplay(1)
            )
        )
    }

    /**
     * Only has non-null value if plan is a free trial and not expired
     */
    @Memoize()
    public get freeTrialExpiration$(): ReactiveStream<Date | null> {
        return new ReactiveStream<Date | null>(
            this.userFeaturePlan$.pipe(
                tap(d => console.log("Plan", d)),
                map(plan =>
                    !!plan
                    && plan.trialPlanEnd
                    && m(plan.trialPlanEnd).isAfter(
                        m(), 'day'
                    )
                        ? new Date(plan.trialPlanEnd)
                        : null
                ),
                takeUntil(this.destroyEvent),
                shareReplay(1)
            )
        )
    }

    @Memoize()
    public get freeTrialDaysRemaining$(): ReactiveStream<number | null> {
        return new ReactiveStream<number | null>(
            this.freeTrialExpiration$.pipe(
                map(expiration =>
                    !!expiration ? m(expiration).diff(m(), 'days') : null
                ),
                takeUntil(this.destroyEvent),
                shareReplay(1)
            )
        )
    }
}

@Injectable({
    providedIn: 'root'
})
export class FeaturePlansManager extends StateModel<FeaturePlansStore> {

    @EmitOnDestroy()
    protected readonly destroyedEvent = new DestroyEvent()

    protected readonly store = new FeaturePlansStore(this.destroyedEvent)

    public constructor(
        protected api: Api,
        protected user: CurrentUser,
        protected confirmService: ConfirmPopupService,
    ) {
        super()

        this.initialize().catch(
            error => console.error(`FeaturePlansManager::initialize`, error),
        )
    }

    protected async initialize() {
        combineLatest([
            /**
             * To ensure current user is initialized
             */
            this.user.dataStream,
            this.user.isRegularUserStream,
        ]).pipe(
            filter(([_, isRegular]) => {
                return isRegular
            }),
            take(1),
            takeUntil(this.destroyedEvent),
        ).subscribe(() => {
            return Promise.all([
                this.refreshAllFeatures(),
                this.refreshAllFeaturePlans(),
                this.refreshCurrentUserFeaturePlan(),
            ])
        })
    }

    public async refreshAllFeatures() {
        try {
            this.store.allFeatures$.next(
                await this.api.subscription.featurePlans.listFeatures()
            )
        } catch (error) {
            console.error(`Cannot load app features`, error)
        }
    }

    public async refreshCurrentUserFeaturePlan() {
        try {
            this.store.userFeaturePlan$.next(
                await this.api.subscription.featurePlans.getPersonalPlan(),
            )
        } catch (error) {
            console.warn(`Unable to load current user feature plan`, error)
        }
    }

    public async updateCurrentUserFeaturePlan(updates: Partial<UserFeaturePlan>) {
        try {
            this.store.userFeaturePlan$.next(
                await this.api.subscription.featurePlans.updatePersonalPlan(updates),
            )
        } catch (error) {
            console.warn(`Unable to update current user feature plan`, error)
        }
    }

    public async cancelCurrentUserFeaturePlan() {
        let currentPlan = await this.state.userFeaturePlan$
        if (currentPlan) {
            const confirmKeepMembership = await this.confirmService.open({
                title: 'Are you sure?',
                description: `You will lose access to all ${currentPlan.currentPlanType} features`,

                confirmButtonLabel: 'Keep membership',
                discardButtonLabel: 'Confirm',
            })
            if (!confirmKeepMembership) {
                let result = await this.api.subscription.subscriptions.cancelSubscription({
                    accountId: await this.user.subscriptionAccountId,
                    subscriptionId: currentPlan.currentSubscriptionId
                })
                if (result) {
                    await this.updateCurrentUserFeaturePlan({
                        currentPlanType: FeaturePlans.Base,
                        currentPlanFrequency: null,
                        currentSubscriptionId: null
                    })
                } else {
                    /**
                     * TODO: Reaching here means that despite there being an active UserFeaturePlan for this user, no active subscriptions were found for this user in Stripe
                     * This is an error state and needs to be handled better
                     */
                    await this.updateCurrentUserFeaturePlan({
                        currentPlanType: FeaturePlans.Base,
                        currentPlanFrequency: null,
                        currentSubscriptionId: null
                    })
                }
            }
        }
    }

    public async refreshAllFeaturePlans() {
        try {
            this.store.allFeaturePlans$.next(
                await this.api.subscription.featurePlans.listFeaturePlans()
            )
        } catch (error) {
            console.warn(`Unable to load all feature plans`, error)
        }
    }
}

export type FeaturePlansManagerState = State<FeaturePlansStore>
