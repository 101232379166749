import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core'
import { Router } from '@angular/router'

import {
    combineLatest,
    Observable,
} from 'rxjs'
import {
    map,
    shareReplay,
    takeUntil,
} from 'rxjs/operators'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    StatefulSubject,
} from '@typeheim/fire-rx'

import {
    Config,
    Memoize,
} from '@undock/core'
import { Api } from '@undock/api'
import { CurrentUser } from '@undock/session'
import {
    TimelineEvent,
    TimelineEventParticipant,
} from '@undock/api/scopes/time/contracts/timeline-event'
import { TimelineEventsManager } from '@undock/timeline/services/timeline-events.manager'
import { DockParticipantStatus } from '@undock/dock/meet/models/dock/dock-participant.model'

import { ConfirmPopupService } from '@undock/common/ui-kit'

@Component({
    selector: 'app-meet-edit-button',
    templateUrl: 'meet-edit-button.component.html',
    styleUrls: ['meet-edit-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
// TODO: Move away from layout module
export class MeetEditButtonComponent {

    public readonly DockParticipantStatus = DockParticipantStatus

    public readonly canRsvpNoStream: Observable<boolean>
    public readonly cantChangeRsvpStream: Observable<boolean>

    @Input() disabled: boolean = false

    @CompleteOnDestroy()
    private readonly eventSubject = new StatefulSubject<TimelineEvent>()

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    public constructor(
        private api: Api,
        private router: Router,
        private config: Config,
        private cd: ChangeDetectorRef,
        private currentUser: CurrentUser,
        private timelineManager: TimelineEventsManager,
        private confirmService: ConfirmPopupService,
    ) {
        // MS delete event instead od setting RSVP "NO" so fro now we'd hide it to prevent incorrect expectations from users
        this.canRsvpNoStream = this.eventSubject.pipe(
            takeUntil(this.destroyedEvent),
            map(event => event.provider !== 'microsoft')
        )

        // MS organizer can only view status as RSVP Yes but can't change it - provider specifics
        this.cantChangeRsvpStream = this.eventSubject.pipe(
            takeUntil(this.destroyedEvent),
            map(event => event.isOrganizer && event.provider === 'microsoft')
        )
    }

    @Input()
    public set event(value: TimelineEvent) {
        if (value) {
            this.eventSubject.next(value)
        }
    }

    @Output() public preAction = new EventEmitter()

    @Memoize()
    public get isDuplicateAllowed(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(
            this.eventSubject.pipe(
                takeUntil(this.destroyedEvent),
                map(event => event.editingAllowed)
            )
        )
    }

    @Memoize()
    public get isRescheduleAllowed(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(
            this.eventSubject.pipe(
                takeUntil(this.destroyedEvent),
                map(event => event.isOrganizer || event.isOwner)
            )
        )
    }

    @Memoize()
    public get currentUserParticipantStream(): Observable<TimelineEventParticipant> {
        return combineLatest([
            this.eventSubject,
            this.currentUser.dataStream,
        ]).pipe(
            map(([event, currentUser]) => {
                /**
                 * This array could contain participants with
                 *      both modern and legacy data structure
                 */
                return event.participants.find(
                    p => currentUser.email === (p?.userData?.email ?? p.emailAddress),
                )
            }),

            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get currentUserParticipantStatusStream(): ReactiveStream<DockParticipantStatus> {
        return new ReactiveStream<DockParticipantStatus>(
            this.currentUserParticipantStream.pipe(
                map((participant) => {

                    if (!participant) {
                        return null
                    }

                    /**
                     * Return new status if exist
                     */
                    if (participant.status) {
                        return participant.status
                    }

                    /**
                     * Casting legacy statuses to the new one
                     */
                    if (participant.denied) {
                        return DockParticipantStatus.Denied
                    }

                    if (participant.deleted) {
                        return DockParticipantStatus.Deleted
                    }

                    if (participant.accepted) {
                        return DockParticipantStatus.Accepted
                    }

                    if (participant.tentative) {
                        return DockParticipantStatus.Tentative
                    }

                    /**
                     * Pending is default status (No response)
                     */
                    return DockParticipantStatus.Pending
                }),

                takeUntil(this.destroyedEvent),
            ),
        )
    }

    private get dockId(): Promise<string> {
        return (async () => (await this.eventSubject).dockId)()
    }

    // -----------------------
    //  Methods for Event Edit
    // -----------------------
    public async meetDelete() {

        const isConfirmed = await this.confirmService.open({
            title: 'Are you sure you want to delete this event?',
            description: `This action could not be undone`,
            confirmButtonLabel: 'Yes, Delete',
            discardButtonLabel: 'Cancel',
        })

        if (isConfirmed) {

            this.preAction.emit('delete-event')

            const event = await this.eventSubject
            if (event.dockId) {
                await this.api.meet.dock.deleteById(event.dockId)
            } else {
                await this.api.calendar.events.deleteById(event.id)
            }

            this.timelineManager.reloadTimeline()
        }

        return null
    }

    public async navigateMeetReschedule() {
        return this.router.navigate([
            'meet', 'edit', await this.ensureDockExistAndGetId()
        ])
    }

    public async navigateMeetDuplicate() {
        return this.router.navigate([
            'meet', 'edit', await this.api.meet.dock.duplicate(
                await this.ensureDockExistAndGetId()
            ),
        ])
    }


    public async rsvpYes() {
        const current = await this.currentUserParticipantStatusStream
        if (current !== DockParticipantStatus.Accepted) {

            const [ event, currentUserEmail ] = await Promise.all([
                this.eventSubject, this.currentUser.email,
            ])

            /**
             * Updating statuses to display changes immediately
             */
            for (let participant of event.participants) {
                if (currentUserEmail === (participant.emailAddress ?? participant?.userData?.email)) {

                    participant.accepted = true
                    participant.denied = false
                    participant.pending = false
                    participant.tentative = false
                    participant.status = DockParticipantStatus.Accepted

                    this.eventSubject.next(event)
                }
            }

            this.eventSubject.next(event)

            await this.api.calendar.rsvp.yes(event.id)

            return this.timelineManager.reloadTimeline()
        }
    }

    public async rsvpNo() {
        const current = await this.currentUserParticipantStatusStream
        if (current !== DockParticipantStatus.Denied) {
            const [ event, currentUserEmail ] = await Promise.all([
                this.eventSubject, this.currentUser.email,
            ])

            /**
             * Updating statuses to display changes immediately
             */
            for (let participant of event.participants) {
                if (currentUserEmail === (participant.emailAddress ?? participant?.userData?.email)) {
                    participant.denied = true
                    participant.pending = false
                    participant.accepted = false
                    participant.tentative = false
                    participant.status = DockParticipantStatus.Denied

                    this.eventSubject.next(event)
                }
            }

            await this.api.calendar.rsvp.no(event.id)

            return this.timelineManager.reloadTimeline()
        }
    }

    public async rsvpMaybe() {
        const current = await this.currentUserParticipantStatusStream
        if (current !== DockParticipantStatus.Tentative) {
            const [ event, currentUserEmail ] = await Promise.all([
                this.eventSubject, this.currentUser.email,
            ])

            /**
             * Updating statuses to display changes immediately
             */
            for (let participant of event.participants) {
                if (currentUserEmail === (participant.emailAddress ?? participant?.userData?.email)) {

                    participant.denied = false
                    participant.pending = false
                    participant.accepted = false
                    participant.tentative = true
                    participant.status = DockParticipantStatus.Tentative

                    this.eventSubject.next(event)
                }
            }

            await this.api.calendar.rsvp.maybe(event.id)

            return this.timelineManager.reloadTimeline()
        }
    }

    protected async ensureDockExistAndGetId(): Promise<string> {
        const event = await this.eventSubject

        let dockId: string
        if (!event.dockId && event.dockKey) {
            const dock = await this.api.meet.dock
                                   .getBySharedAccessSecret(event.dockKey)
            dockId = dock.id
        } else {
            dockId = event.dockId
        }
        return dockId
    }
}
