import {
    Pipe,
    PipeTransform,
} from '@angular/core'

import { MeetingDuration } from '@undock/time/availability/services/availability.service'
import { ScheduleEditView } from '@undock/profile/settings/view-models/schedule-edit.vmodel'


@Pipe({
    name: 'meetingDuration', pure: true,
})
export class MeetingDurationPipe implements PipeTransform {

    public transform(duration: MeetingDuration, view: ScheduleEditView = ScheduleEditView.Default): string {

        if (!duration) {
            return ''
        }

        const hours = Math.floor(duration / 60),
            minutes = Math.floor(duration - hours * 60)

        let durationString = ''

        if (view == ScheduleEditView.Default) {
            if (hours > 0) {
                durationString += hours === 1 ? `${hours} hour ` : `${hours} hours `
            }

            if (minutes > 0) {
                durationString += minutes === 1 ? `${minutes} min` : `${minutes} min`
            }
        } else {
            if (hours > 0) {
                durationString += hours === 1 ? `${hours} h ` : `${hours} h `
            }

            if (minutes > 0) {
                durationString += minutes === 1 ? `${minutes} m` : `${minutes} m`
            }
        }

        return durationString.trim()
    }
}
