import { Injectable } from '@angular/core'
import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
} from '@typeheim/fire-rx'
import {
    compareDeeplyBy,
    Memoize,
} from '@undock/core'
import { CurrentUser } from '@undock/session'
import {
    IOrganization,
    IOrganizationAggregate,
} from '@undock/api/scopes/organizations/contracts'
import {
    distinctUntilChanged,
    switchMap,
    takeUntil,
} from 'rxjs/operators'
import { Api } from '@undock/api'


@Injectable({
    providedIn: 'root',
})
export class OrganizationsProvider {

    @EmitOnDestroy()
    private destroyEvent = new DestroyEvent()

    public constructor(
        private api: Api,
        private currentUser: CurrentUser,
    ) {}

    @Memoize()
    public get own$(): ReactiveStream<IOrganizationAggregate[]> {
        return new ReactiveStream(
            this.currentUser.dataStream.pipe(
                takeUntil(this.destroyEvent),
                distinctUntilChanged(compareDeeplyBy('_id')),
                switchMap(() => {
                    return this.api.organizations.own.list()
                }),
            )
        )
    }
}
