import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core'
import {
    MeetingModeExtended,
    MeetingModeOptionsProvider,
} from '@undock/dock/meet/services/data-providers/meeting-mode-options.provider'
import { Memoize } from '@undock/core'
import {
    combineLatest,
    Observable,
} from 'rxjs'
import { map } from 'rxjs/dist/types/operators'
import { MeetingMode } from '@undock/dock/meet'
import { ValuesSelectorOption } from '@undock/common/ui-kit'


@Component({
    selector: 'app-meet-edit-mode-select-v2',
    templateUrl: 'meeting-mode-select-v2.component.html',
    styleUrls: ['meeting-mode-select.component.scss']
})
export class MeetingModeSelectV2Component {


    @Output() onMeetingModeChanged = new EventEmitter<MeetingMode>()

    protected _isDraftType: boolean = false
    protected _showAudioOnly: boolean = true
    protected _showBroadcast: boolean = true
    protected _meetingModeSelected: MeetingMode = MeetingMode.Video
    protected _isAllowedToChangeMode: boolean
    protected _availableMeetingModeOptions: ValuesSelectorOption<MeetingModeExtended>[]

    public constructor(
        private meetingModeOptionsProvider: MeetingModeOptionsProvider,
    ) {}


    public get isDraftType() {
       return this._isDraftType
    }

    public get showAudioOnly() {
       return this._showAudioOnly
    }

    public get showBroadcast() {
       return this._showBroadcast
    }

    public get meetingModeSelected() {
       return this._meetingModeSelected
    }


    @Input() set isDraftType(value: boolean) {
        if (this._isDraftType !== value) {
            this.resetPreCalculatedState()
        }
        this._isDraftType = value
    }

    @Input() set showAudioOnly(value: boolean) {
        if (this._showAudioOnly !== value) {
            this.resetPreCalculatedState()
        }
        this._showAudioOnly = value
    }

    @Input() set showBroadcast(value: boolean) {
        if (this._showBroadcast !== value) {
            this.resetPreCalculatedState()
        }
        this._showBroadcast = value
    }

    @Input() set meetingModeSelected(value: MeetingMode) {
        if (this._meetingModeSelected !== value) {
            this.resetPreCalculatedState()
        }
        this._meetingModeSelected = value
    }


    public get isAllowedToChangeMode(): boolean {
        if (this._isAllowedToChangeMode === undefined) {
            this._isAllowedToChangeMode = this.isDraftType
                || this.meetingModeSelected === MeetingMode.Video
                || this.meetingModeSelected === MeetingMode.Audio
        }
        return this._isAllowedToChangeMode
    }


    public get availableMeetingModeOptions(): ValuesSelectorOption<MeetingModeExtended>[] {
        if (this._availableMeetingModeOptions === undefined) {
            let options = this.meetingModeOptionsProvider.availableExtendedMeetingModeOptions

            if (!this.showAudioOnly) {
                options = options.filter(option => option.value !== MeetingModeExtended.Audio)
            }

            if (!this.showBroadcast) {
                options = options.filter(option => option.value !== MeetingModeExtended.Broadcast)
            }

            if (this.isDraftType) {
                this._availableMeetingModeOptions = options
            } else {
                /**
                 * Only Audio and Video are allowed to be changed for existing events
                 */
                this._availableMeetingModeOptions = options.map(option => ({
                    ...option,
                    disabled: ![MeetingModeExtended.Audio, MeetingModeExtended.Video].includes(option.value),
                }))
            }
        }
        return this._availableMeetingModeOptions
    }


    protected resetPreCalculatedState() {
        delete this._isAllowedToChangeMode
        delete this._availableMeetingModeOptions
    }
}
