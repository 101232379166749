import {
    Injectable,
    OnDestroy,
} from '@angular/core'

import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    StatefulSubject,
    CompleteOnDestroy,
} from '@typeheim/fire-rx'
import {
    FilterFunction,
    remove,
    save,
} from '@typeheim/orm-on-fire'

import {
    FirestoreUser,
    FirestoreUserCollection,
} from '@undock/user'
import { Model } from '@undock/core'
import {
    Topic,
    TopicsCollection,
} from '@undock/dock/meet/models/topic.model'
import { CurrentUser } from '@undock/session'
import { TopicFactory } from '@undock/dock/meet/models/factories/topic.factory'


@Injectable()
export class TopicsManager implements OnDestroy {

    public readonly topicsStream: ReactiveStream<Topic[]>

    @CompleteOnDestroy()
    private topicsSubject = new StatefulSubject<Topic[]>()

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    public constructor(
        private currentUser: CurrentUser,
        private topicsFactory: TopicFactory,
    ) {
        this.topicsStream = this.topicsSubject.asStream()
    }


    public initializeForModel(model: Model): void {
        this.initialize(filter => filter.dockId.equal(model.id))
    }

    public async new(model: Model, properties: Partial<Topic>): Promise<Topic> {
        let topic = await this.topicsFactory.create(model, {
            ...properties, position: await this.getMaximumTopicPosition() + 1,
        })

        await this.save(topic)

        return topic
    }

    public async save(topic: Topic): Promise<void> {
        return save(topic)
    }

    public async remove(topic: Topic, softDelete = true): Promise<void> {

        if (softDelete && 'removed' in topic) {
            topic.removed = true
            return save(topic)
        }

        return remove(topic)
    }

    public async detachUser(topic: Topic): Promise<void> {
        topic.assignedUser.link(null)
        topic.assignedUserId = null

        return save(topic)
    }

    public async assignUser(topic: Topic, user: FirestoreUser): Promise<void> {
        topic.assignedUserId = user.id
        topic.assignedUser.link(user)

        return save(topic)
    }

    public async toggleCompleteStatus(topic: Topic): Promise<void> {
        if (!topic.complete) {
            let currentUserId = await this.currentUser.id
            let currentUserModel = await FirestoreUserCollection.one(currentUserId).get()

            topic.complete = true
            topic.completedById = currentUserId
            topic.completedBy.link(currentUserModel)
        } else {
            topic.complete = false
            topic.completedById = null
            topic.completedBy.link(null)
        }

        return this.save(topic)
    }

    protected initialize(filterFn: FilterFunction<Topic>) {
        TopicsCollection.all().filter(
            filter => filterFn(filter),
        ).filter(
            /**
             * @description `Preventing loading soft-deleted models`
             */
            filter => filter.removed.equal(false),
        ).filter(
            /**
             * @description `isInitialized` is value which prevents
             *           getting updates from uncompleted documents
             */
            filter => filter.isInitialized.equal(true),
        )
                        .debounceUpdates(100)
                        .stream()
                        .emitUntil(this.destroyedEvent)
                        .subscribe(topics => {
                            this.topicsSubject.next(
                                topics.sort(
                                    (a, b) => a.position - b.position,
                                ),
                            )
                        })
    }

    protected async getMaximumTopicPosition(): Promise<number> {
        let topics = await this.topicsSubject

        /**
         * Returns position (sortOrder) of the latest Topic
         */
        return topics.reduce((carry, item) => {
            return carry > item.position ? carry : item.position
        }, 0)
    }

    public ngOnDestroy() {}
}
