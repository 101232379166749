import {
    Injectable,
    OnDestroy,
} from '@angular/core'

import {
    map,
    shareReplay,
    takeUntil,
} from 'rxjs/operators'
import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
} from '@typeheim/fire-rx'

import {
    FirestoreUser,
    FirestoreUserCollection,
} from '@undock/user/models/firestore.user'
import { Api } from '@undock/api'
import { AuthSession } from '@undock/auth'
import {
    Profile,
    ProfileCollection,
} from '@undock/user/models/profile.model'


@Injectable({
    providedIn: 'root',
})
export class ProfilesProvider implements OnDestroy {

    /**
     * ----------------------------------------------------------
     *  Class is intended for profiles data loading optimization
     *
     *  In the most of cases only public part of profile is used
     * ----------------------------------------------------------
     */

    @EmitOnDestroy()
    private readonly destroyEvent = new DestroyEvent()

    /**
     * FirestoreUser is used temporary until public profile
     *          functionality are not fully implemented yet
     *
     * @deprecated
     */
    private readonly cache: {
        [criteria: string]: { [value: string]: ReactiveStream<FirestoreUser> }
    } = {}

    private readonly profileCache = new Map<string, ReactiveStream<Profile>>()

    public constructor(
        protected api: Api,
        protected authSession: AuthSession,
    ) {
        this.authSession.isLoggedInStream.then(isLoggedIn => {
            if (isLoggedIn) {
                this.preloadContacts()
            }
        })
    }

    protected async preloadContacts() {
        let connectedUsers = await this.api.contacts.connection.getConnectedUsersIds()
        connectedUsers?.forEach(userId => this.getProfileByUid(userId))
    }


    public getById(id: string): ReactiveStream<Profile> {
        const key = `profile[id=${id}]`
        if (!(this.profileCache[key] instanceof ReactiveStream)) {
            this.profileCache[key] = ProfileCollection.all()
                                                      .filter(filter => filter.undockId.equal(id))
                                                      .stream()
                                                      .emitUntil(this.destroyEvent)
                                                      .pipe(map(result => result[0] ?? null))
        }
        return this.profileCache[key]
    }

    public getByUId(uid: string): ReactiveStream<Profile> {
        const key = `profile[uid=${uid}]`
        if (!(this.profileCache[key] instanceof ReactiveStream)) {
            this.profileCache[key] = ProfileCollection.one(uid)
                                                      .stream()
                                                      .emitUntil(this.destroyEvent)
        }
        return this.profileCache[key]
    }


    public getProfileById(id: string): ReactiveStream<FirestoreUser> {
        if (!this.cache['id']) {
            this.cache['id'] = {}
        }

        if (!this.cache['id'][id]) {
            this.cache['id'][id] = FirestoreUserCollection.one(id)
                                                          .stream()
                                                          .emitUntil(this.destroyEvent)
        }

        return this.cache['id'][id]
    }

    public getProfileByUid(uid: string): ReactiveStream<FirestoreUser> {
        if (!this.cache['uid']) {
            this.cache['uid'] = {}
        }

        if (!this.cache['uid'][uid]) {
            this.cache['uid'][uid] = new ReactiveStream<FirestoreUser>(
                FirestoreUserCollection.filter(
                    filter => filter.firebaseId.equal(uid),
                ).stream().emitUntil(this.destroyEvent).pipe(
                    map(
                        profiles => profiles.length > 0 ? profiles[0] : null,
                    ),

                    takeUntil(this.destroyEvent),
                    shareReplay({ bufferSize: 1, refCount: true }),
                ),
            )
        }

        return this.cache['uid'][uid]
    }

    public getProfilesByUids(uids: string[]): Promise<FirestoreUser[]> {
        let userQueries = uids.map(uid => this.getProfileByUid(uid))
        return Promise.all(userQueries)
    }

    public getProfileByEmail(email: string): ReactiveStream<FirestoreUser> {
        if (!this.cache['email']) {
            this.cache['email'] = {}
        }

        if (!this.cache['email'][email]) {
            this.cache['email'][email] = new ReactiveStream<FirestoreUser>(
                FirestoreUserCollection.filter(
                    filter => filter.email.equal(email),
                ).stream().emitUntil(this.destroyEvent).pipe(
                    map(
                        profiles => profiles.length > 0 ? profiles[0] : null,
                    ),

                    takeUntil(this.destroyEvent),
                    shareReplay({ bufferSize: 1, refCount: true }),
                ),
            )
        }

        return this.cache['email'][email]
    }


    public ngOnDestroy() {
        this.proceedCacheCleanup()
    }

    protected proceedCacheCleanup() {
        for (let criteria in this.cache) {
            if (this.cache.hasOwnProperty(criteria)) {
                /**
                 * Stopping all reactive streams from the ORM
                 */
                for (let key in this.cache[criteria]) {
                    if (this.cache[criteria].hasOwnProperty(key)) {
                        if (this.cache[criteria][key] instanceof ReactiveStream) {
                            this.cache[criteria][key].stop()
                        }

                        delete this.cache[criteria][key]
                    }
                }

                /**
                 * Cleaning cache
                 */
                this.cache[criteria] = {}
            }
        }
    }
}
