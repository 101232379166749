<div class="switch-toggle-input-box" [class.disabled]="disabled">
    <div class="switch-toggle-input">

        <input type="checkbox" id="{{id}}" [(ngModel)]="toggled" [disabled]="disabled"
               (change)="toggledChange.emit($event.target.checked); changed.emit($event.target.checked)">
        <label class="toggle-button-switch" for="{{id}}"></label>

        <div class="toggle-button-text">
            <div class="toggle-button-text-on">{{ buttonTextOn }}</div>
            <div class="toggle-button-text-off">{{ buttonTextOff }}</div>
        </div>
    </div>
    <span *ngIf="switchLabel" class="switch-toggle-switch-label">{{ switchLabel }}</span>
</div>

<div class="switch-toggle-label-box" [class.disabled]="disabled">
    <div class="switch-toggle-label">{{ label }}</div>
    <div class="switch-toggle-sublabel">{{ subLabel }}</div>
</div>

