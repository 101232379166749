<ng-container *ngIf="currentTopicStream | stream as topic">
    <div class="topic-view-container topic-list" [ngClass]="{'-bg-completed' : topic.complete}">
        <div class="topic-list__header">
            <ng-container *ngIf="displayCdkDragToggle">
                <span class="topic-list__drag-handle-area"></span>
                <span class="topic-list__range"></span>
            </ng-container>

            <div class="topic-list__topic-status">
                <button class="topic-list__complete-topic-button" (click)="toggleCompleteStatus()"
                        [matTooltipPosition]="TooltipPosition.BOTTOM"
                        [disabled]="!(isEditModeStream | stream)"
                        [@isComplete]="topic.complete ? 'true' : 'false'"
                        [matTooltip]="topic.complete ? 'Unresolve' : 'Resolve'"
                >
                    <span class="material-icons">done</span>
                </button>
            </div>

            <div class="topic-list__titles">
                <div class="topic-list__topic-input-title">
                    <form autocomplete="off">
                        <input name="topic-title" autocomplete="off" data-private type="text" #topicTextInput
                               [(ngModel)]="topic.text"
                               [ngModelOptions]="{standalone: true}"
                               [disabled]="!(isEditModeStream | stream)"
                               [ngClass]="topic.complete ? '-strike' : ''"
                        />
                    </form>
                </div>
            </div>
        </div>

        <!--header-right-side-->
        <div class="topic-list__topic-navigation">
            <div class="action-icon topic-list__participant-wrapper">
                <app-meet-participant-selector-trigger class="topic-list__participant-selector"
                                                       [title]="'Assign topic'"
                                                       [isEditMode]="isEditModeStream | stream"
                                                       [selectedIds]="assignedUsersIdsStream | async"
                                                       (onSelected)="onParticipantSelectedEvent($event)"
                ></app-meet-participant-selector-trigger>
            </div>

            <ng-container [ngSwitch]="isVideoProcessingStream | stream">
                <ng-container *ngSwitchCase="true">
                    <div class="action-icon topic-list__record-item">
                        <spinner [size]="30" [tickness]="5" [color]="'#2B2733'"></spinner>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="false">
                    <ng-container *ngIf="isEditModeStream | stream">
                        <div class="action-icon topic-list__record-item" [ngClass]="{'hidden': hasVideoAttachmentStream | stream}">
                            <app-recorder [bottomLabelText]="(currentTopicStream | stream)?.text"
                                          (onRecordingReady)="onRecordingReady($event)"
                            ></app-recorder>
                        </div>
                    </ng-container>

                    <ng-template #contentTemplate>
                        <div class="floating-player">
                            <button class="close-button" (click)="toggleVideoPlayer()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                     fill="none" class="ng-tns-c268-0">
                                    <path d="M15 5L5 15" stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                                          stroke-linejoin="round" class="ng-tns-c268-0"></path>
                                    <path d="M5 5L15 15" stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                                          stroke-linejoin="round" class="ng-tns-c268-0"></path>
                                </svg>
                            </button>
                            <video controls autoplay playsinline [src]="attachmentUrl | stream"></video>
                            <div class="controls-bar" *ngIf="isEditModeStream | stream">
                                <p class="title">{{ (currentTopicStream | stream)?.text }}</p>
                                <button class="video-delete-btn" (click)="removeAttachment()">Delete Video</button>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template #previousVersionOfVideoModal>
                    <div *ngIf="hasVideoAttachmentStream | stream" class="action-icon topic-list__record-item">
                        <button class="video-attachment-button" (click)="toggleVideoPlayer()">
                            <app-icon-play [width]="20" [height]="20" [color]="'#FFFFFF'"></app-icon-play>
                        </button>

                        <section class="topic-list__attached-video floating-player">
                            <div class="floating-player__wrapper">
                                <button class="floating-player__button-close-player" (click)="toggleVideoPlayer()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                         fill="none" class="ng-tns-c268-0">
                                        <path d="M15 5L5 15" stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                                              stroke-linejoin="round" class="ng-tns-c268-0"></path>
                                        <path d="M5 5L15 15" stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                                              stroke-linejoin="round" class="ng-tns-c268-0"></path>
                                    </svg>
                                </button>

                                <video controls autoplay playsinline [src]="attachmentUrl | stream"></video>

                                <ng-container *ngIf="isEditModeStream | stream">
                                    <div class="floating-player__controls-bar">
                                        <p class="floating-player__header-title">
                                            {{ (currentTopicStream | stream)?.text  }}
                                        </p>
                                        <button class="floating-player__button-video -delete"
                                                (click)="removeAttachment()">Delete Video
                                        </button>
                                    </div>
                                </ng-container>
                            </div>
                        </section>
                    </div>
                    </ng-template>

                    <div *ngIf="hasVideoAttachmentStream | stream" class="action-icon topic-list__record-item">
                        <button class="video-attachment-button" (click)="toggleVideoPlayer()">
                            <app-icon-play [width]="20" [height]="20" [color]="'#FFFFFF'"></app-icon-play>
                        </button>
                    </div>

                </ng-container>
            </ng-container>

            <app-meet-topic-actions
                    class="action-icon"
                    [isEditingAllowed]="isEditModeStream | stream"
                    (event)="processMeetingAction($event)"
            ></app-meet-topic-actions>

            <!--<ng-container *ngIf="isEditModeStream | stream">
                <div class="topic-list__header-actions">
                    <span class="material-icons -delete-icon" (click)="removeTopic()" matTooltip="Delete">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18"
                                                     viewBox="0 0 14 18" fill="none">
                             <path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM4 6H10C10.55 6 11 6.45 11 7V15C11 15.55 10.55 16 10 16H4C3.45 16 3 15.55 3 15V7C3 6.45 3.45 6 4 6ZM10.5 1L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1H10.5Z"
                                   fill="#807A8E"/>
                         </svg>
                    </span>

                    <span class="material-icons more">more_vertical</span>
                </div>
            </ng-container>-->
        </div>
    </div>
</ng-container>




