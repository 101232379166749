import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core'

import { v4 as uuid } from 'uuid'

@Component({
    selector: 'app-switch-toggle',
    templateUrl: 'switch-toggle.component.html',
    styleUrls: [`switch-toggle.component.scss`],
})
export class SwitchToggleComponent {

    public id = uuid()

    @Input() disabled: boolean = false

    @Input() buttonTextOn: string = ''
    @Input() buttonTextOff: string = ''
    @Input() label: string
    @Input() subLabel: string
    @Input() switchLabel: string

    @Input() toggled: boolean
    @Output() toggledChange = new EventEmitter<boolean>()
    @Output() changed = new EventEmitter<boolean>()
}
