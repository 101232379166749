import { Injectable } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import {
    default as m,
    Moment,
} from 'moment'

import {
    of,
    timer,
    combineLatest,
} from 'rxjs'
import {
    map,
    take,
    filter,
    takeUntil,
    shareReplay,
    distinctUntilChanged,
} from 'rxjs/operators'
import {
    DestroyEvent,
    ValueSubject,
    EmitOnDestroy,
    ReactiveStream,
    StatefulSubject,
    CompleteOnDestroy,
} from '@typeheim/fire-rx'

import { Memoize } from '@undock/core'
import { MomentRange } from '@undock/time/availability'
import { AvailabilitySlot } from '@undock/api/scopes/profile/contracts'
import { AvailabilityProvider } from '@undock/time/availability/services/availability.provider'
import { AvailabilitySet } from '@undock/api/scopes/profile/contracts/availability'
import {
    findBestSlotOnDay,
    sortSlotsByScore,
} from '@undock/core/utils/find-best-slot-on-day'


@Injectable()
export class AvailabilityViewModel {

    @CompleteOnDestroy()
    private selectedAvailabilityDaySubject = new StatefulSubject<Moment>()

    @CompleteOnDestroy()
    private availabilityDaysCountToDisplaySubject = new ValueSubject<number>(7)

    @CompleteOnDestroy()
    private loadAvailabilityRangeStartSubject = new StatefulSubject<Moment>()

    @CompleteOnDestroy()
    private displayAvailabilityRangeStartSubject = new StatefulSubject<Moment>()

    /**
     * Will preload the closest multiple of the number of days displayed
     */
    private readonly preloadAvailabilityDaysCount = 14 //  2 weeks

    /**
     * Will skip N empty availability ranges after profile page was loaded
     */
    private readonly emptyAvailabilityRangesToSkip = 3

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    public constructor(
        protected route: ActivatedRoute,
        protected availabilityProvider: AvailabilityProvider,
    ) {}

    @Memoize()
    public get displayAvailabilityStream(): ReactiveStream<AvailabilitySet[]> {
        return new ReactiveStream<AvailabilitySet[]>(
            combineLatest([
                this.groupAvailabilityStream,
                this.displayAvailabilityRangeStartStream,
                this.availabilityDaysCountToDisplayStream,
            ]).pipe(
                map(sources => {
                    const [availabilitySets, rangeStart, daysCount] = sources

                    if (availabilitySets.length > 0) {
                        const setsRangeStartIndex = availabilitySets.findIndex(set => {
                            return set.day.isSame(rangeStart, 'day')
                        })

                        return availabilitySets.slice(setsRangeStartIndex, setsRangeStartIndex + daysCount)
                    }

                    return []
                }),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get selectedAvailabilityDayStream(): ReactiveStream<Moment> {
        return this.selectedAvailabilityDaySubject.asStream()
    }

    @Memoize()
    public get loadAvailabilityDatesRangeStream(): ReactiveStream<MomentRange> {
        return new ReactiveStream(
            combineLatest([
                this.loadAvailabilityRangeStartSubject,
                this.availabilityDaysCountToDisplayStream,
            ]).pipe(
                map(([start, daysCount]) => {
                    return {
                        end: start.clone().endOf('day')
                                  .add(Math.max(this.preloadAvailabilityDaysCount, daysCount), 'days'),
                        start: start.clone().startOf('day'),
                    }
                }),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get displayAvailabilityDatesRangeStream(): ReactiveStream<MomentRange> {
        return new ReactiveStream(
            combineLatest([
                this.displayAvailabilityRangeStartStream,
                this.availabilityDaysCountToDisplayStream,
            ]).pipe(
                map(([start, daysCount]) => {
                    return {
                        start, end: start.clone()
                                         .add(daysCount - 1, 'days'),
                    }
                }),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get displayAvailabilityRangeStartStream(): ReactiveStream<Moment> {
        return this.displayAvailabilityRangeStartSubject.asStream()
    }

    @Memoize()
    public get isAvailabilityLoadingStream(): ReactiveStream<boolean> {
        return this.availabilityProvider.isAvailabilityLoadingStream
    }

    @Memoize()
    public get suggestedAvailableSlotStream(): ReactiveStream<AvailabilitySlot> {
        return this.availabilityProvider.suggestedAvailableSlotStream
    }

    @Memoize()
    public get availabilityDaysCountToDisplayStream(): ReactiveStream<number> {
        return new ReactiveStream<number>(
            this.availabilityDaysCountToDisplaySubject.pipe(
                distinctUntilChanged(),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get groupAvailabilityStream(): ReactiveStream<AvailabilitySet[]> {
        return this.availabilityProvider.availabilityStream
    }

    @Memoize()
    public get groupAvailabilityNonEmptyDatesStream(): ReactiveStream<string[]> {
        return new ReactiveStream<string[]>(
            this.groupAvailabilityStream.pipe(
                distinctUntilChanged(),
                takeUntil(this.destroyedEvent),
                map((data) => data
                    .filter(({ slots }) => slots.length)
                    .map(({ day }) => day.format('YYYY-MM-DD')),
                ),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }


    /**
     * TODO: Rework this view-model to initialize availability provider
     */
    public async initViewModel() {
        this.initSchedulerForReloadingAvailability()
        this.subscribeForSuggestedSlotAndSelectAvailabilityDay()
        this.subscribeForSelectingFirstAvailableDayInDisplayedAvailability()

        /**
         * By default, should display availability from current date
         */
        this.selectedAvailabilityDaySubject.next(m())
        this.loadAvailabilityRangeStartSubject.next(m())
        this.displayAvailabilityRangeStartSubject.next(m())
    }

    /**
     * Shifts current availability range for N days forward
     */
    public async nextDays(days): Promise<void> {
        return this.selectRangeStartForAvailabilityDisplaying(
            (await this.displayAvailabilityRangeStartSubject).clone().add(days, 'days'),
        )
    }

    /**
     * Shifts current availability range for N days backward
     */
    public async previousDays(days): Promise<void> {
        return this.selectRangeStartForAvailabilityDisplaying(
            (await this.displayAvailabilityRangeStartSubject).clone().subtract(days, 'days'),
        )
    }

    public async selectAvailabilityDay(value: Moment, centerSelectedDay: boolean = false) {
        if (value) {
            const [
                displayAvailabilityRangeStart, availabilityDaysCountToDisplay,
            ] = await Promise.all([
                this.displayAvailabilityRangeStartStream,
                this.availabilityDaysCountToDisplayStream,
            ])

            let displayAvailabilityRangeEnd = displayAvailabilityRangeStart.clone()
                                                                           .add(availabilityDaysCountToDisplay - 1, 'days')

            let isSelectedSlotDisplayed = value.isBetween(
                displayAvailabilityRangeStart,
                displayAvailabilityRangeEnd,
                'days', '[]',
            )

            if (centerSelectedDay || !isSelectedSlotDisplayed) {
                let newDisplayRangeStart = value.clone()
                                                .subtract(
                                                    Math.floor(availabilityDaysCountToDisplay / 2), 'days',
                                                )

                await this.selectRangeStartForAvailabilityDisplaying(newDisplayRangeStart)
            }
        }

        this.selectedAvailabilityDaySubject.next(value)
    }

    public setDisplayDaysCount(value: number) {
        this.availabilityDaysCountToDisplaySubject.next(value)
    }

    public forceReloadGroupAvailability() {
        return this.availabilityProvider.forceReloadAvailability()
    }

    public async selectRangeStartForAvailabilityDisplaying(value: Date | Moment) {
        // Ensure value is Moment
        value = (m.isMoment(value) ? value : m(value)) as Moment
        const loadedRange = await this.loadAvailabilityDatesRangeStream
        const availabilityDaysCountToDisplay = this.availabilityDaysCountToDisplaySubject.value

        const displayRangeStartValid = value.isBetween(
            loadedRange.start, loadedRange.end, 'days', '[]',
        )
        const displayRangeEndValid = value.clone()
            .add(availabilityDaysCountToDisplay - 1, 'days')
            .isBetween(loadedRange.start, loadedRange.end, 'days', '[]')

        if (!displayRangeStartValid || !displayRangeEndValid) {
            // Adjust the range for loading availability
            this.selectRangeStartForAvailabilityLoading(value.clone().startOf('day'))
        }

        this.selectedAvailabilityDaySubject.next(value)
        this.displayAvailabilityRangeStartSubject.next(value)
    }

    protected selectRangeStartForAvailabilityLoading(value: Date | Moment) {
        this.loadAvailabilityRangeStartSubject.next(m.isMoment(value) ? value : m(value))
    }

    protected initSchedulerForReloadingAvailability() {
        const schedulerInterval = 10 ** 3 * 60 * 5
        timer(schedulerInterval, schedulerInterval).pipe(
            takeUntil(this.destroyedEvent),
        ).subscribe(
            () => this.forceReloadGroupAvailability(),
        )
    }

    protected subscribeForSuggestedSlotAndSelectAvailabilityDay() {
        const emptyRangesSubscription = this.availabilityProvider.suggestedAvailableSlotStream.pipe(
            /**
             * Should emit only if there is no suggested slot in current range
             */
            filter(suggestedSlot => !Boolean(suggestedSlot)),

            take(this.emptyAvailabilityRangesToSkip),
            takeUntil(this.destroyedEvent),
        ).subscribe(async (slot: never) => {

            /**
             * If no suggested slot was found, jump to the first day in the set that has any availability
             */
            const availability = await this.groupAvailabilityStream
            if (availability?.length) {
                let day = availability.find(d => d.slots?.filter(s => s.type !== 'event').length)
                if (day) {
                    return this.selectRangeStartForAvailabilityDisplaying(day.day)
                }
            } else {
                /**
                 * If no availability found in range, force switching to the next availability date range
                 */
                const loadAvailabilityDatesRange = await this.loadAvailabilityDatesRangeStream
                return this.selectRangeStartForAvailabilityDisplaying(loadAvailabilityDatesRange.end)
            }
        })

        /**
         * Will select a suggested slot day after not empty availability is loaded
         */
        return this.availabilityProvider.suggestedAvailableSlotStream.pipe(
            /**
             * Should emit only if valid suggested slot been provided
             */
            filter(suggestedSlot => Boolean(suggestedSlot)),

            take(1),
            takeUntil(this.destroyedEvent),
        ).subscribe(async (suggestedSlot) => {
            /**
             * Unsubscribe from skipping empty ranges if got a non-empty one
             */
            emptyRangesSubscription.unsubscribe()

            /**
             * If the suggested slot is more than 4 days away, but there is availability before that, jump
             * to the day with the best slot within the next 4 days
             */
            let suggestedDay = m(suggestedSlot.timeStamp).startOf('day'),
                today = m()

            if (suggestedDay.diff(today.startOf('day'), 'days') > 3) {
                const availability = await this.groupAvailabilityStream
                if (availability?.length &&
                    availability.find(
                        d => d.day.diff(today, 'days') < 4 && d.slots?.filter(s => s.type !== 'event').length
                    )) {
                    let highestScoreSlots = availability.slice(0, 4).map(
                        day => findBestSlotOnDay(day)
                    ).filter(s => Boolean(s))
                    sortSlotsByScore(highestScoreSlots)

                    return this.selectAvailabilityDay(m(highestScoreSlots[0].timeStamp).startOf('day'))
                }
            }

            return this.selectAvailabilityDay(suggestedDay)
        })
    }

    protected subscribeForSelectingFirstAvailableDayInDisplayedAvailability() {
        return combineLatest([
            this.displayAvailabilityStream,
            this.selectedAvailabilityDaySubject,
        ]).pipe(
            takeUntil(this.destroyedEvent),
        ).subscribe(([displayAvailability, selectedDay]) => {
            for (let set of displayAvailability) {
                // Searching for selected day in availability set
                if (set.day.isSame(selectedDay, 'day')) {
                    // If selected availability day contain no slots
                    if (set.slots.length === 0) {
                        // Trying to select first available day in the displayed set
                        for (let set of displayAvailability) {
                            // If availability day is not empty
                            if (set.slots.length > 0) {
                                return this.selectAvailabilityDay(set.day)
                            }
                        }
                    }
                }
            }
        })
    }
}
