import {
    Collection,
    CreatedDateField,
    DocRef,
    Entity,
    EntityFilter,
    Field,
    ID,
    Reference,
    UpdatedDateField,
} from '@typeheim/orm-on-fire'
import { LazyLoadStream } from '@typeheim/fire-rx'

import { StorageFile } from '@undock/core/models/storage-file.model'
import { ShouldInitialize } from '@undock/core/contracts/model/should-initialize.interface'

export class AttachmentScope {
    public static initialized(filter: EntityFilter<Attachment>) {
        return filter.isInitialized.equal(true)
    }
}

export const ATTACHMENT_COLLECTION_NAME = 'attachment'

@Entity({
    collection: ATTACHMENT_COLLECTION_NAME,
})
export class Attachment implements ShouldInitialize {

    public readonly fileStream: LazyLoadStream<StorageFile>

    @ID() id: string

    /**
     * Attachment owner entity id
     */
    @Field() ownerId: string

    /**
     * Attachment owner Type
     */
    @Field() ownerType: string

    @Field() name: string
    @Field() size: number
    @Field() type: string

    @Field() tags: string[] = []

    @Field() isInitialized: boolean

    @Field() isCopy: boolean
    @Field() originalAttachmentId: string
    @DocRef(Attachment) originalAttachment: Reference<Attachment>

    @Field() fileId: string
    @DocRef(StorageFile) file: Reference<StorageFile>

    @CreatedDateField() createdAt: Date
    @UpdatedDateField() updatedAt: Date

    public constructor() {
        this.fileStream = new LazyLoadStream(() => this.file.get())
    }
}

export const AttachmentCollectionGroup = Collection.groupOf<Attachment>(Attachment)
