<section
        class="availability-slot-container {{currentSlot.score >= 90 ? 'best-time-slot' : ''}} {{currentSlot.score < 40 ? 'low-priority-time-slot' : ''}}"
        [class.slot-selected]="isSlotSelected"
>

    <span class="availability-slot-date-label" style="width: 100%;">
        <span class="availability-slot-date-label">
            {{ currentSlot.label }}
            <img *ngIf="currentSlot.score >= 90" class="availability-slot-best-icon" src="../../../../../../projects/web/assets/img/bolt-white.svg"
                 alt="Best" height="11" width="10">
        </span>
    </span>

</section>
