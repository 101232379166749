import { ApiRoute } from '@undock/api'
import {
    IOrganization,
    IOrganizationAggregate,
    IOrganizationMember,
} from '@undock/api/scopes/organizations/contracts'


export class OwnRoute extends ApiRoute {

    public init() {
        this.route = 'organizations/own'
    }

    public getById(organizationId: string) {
        return this.get<IOrganizationAggregate>()
                   .endpoint(`id/${organizationId}`)
    }

    public list(page: number = 0, pageSize: number = 50) {
        return this.get<IOrganizationAggregate[]>()
                   .withParams({
                       page: `${page}`,
                       pageSize: `${pageSize}`,
                   })
                   .endpoint(`list`)
    }

    public listMembers(
        organizationId: string,
        page: number = 0,
        pageSize: number = 50,
    ) {
        return this.get<IOrganizationMember[]>()
                   .withParams({
                       page: `${page}`,
                       pageSize: `${pageSize}`,
                   })
                   .endpoint(`list-members/${organizationId}`)
    }

    public create(data: IOrganization) {
        return this.post<IOrganization>()
                   .withData(data)
                   .endpoint(`create`)
    }

    public update(organizationId: string, data: Partial<IOrganization>) {
        return this.post<IOrganization>()
                   .withData(data)
                   .endpoint(`update/${organizationId}`)
    }
}
