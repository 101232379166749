import { Injectable } from '@angular/core'

import * as m from 'moment'

import {
    State,
    StateModel,
    StreamStore,
} from '@typeheim/fluent-states'
import {
    DestroyEvent,
    EmitOnDestroy,
    StatefulSubject,
    ValueSubject,
} from '@typeheim/fire-rx'
import {
    combineLatest,
    skip,
    timer,
} from 'rxjs'
import {
    distinctUntilChanged,
    takeUntil,
} from 'rxjs/operators'

import {
    ArrayHelpers,
    AppEventsDispatcher,
} from '@undock/core'
import { Api } from '@undock/api'
import { Router } from '@angular/router'
import {
    UiTimelineEvent,
    UpdateEventsStorageResult,
} from '@undock/dashboard/contracts'
import {
    DAY_DURATION_MS,
    MIN_DURATION_MS,
} from '@undock/dashboard/constants'
import { DateRange } from '@undock/time/availability'
import { CurrentUser } from '@undock/session'
import {
    getOverlapTypeForRanges,
    getTotalRangeForOverlappedRanges,
    RangeMs,
} from '@undock/core/utils/ranges-overlap'
import {
    SnackbarManager,
} from '@undock/common/ui-kit/services/snackbar.manager'
import {
    TimelineDirection,
    TimelineEvent,
} from '@undock/api/scopes/time/contracts/timeline-event'
import { CalendarEventStatusesProvider } from '@undock/calendar/services/calendar-event-statuses.provider'
import {
    BaseTimelineRequest,
    GetTimelineEventsPaginationOptions,
} from '@undock/api/scopes/calendar/routes/timeline.route'


@Injectable({
    providedIn: 'root',
})
export class CalendarEventsStorage extends StateModel<CalendarEventsStore> {

    /**
     * All event ranges presented in the storage
     */
    protected loadedRanges = new Array<RangeMs>()

    protected readonly store = new CalendarEventsStore()

    @EmitOnDestroy()
    protected readonly destroyEvent = new DestroyEvent()

    public constructor(
        protected api: Api,
        protected router: Router,
        protected currentUser: CurrentUser,
        protected eventsManager: AppEventsDispatcher,
        protected snackbarManager: SnackbarManager,
        protected eventStatusesManager: CalendarEventStatusesProvider,
    ) {
        super()

        this.initialize()
    }

    protected initialize() {
        this.initStatusRefreshListener()
        this.initCurrentRangeRefreshListener()

        /**
         * Reset the cache if user is changed
         */
        this.currentUser.uidStream.pipe(
            distinctUntilChanged(),
            skip(1),
            takeUntil(this.destroyEvent),
        ).subscribe(() => {
            this.loadedRanges = []
            this.store.events.next([])
        })
    }

    public getAllLoadedEvents(): UiTimelineEvent[] {
        return this.store.events.getValue()
    }

    public async getFutureEventsPage(
        boundary: Date, eventsCountPerPage: number = 25,
    ): Promise<UpdateEventsStorageResult> {
        /**
         * Trying to get events page from the cache
         */
        const currentEvents = this.store.events.getValue()
        if (currentEvents.length > 0) {
            /**
             * Range to search all loaded events overlapping with future
             */
            const futureOverlapRange: RangeMs = {
                startMs: boundary.valueOf(),
                endMs: Math.max.apply(null, currentEvents.map(e => e.endMs)),
            }

            /**
             * Searching for all cached events for future overlap range
             */
            const cachedEventsForRange = currentEvents.filter(
                event => getOverlapTypeForRanges(event, futureOverlapRange) > 0,
            )

            if (cachedEventsForRange.length >= eventsCountPerPage) {
                /**
                 * Sorting cached events by start date
                 */
                cachedEventsForRange.sort((a, b) => a.startMs - b.startMs)

                /**
                 * Getting the page of events from the cached range
                 */
                const cachedEventsPage = cachedEventsForRange.slice(0, eventsCountPerPage)
                return {
                    rangeLoaded: {
                        startMs: boundary.valueOf(),
                        endMs: Math.max.apply(null, cachedEventsPage.map(e => e.startMs)),
                    },
                    loadedEvents: cachedEventsPage,
                    updatedEvents: [], removedEvents: [],
                }
            }
        }


        let loadedEvents = []
        if (await this.currentUser.isRegularUser) {
            /**
             * Loading events from the API
             */
            loadedEvents = this.prepareUiTimelineEvents(
                await this.api.calendar.timeline.getTimelineEventsPage({
                    start: boundary.toISOString(),
                    order: TimelineDirection.Future,
                    pageSize: eventsCountPerPage, page: 0,
                    ...await this.getAdditionalOptionsForEventsRequest(),
                })
            )
        }

        if (loadedEvents.length > 0) {
            const preparedEvents = this.prepareUiTimelineEvents(loadedEvents)

            /**
             * Using the biggest startMs from all loaded events as the range end
             */
            return this.updateEventsStorageWithLoadedEvents(preparedEvents, {
                startMs: boundary.valueOf(),
                endMs: Math.max.apply(null, preparedEvents.map(e => e.startMs)),
            })
        }

        /**
         * Updating storage for all range from range start to last event endMs
         */
        return this.updateEventsStorageWithLoadedEvents([], {
            startMs: boundary.valueOf(), endMs: 32503680000000, // Far future date
        })
    }


    public async getHistoryEventsPage(
        boundary: Date, eventsCountPerPage?: number,
    ): Promise<UpdateEventsStorageResult>  {
        /**
         * Trying to get events page from the cache
         */
        const currentEvents = this.store.events.getValue()
        if (currentEvents.length > 0) {
            /**
             * Range to search all loaded events overlapping with history
             */
            const historyOverlapRange: RangeMs = {
                endMs: boundary.valueOf(),
                startMs: Math.min.apply(null, currentEvents.map(e => e.startMs)),
            }

            const cachedEventsForRange = currentEvents.filter(
                event => getOverlapTypeForRanges(event, historyOverlapRange) > 0,
            )

            /**
             * It seems we can use cached events
             */
            if (cachedEventsForRange.length >= eventsCountPerPage) {
                /**
                 * Sorting cached events by start date
                 */
                cachedEventsForRange.sort((a, b) => a.startMs - b.startMs)

                /**
                 * Getting the page of events from cached range
                 */
                const cachedEventsPage = cachedEventsForRange.slice(-eventsCountPerPage)
                return {
                    rangeLoaded: {
                        endMs: boundary.valueOf(),
                        startMs: Math.min.apply(null, cachedEventsPage.map(e => e.endMs)),
                    },
                    loadedEvents: cachedEventsPage,
                    updatedEvents: [], removedEvents: [],
                }
            }
        }


        let loadedEvents = []

        /**
         * Loading events from the API
         */
        if (await this.currentUser.isRegularUser) {
            loadedEvents = this.prepareUiTimelineEvents(
                await this.api.calendar.timeline.getTimelineEventsPage({
                    start: boundary.toISOString(),
                    order: TimelineDirection.History,
                    pageSize: eventsCountPerPage, page: 0,
                    ...await this.getAdditionalOptionsForEventsRequest(),
                })
            )
        }

        if (loadedEvents.length > 0) {
            const preparedEvents = this.prepareUiTimelineEvents(loadedEvents)

            /**
             * Using the lowest endMs from all loaded events as the range start
             */
            return this.updateEventsStorageWithLoadedEvents(preparedEvents, {
                endMs: boundary.valueOf(),
                startMs: Math.min.apply(null, preparedEvents.map(e => e.endMs)),
            })
        }

        return this.updateEventsStorageWithLoadedEvents([], {
            endMs: boundary.valueOf(), startMs: 0,
        })
    }

    public async getEventsForDateRange(
        range: DateRange, ignoreCachedEvents?: boolean,
    ): Promise<UpdateEventsStorageResult> {

        const rangeMs: RangeMs = {
            startMs: range.start.valueOf(), endMs: range.end.valueOf(),
        }

        if (!ignoreCachedEvents && this.loadedRanges.length > 0) {
            /**
             * Loaded ranges which overlap with requested one
             */
            const fullOverlapRange = this.loadedRanges.find(
                loadedRange => getOverlapTypeForRanges(rangeMs, loadedRange) === 1,
            )

            /**
             * We can get allEvents events from the cache
             */
            if (fullOverlapRange) {
                /**
                 * Fetching cached events
                 */
                const events = this.store.events.value.filter(
                    event => getOverlapTypeForRanges(event, rangeMs) > 0
                )

                return {
                    rangeLoaded: rangeMs,
                    loadedEvents: events,
                    updatedEvents: [], removedEvents: [],
                }
            } else {
                /**
                 * Seems to be partial overlap with one of loaded ranges
                 *
                 * @TODO: Handle partial overlapping to improve caching
                 */
            }
        }

        let events = []

        /**
         * Loading events from the API
         */
        if (await this.currentUser.isRegularUser) {
            events = await this.api.calendar.timeline.getTimelineEvents({
                start: range.start.toISOString(), end: range.end.toISOString(),
                ...await this.getAdditionalOptionsForEventsRequest(),
            })
        }

        if (events.length > 0) {
            return this.updateEventsStorageWithLoadedEvents(
                this.prepareUiTimelineEvents(events), rangeMs,
            )
        }

        /**
         * Will remove all cached events for this range
         */
        return this.updateEventsStorageWithLoadedEvents([], rangeMs)
    }

    /**
     * Removes cached calendar event version
     */
    public deleteEventFromTheStorage(event: UiTimelineEvent) {
        /**
         * Remove deleted event from the cache
         */
        this.store.events.next(
            this.store.events.value.filter(
                storeEvent => storeEvent.id !== event.id
            )
        )

        this.store.onStorageUpdated.next({
            loadedEvents: [],
            updatedEvents: [],
            removedEvents: [event],
        })

        this.refreshEventStatuses()
    }

    /**
     * Removes cached calendar event versions
     */
    public deleteMultipleEventsFromTheStorage(events: UiTimelineEvent[]) {
        /**
         * Remove deleted events from the cache
         */
        let idsToRemove = events.map(event => event.id)
        this.store.events.next(
            this.store.events.value.filter(
                storeEvent => !idsToRemove.includes(storeEvent.id)
            )
        )

        this.store.onStorageUpdated.next({
            loadedEvents: [],
            updatedEvents: [],
            removedEvents: events,
        })

        this.refreshEventStatuses()
    }

    public refreshCurrentEvents() {
        this.store.forceRefreshCurrentEventsRangeStream.next()
        this.refreshEventStatuses()
    }

    public refreshEventStatuses() {
        this.store.forceRefreshEventStatusesStream.next()
    }


    protected initStatusRefreshListener() {
        /**
         * Updates event statuses every 3 seconds
         */
        combineLatest([
            timer(1_000, 3_000),
            this.store.forceRefreshEventStatusesStream,
        ]).pipe(
            takeUntil(this.destroyEvent),
        ).subscribe(() => {
            const events = this.store.events.getValue()
            if (events.length > 0) {
                const refreshRes = this.eventStatusesManager
                                       .refreshEventStatuses(events)

                if (refreshRes.updatedEventIds.length > 0) {
                    /**
                     * Update events storage
                     */
                    this.store.events.next(refreshRes.allEvents)

                    /**
                     * Emit information the store is updated
                     */
                    this.store.onStorageUpdated.next({
                        loadedEvents: [],
                        removedEvents: [],
                        updatedEvents: refreshRes.allEvents.filter(
                            event => refreshRes.updatedEventIds.includes(event.id)
                        )
                    })

                    console.log(`Statuses refreshed for ${refreshRes.updatedEventIds.length} events`)
                }
            }
        })
    }

    /**
     * Refreshes 2 weeks interval every 5 minutes
     */
    protected initCurrentRangeRefreshListener() {
        const refreshInterval = 5 * 60 * 1000 // 5 minutes
            , datesRangeToRefresh = {
            start: m().startOf('day').toDate(),
            end: m().add(2, 'weeks').toDate(),
        }

        combineLatest([
            timer(refreshInterval, refreshInterval),
            this.store.forceRefreshCurrentEventsRangeStream,
        ]).pipe(
            takeUntil(this.destroyEvent),
        ).subscribe(async () => {
            try {
                this.store.onStorageUpdated.next(
                    await this.getEventsForDateRange(
                        datesRangeToRefresh, true,
                    )
                )
            } catch (error) {
                console.warn(`Cannot refresh events range`, error)
            }
        })
    }


    /**
     * Will remove all events for provided range if not exist
     */
    protected updateEventsStorageWithLoadedEvents(
        loadedEvents: UiTimelineEvent[], range: { startMs: number, endMs: number},
    ): UpdateEventsStorageResult {
        /**
         * Method should return result
         */
        const updateResult = {
            rangeLoaded: range,

            /**
             * Defaults
             */
            loadedEvents: [],
            updatedEvents: [],
            removedEvents: [],
        } as UpdateEventsStorageResult


        /**
         * Extends days end
         */
        range.endMs += 1


        /**
         * Updating loaded ranges
         */
        if (this.loadedRanges.length > 0) {
            /**
             * Pushing new range to the list
             */
            this.loadedRanges.push(range)

            /**
             * Sorting ranges by start date ASC
             */
            this.loadedRanges.sort((a, b) => {
                return a.startMs - b.startMs
            })

            /**
             * Re-calculating the list of loaded ranges
             */
            for (let i = 0; i < this.loadedRanges.length - 1;) {
                /**
                 * Checking if ranges overlaps
                 */
                const overlapType = getOverlapTypeForRanges(
                    this.loadedRanges[i], this.loadedRanges[i + 1],
                )

                if (overlapType > 0) {
                    /**
                     * Replacing range with merged current and next ranges
                     */
                    this.loadedRanges[i] = getTotalRangeForOverlappedRanges([
                        this.loadedRanges[i], this.loadedRanges[i + 1],
                    ])

                    /**
                     * Removing next range after it been merged
                     */
                    this.loadedRanges.splice(i + 1, 1)
                } else {
                    i++
                }
            }

            /**
             * Sorting ranges by start date ASC
             */
            this.loadedRanges.sort((a, b) => {
                return a.startMs - b.startMs
            })
        } else {
            this.loadedRanges.push(range)
        }

        updateResult.rangeLoaded = range

        const currentEvents = this.store.events.getValue()
            , loadedEventIds = loadedEvents.map(e => e.id)
            , currentEventIds = currentEvents.map(e => e.id)

        let updatedEventIds: string[] = [],
            removedEventIds: string[] = []

        if (currentEvents.length > 0) {
            /**
             * All events to be updated in the storage
             */
            updatedEventIds = ArrayHelpers.findArraysIntersection(currentEventIds, loadedEventIds)

            const currentEventsForRangeIds = currentEvents.filter(
                event => getOverlapTypeForRanges(event, range) > 0
            ).map(event => event.id)

            const updatedEventsForRangeIds = ArrayHelpers.findArraysIntersection(
                currentEventsForRangeIds, loadedEventIds,
            )

            removedEventIds = ArrayHelpers.findArraysDifference(currentEventsForRangeIds, updatedEventsForRangeIds)
        }


        /**
         * Copying statuses from the original events
         */
        if (updatedEventIds.length > 0) {
            for (let id of updatedEventIds) {
                const loadedEvent = loadedEvents.find(e => e.id === id)
                    , originalEvent = currentEvents.find(e => e.id === id)

                /**
                 * Copying existing event state
                 */
                if (loadedEvent && originalEvent) {
                    loadedEvent.state = originalEvent.state
                    loadedEvent.style = originalEvent.style
                }
            }
        }


        /**
         * All collected events in the storage
         */
        let totalEvents = ArrayHelpers.filterUniqueWithCache(
            loadedEvents.concat(currentEvents), event => event.id,
        )

        if (removedEventIds.length > 0) {
            /**
             * Collecting removed events in the update
             */
            updateResult.removedEvents = currentEvents.filter(
                event => removedEventIds.includes(event.id)
            )

            /**
             * Filtering total events to remove deleted items
             */
            totalEvents = totalEvents.filter(event => !removedEventIds.includes(event.id))
        }

        /**
         * Events with refreshed statuses
         */
        const refreshRes = this.eventStatusesManager
                               .refreshEventStatuses(totalEvents)
        /**
         * Collecting events updated by status refresh
         */
        if (refreshRes.updatedEventIds.length > 0) {
            updatedEventIds = updatedEventIds.concat(refreshRes.updatedEventIds)
        }

        updateResult.loadedEvents = refreshRes.allEvents.filter(
            refreshedEvent => loadedEventIds.includes(refreshedEvent.id)
        )

        updateResult.updatedEvents = refreshRes.allEvents.filter(
            refreshedEvent => updatedEventIds.includes(refreshedEvent.id)
        )

        /**
         * Adding new and updating old events with new version
         */
        this.store.events.next(refreshRes.allEvents)
        this.store.onStorageUpdated.next(updateResult)

        return updateResult
    }


    protected prepareUiTimelineEvents(events: TimelineEvent[]): UiTimelineEvent[] {
        return events.map(event => {
            const start = new Date(event.start)
                , end = new Date(event.end)
                , eventDaysTs = []

            const endMs = end.valueOf()
                , startMs = start.valueOf()
                , durationMs = endMs - startMs
                , duration = Math.round(durationMs / MIN_DURATION_MS)

            /**
             * Collecting all days for the event
             */
            let dayTs = m(start).startOf('day').valueOf()
            if (event.allDay) {
                while (dayTs < endMs) {
                    eventDaysTs.push(dayTs)
                    dayTs += DAY_DURATION_MS
                }
            } else {
                /**
                 * @TODO: Support multi-day plain events
                 */
                eventDaysTs.push(dayTs)
            }

            /**
             * Original event with custom metadata
             */
            return Object.assign(event, {
                startMs, endMs, duration, durationMs, eventDaysTs,
            })
        })
    }

    protected async getAdditionalOptionsForEventsRequest(): Promise<Partial<BaseTimelineRequest>> {
        return {}
    }
}

export class CalendarEventsStore extends StreamStore {

    /**
     * Storage for all events
     */
    public readonly events = new ValueSubject<UiTimelineEvent[]>([])

    /**
     * Emits when there are any updates for the storage
     */
    public readonly onStorageUpdated = new StatefulSubject<UpdateEventsStorageResult>()

    /**
     * Triggers
     */
    public readonly forceRefreshEventStatusesStream = new ValueSubject<void>(null)
    public readonly forceRefreshCurrentEventsRangeStream = new ValueSubject<void>(null)
}

export type CalendarEventsStoreState = State<CalendarEventsStore>
