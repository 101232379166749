import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core'

import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
} from '@typeheim/fire-rx'
import {
    map,
    shareReplay,
    takeUntil,
} from 'rxjs/operators'
import {
    combineLatest,
    Observable,
} from 'rxjs'

import {
    FirestoreUser,
    PublicProfileData,
} from '@undock/user'
import { Memoize } from '@undock/core'
import { CurrentUser } from '@undock/session'
import {
    DockParticipant,
    DockParticipantRole,
    DockParticipantStatus,
} from '@undock/dock/meet/models/dock/dock-participant.model'
import { DockFacade } from '@undock/dock/meet/services/facade/dock.facade'
import { ProfilesProvider } from '@undock/user/services/profiles.provider'
import { DockParticipantsManager } from '@undock/dock/meet/services/dock/dock-participants.manager'
import { TooltipPosition } from '@undock/common/ui-kit/contracts/tooltip.position'


@Component({
    selector: 'app-meet-edit-participants',
    templateUrl: 'edit-participants.component.html',
    styleUrls: ['edit-participants.component.scss'],
})
export class EditParticipantsComponent {

    @Input() showIcon = false
    @Input() readonlyMode = false

    @Output() onParticipantAdded = new EventEmitter<FirestoreUser | string>()
    @Output() onParticipantRemoved = new EventEmitter<string>()

    public readonly TooltipPosition = TooltipPosition

    public readonly DockParticipantRole = DockParticipantRole
    public readonly DockParticipantStatus = DockParticipantStatus

    public readonly isEditModeStream: ReactiveStream<boolean>
    public readonly participantEmailsStream: ReactiveStream<string[]>
    public readonly participantsStream: ReactiveStream<DockParticipant[]>
    public readonly pendingParticipantsStream: ReactiveStream<PublicProfileData[]>


    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()


    public constructor(
        public profilesProvider: ProfilesProvider,
        private dockFacade: DockFacade,
        private currentUser: CurrentUser,
        private participantsManager: DockParticipantsManager,
    ) {
        this.isEditModeStream = this.dockFacade.isEditModeStream
        this.participantsStream = this.participantsManager.activeParticipantsStream
        this.participantEmailsStream = this.participantsManager.participantsEmailStream
        this.pendingParticipantsStream = this.participantsManager.pendingParticipantsStream
    }

    @Memoize()
    public get allParticipantsStream(): Observable<DockParticipant[]> {
        return combineLatest([
            this.participantsManager.activeParticipantsStream,
            this.participantsManager.pendingDeleteParticipantsStream,
        ]).pipe(
            map(([active, pendingDelete]) => {
                return [
                    ...active.filter(p => p.role !== DockParticipantRole.Visitor),
                    ...pendingDelete.filter(p => p.role !== DockParticipantRole.Visitor),
                ]
            }),

            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public canDeleteParticipant(participant: DockParticipant): Observable<boolean> {
        /**
         * @Memoize decorator will create one stream per each participant
         */
        return combineLatest([
            this.isEditModeStream,
            this.participantsManager.currentUserParticipantStream,
            this.participantsManager.pendingDeleteParticipantsEmailsStream,
        ]).pipe(
            map(sources => {
                const [isEditMode, currentUserParticipant, deleteEmails] = sources

                if (
                    !isEditMode ||
                    currentUserParticipant.id === participant.id ||
                    deleteEmails.includes(participant.userData.email)
                ) {
                    return false
                }

                /**
                 * Only the organizer of a meeting can remove an attendee
                 *
                 * @description https://app.clickup.com/t/hk4vjp
                 */
                return currentUserParticipant.isOwner
            }),

            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public isParticipantMarkedToDelete(participant: DockParticipant): Observable<boolean> {
        /**
         * @Memoize decorator will create one stream per each participant
         */
        return this.participantsManager.pendingDeleteParticipantsEmailsStream.pipe(
            map(
                emailsToDelete => emailsToDelete.includes(participant.userData.email),
            ),
        )
    }


    public async removeParticipantByEmail(email: string) {
        await this.participantsManager.removeParticipantByEmail(email)
        return this.onParticipantRemoved.emit(email)
    }

    public removeParticipantFromDeleteList(participant: DockParticipant) {
        return this.participantsManager.removeParticipantFromDeleteList(participant)
    }

    public async onContactSelected(contact: FirestoreUser | string) {
        let profile: FirestoreUser
        if (typeof contact === 'string') {
            /**
             * Trying to get Undock user by provided email
             */
            profile = await this.profilesProvider.getProfileByEmail(contact)
            if (!profile) {
                /**
                 * Contact isn't an Undock user
                 */
                await this.participantsManager.addParticipantByEmail(contact)
                return this.onParticipantAdded.emit(contact)
            }
        }

        profile = profile ?? contact as FirestoreUser

        /**
         * If contact is Undock user
         */
        await this.participantsManager.addParticipant(profile)
        return this.onParticipantAdded.emit(contact)
    }
}
