import {
    Input,
    Component,
    ChangeDetectionStrategy,
} from '@angular/core'
import { AvailabilitySlot } from '@undock/api/scopes/profile/contracts'


@Component({
    selector: 'app-time-availability-slot-view',
    templateUrl: 'availability-slot-view.component.html',
    styleUrls: ['availability-slot-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvailabilitySlotViewComponent {
    @Input('slot') currentSlot: AvailabilitySlot
    @Input('selected') isSlotSelected: boolean = false
}
