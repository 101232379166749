import {
    Provider,
    InjectionToken,
} from '@angular/core'
import { CurrentUser } from '@undock/session/models/current-user'
import { FeaturePlansManager } from '@undock/feature-plans/services/feature-plans.manager'
import { environment } from '@web/environments/environment'
import { from, combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { ReactiveStream } from '@typeheim/fire-rx'

const earlyAdopters = [
    'dan@levlo.co',
    'info@ce-iv.com',
    'BrandenLloyd@outlook.com',
    'Ruthus.Cardenas@hotmail.com',
    'kovyzobuk@gmail.com',
    'wicadom372@gmail.com',
    'Undockuser1@gmail.com',
    'undock1testuser@gmail.com',
    'undock2testuser@gmail.com',
    'undock3testuser@gmail.com',
    'undock4testuser@gmail.com',
    'undock5testuser@gmail.com',
    'undock6testuser@gmail.com',
    'undock7testuser@gmail.com',
    'undock8testuser@gmail.com',
    'zhenya@undockdevs.onmicrosoft.com',
]

export const IS_BETA_USER = new InjectionToken('IS_BETA_USER')

export const IS_BETA_USER_PROVIDER: Provider= {
    provide: IS_BETA_USER,
    useFactory: (
        currentUser: CurrentUser,
        featurePlansManager: FeaturePlansManager,
    ): ReactiveStream<boolean> => {
        // force beta access on local dev
        if (environment.appMode === 'dev') {
            return new ReactiveStream(from(Promise.resolve(true)))
        }
        return new ReactiveStream(
            combineLatest([
                currentUser.dataStream,
                featurePlansManager.state.userFeaturePlan$,
            ]).pipe(
                map(([user, userPlan]) => {
                    return userPlan.hasBetaAccess
                        || earlyAdopters.includes(user?.email)
                        || user?.email?.includes('@undock.com')
                })
            )
        )
    },
    deps: [CurrentUser, FeaturePlansManager],
}
