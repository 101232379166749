import {
    Collection,
    CreatedDateField,
    Entity,
    Field,
    ID,
    UpdatedDateField,
} from '@typeheim/orm-on-fire'
import { SharedCollectionEntity } from '@undock/core'
import { DateRange } from '@undock/time/availability'

/** @deprecated */
export enum CalendarEventType {
    /**
     * Plain event from the calendar
     */
    Event = 'Event',

    /**
     * Undock event with Dock entity
     */
    Meeting = 'Meeting',

    /**
     * Same as Meeting type
     */
    Broadcast = 'Broadcast'
}

/** @deprecated */
export enum CalendarEventVisibility {
    /**
     * Means the event is visible for all contacts
     */
    Connections = 'Connections',

    /**
     * Default visibility status
     */
    Participants = 'Participants',
}


export const CALENDAR_EVENT_COLLECTION = 'calendar-event'

/**
 * This entity is used for Broadcasts only
 * TODO: Rework broadcasts functionality and remove this entity
 * @deprecated
 */
@Entity({
    collection: CALENDAR_EVENT_COLLECTION,
})
export class CalendarEvent implements SharedCollectionEntity {
    @ID() readonly id: string

    @Field() ownerId: string
    @Field() ownerType: string

    // Firebase id of event author user
    @Field() authorId: string

    @Field() title: string
    @Field() location?: string
    @Field() description?: string

    @Field() dates: DateRange

    @Field() type: CalendarEventType
    @Field() visibility: CalendarEventVisibility

    // Used for events search
    @Field() participantIds: Array<string>
    @Field() participantEmails: Array<string>

    @CreatedDateField() createdAt: Date
    @UpdatedDateField() updatedAt: Date
}

export const CalendarEventCollection = Collection.of(CalendarEvent)
