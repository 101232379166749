import {
    Collection,
    CollectionRef,
    CreatedDateField,
    Entity,
    Field,
    ID,
    UpdatedDateField,
} from '@typeheim/orm-on-fire'
import { PrivateNote } from '@undock/core'


const PROFILE_COLLECTION_NAME = 'account-profile'

@Entity({
    collection: PROFILE_COLLECTION_NAME,
})
export class Profile {

    /**
     * Same as uid property
     */
    @ID() readonly id: string

    /**
     * Used for backward compatibility.
     *
     * @deprecated Avoid using this property.
     */
    @Field() readonly shortId?: string

    /**
     * Id of the user entity from the legacy MongoDB storage
     *
     * @deprecated Avoid using this property. It should be removed soon
     */
    @Field() readonly undockId?: string

    @Field() isVerified: boolean = false
    @Field() isRegularUser: boolean = true

    @Field() username: string
    @Field() lastName: string
    @Field() firstName: string
    @Field() displayName: string

    @Field() email: string
    @Field() emailType: string

    @Field() phoneNumber: string

    @Field() imageUrl: string
    @Field() profileUrl: string
    @Field() bannerImageUrl: string

    @Field() website: string = ''
    @Field() fullBio: string = ''
    @Field() shortBio: string = ''
    @Field() location: string = ''

    @Field() lastTimeZone: string
    @Field() lastTimeZoneCity: string

    /**
     * The list of links to social accounts
     */
    @Field() socialLinks: Record<string, string> = {}

    @CreatedDateField() createdAt: Date
    @UpdatedDateField() updatedAt: Date

    @CollectionRef(PrivateNote) privateNotes: Collection<PrivateNote>
}

export const ProfileCollection = Collection.of<Profile>(Profile)
