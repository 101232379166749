import { AppFeatures } from '@undock/api/scopes/subscriptions/contracts/app-feature.interface'
import {
    SubscriptionFrequency,
    SubscriptionPrice,
} from '@undock/api/scopes/subscriptions/contracts/subscriptions.interface'

export enum AppType {
    Web= 'Web',
    Portal = 'Portal',
}

export enum FeaturePlans {
    Base = 'Base',
    Premium = 'Premium',
    Unlimited = 'Unlimited',
    PremiumTrial = 'PremiumTrial',
    UnlimitedTrial = 'UnlimitedTrial',
}

export class FeaturePlan {
    name: string
    type: FeaturePlans
    description: string
    productId: string
    prices: SubscriptionPrice[]
    isVisible: boolean
    isDefaultPlan: boolean
    extendsFeaturesOf: FeaturePlans[]
}

export interface UserFeaturePlan {
    userId: string
    hasBetaAccess: boolean
    currentPlanType: FeaturePlans
    currentPlanFrequency: SubscriptionFrequency
    currentSubscriptionId?: string
    overridePlanFeatures: AppFeatures[]
    trialPlanEnd?: Date
}
