import {
    Injectable,
    Optional,
} from '@angular/core'
import { Clipboard } from '@angular/cdk/clipboard'

import { default as m } from 'moment'

import {
    State,
    StateModel,
} from '@typeheim/fluent-states'
import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    StatefulSubject,
    ValueSubject,
} from '@typeheim/fire-rx'
import {
    distinctUntilChanged,
    map,
    switchMap,
    takeUntil,
} from 'rxjs/operators'
import {
    combineLatest,
    from,
    Observable,
    of,
    Subject,
    Subscription,
} from 'rxjs'

import {
    Dock,
    MeetingMode,
    ScheduleMode,
    DockCollection,
    DockVisibility,
    ConferenceMode,
    DockSharedAccessMode,
    MeetingDurationOption,
} from '@undock/dock/meet'
import { Api } from '@undock/api'
import {
    BrowserTime,
    TimezoneData,
} from '@undock/time/availability'
import {
    clone,
    Memoize,
    Validations,
    compareDeeply,
    ArrayHelpers,
} from '@undock/core'
import { CurrentUser } from '@undock/session'
import { MeetingDuration } from '@undock/time/availability/services/availability.service'
import { generateMeetingTitle } from '@undock/dock/meet/utils/meeting-titles-generator'
import { EditMeetingData } from '@undock/dock/meet/contracts/edit-meeting-data.interface'
import { EventAttendee } from '@undock/api/scopes/time/contracts/timeline-event/event-attendee.interface'
import { EventSchedule } from '@undock/api/scopes/time/contracts/timeline-event/event-schedule.interface'
import { MeetingDurationOptionsProvider } from '@undock/dock/meet/services/data-providers/meeting-duration-options.provider'
import { StreamStoreV2 } from '@undock/core/services/stream-store-v2'
import { AttendeeWithMeta } from '@undock/dock/meet/ui/pages/edit-meeting/components/edit-attendees'
import { UserConferenceLinkType } from '@undock/api/scopes/user/contracts/user.interface'
import { DraftMeetingData } from '@undock/dock/meet/contracts/draft-meeting-data.interface'
import { CalendarEventsStorage } from '@undock/calendar/services/calendar-events.storage'
import { Plan } from '@undock/time/plans/contracts/plan.interface'


/**
 * Default duration of availability slot
 */
export const AVAILABILITY_SLOT_DURATION = 30

export class EventFormStore extends StreamStoreV2 {

    public onSubmit = new Subject<EditMeetingData>()
    public showSkeleton: ValueSubject<boolean> = new ValueSubject(true)

    public dockIdStream: ValueSubject<string> = new ValueSubject(null)
    public planIdStream: ValueSubject<string> = new ValueSubject(null)

    public titleStream: StatefulSubject<string> = new StatefulSubject()
    public notesStream: StatefulSubject<string> = new StatefulSubject()

    public attendeesStream: StatefulSubject<EventAttendee[]> = new StatefulSubject()

    public durationStream: ValueSubject<number> = new ValueSubject(null)
    public locationStream: StatefulSubject<string> = new StatefulSubject()
    public inPersonLocationUrlStream: StatefulSubject<string> = new StatefulSubject()

    public meetingModeStream: StatefulSubject<MeetingMode> = new StatefulSubject()
    public scheduleModeStream: StatefulSubject<ScheduleMode> = new StatefulSubject()
    public visibilityModeStream: StatefulSubject<DockVisibility> = new StatefulSubject()
    public conferenceModeStream: StatefulSubject<ConferenceMode> = new StatefulSubject()

    /**
     * Properties required for scheduling process
     */
    public eventScheduleStream: StatefulSubject<EventSchedule> = new StatefulSubject()
    public browserTimeZoneDataStream: StatefulSubject<TimezoneData> = new StatefulSubject()
    public selectedTimeZoneDataStream: StatefulSubject<TimezoneData> = new StatefulSubject()

    public availableMeetingDurationOptionsStream: StatefulSubject<MeetingDurationOption[]> = new StatefulSubject()

    /**
     * Original meeting data to track any changes in the view model
     */
    public originalEventDataStream: StatefulSubject<EditMeetingData> = new StatefulSubject()

    /**
     * Overrides preferred conference link
     *
     * @warning This property is used only for draft meetings
     */
    public conferenceLinkTypeStream: ValueSubject<UserConferenceLinkType> = new ValueSubject(null)

    public readonly isPrivate$: StatefulSubject<boolean> = new StatefulSubject()
    public readonly isNonBlocking$: StatefulSubject<boolean> = new StatefulSubject()

    @Memoize()
    public get formLabelTextStream(): Observable<string> {
        return this.isDraftModeStream.pipe(
            map(isDraftMode => {
                return isDraftMode ? 'New event' : 'Edit event'
            }),
        )
    }

    @Memoize()
    public get saveButtonTextStream(): Observable<string> {
        return this.isDraftModeStream.pipe(
            map(isDraftMode => {
                return isDraftMode ? 'Create event' : 'Save event'
            }),
        )
    }

    @Memoize()
    public get attendeesWithMetaStream(): Observable<AttendeeWithMeta[]> {
        return combineLatest([
            this.attendeesStream,
            this.isDraftModeStream,
            this.isOwnerModeStream,
        ]).pipe(
            map(sources => {
                const [
                    attendees,
                    isDraftMode,
                    isOwnerMode,
                ] = sources

                return attendees.map(attendee => ({
                    ...attendee,
                    canDelete: isDraftMode
                        ? !attendee.isOrganizer
                        : isOwnerMode && !attendee.isOrganizer
                }))
            }),
        )
    }

    @Memoize()
    public get relatedDockStream(): ReactiveStream<Dock> {
        /**
         * TODO: Add some sort of destroyEvent here
         */
        return new ReactiveStream(
            this.dockIdStream.pipe(
                distinctUntilChanged(),
                switchMap(dockId => {
                    if (dockId) {
                        return DockCollection.one(dockId).stream()
                    }
                    return of(Promise.resolve(null))
                })
            )
        )
    }

    @Memoize()
    public get isDraftModeStream(): ReactiveStream<boolean> {
        return new ReactiveStream(
            this.originalEventDataStream.pipe(
                map(data => data.isDraft)
            )
        )
    }

    @Memoize()
    public get isEditModeStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(
            from(Promise.resolve(true)) // TODO: Add correct ACL checking here
        )
    }

    @Memoize()
    public get isOwnerModeStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(
            from(Promise.resolve(true)) // TODO: Add correct ACL checking here
        )
    }

    @Memoize()
    public get selectedTimeZoneNameStream(): ReactiveStream<string> {
        return new ReactiveStream(
            this.selectedTimeZoneDataStream.pipe(map(data => data.zone)),
        )
    }

    /**
     * Returns closes valid availability request duration
     */
    @Memoize()
    public meetingDurationForAvailabilityStream(): ReactiveStream<MeetingDuration> {
        return new ReactiveStream<MeetingDuration>(
            combineLatest([
                this.durationStream,
                this.availableMeetingDurationOptionsStream,
            ]).pipe(
                map(([duration, options]) => {

                    /**
                     * Calculating differences between requested duration and available options
                     */
                    const optionDiffs = options.map(
                        option => Math.abs(duration - (option.value - option.gap)),
                    )

                    /**
                     * Searching for closest duration option from available ones
                     */
                    const closestOption = options[optionDiffs.indexOf(Math.min.apply(this, optionDiffs))]

                    if (closestOption) {
                        return closestOption.value
                    }

                    return options[0] ? options[0].value : AVAILABILITY_SLOT_DURATION
                }),

                distinctUntilChanged(),
            ),
        )
    }

    @Memoize()
    public get isMeetingDraftTypeStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(
            this.originalEventDataStream.pipe(
                map(event => Boolean(event?.isDraft)),
                distinctUntilChanged(),
            ),
        )
    }

    @Memoize()
    public get defaultMeetingTitleStream(): ReactiveStream<string> {
        return new ReactiveStream<string>(
            combineLatest([
                this.attendeesStream,
                this.meetingModeStream,
            ]).pipe(
                map(([attendees, mode]) => {
                    let titlePrefix: string
                    if (mode === MeetingMode.Broadcast) {
                        titlePrefix = 'Broadcasting'
                    }
                    return generateMeetingTitle(attendees.map(a => a.userData), titlePrefix)
                }),
            ),
        )
    }
}


@Injectable()
export class EventFormStateModel extends StateModel<EventFormStore> {

    protected store = new EventFormStore()

    @EmitOnDestroy()
    protected destroyEvent = new DestroyEvent()

    public constructor(
        protected api: Api,
        protected user: CurrentUser,
        protected clipboard: Clipboard,
        protected browserTime: BrowserTime,
        protected calendarEventsStorage: CalendarEventsStorage,
        protected meetingDurationOptionsProvider: MeetingDurationOptionsProvider,
    ) {
        super()
    }

    /**
     * Fill initial form properties
     */
    public async initViewModel(
        data: EditMeetingData | DraftMeetingData,
    ) {
        /**
         * Saving original data to the storage
         */
        this.store.originalEventDataStream.next(data)

        /**
         * Using current time-zone by default
         */
        this.store.selectedTimeZoneDataStream.next(
            await this.browserTime.timeZoneDataStream
        )

        /**
         * Will apply defaults on meeting mode change
         */
        this.subscribeForBrowserTimeZone()
        this.subscribeForSelectedMeetingMode()
        this.subscribeForAvailableDurationOptions()

        this.store.dockIdStream.next(data.dockId)
        this.store.planIdStream.next(data['planId'])

        /**
         *  Initial Meeting options. These options shouldn't be updated in real-time.
         */
        this.setTitle(data.title)
        this.setNotes(data.notes)
        this.setLocation(data.location)
        this.setMeetingMode(data.mode)
        this.setEventSchedule(data.schedule)
        this.setEventAttendees(data.attendees)
        this.setInPersonLocationUrl(data.inPersonLocationUrl)

        if (data.conferenceLinkType) {
            this.setConferenceLinkType(data.conferenceLinkType)
        }

        this.setIsPrivate(data.isPrivate)
        this.setIsNonBlocking(data.isNonBlocking)

        await Promise.all([
            this.selectScheduleMode(),
            this.selectVisibilityMode(this.getVisibilityMode(data)),
            this.selectConferenceMode(this.getConferenceMode(data)),
            this.selectMeetingDuration(this.getMeetingDuration(data)),
        ])
        this.store.showSkeleton.next(false)
    }

    public setTitle(value: string) {
        this.store.titleStream.next(value ?? '')
    }

    public setNotes(value: string) {
        this.store.notesStream.next(value ?? '')
    }

    public setLocation(value: string) {
        this.store.locationStream.next(value ?? '')
    }

    public setInPersonLocationUrl(value: string) {
        this.store.inPersonLocationUrlStream.next(value ?? '')
    }

    public setMeetingMode(mode: MeetingMode) {
        this.store.meetingModeStream.next(mode ?? MeetingMode.Video)
    }

    public setEventSchedule(schedule: EventSchedule) {
        this.store.eventScheduleStream.next(schedule)
        if (schedule.start && schedule.end) {
            this.store.durationStream.next(
                Math.round((schedule.end.valueOf() - schedule.start.valueOf()) / 60 / 1000)
            )
        }
    }

    public setConferenceLinkType(type: UserConferenceLinkType) {
        this.store.conferenceLinkTypeStream.next(type)
    }

    public setIsPrivate(value: boolean) {
        this.store.isPrivate$.next(Boolean(value))
    }

    public setIsNonBlocking(value: boolean) {
        this.store.isNonBlocking$.next(Boolean(value))
    }

    public async selectScheduleMode(mode?: ScheduleMode) {
        if (!mode) {
            const availableModes = this.getAvailableScheduleModes(
                await this.store.meetingModeStream,
            )
            /**
             * Select first available schedule mode
             */
            mode = availableModes[0]
        }

        this.store.scheduleModeStream.next(mode)
    }

    public async selectConferenceMode(mode?: ConferenceMode) {
        if (!mode) {
            /**
             * Select default conference mode if no one provided
             */
            const availableModes = this.getAvailableConferenceModes(
                await this.store.meetingModeStream,
            )

            mode = availableModes[0]
        }

        this.store.conferenceModeStream.next(mode)
    }

    public async selectVisibilityMode(mode?: DockVisibility) {
        if (!mode) {
            /**
             * Select default conference mode if no one provided
             */
            const availableModes = this.getAvailableVisibilityModes(
                await this.store.meetingModeStream,
            )

            mode = availableModes[0]
        }

        this.store.visibilityModeStream.next(mode)
    }

    public async selectMeetingDuration(requestedDuration?: MeetingDuration) {
        const [ schedule, duration ] = await Promise.all([
            this.store.eventScheduleStream,
            this.store.durationStream,
        ])

        if (duration !== requestedDuration) {
            this.store.durationStream.next(requestedDuration)
            /**
             * Updating event schedule end date
             */
            if (schedule) {
                this.store.eventScheduleStream.next({
                    ...schedule,
                    end: m(schedule.start).add(requestedDuration, 'minutes').toDate(),
                })
            }
        }
    }

    public selectTimeZone(timeZone: TimezoneData) {
        this.store.selectedTimeZoneDataStream.next(timeZone)
    }

    public setEventAttendees(attendees: EventAttendee[]) {
        this.store.attendeesStream.next(attendees)
    }


    public async submitForm(
        additionalParams: Partial<EditMeetingData> = {},
    ) {
        const isValid: boolean = true
        // TODO: Validate form fields
        if (isValid) {
            this.store.onSubmit.next({
                ...await this.getUpdatedMeetingData(),
                ...additionalParams,
            })
        }
    }

    public async isMeetingHasUnsavedChanges(): Promise<boolean> {
        const meetingData = await this.getUpdatedMeetingData()
        const originalData = await this.store.originalEventDataStream

        /**
         * Drafts always `unsaved`
         */
        if (meetingData.isDraft) {
            return true
        }

        return meetingData.mode !== originalData.mode
            || meetingData.title !== originalData.title
            || meetingData.notes !== originalData.notes
            || meetingData.location !== originalData.location
            || !compareDeeply(meetingData.schedule, originalData.schedule)
            || !compareDeeply(
                meetingData.attendees.map(attendee => attendee.email),
                originalData.attendees.map(attendee => attendee.email)
            )
    }

    public async getUpdatedMeetingData(): Promise<EditMeetingData> {
        /**
         * Shouldn't mutate original data
         */
        const meetingData = clone(
            await this.store.originalEventDataStream
        )

        const [
            title,
            notes,
            attendees,
            meetingMode,
            scheduleMode,
            eventSchedule,
            visibilityMode,
            conferenceMode,
            meetingDuration,
            conferenceLinkType,
        ] = await Promise.all([
            this.store.titleStream,
            this.store.notesStream,
            this.store.attendeesStream,
            this.store.meetingModeStream,
            this.store.scheduleModeStream,
            this.store.eventScheduleStream,
            this.store.visibilityModeStream,
            this.store.conferenceModeStream,
            this.store.durationStream,
            this.store.conferenceLinkTypeStream,
        ])

        const [isPrivate, isNonBlocking] = await Promise.all([
            this.store.isPrivate$,
            this.store.isNonBlocking$,
        ])

        meetingData.mode = meetingMode
        meetingData.title = title
        meetingData.notes = notes
        meetingData.attendees = attendees
        meetingData.visibilityMode = visibilityMode
        meetingData.conferenceMode = conferenceMode
        meetingData.conferenceLinkType = conferenceLinkType
        meetingData.isPrivate = isPrivate
        meetingData.isNonBlocking = isNonBlocking

        if (scheduleMode === ScheduleMode.Schedule) {
            meetingData.schedule = eventSchedule
        } else {
            meetingData.schedule = {
                isAllDay: false,
                start: m().toDate(),
                end: m().add(meetingDuration, 'minutes').toDate(),
            }
        }

        if (!Validations.isNotEmptyString(meetingData.title)) {
            /**
             * Assign default title to the meeting if isn't set up
             */
            meetingData.title = await this.store.defaultMeetingTitleStream
        }

        /**
         * Saving meeting location
         */
        meetingData.location = await this.store.locationStream
        if (meetingData.mode === MeetingMode.InPerson) {
            meetingData.inPersonLocationUrl = await this.store.inPersonLocationUrlStream
        }

        if (meetingData.mode === MeetingMode.Broadcast) {
            /**
             * Hard-coded temporarily until shared-access mode is not implemented
             */
            meetingData.sharedAccessMode = meetingData.visibilityMode === DockVisibility.Connections ?
                DockSharedAccessMode.Connections : DockSharedAccessMode.Link
        }

        return meetingData
    }

    /**
     * Modifies original event data (saves draft meeting)
     */
    public async assignNewDraftDock(): Promise<void> {
        /**
         * Remove current dock if exist
         */
        await this.removeAgendaAndDraftDock()

        this.store.showSkeleton.next(true)

        const [
            draftDockId, originalData,
        ] = await Promise.all([
            this.api.meet.dock.createDraft(),
            this.store.originalEventDataStream,
        ])
        this.store.notesStream.next('')
        this.store.dockIdStream.next(draftDockId)
        this.store.originalEventDataStream.next({ ...originalData, dockId: draftDockId })
        this.store.showSkeleton.next(false)
    }

    /**
     * Modifies original event data (saves draft meeting)
     */
    public async removeAgendaAndDraftDock(): Promise<void> {
        this.store.showSkeleton.next(true)
        if (this.store.dockIdStream.value) {
            /**
             * This is not important for current flow to proceed
             */
            this.api.meet.dock.deleteById(this.store.dockIdStream.value)
                .catch(error => console.error(`Cannot delete draft dock`, error))

            const originalData = await this.store.originalEventDataStream

            this.store.notesStream.next('')
            this.store.dockIdStream.next(null)
            this.store.originalEventDataStream.next({ ...originalData, dockId: null })

            /**
             * Update draft meeting
             */
            await this.api.meet.meetings
                      .updateDraftMeeting(originalData._id, { dockId: null })
        }
        this.store.showSkeleton.next(false)
    }

    /**
     * Modifies original event data (saves draft meeting)
     */
    public async applyUserCommand(plan: Plan): Promise<void> {
        this.store.showSkeleton.next(true)

        /**
         * Loading duplicated draft dock entity
         */
        const draftDock = await DockCollection.one(
            await this.api.meet.dock.duplicate(plan.draftDockId)
        ).get()

        if (draftDock) {
            this.store.planIdStream.next(plan._id)
            this.store.dockIdStream.next(draftDock.id)
            this.store.notesStream.next(draftDock.note)
            const originalData = await this.store.originalEventDataStream
            this.store.originalEventDataStream.next({ ...originalData, dockId: draftDock.id })
            await this.api.meet.meetings.updateDraftMeeting(originalData._id, { dockId: draftDock.id })
        }

        this.store.showSkeleton.next(false)
    }

    /**
     * Reloads events cache to display all changes
     */
    public async reloadPossiblyMutatedDashboardRanges() {
        const [updatedData, originalData ] = await Promise.all([
            this.getUpdatedMeetingData(), this.store.originalEventDataStream,
        ])

        const daysToUpdate: Array<string> = []

        daysToUpdate.push(
            m(updatedData.schedule.end).startOf('day').toISOString()
        )

        daysToUpdate.push(
            m(updatedData.schedule.start).startOf('day').toISOString()
        )

        if (originalData.schedule.end) {
            daysToUpdate.push(
                m(originalData.schedule.end).startOf('day').toISOString()
            )
        }

        if (originalData.schedule.start) {
            daysToUpdate.push(
                m(originalData.schedule.start).startOf('day').toISOString()
            )
        }

        /**
         * Reloading all affected ranges of events to be sure changes are applied
         */
        await Promise.all(
            ArrayHelpers.filterUnique(daysToUpdate).map(async day => {
                return this.calendarEventsStorage.getEventsForDateRange({
                    start: new Date(day), end: m(day).endOf('day').toDate(),
                }, true)
            })
        )
    }


    protected getVisibilityMode(meeting: EditMeetingData): DockVisibility {
        return meeting?.visibilityMode
    }

    protected getConferenceMode(meeting?: EditMeetingData): ConferenceMode {
        return meeting?.conferenceMode
    }

    protected getMeetingDuration(meeting: EditMeetingData): MeetingDuration {
        if (meeting.schedule && meeting.schedule.start && meeting.schedule.end) {
            /**
             * Calculate duration if meeting has start and end dates
             */
            return Math.abs(
                m(meeting.schedule.end).diff(meeting.schedule.start, 'minutes'),
            )
        }

        return AVAILABILITY_SLOT_DURATION
    }

    protected getAvailableScheduleModes(meetingMode: MeetingMode): ScheduleMode[] {
        if (meetingMode === MeetingMode.Broadcast) {
            return [
                ScheduleMode.Instant, ScheduleMode.Schedule,
            ]
        }

        return [ScheduleMode.Schedule]
    }

    protected getAvailableVisibilityModes(meetingMode: MeetingMode): DockVisibility[] {
        if (meetingMode === MeetingMode.Broadcast) {
            return [
                DockVisibility.Connections, DockVisibility.Participants,
            ]
        }

        return [DockVisibility.Participants]
    }

    protected getAvailableConferenceModes(meetingMode: MeetingMode): ConferenceMode[] {
        if (meetingMode === MeetingMode.Broadcast) {
            return [
                ConferenceMode.Individual, ConferenceMode.Forum,
            ]
        }

        return [ConferenceMode.Room]
    }

    protected subscribeForBrowserTimeZone() {
        /**
         * Bind browser time zone stream in the store
         */
        this.browserTime.timeZoneDataStream.pipe(
            takeUntil(this.destroyEvent)
        ).subscribe(
            timeZone => this.store.browserTimeZoneDataStream.next(timeZone)
        )
    }

    protected subscribeForSelectedMeetingMode(): Subscription {
        return this.store.meetingModeStream.pipe(
            distinctUntilChanged(),
            takeUntil(this.destroyEvent),
        ).subscribe(async meetingMode => {
            const [
                selectedScheduleMode, selectedConferenceMode, selectedVisibilityMode,
                scheduleModesAvailable, conferenceModesAvailable, visibilityModesAvailable,
            ] = await Promise.all([
                this.store.scheduleModeStream,
                this.store.conferenceModeStream,
                this.store.visibilityModeStream,
                this.getAvailableScheduleModes(meetingMode),
                this.getAvailableConferenceModes(meetingMode),
                this.getAvailableVisibilityModes(meetingMode),
            ])

            if (!scheduleModesAvailable.includes(selectedScheduleMode)) {
                /**
                 * Force select default schedule mode if current is wrong
                 */
                await this.selectScheduleMode()
            }

            if (!conferenceModesAvailable.includes(selectedConferenceMode)) {
                /**
                 * Force select default conference mode if current is wrong
                 */
                await this.selectConferenceMode()
            }

            if (!visibilityModesAvailable.includes(selectedVisibilityMode)) {
                /**
                 * Force select default visibility mode if current is wrong
                 */
                await this.selectVisibilityMode()
            }

            if (meetingMode === MeetingMode.Broadcast) {
                /**
                 * Broadcasts should be instant at default
                 */
                await this.selectScheduleMode(ScheduleMode.Instant)

                /**
                 * Should select 60 as default duration for Broadcasts
                 */
                await this.selectMeetingDuration(60)

                /**
                 * Broadcasts should be visible for connections at default
                 */
                await this.selectVisibilityMode(DockVisibility.Connections)
            }
        })
    }

    protected subscribeForAvailableDurationOptions(): Subscription {
        return this.meetingDurationOptionsProvider
                   .currentUserAvailableMeetingDurationOptionsStream.pipe(
                        takeUntil(this.destroyEvent)
                    ).subscribe(
                        options => this.store.availableMeetingDurationOptionsStream.next(options)
                    )
    }
}

export type EventFormState = State<EventFormStore>
