<section class="image-upload-preview-container">
    <div class="image-preview-box">
        <img [src]="uploadedImageUrl" [default]="placeholderImageUrl" alt />
    </div>

    <div class="controls-box">
        <p class="description">{{ description }}</p>
        <div class="actions-box">
            <app-image-upload
                    (onUploaded)="onImageUploaded.next($event)"
            ></app-image-upload>

            <button class="delete-button" mat-button
                    *ngIf="showDeleteButton" (click)="onImageUploaded.next(null)"
            >Delete</button>
        </div>
    </div>
</section>
