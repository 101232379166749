import { Injectable } from '@angular/core'

import { CurrentUser } from '@undock/session'

import { FirestoreUserCollection } from '@undock/user'

import { AbstractFactory } from '@undock/core/models/factories/abstract.factory'

import { Dock } from '@undock/dock/meet/models/dock.model'
import {
    ActionItem,
    ActionItemsCollection,
} from '@undock/dock/meet/models/action-item.model'


@Injectable()
export class ActionItemFactory extends AbstractFactory<ActionItem> {

    protected defaultProperties: Partial<ActionItem> = {
        text: '',
        weight: 1,
        complete: false,
        position: 99999,
        removed: false,
    }

    public constructor(
        private currentUser: CurrentUser,
    ) { super() }

    public async create(
        dock: Dock, properties: Partial<ActionItem> = {},
    ): Promise<ActionItem> {
        let [item, currentUserModel] = await Promise.all([
            ActionItemsCollection.new(),
            FirestoreUserCollection.one(await this.currentUser.id).get(),
        ])

        this.assignDefaultData(item, properties)

        item.dock.link(dock)
        item.dockId = dock.id

        item.author.link(currentUserModel)
        item.authorId = currentUserModel.id

        item.isInitialized = true

        return item
    }
}
