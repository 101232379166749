import { ApiRoute } from '@undock/api'

export class InviteRoute extends ApiRoute {

    public init() {
        this.route = 'organizations/invite'
    }

    public create(
        organizationId: string,
        invitedUserEmails: string[],
    ) {
        return this.post()
                   .withData({
                       organizationId,
                       invitedUserEmails,
                   })
                   .endpoint(`create`)
    }

    public getById(inviteId: string) {
        return this.get()
                   .endpoint(`id/${inviteId}`)
    }

    public respond(data: any) {
        return this.post()
                   .withData(data)
                   .endpoint(`respond`)
    }
}
