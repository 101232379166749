import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Input,
    OnInit,
    QueryList,
    Self,
    ViewChild,
} from '@angular/core'
import {
    DOWN_ARROW,
    END,
    ENTER,
    HOME,
    UP_ARROW,
} from '@angular/cdk/keycodes'
import {
    ControlValueAccessor,
    FormControl,
    NgControl,
} from '@angular/forms'
import { MatOption } from '@angular/material/core'
import { MatSelect } from '@angular/material/select'

import {
    BehaviorSubject,
    combineLatest,
    Observable,
} from 'rxjs'
import {
    delay,
    map,
    take,
    takeUntil,
} from 'rxjs/operators'
import {
    DestroyEvent,
    EmitOnDestroy,
} from '@typeheim/fire-rx'

// match in css file as (5+1)*optionHeight + padding
const SCROLL_PADDING = 4
const SCROLL_OPTIONS_COUNT = 5
const SCROLL_OPTION_HEIGHT = 45
const SCROLL_HEIGHT = SCROLL_OPTION_HEIGHT * (1 + SCROLL_OPTIONS_COUNT) + 2 * SCROLL_PADDING

@Component({
    selector: 'app-select-filter-input',
    templateUrl: './select-filter-input.component.html',
    styleUrls: ['./select-filter-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectFilterInputComponent implements OnInit, ControlValueAccessor {

    private readonly SELECT_OFFSET = 1

    @Input() placeholder = 'Search...'

    private ignoredOptionSubject = new BehaviorSubject<MatOption>(null)

    @Input() set ignoredOption(value: MatOption) {
        this.ignoredOptionSubject.next(value)
    }

    private domOptionsSubject: BehaviorSubject<QueryList<MatOption>> = new BehaviorSubject<QueryList<MatOption>>(null)

    private previousFirstOption: MatOption

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    @ViewChild('noResults', { read: ElementRef, static: true }) noResults: ElementRef
    @ViewChild('searchSelectInput', { read: ElementRef, static: true }) searchSelectInput: ElementRef
    @ViewChild('selectFilterInputFieldWrapper', { read: ElementRef, static: true }) searchWrapperElement: ElementRef

    public filterFormControl: FormControl = new FormControl('')

    public get options(): MatOption[] {
        return this.domOptionsSubject.getValue().toArray()
    }

    constructor(
        private changeDetector: ChangeDetectorRef,
        @Self() private ngControl: NgControl,
        @Inject(MatSelect) private matSelect: MatSelect,
        @Inject(MatOption) private matOption: MatOption = null,
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this
        }
    }

    /**
     * Implement all required methods for ngControl.valueAccessor
     */
    public onChange = (v) => {}
    public onTouched: Function = () => {}
    public registerOnTouched = (fn: Function) => { this.onTouched = fn }
    public registerOnChange = (fn: (value: string) => void) => {
        this.filterFormControl.valueChanges.subscribe(fn)
        this.onChange = fn
    }
    public writeValue = (value: string) => {
        this.filterFormControl.setValue(value)
        this.changeDetector.detectChanges()
    }

    ngOnInit() {
        this.matSelect.panelClass = (this.matSelect.panelClass
            ? `${this.matSelect.panelClass} `
            : ''
        ) + 'filter-input-position-fix'

        this.matSelect.openedChange
            .pipe(
                delay(1),
                takeUntil(this.destroyedEvent),
            )
            .subscribe((opened) => {
                if (opened) {
                    this.focusFilterInput()
                    this.syncInputWidthWithOptions()
                    this.syncSelectScrollPosition()
                } else {
                    this.resetForm()
                }
            })

        this.matSelect.openedChange
            .pipe(take(1))
            .pipe(takeUntil(this.destroyedEvent))
            .subscribe(() => {
                const keyManager = this.matSelect._keyManager
                if (keyManager) {
                    keyManager.change
                              .pipe(takeUntil(this.destroyedEvent))
                              .subscribe(() => this.syncSelectScrollPosition())
                }

                const domOptions = this.matSelect.options
                this.domOptionsSubject.next(domOptions)
                this.previousFirstOption = this.options[this.SELECT_OFFSET]

                // reset first filter element in Options set was changed
                domOptions
                    .changes
                    .pipe(
                        delay(1),
                        takeUntil(this.destroyedEvent),
                    )
                    .subscribe((data) => {
                        // offset on 1 element from filter-option
                        const currentFirstOption = this.options[this.SELECT_OFFSET]
                        if (keyManager && this.matSelect.panelOpen) {
                            // update width of container
                            const isSame = this.isSameFirstOption(currentFirstOption)
                            const selectedOptionExists = this.isSelectedOptionExists()
                            if (!isSame || !selectedOptionExists) {
                                keyManager.setFirstItemActive()
                            }
                            this.syncInputWidthWithOptions()
                            this.syncSelectScrollPosition()
                        }
                        this.previousFirstOption = currentFirstOption
                    })
            })

        this.emptyListObservable.pipe(
            takeUntil(this.destroyedEvent),
        ).subscribe(isEmpty => {
            if( isEmpty ){
                this.noResults.nativeElement.classList.add('visible')
                this.filterOptionHostElement.classList.add('no-results')
            } else {
                this.noResults.nativeElement.classList.remove('visible')
                this.filterOptionHostElement.classList.remove('no-results')
            }
        })

        this.domOptionsSubject.pipe(
            takeUntil(this.destroyedEvent),
        ).subscribe(() => {
            this.changeDetector.detectChanges()
        })

    }

    private syncSelectScrollPosition(): void {
        const { matSelect: { panel, _keyManager, options }, optionHeight } = this
        if (panel && options.length > 0) {
            const element = panel.nativeElement
            const selectedOptionIndex = _keyManager.activeItemIndex || 0
            const indexOfOptionToFitIntoView = selectedOptionIndex - 1
            const scrollPosition = element.scrollTop

            const wrapperHeight = this.searchWrapperElement.nativeElement.offsetHeight
            const visibleOptionsCount = Math.floor((SCROLL_HEIGHT - wrapperHeight) / optionHeight)
            const firstOptionIndex = Math.round((scrollPosition + wrapperHeight) / optionHeight) - 1

            if (firstOptionIndex >= indexOfOptionToFitIntoView) {
                element.scrollTop = indexOfOptionToFitIntoView * optionHeight
            } else if (firstOptionIndex + visibleOptionsCount <= indexOfOptionToFitIntoView) {
                element.scrollTop = (indexOfOptionToFitIntoView + 1) * optionHeight - (SCROLL_HEIGHT - wrapperHeight)
            }
        }
    }

    public syncInputWidthWithOptions() {
        if (!this.searchWrapperElement || !this.searchWrapperElement.nativeElement) {
            return
        }
        let element: HTMLElement = this.searchWrapperElement.nativeElement
        let panelElement: HTMLElement
        let parentElement = element.parentElement

        while (!panelElement && parentElement) {
            if (element.classList.contains('mat-select-panel')) {
                panelElement = element
                break
            }
            element = parentElement
            parentElement = element.parentElement
        }

        if (panelElement) {
            panelElement.classList.add('with-filter')
            this.searchWrapperElement.nativeElement.style.width = (panelElement.clientWidth - 2 * SCROLL_PADDING) + 'px'
        }
    }

    private get filterOptionHostElement(): HTMLElement {
        return this.matOption._getHostElement()
    }

    private get optionHeight(): number {
        if (this.matSelect.options.length) {
            return this.matSelect.options.first._getHostElement().getBoundingClientRect().height
        }
        return 0
    }

    public get isCleanButtonVisible(): boolean {
        return Boolean(this.filterFormControl.value)
    }

    public get emptyListObservable(): Observable<boolean> {
        return combineLatest([
            this.filterFormControl.valueChanges,
            this.domOptionsSubject,
            this.ignoredOptionSubject,
        ]).pipe(
            // 1 is filter mat-option, 2 is hidden current selected mat-option
            map(([value, options, ignoredOption]) => {
                return value && (ignoredOption ? 2 : 1) === options?.length
            }),
        )
    }

    public focusFilterInput(): void {
        if (this.searchSelectInput) {
            this.searchSelectInput.nativeElement.focus()
        }
    }

    public resetForm() {
        this.filterFormControl.setValue('')
        this.focusFilterInput()
    }

    public onKeyDown(event: KeyboardEvent) {
        const { key, keyCode } = event
        if (1 === key.length && /[a-zA-Z0-9 ]/img.test(key) || ([HOME, END].includes(keyCode))) {
            event.stopPropagation()
        }
    }

    public onKeyUp(event: KeyboardEvent) {

        /**
         * @todo
         * UP_ARROW - select last
         * DOWN_ARROW - sometimes it doesn't select first element
         * UP_ARROW - verify if we need to select first and change select
         */

        const { keyCode } = event

        switch (keyCode) {
            case UP_ARROW:
                break
            case DOWN_ARROW:
                break
            case ENTER:
                break
        }
    }

    private isSameFirstOption(option: MatOption): boolean {
        return this.matSelect.compareWith(this.previousFirstOption, option)
    }

    private isSelectedOptionExists(): boolean {
        const activeItem = this.matSelect?._keyManager?.activeItem
        return Boolean(this.options.find(option => this.matSelect.compareWith(option, activeItem)))
    }

}
