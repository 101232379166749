import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    Injector,
    Input,
    TemplateRef,
    ViewChild,
} from '@angular/core'
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations'
import { ConfirmPopupService } from '@undock/common/ui-kit'

import {
    combineLatest,
    fromEvent,
    Observable,
} from 'rxjs'
import {
    debounceTime,
    distinctUntilChanged,
    map,
    switchMap,
    takeUntil,
} from 'rxjs/operators'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    StatefulSubject,
    ValueSubject,
} from '@typeheim/fire-rx'

import {
    Attachment,
    AttachmentsManager,
    generateFileName,
    Memoize,
} from '@undock/core'
import { CurrentUser } from '@undock/session'
import { Topic } from '@undock/dock/meet/models/topic.model'
import { TopicsManager } from '@undock/dock/meet/services/topics.manager'
import { TooltipPosition } from '@undock/common/ui-kit/contracts/tooltip.position'
import { DockParticipant } from '@undock/dock/meet/models/dock/dock-participant.model'
import { ProfilesProvider } from '@undock/user/services/profiles.provider'
import { RecorderComponent } from '@undock/core/components/recorder/recorder.component'
import { SimpleModalService } from 'ngx-simple-modal'
import { ParentDialogModalComponent } from '@undock/common/ui-kit/ui/components'
import {
    TOPICS_ADAPTER,
    TopicsAdapterInterface,
} from '@undock/dock/meet/contracts/ui-adapters/topics.adapter'
import { UserData } from '@undock/user'

export const isTopicCompleteAnimation = trigger('isComplete', [
    state('true', style({
        background: '#2B2733',
        borderColor: '#2B2733',
        color: '#ffffff',
    })),
    state('false', style({
        background: '#ffffff',
        borderColor: '#CECADA',
        color: '#ffffff',
    })),
    transition('true <=> false', [
        animate('0.2s'),
    ]),
])

@Component({
    selector: 'app-meet-topic-view',
    templateUrl: 'topic-view.component.html',
    styleUrls: ['topic-view.component.scss'],
    animations: [
        isTopicCompleteAnimation
    ],
})
export class TopicViewComponent implements AfterViewInit {

    public readonly TooltipPosition = TooltipPosition

    @Input() displayCdkDragToggle: boolean = true


    @ViewChild(RecorderComponent)
    protected recorder: RecorderComponent

    @ViewChild('topicTextInput')
    protected topicTextInput: ElementRef<HTMLInputElement>


    public readonly currentTopicStream: ReactiveStream<Topic>


    @CompleteOnDestroy()
    public readonly videPlayerEnabledStream = new ValueSubject(false)

    @CompleteOnDestroy()
    public readonly isVideoProcessingStream = new ValueSubject(false)


    @CompleteOnDestroy()
    protected currentTopicSubject = new StatefulSubject<Topic>()

    protected readonly topicTextSavingDebounceTime = 500 // ms


    @EmitOnDestroy()
    protected readonly destroyedEvent = new DestroyEvent()

    @ViewChild('contentTemplate') contentTemplate: TemplateRef<any>

    public constructor(
        protected injector: Injector,
        protected currentUser: CurrentUser,
        @Inject(TOPICS_ADAPTER)
        protected topicsAdapter: TopicsAdapterInterface,
        protected confirmService: ConfirmPopupService,
        protected profilesProvider: ProfilesProvider,
        private simpleModalService: SimpleModalService,
    ) {
        this.currentTopicStream = this.currentTopicSubject.asStream()
        this.videPlayerEnabledStream.subscribe(status => {
            if (status) {
                this.simpleModalService.addModal(ParentDialogModalComponent, { contentTemplate: this.contentTemplate })
            } else {
                this.simpleModalService.removeAll().then().catch()
            }
        })
    }


    @Input('topic') set topic(topic: Topic) {
        this.currentTopicSubject.next(topic)
    }

    /**
     * --------------------------------------------------------
     *         Providers with deferred initialization
     * --------------------------------------------------------
     */

    @Memoize()
    protected get topicsManager(): TopicsManager {
        return this.injector.get(TopicsManager)
    }

    @Memoize()
    protected get attachmentsManager(): AttachmentsManager {
        return this.injector.get(AttachmentsManager)
    }


    @Memoize()
    public get isEditModeStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(
            combineLatest([
                this.currentTopicSubject,
                this.currentUser.dataStream,
                this.topicsAdapter.isEditModeStream,
            ]).pipe(
                map(sources => {
                    const [topic, user, isEditMode] = sources

                    return isEditMode ? isEditMode : (topic.authorId === user._id)
                }),
            ),
        )
    }

    @Memoize()
    public get videoAttachmentStream(): ReactiveStream<Attachment> {
        return new ReactiveStream(this.currentTopicSubject.pipe(
            distinctUntilChanged(
                (prev, next) => prev.id === next.id,
            ),
            switchMap(
                topic => topic.attachmentsStream,
            ),
            map(attachments => attachments[0]),
        ))
    }

    @Memoize()
    public get hasVideoAttachmentStream(): Observable<boolean> {
        return this.currentTopicSubject.pipe(
            distinctUntilChanged(
                (prev, next) => prev.id === next.id,
            ),
            switchMap(
                topic => topic.attachmentsStream,
            ),
            map(attachments => attachments?.length > 0),
        )
    }

    @Memoize()
    public get attachmentUrl(): Observable<string> {
        return this.videoAttachmentStream
                   .pipe(switchMap(attachment => {
                       if (!attachment) {
                           return Promise.resolve(null)
                       }
                       return this.attachmentsManager.generateAttachmentUrl(attachment)
                   }))
    }

    @Memoize()
    public get assignedUsersIdsStream(): Observable<string[]> {
        return this.currentTopicStream.pipe(
            takeUntil(this.destroyedEvent),
            map(
                topic => [topic.assignedUserId].filter(assignedUserId => !!assignedUserId),
            ),
        )
    }

    public async ngAfterViewInit() {
        if ((await this.isEditModeStream) && this?.topicTextInput?.nativeElement) {
            fromEvent<KeyboardEvent>(
                this.topicTextInput.nativeElement, 'keyup',
            ).pipe(
                takeUntil(this.destroyedEvent),
                debounceTime(this.topicTextSavingDebounceTime),
            ).subscribe(
                async () => this.topicsManager.save(await this.currentTopicSubject),
            )
        }
    }

    public async likeTopic() {
        /**
         * @TODO: Finish method.
         */
    }

    public async removeTopic() {
        const confirmed = await this.confirmService.open({
            title: 'Do you want to delete this topic?',
            description: 'This action cannot be undone.',
            confirmButtonLabel: 'Delete',
            discardButtonLabel: 'Cancel',
        })

        if (confirmed) {
            await this.removeAttachment()
            await this.topicsManager.remove(await this.currentTopicSubject)
        }
    }

    public async toggleCompleteStatus() {
        await this.topicsManager.toggleCompleteStatus(await this.currentTopicSubject)
    }

    public async removeAttachment(togglePlayer = true) {
        let attachment = await this.videoAttachmentStream
        if (attachment) {
            this.isVideoProcessingStream.next(true)
            this.videPlayerEnabledStream.next(false)
            await this.attachmentsManager.removeAttachment(attachment)
            this.isVideoProcessingStream.next(false)
        }
    }

    public toggleVideoPlayer() {
        let nextState = !this.videPlayerEnabledStream.value
        this.videPlayerEnabledStream.next(nextState)
    }

    public async addNewVideo(deleteCurrent = true) {
        if (this.recorder) {
            this.isVideoProcessingStream.next(true)

            if (deleteCurrent) {
                await this.removeAttachment()
            }

            await this.recorder.startRecording()

            this.isVideoProcessingStream.next(false)
        } else {
            console.warn(`Could not reload new video: Recorder doesn't exists`)
        }
    }


    public async onRecordingReady(blob: Blob) {
        this.isVideoProcessingStream.next(true)

        const topic = await this.currentTopicSubject

        await this.attachmentsManager.addAttachments(topic, [{
            name: await this.generateFileNameForRecording(), target: blob,
        }])

        this.isVideoProcessingStream.next(false)
    }

    public async onParticipantSelectedEvent(user: UserData): Promise<void> {
        if (user && user.id) {
            await this.topicsManager.assignUser(
                await this.currentTopicSubject,
                await this.profilesProvider.getProfileById(user.id),
            )
        } else {
            await this.topicsManager.detachUser(await this.currentTopicSubject)
        }
    }

    protected async generateFileNameForRecording(): Promise<string> {
        const topic = await this.currentTopicSubject

        let preparedTopicText = topic.text.slice(0, 20)
                                     .replace(/\s/, '-')

        return `${preparedTopicText}_${generateFileName('mp4')}`
    }

    public processMeetingAction($event: string): any {
        switch ($event) {
            case 'delete':
                return this.removeTopic()
        }
    }
}
