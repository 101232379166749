import {
    Input,
    Directive,
    TemplateRef,
    ViewContainerRef,
    ChangeDetectorRef,
} from '@angular/core'
import {
    Overlay,
    OverlayRef,
    ConnectedPosition,
} from '@angular/cdk/overlay'
import { TemplatePortal } from '@angular/cdk/portal'

import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'


@Directive({
    selector: '[udDropdown]',
    exportAs: 'udDropdownRef'
})
export class DropdownDirective {

    /**
     * TODO: Find better name directive and inputs
     */
    public readonly isOpenedStream: ReactiveStream<boolean>

    protected overlayRef: OverlayRef
    protected resizeObserver: ResizeObserver

    /**
     * Dropdown fits to the host element
     */
    @Input() dropdownHostEl: HTMLElement

    @Input() dropdownAutoOpen: boolean = true

    /**
     * Reference to the dropdown content tmpl
     */
    @Input() overlayContentRef: TemplateRef<any>

    @CompleteOnDestroy()
    protected isOpenedSubject = new ValueSubject(false)

    @EmitOnDestroy()
    protected readonly destroyEvent = new DestroyEvent()

    public constructor(
        protected readonly overlay: Overlay,
        protected readonly viewContainerRef: ViewContainerRef,
        protected readonly changeDetectorRef: ChangeDetectorRef,
    ) {
        this.isOpenedStream = this.isOpenedSubject.asStream()
    }

    public get isOpened(): boolean {
        return this.isOpenedSubject.getValue()
    }

    public ngOnInit() {
        if (!this.dropdownHostEl) {
            this.dropdownHostEl = this.viewContainerRef.element.nativeElement
        }
    }

    public ngAfterViewInit() {
        this.initOverlay()
        this.initListeners()
    }

    public open(): void {
        if (!this.isOpenedSubject.value) {
            if (this.overlayRef) {
                this.overlayRef.attach(
                    new TemplatePortal(
                        this.overlayContentRef, this.viewContainerRef,
                    ),
                )

                if (this.dropdownHostEl) {
                    /**
                     * Set the observed element width by default
                     */
                    this.setOverlayWidth(this.dropdownHostEl.offsetWidth)
                }
            }

            this.isOpenedSubject.next(true)
        }
    }

    public close(): void {
        if (this.isOpenedSubject.value) {
            if (this.overlayRef) {
                this.overlayRef.detach()
            }
            this.isOpenedSubject.next(false)
        }
    }

    public toggle(): void {
        this.isOpenedSubject.value ? this.close() : this.open()
    }

    protected initOverlay() {
        const positionStrategy = this.overlay
                                     .position()
                                     .flexibleConnectedTo(this.dropdownHostEl)
                                     .withPositions([{
                                         originX: 'start',
                                         originY: 'bottom',
                                         overlayX: 'start',
                                         overlayY: 'top',
                                     } as ConnectedPosition])
                                     .withPush(false)

        this.overlayRef = this.overlay.create({
            positionStrategy,
            scrollStrategy: this.overlay.scrollStrategies.noop(),
        })

        const observedElement = this.dropdownHostEl
        if (observedElement) {
            /**
             * Set the observed element width by default
             */
            this.setOverlayWidth(observedElement.offsetWidth)

            /**
             * Create an observer to track the observed element dimensions change
             */
            this.resizeObserver = new ResizeObserver((events) => {
                if (Array.isArray(events) && events.length > 0) {
                    this.setOverlayWidth(events[0].borderBoxSize[0].inlineSize)
                }
            })
            this.resizeObserver.observe(observedElement)
            this.destroyEvent.subscribe(() => this.resizeObserver.unobserve(observedElement))
        }
    }

    protected initListeners() {
        const listener = (event: MouseEvent) => {
            if (
                event.target instanceof SVGElement ||
                event.target instanceof HTMLElement
            ) {
                if (
                    this.dropdownHostEl.contains(event.target) ||
                    this.overlayRef.overlayElement.contains(event.target)
                ) {
                    if (this.dropdownAutoOpen) {
                        this.open()
                    }
                } else {
                    this.close()
                }
            }
        }

        document.body.addEventListener('click', listener)
        document.body.addEventListener('focusin', listener)

        this.destroyEvent.subscribe(() => {
            this.close()
            document.body.removeEventListener('click', listener)
            document.body.removeEventListener('focusin', listener)
        })
    }

    protected setOverlayWidth(width: number): void {
        if (this.overlayRef?.overlayElement) {
            this.overlayRef.overlayElement.style.width = `${width}px`
            this.changeDetectorRef.detectChanges()
        }
    }
}
