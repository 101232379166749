<ng-container [ngSwitch]="isDisabled">
    <ng-container *ngSwitchCase="true">
        <div class="event-options-icon-wrapper"
             [ngSwitch]="iconTemplate | boolean"
        >
            <ng-container
                    *ngSwitchCase="true" [ngTemplateOutlet]="iconTemplate"
            ></ng-container>

            <ng-container *ngSwitchCase="false">
                <button class="event-more-button expand-button button" mat-button>
                    <i class="material-icons-outlined button-icon">more_vert</i>
                </button>
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="false">
        <div class="event-options-icon-wrapper"
             [ngSwitch]="iconTemplate | boolean"
             [matMenuTriggerFor]="eventOptionsTmpl"
             (click)="$event.stopPropagation()"
        >
            <ng-container
                *ngSwitchCase="true" [ngTemplateOutlet]="iconTemplate"
            ></ng-container>

            <ng-container *ngSwitchCase="false">
                <button class="event-more-button expand-button button" mat-button>
                    <i class="material-icons-outlined button-icon">more_vert</i>
                </button>
            </ng-container>
        </div>

        <mat-menu #eventOptionsTmpl>
            <ng-template matMenuContent>
                <ng-container *ngIf="!hideRsvp">
                    <ng-container *ngIf="!canChangeRsvp; else fullRsvpMenu" class="button">
                        <button mat-menu-item>
                        <span class="material-menu-item-box -rsvp">
                            RSVP
                            <ng-container [ngTemplateOutlet]="rsvpStatuses"></ng-container>
                        </span>
                        </button>
                    </ng-container>

                    <ng-template #fullRsvpMenu>
                        <button mat-menu-item [matMenuTriggerFor]="rsvpAvailability" class="button">
                        <span class="material-menu-item-box -rsvp">
                            RSVP
                            <ng-container [ngTemplateOutlet]="rsvpStatuses"></ng-container>
                        </span>

                            <mat-menu #rsvpAvailability>
                                <button mat-menu-item class="button">
                                    <span class="material-menu-item-box" (click)="rsvpYes()">Yes</span>
                                </button>
                                <button *ngIf="canUseRsvpNo" mat-menu-item class="button">
                                    <span class="material-menu-item-box" (click)="rsvpNo()">No</span>
                                </button>
                                <button mat-menu-item class="button">
                                    <span class="material-menu-item-box" (click)="rsvpMaybe()">Maybe</span>
                                </button>
                            </mat-menu>
                        </button>
                    </ng-template> <!-- #fullRsvpMenu -->

                    <ng-template #rsvpStatuses>
                        <ng-container [ngSwitch]="currentRsvpStatus">
                            <span *ngSwitchCase="RsvpStatus.Declined">No</span>
                            <span *ngSwitchCase="RsvpStatus.Accepted">Yes</span>
                            <span *ngSwitchCase="RsvpStatus.Tentative">Maybe</span>
                        </ng-container>
                    </ng-template> <!-- #rsvpStatuses -->
                </ng-container>

                <ng-container *ngIf="isRescheduleAllowed">
                    <button mat-menu-item (click)="rescheduleEvent()" class="button">
                        <a class="material-menu-item-box">Reschedule</a>
                    </button>
                </ng-container>

                <ng-container *ngIf="isDuplicateAllowed ">
                    <button mat-menu-item (click)="duplicateEvent()" class="button">
                        <a class="material-menu-item-box">Duplicate</a>
                    </button>
                </ng-container>

                <button mat-menu-item (click)="deleteEvent()" class="button">
                    <a class="material-menu-item-box -delete">Delete</a>
                </button>
            </ng-template>
        </mat-menu>
    </ng-container>
</ng-container>
