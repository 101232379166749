import { Injectable } from '@angular/core'
import {
    State,
    StateModel,
    StreamStore,
} from '@typeheim/fluent-states'
import { ValueSubject } from '@typeheim/fire-rx'


@Injectable({
    providedIn: 'root',
})
export class LayoutStateModel extends StateModel<LayoutStore> {
    protected store = new LayoutStore()

    public switchToMobileAppMode() {
        this.store.layoutEnabledStream.next(false)
    }

    public switchToWebAppMode() {
        this.store.layoutEnabledStream.next(false)
    }

    public switchToChromeExtMode() {
        this.store.layoutEnabledStream.next(false)
    }

}

export class LayoutStore extends StreamStore {
    public layoutEnabledStream: ValueSubject<boolean> = new ValueSubject<boolean>(true)
}

export type LayoutState = State<LayoutStore>
