import { Component } from '@angular/core'


@Component({
    selector: 'app-critical-error',
    template: `
        <div class="critical-error-container" style="display: flex">
            
            <h2>Critical error caused</h2>
            
            <p class="description">
                You can try to go homepage. <br/>
                If the error will happen again please click <b>Clear site data</b> button. 
            </p>
            
            <div class="actions">
                <button udButton class="home" (click)="goToHomePage()">Home</button>
                <button udButton class="clear" (click)="clearSiteData()">Clear site data</button>
            </div>
        </div>
    `,
    styles: [`
        :host {
            width: 100%;
            height: 100%;
            
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: center;
        }

        .critical-error-container {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;

            padding: 2.8rem 1.9rem;
            border-radius: 0.8rem;
            
            min-width: 52rem;
            
            color: white;
            background: rgba(0, 0, 0, 0.45);
        }
        
        h2 {
            font-size: 3.2rem;
            font-family: 'Inter', sans-serif;
        }
        
        .description {
            font-size: 1.6rem;
            text-align: center;
            color: rgba(255, 255, 255, 0.8);
            font-family: 'Inter', sans-serif;
        }
        
        .actions {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            
            width: 100%;
            margin-top: 2rem;
        }
        
        .actions .home {
            color: black;
            background: white;
            margin-right: 2rem;
        }
    `],
})
export class CriticalErrorPage {

    public goToHomePage() {
        /**
         * Reloads the page
         */
        window.location.href = `/`
    }

    public async clearSiteData() {
        /**
         * Clearing all Local/Session Storage data
         */
        try {
            window.localStorage.clear()
            window.sessionStorage.clear()
        } catch (error) {
            console.warn(`Unable clear Local/Session storage`, error)
        }

        /**
         * Clearing all site Cookies
         */
        try {
            let cookies = document.cookie.split(' ')
            for (let c = 0; c < cookies.length; c++) {
                let d = window.location.hostname.split('.')
                while (d.length > 0) {
                    let cookieBase = encodeURIComponent(cookies[c].split('')[0].split('=')[0]) + '= expires=Thu, 01-Jan-1970 00:00:01 GMT domain=' + d.join('.') + ' path='
                    let p = location.pathname.split('/')
                    document.cookie = cookieBase + '/'
                    while (p.length > 0) {
                        document.cookie = cookieBase + p.join('/')
                        p.pop()
                    }
                    d.shift()
                }
            }
        } catch (error) {
            console.warn(`Unable clear Cookies`, error)
        }

        /**
         * Clearing all IndexedDBs
         */
        try {
            // @ts-ignore
            let databases = await indexedDB.databases()

            if (databases) {
                for (let database of databases) {
                    indexedDB.deleteDatabase(database.name)
                }
            }
        } catch (error) {
            console.warn(`Unable delete IndexedDBs`, error)
        }

        return this.goToHomePage()
    }
}
