import { Injectable } from '@angular/core'

import { Config } from '@undock/core/models/config'
import { AclScope } from '@undock/api/scopes/acl/acl.scope'
import { TimeScope } from '@undock/api/scopes/time/time.scope'
import { AuthScope } from '@undock/api//scopes/auth/auth.scope'
import { UserScope } from '@undock/api//scopes/user/user.scope'
import { UndockClient } from '@undock/api/services/undock.client'
import { SearchScope } from '@undock/api/scopes/search/search.scope'
import { MeetingsScope } from '@undock/api//scopes/meet/meetings.scope'
import { ProfileScope } from '@undock/api/scopes/profile/profile.scope'
import { PartnersScope } from '@undock/api/scopes/partners/partners.scope'
import { ContactsScope } from '@undock/api/scopes/contacts/contacts.scope'
import { CalendarScope } from '@undock/api/scopes/calendar/calendar.scope'
import { SchedulesScope } from '@undock/api/scopes/schedules/schedules.scope'
import { UrlHandleScope } from '@undock/api/scopes/url-handle/url-handle.scope'
import { IntegrationsScope } from '@undock/api/scopes/integrations/integrations.scope'
import { PlansScope } from '@undock/api/scopes/plans/plans.scope'
import { TerminalScope } from '@undock/api/scopes/terminal/terminal.scope'
import { LocationsScope } from '@undock/api/scopes/locations/locations.scope'
import { AnalyticsScope } from '@undock/api/scopes/analytics/analytics.scope'
import { ChargesScope } from '@undock/api/scopes/charges/charges.scope'
import { SubscriptionsScope } from '@undock/api/scopes/subscriptions/subscriptions.scope'
import { NLPScope } from '@undock/api/scopes/nlp/nlp.scope'
import { OrganizationsScope } from '@undock/api/scopes/organizations/organizations.scope'

@Injectable({
    providedIn: 'root',
})
export class Api {
    public readonly acl: AclScope
    public readonly nlp: NLPScope
    public readonly auth: AuthScope
    public readonly analytics: AnalyticsScope
    public readonly user: UserScope
    public readonly time: TimeScope
    public readonly plans: PlansScope
    public readonly meet: MeetingsScope
    public readonly charge: ChargesScope
    public readonly profile: ProfileScope
    public readonly contacts: ContactsScope
    public readonly calendar: CalendarScope
    public readonly partners: PartnersScope
    public readonly terminal: TerminalScope
    public readonly urlHandle: UrlHandleScope
    public readonly integrations: IntegrationsScope
    public readonly subscription: SubscriptionsScope
    public readonly schedules: SchedulesScope
    public readonly search: SearchScope
    public readonly locations: LocationsScope
    public readonly organizations: OrganizationsScope

    public constructor(
        client: UndockClient, config: Config,
    ) {
        const context = { client, config }

        this.acl = new AclScope(context)
        this.nlp = new NLPScope(context)
        this.auth = new AuthScope(context)
        this.analytics = new AnalyticsScope(context)
        this.user = new UserScope(context)
        this.time = new TimeScope(context)
        this.plans = new PlansScope(context)
        this.meet = new MeetingsScope(context)
        this.profile = new ProfileScope(context)
        this.charge = new ChargesScope(context)
        this.calendar = new CalendarScope(context)
        this.partners = new PartnersScope(context)
        this.contacts = new ContactsScope(context)
        this.terminal = new TerminalScope(context)
        this.urlHandle = new UrlHandleScope(context)
        this.integrations = new IntegrationsScope(context)
        this.subscription = new SubscriptionsScope(context)
        this.schedules = new SchedulesScope(context)
        this.search = new SearchScope(context)
        this.locations = new LocationsScope(context)
        this.organizations = new OrganizationsScope(context)
    }
}
