import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'


@Component({
    selector: 'app-ai-loading',
    templateUrl: 'ai-loading.component.html',
    styleUrls: ['ai-loading.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AILoadingComponent {

    @Input() width: string = '100%'
    @Input() height: string = '75px'

}
