import { Injectable } from '@angular/core'
import {
    HttpClient,
    HttpHeaders,
    HttpParams,
} from '@angular/common/http'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { AuthHeaders } from '../models/auth-headers'

@Injectable()
export class UndockClient {
    constructor(private http: HttpClient, private authHeaders: AuthHeaders) {}

    async get<ResponseData = Object>(url: string, options?: HttpOptions): Promise<ResponseData | null> {
        let request = this.http
                          .get<ApiResponse<ResponseData>>(url, await this.addAuthHeadersToOptions(options))

        return await this.executeRequest<ResponseData>(request)
    }

    async post<ResponseData = Object>(url: string, body: any | null, options?: HttpOptions): Promise<ResponseData | null> {
        let request = this.http
                          .post<ApiResponse<ResponseData>>(url, body, await this.addAuthHeadersToOptions(options))

        return await this.executeRequest<ResponseData>(request)
    }

    async delete<ResponseData = Object>(url: string, options?: HttpOptions): Promise<ResponseData | null> {
        let request = this.http
                          .delete<ApiResponse<ResponseData>>(url, await this.addAuthHeadersToOptions(options))

        return await this.executeRequest<ResponseData>(request)
    }

    async put<ResponseData = Object>(url: string, body: any | null, options?: HttpOptions): Promise<ResponseData | null> {
        let request = this.http
                          .put<ApiResponse<ResponseData>>(url, body, await this.addAuthHeadersToOptions(options))

        return await this.executeRequest<ResponseData>(request)
    }

    async patch<ResponseData = Object>(url: string, body: any | null, options?: HttpOptions): Promise<ResponseData | null> {
        let request = this.http
                          .patch<ApiResponse<ResponseData>>(url, body, await this.addAuthHeadersToOptions(options))

        return await this.executeRequest<ResponseData>(request)
    }

    private async addAuthHeadersToOptions(options?: HttpOptions): Promise<HttpOptions> {
        if (!options) {
            options = { headers: null }
        }
        options.headers = await this.authHeaders.getFullyAuthenticatedRequest()

        return options
    }

    /**
     * @throws HttpException
     */
    private async executeRequest<ResponseType>(request: Observable<any>): Promise<ResponseType | null> {
        let response = null
        try {
            response = await request
                .pipe(map(response => response.data))
                .toPromise()
        } catch (error) {
            throw new HttpException(error?.error?.message ?? '', error.status, error?.error ?? undefined)
        }

        return response
    }

}

export interface HttpOptions {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
}

export interface ApiResponse<T = any> {
    success: boolean
    message: string
    data?: T
}

export class HttpException extends Error {

    protected status: number
    protected error: any

    public constructor(message: string, statusCode: number, error?: any) {
        super(message)

        this.status = statusCode
        this.error = error
        this.name = HttpException.name
    }

    public getMessage() {
        return this.message
    }

    public getStatus() {
        return this.status
    }

    public getError() {
        return this.error
    }
}
