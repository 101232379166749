import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import {
    DestroyEvent,
    EmitOnDestroy,
} from '@typeheim/fire-rx'
import { takeUntil } from 'rxjs/operators'

import { AvailableTimeSlotViewComponent } from '@undock/time/availability/components'


@Component({
    selector: 'app-time-available-time-slot-view-embed',
    templateUrl: 'available-time-slot-view-embed.component.html',
    styleUrls: ['available-time-slot-view-embed.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvailableTimeSlotViewEmbedComponent extends AvailableTimeSlotViewComponent implements OnInit {

    @Input() textColor: string
    @Input() backgroundColor: string
    @Input() iconColor: string

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    ngOnInit() {
        if (this.best){
            this.iconColor = '#DFC051'; // Complementary/Gold Main
        } else if( this.slot.score >= 80 ) {
            this.iconColor = '#CECADA'; // Grayscale/Light 1
        }
    }
}
