import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
} from '@angular/core'
import {
    ActivatedRoute,
    Router,
} from '@angular/router'
import {
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms'
import { Title } from '@angular/platform-browser'
import { takeUntil } from 'rxjs/operators'

import {
    Config,
    Memoize,
    openLinkBlank,
} from '@undock/core'
import { AuthManager } from '@undock/auth/services/auth.manager'
import { FirebaseApp } from '@undock/session/contracts/firebase-app.token'
import { SignInPage } from '@undock/auth-ui'


@Component({
    selector: 'ud-extensionsignin-page',
    templateUrl: 'extension-sign-in-page.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        'extension-sign-in-page.component.scss',
        '../signin/sign-in-page.component.scss',
        '../shared-styles/login-signup.scss',
    ],
})
export class ExtensionSignInPage extends SignInPage {

    public constructor(
        protected title: Title,
        protected router: Router,
        protected authManager: AuthManager,
        protected activatedRoute: ActivatedRoute,
        protected changeDetector: ChangeDetectorRef,
        @Inject(FirebaseApp) protected firebaseApp,

        public config: Config
    ) {
        super(title, router, authManager, activatedRoute, changeDetector, firebaseApp)
    }

    public async ngOnInit() {
        /**
         * Will redirect if user logged in in the different tab
         */
        this.authManager.isRegularUserStream.pipe(
            takeUntil(this.destroyedEvent),
        ).subscribe(state => state ? this.redirectAfterSuccessfulLogIn() : null)
    }

    @Memoize()
    public get loginForm(): FormGroup {
        return new FormGroup({
            'email': new FormControl('', [
                Validators.email,
                Validators.required,
            ]),
            'password': new FormControl('', [
                Validators.required,
                Validators.minLength(6),
            ]),
        })
    }

    /**
     * Overridden from parent component
     */
    public async loginWithGoogle() {
        openLinkBlank(`${this.config.clientDomain}login?provider=google`)
    }

    /**
     * Overridden from parent component
     */
    public async loginWithMicrosoft() {
        openLinkBlank(`${this.config.clientDomain}login?provider=microsoft`)
    }

    public async loginWithEmailPassword() {
        if (!this.isLoginProcessing) {
            this.ssoAuthErrorStream.next('')
            this.passwordAuthErrorStream.next('')
            this.isAuthFormSubmittedStream.next(true)

            if (this.loginForm.invalid) {
                return this.loginForm.markAllAsTouched()
            }

            try {
                this.isLoginProcessing = true
                await this.authManager.signInWithEmailAndPassword(
                    this.formControl('email').value,
                    this.formControl('password').value,
                )
                await this.redirectAfterSuccessfulLogIn()
            } catch (error) {
                const authValidationErrorCodes = [
                    'auth/invalid-email',
                    'auth/user-disabled',
                    'auth/user-not-found',
                    'auth/wrong-password',
                ]
                let errorMessage = 'Something went wrong, please try again!'

                if (authValidationErrorCodes.includes(error?.code)) {
                    errorMessage = 'User does not exist or credentials are invalid!'
                }
                this.passwordAuthErrorStream.next(errorMessage)
                this.changeDetector.detectChanges()
            } finally {
                this.isLoginProcessing = false
            }
        }
    }

    public async navigateToSignup() {
        openLinkBlank(`${this.config.clientDomain}signup`)
    }

    /**
     * Blocks login with custom account
     *
     * Overridden from parent component
     */
    public async loginWithCustomToken(token: string): Promise<void> {
        return null
    }

    /**
     * Overridden from parent component
     * @protected
     */
    protected async redirectAfterSuccessfulLogIn() {
        return window.location.href = window.location.origin + '/chrome/sidebar'
    }
}
