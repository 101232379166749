export class Validations {

    static readonly urlRE = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
    static readonly emailRE = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    public static isNotEmptyString(value: string): boolean {
        return (typeof value === 'string') && value.replace(/^\s+/, '')
                                                   .replace(/\s+$/, '') !== ''
    }

    public static isValidUrl(value: string): boolean {
        return typeof value === 'string' && this.urlRE.test(value.trim())
    }

    public static isValidEmail(value: string): boolean {
        return typeof value === 'string' && this.emailRE.test(value.trim())
    }
}

