import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'


@Component({
    selector: 'app-email-icon',
    templateUrl: 'email-icon.component.html',
    styleUrls: ['email-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailIconComponent extends IconTemplateComponent {

    @Input() width = 25
    @Input() height = 24
    @Input() color = '#2B2733'

}
