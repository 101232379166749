import {
    OnInit,
    Component,
    ChangeDetectionStrategy,
} from '@angular/core'
import {
    Router,
    ActivatedRoute,
} from '@angular/router'

import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'

import {
    getQueryParam,
} from '@undock/core'
import { Api } from '@undock/api/services/api.service'
import { AuthManager } from '@undock/auth/services/auth.manager'
import { AnimationOptions } from 'ngx-lottie'
import { BOOKING_CONFIRMATION_ANIMATION } from '@undock/profile/public/ui/animations/booking-confirmation'
import { User } from '@undock/user'


@Component({
    selector: 'app-invite-sign-up-page',
    templateUrl: 'invite-sign-up.page.html',
    styleUrls: ['invite-sign-up.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InviteSignUpPage implements OnInit {

    private readonly _animation: AnimationOptions = BOOKING_CONFIRMATION_ANIMATION

    private readonly INVITEE_USER_PROFILE_PARAM = 'i'

    @CompleteOnDestroy()
    public targetProfileStream = new ValueSubject<User>(null)

    @CompleteOnDestroy()
    protected isProfileLoadingSubject = new ValueSubject<boolean>(false)

    @EmitOnDestroy()
    private readonly destroyEvent = new DestroyEvent()

    public constructor(
        private api: Api,
        private router: Router,
        private authManager: AuthManager,
        private activatedRoute: ActivatedRoute
    ) {}

    public async ngOnInit() {
        this.isProfileLoadingSubject.next(true)

        try {
            const authUser = await this.authManager.authUserStream
            if (authUser.isAnonymous) {
                let userProfile = getQueryParam(this.activatedRoute, this.INVITEE_USER_PROFILE_PARAM)
                if (userProfile) {
                    this.targetProfileStream.next(
                        await this.loadUserByProfileUrl(userProfile),
                    )
                }
                else {
                    return this.navigateToSignup()
                }
            }
            else {
                return this.navigateToSignup()
            }
        } catch (e) {
            console.warn(`Unable to load profile`, e)
            return this.navigateToSignup()
        } finally {
            this.isProfileLoadingSubject.next(false)
        }
    }

    public get animation(): AnimationOptions {
        return this._animation
    }

    public async navigateToSignup(updateInviteCount: boolean = false) {
        const viewedProfile = await this.targetProfileStream
        const queryParams = viewedProfile ? {
            queryParams: { utm_source: 'user_referral', utm_medium: 'invite', utm_campaign: viewedProfile.profileUrl }
        } : {}

        if (viewedProfile && updateInviteCount) {
            await this.api.user.analytics.updateSentInvitesCountForUserProfile(viewedProfile.profileUrl)
        }

        return this.router.navigate(['signup'], queryParams)
    }

    protected async loadUserByProfileUrl(profileUrl: string): Promise<User> {
        let user: User = await this.api.user.profile.getByProfileUrl(profileUrl)

        if (!user) {
            throw new Error(`User ${profileUrl} is not found`)
        }

        return user
    }

}
