import { Injectable } from '@angular/core'
import {
    State,
    StateModel,
} from '@typeheim/fluent-states'
import { Api } from '@undock/api'
import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'
import {
    clone,
    Config,
    Memoize,
} from '@undock/core'
import { StreamStoreV2 } from '@undock/core/services/stream-store-v2'
import {
    TimeCommandActions,
    TimeCommandBlueprint,
    TimeCommandBlueprintEvent,
    TimeCommandBlueprintHold,
    TimeCommandPromptResponse,
} from '@undock/api/scopes/nlp/routes/commands.route'
import { TimeSearchStateModel } from '@undock/time/prompt/states/time-search.state-model'
import {
    SnackbarManager,
    SnackbarPosition,
} from '@undock/common/ui-kit/services/snackbar.manager'
import { CalendarEventsStorage } from '@undock/calendar/services/calendar-events.storage'
import moment from 'moment'
import {
    catchError,
    combineLatest,
    map,
    of,
} from 'rxjs'
import { SseService } from '@undock/api/services/sse.service'
import { takeUntil } from 'rxjs/operators'
import { ServerSentEventType } from '@undock/core/contracts/server-sent-event/server-sent-event-type'


@Injectable()
export class TimeCommandViewModel extends StateModel<TimeCommandStore> {

    protected store = new TimeCommandStore()

    protected ignoreNextResponseFlag: boolean = false

    @EmitOnDestroy()
    protected readonly destroyEvent = new DestroyEvent()

    constructor(
        private api: Api,
        private config: Config,
        private sseService: SseService,
        private snackbarManager: SnackbarManager,
        private timeSearchState: TimeSearchStateModel,
        private calendarEventStorage: CalendarEventsStorage
    ) {
        super()
    }

    public async submitPrompt(prompt: string) {
        this.setIsLoading(true)

        try {

            let commandSessionId = await this.api.nlp.commands.submitCommandPromptWithSse(
                prompt,
                await this.store.isTrainingModeStream
            )
            if (commandSessionId) {
                const responseStream = await this.sseService.stream<TimeCommandPromptResponse>(`nlp/time/command/submit-sse/${commandSessionId}`)
                if (responseStream) {
                    responseStream.pipe(
                        catchError(error => {
                            console.error("ERROR in time command status update stream: ", error)
                            return of({
                                type: ServerSentEventType.End,
                                data: null
                            })
                        }),
                        takeUntil(this.destroyEvent)
                    ).subscribe(response => {
                        if (response?.type === ServerSentEventType.StatusUpdate) {
                            if (response.data?.status) {
                                this.store.loadingStatusMessageStream.next(response.data.status)
                            }
                        } else if (response?.type === ServerSentEventType.End) {
                            if (response.data?.blueprint) {
                                this.setResponse(response.data)
                            } else {
                                console.log("Time Command Error:", response.data?.status ?? 'unknown')

                                if (response.data?.status?.toLowerCase().includes('timeout')) {
                                    let snackbar = this.snackbarManager.error("An error occured processing your request", SnackbarPosition.BottomCenter, "Retry")
                                    if (snackbar) {
                                        snackbar.onAction().subscribe(() => {
                                            this.submitPrompt(prompt)
                                            snackbar.dismiss()
                                        })
                                    }
                                } else {
                                    this.snackbarManager.error("Please try your command again.", SnackbarPosition.BottomCenter)
                                }

                            }
                            this.setIsLoading(false)
                        }
                    })
                }
            }
        } catch (err) {
            console.log("Time Command Error:", err)
            this.snackbarManager.error("Please try your command again.", SnackbarPosition.BottomCenter)
        }
    }

    public async cancelPrompt() {
        if (await this.store.isLoadingStream) {
            this.ignoreNextResponseFlag = true
            this.setIsLoading(false)
        }
    }

    public async trainCommand(blueprint: TimeCommandBlueprint) {
        this.setIsLoading(true)

        try {
            await this.api.nlp.commands.trainCommand(
                await this.store.blueprintStream
            )
            await this.clearEventSelections()
        } catch (err) {
            console.log("Time Command Error:", err)
            this.snackbarManager.error("Please try again.", SnackbarPosition.BottomCenter)
        }

        this.setIsLoading(false)
    }

    public async setResponse(response: TimeCommandPromptResponse) {
        if (this.ignoreNextResponseFlag) {
            this.ignoreNextResponseFlag = false
            return
        }
        this.store.responseStream.next(response)
        this.store.blueprintStream.next(response.blueprint)
    }

    public async clearResponse() {
        this.store.currentlyEditedCommandEventSubject.next(null)
        this.store.responseStream.next(null)
        this.store.blueprintStream.next(null)
    }

    public async updateBlueprint(blueprint: TimeCommandBlueprint) {
        this.store.blueprintStream.next(blueprint)
    }

    public async executeCommand(blueprint: TimeCommandBlueprint) {
        this.setIsLoading(true)
        try {
            await this.api.nlp.commands.executeCommand(
                await this.store.blueprintStream
            )

            this.store.isConfirmationModeStream.next(true)
            this.calendarEventStorage.refreshCurrentEvents()
        } catch (err) {
            console.log("Time Command Error:", err)
            this.snackbarManager.error("There was a problem. Please try again.", SnackbarPosition.BottomCenter)
        }

        this.setIsLoading(false)
    }

    public async editNewCommandEvent(event: TimeCommandBlueprintEvent) {
        if (event && !event.schedule?.start) {
            /**
             * If there is no start/end time suggested, add one within the event's action timeframe before editing it or it will
             * cause errors when creating a draft meeting from it
             */
            let blueprint = await this.store.blueprintStream
            if (blueprint) {
                let relevantAction = blueprint.actions.find(
                    ac => ac.events.some(e => e.iCalUId === event.iCalUId)
                )
                if (relevantAction && relevantAction.timeframe?.length) {
                    let start = moment(relevantAction.timeframe[0].start).hours(moment().hours()).add(1, 'hour').startOf('hour')
                    event = clone({
                        ...event,
                        schedule: {
                            start: start.toDate(),
                            end: moment(start).add(30, 'minutes').toDate(),
                            isAllDay: false
                        }
                    })
                }
            }
        }
        this.store.currentlyEditedCommandEventSubject.next(event)
    }

    public async clearConfirmation() {
        await this.clearResponse()
        await this.timeSearchState.clearSearch()
        this.store.isConfirmationModeStream.next(false)
    }

    public async applyNewEventUpdates(updatedEvent: TimeCommandBlueprintEvent) {
        let blueprint = clone(await this.store.blueprintStream)
        for (let action of blueprint.actions) {
            let eventToUpdateIndex = action.events.findIndex(event => event.iCalUId === updatedEvent.iCalUId)
            if (eventToUpdateIndex !== -1) {
                action.events[eventToUpdateIndex] = updatedEvent
                return this.updateBlueprint(blueprint)
            }
        }
    }

    public async applyRescheduleEventUpdates(updatedEvent: TimeCommandBlueprintEvent) {
        let blueprint = clone(await this.store.blueprintStream)
        for (let action of blueprint.actions) {
            let relevantEventIndex = action.events.findIndex(event => event.iCalUId === updatedEvent.iCalUId)
            if (relevantEventIndex !== -1) {
                action.timeSlots = [updatedEvent.reschedule.start.toISOString()]
                action.events[relevantEventIndex] = updatedEvent
                return this.updateBlueprint(blueprint)
            }
        }
    }

    public async updateActionResponseMessage(actionId: string, message: string) {
        let blueprint = clone(await this.store.blueprintStream)
        let action = blueprint.actions.find(a => a.id === actionId)
        if (action) {
            action.response = message
            return this.updateBlueprint(blueprint)
        }
    }

    public async addTimeSlotToAction(actionId: string, slot: string) {
        let blueprint = clone(await this.store.blueprintStream)
        let action = blueprint.actions.find(a => a.id === actionId)
        if (action) {
            if (action.action === TimeCommandActions.ShareAvailability) {
                let updatedAction = await this.api.nlp.commands.addSlotToShareAvailabilityAction(blueprint, actionId, slot)
                if (updatedAction) {
                    action.timeSlots = updatedAction.timeSlots
                    action.proposal = updatedAction.proposal
                    action.response = updatedAction.response
                }
            } else {
                if (!action.timeSlots.includes(slot)) {
                    action.timeSlots.push(slot)
                }
            }
            return this.updateBlueprint(blueprint)
        }
    }

    public async updateTimeSlotsForAction(actionId: string, addedSlots: string[], removedSlots: string[]) {
        let blueprint = clone(await this.store.blueprintStream)
        let action = blueprint.actions.find(a => a.id === actionId)
        if (action) {
            if (action.action === TimeCommandActions.ShareAvailability) {
                let updatedAction = await this.api.nlp.commands.updateSlotsForShareAvailabilityAction(blueprint, actionId, addedSlots, removedSlots)
                if (updatedAction) {
                    action.timeSlots = updatedAction.timeSlots
                    action.proposal = updatedAction.proposal
                    action.response = updatedAction.response
                }
            } else {
                let slots = action.timeSlots
                addedSlots.forEach(slot => {
                    if (!slots.includes(slot)) {
                        action.timeSlots.push(slot)
                    }
                })
                removedSlots.forEach(slot => {
                    let index = slots.indexOf(slot)
                    if (index !== -1) {
                        slots.splice(index, 1)
                    }
                })
            }
            return this.updateBlueprint(blueprint)
        }
    }

    public async toggleEventSelection(event: TimeCommandBlueprintEvent) {
        let blueprint = clone(await this.store.blueprintStream)
        for (let action of blueprint.actions) {
            let ev = action.events.find(e => e.iCalUId === event.iCalUId)
            if (ev) {
                ev.isSelected = !ev.isSelected
                return this.updateBlueprint(blueprint)
            }
        }
    }

    public async toggleHoldSelection(hold: TimeCommandBlueprintHold) {
        let blueprint = clone(await this.store.blueprintStream)
        for (let action of blueprint.actions) {
            let hl = action.holds.find(h => h.id === hold.id)
            if (hl) {
                hl.isSelected = !hl.isSelected
                return this.updateBlueprint(blueprint)
            }
        }
    }

    public async selectAllEvents() {
        let blueprint = clone(await this.store.blueprintStream)
        for (let action of blueprint.actions) {
            for (let event of action.events) {
                event.isSelected = true
            }
        }
        return this.updateBlueprint(blueprint)
    }

    public async clearEventSelections() {
        let blueprint = clone(await this.store.blueprintStream)
        for (let action of blueprint.actions) {
            for (let event of action.events) {
                event.isSelected = false
            }
        }
        return this.updateBlueprint(blueprint)
    }

    public async toggleTrainingMode() {
        this.store.isTrainingModeStream.next(
            !(await this.store.isTrainingModeStream)
        )

        if (await this.store.isTrainingModeStream) {
            return this.clearEventSelections()
        }
    }

    protected setIsLoading(value: boolean) {
        this.store.isLoadingStream.next(value)

        if (!value) {
            this.store.loadingStatusMessageStream.next("Processing")
        }
    }
}

export class TimeCommandStore extends StreamStoreV2 {
    public isLoadingStream = new ValueSubject<boolean>(false)

    public loadingStatusMessageStream = new ValueSubject<string>("Processing")

    public responseStream: ValueSubject<TimeCommandPromptResponse> = new ValueSubject<TimeCommandPromptResponse>(null)

    public blueprintStream: ValueSubject<TimeCommandBlueprint> = new ValueSubject<TimeCommandBlueprint>(null)

    public isConfirmationModeStream: ValueSubject<boolean> = new ValueSubject<boolean>(false)

    public isTrainingModeStream: ValueSubject<boolean> = new ValueSubject<boolean>(false)

    public currentlyEditedCommandEventSubject: ValueSubject<TimeCommandBlueprintEvent> = new ValueSubject<TimeCommandBlueprintEvent>(null)

    @Memoize()
    public get currentlyEditedCommandEventActionTypeStream(): ReactiveStream<TimeCommandActions> {
        return new ReactiveStream<TimeCommandActions>(
            combineLatest([
                this.currentlyEditedCommandEventSubject,
                this.blueprintStream
            ]).pipe(
                map(([editedCommand, blueprint]) => {
                    return editedCommand
                        ? blueprint.actions.find(
                            ac => ac.events.some(e => e.iCalUId === editedCommand.iCalUId)
                          )?.action ?? null
                        : null
                }),
            ))
    }
}

export type TimeCommandState = State<TimeCommandStore>
