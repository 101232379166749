import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core'

import {
    combineLatest,
    filter,
    fromEvent,
    merge,
    Observable,
    tap,
} from 'rxjs'
import {
    debounceTime,
    distinctUntilChanged,
    map,
    shareReplay,
    switchMap,
    take,
    takeUntil,
} from 'rxjs/operators'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    StatefulSubject,
    ValueSubject,
} from '@typeheim/fire-rx'

import {
    compareDeeply,
    Memoize,
    Validations,
} from '@undock/core'
import { Api } from '@undock/api'
import {
    FirestoreUser,
    PublicProfileData,
} from '@undock/user'
import { CurrentUser } from '@undock/session'

import { ProfilesProvider } from '@undock/user/services/profiles.provider'
import {
    SnackbarManager,
    SnackbarPosition,
} from '@undock/common/ui-kit/services/snackbar.manager'
import { KeyboardEventsListener } from '@undock/hotkeys/services/keyboard-events.listener'


@Component({
    selector: 'app-user-contacts-search',
    templateUrl: 'contacts-search.component.html',
    styleUrls: ['contacts-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsSearchComponent {

    /**
     * ------------------------------------------------------
     *                Inputs and Outputs
     * ------------------------------------------------------
     */
    @Input() disabledEmails: string[]

    @Input() showBorder: boolean = true
    @Input() showSearchIcon: boolean = true
    @Input() showResultsPopup: boolean = true
    @Input() placeholder: string = 'Name or email'

    @Input() autoFocus: boolean = false

    /**
     * Current value of the contact search input field
     */
    @Output() inputValue = new EventEmitter<string>()

    /**
     * When an updated list of search results is generated
     */
    @Output() onSearchResults = new EventEmitter<FirestoreUser[]>()

    /**
     * When contact is selected or enter key pressed
     */
    @Output() onSelected = new EventEmitter<FirestoreUser | string>()


    @CompleteOnDestroy()
    public searchCriteriaStream = new StatefulSubject<string>()

    @CompleteOnDestroy()
    public targetedContactSubject = new ValueSubject<FirestoreUser | string>(null)

    @CompleteOnDestroy()
    public isSearchResultsLoadingStream = new ValueSubject<boolean>(false)

    @ViewChild('usersSearchInput')
    private usersSearchInput: ElementRef<HTMLInputElement>


    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    private readonly contactsSearchDebounceTime = 500

    private readonly maxParticipantsCountToDisplay = 50

    public isReverseDirection: boolean = false

    public constructor(
        public profilesProvider: ProfilesProvider,
        private api: Api,
        private elementRef: ElementRef,
        private currentUser: CurrentUser,
        private snackbarManager: SnackbarManager,
        private keyEventsListener: KeyboardEventsListener,
    ) {}

    /**
     * TODO: if needed
     */
    // ngOnInit() {
    //     /**
    //      * If the search results would be too close to the bottom, have them appear on top of the search field
    //      */
    //     if (DomUtils.getApplicationHeight() - this.elementRef.nativeElement.getBoundingClientRect().bottom < this.maxParticipantsCountToDisplay * 40) {
    //         this.isReverseDirection = true
    //     }
    // }

    @Memoize()
    public get usersUIDsSearchStream(): Observable<string[]> {
        return combineLatest([
            this.searchCriteriaStream,
            this.currentUser.isRegularUserStream,
        ]).pipe(
            tap(() => this.isSearchResultsLoadingStream.next(true)),
            switchMap(async sources => {
                const [criteria, isRegularUser] = sources

                /**
                 * Guest aren't able to have any contacts
                 */
                if (isRegularUser && criteria && criteria.length > 0) {
                    let userUIDs = await this.api.contacts.search.getIdsForAutocomplete(criteria)

                    return userUIDs.slice(0, this.maxParticipantsCountToDisplay)
                }

                return []
            }),

            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get userProfilesSearchStream(): ReactiveStream<FirestoreUser[]> {
        return new ReactiveStream<FirestoreUser[]>(this.usersUIDsSearchStream.pipe(
            switchMap(async uids => {
                if (uids?.length) {
                    return await this.profilesProvider.getProfilesByUids(uids)
                }
                return []
            }),
            map((profiles: FirestoreUser[]) => profiles.filter(p => !this.disabledEmails?.includes(p?.email))),
            tap((profiles) => {
                if (profiles?.length) {
                    this.targetedContactSubject.next(profiles[0])
                }

                this.isSearchResultsLoadingStream.next(false)
                this.onSearchResults.emit(profiles)
            }),
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        ))
    }

    @Memoize()
    public get targetedContactStream(): ReactiveStream<PublicProfileData | string> {
        return this.targetedContactSubject.asStream()
    }

    @Memoize()
    public get isNothingFoundStream(): Observable<boolean> {
        return this.usersUIDsSearchStream.pipe(
            map(UIDs => UIDs && UIDs.length === 0),
        )
    }

    @Memoize()
    public get isAllowKeyListenerStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(
            combineLatest([
                this.userProfilesSearchStream,
                this.searchCriteriaStream
            ]).pipe(
                map(([profiles, term]) => profiles?.length > 0 || Validations.isNotEmptyString(term)),
                takeUntil(this.destroyedEvent),
                shareReplay(1),
            ))
    }

    @Memoize()
    protected get isKeyListenerRegisteredStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(this.isAllowKeyListenerStream.pipe(
            distinctUntilChanged(
                (prev, next) => compareDeeply(prev, next),
            ),
            tap(isAllowed => {
                if (isAllowed) {
                    this.keyEventsListener.subscribe({
                            'Enter': this.onSelectContactKey,
                            'Up': this.onPreviousContactKey,
                            'Down': this.onNextContactKey,
                            'Escape': this.onCloseSearchResultsKey
                        },
                        {
                            priority: 200,
                            allowInputs: true,
                            terminal: true,
                            preventDefault: true,
                            takeUntil: merge(
                                this.destroyedEvent,
                                this.isAllowKeyListenerStream.pipe(
                                    filter(isAllowed => !isAllowed),
                                    take(1),
                                ),
                            ),
                        })
                }
            }),
            takeUntil(this.destroyedEvent),
            shareReplay(1),
        ))
    }

    public async ngAfterViewInit() {

        if (this.autoFocus) {
            this.focusSearchInput()
        }

        if (this.usersSearchInput?.nativeElement) {
            const searchInputStream = fromEvent<KeyboardEvent>(
                this.usersSearchInput.nativeElement, 'keyup',
            ).pipe(
                takeUntil(this.destroyedEvent),
            )

            searchInputStream.subscribe(event => {
                if (event.key === 'Enter') {
                    this.onEmailAddressSelected(`${this.usersSearchInput.nativeElement.value}`)
                }
            })

            searchInputStream.pipe(
                debounceTime(this.contactsSearchDebounceTime),
                map(() => this.usersSearchInput.nativeElement.value),
            ).subscribe(criteria => {
                this.searchCriteriaStream.next(criteria)
            })
        }
    }

    public onSelectContactKey = async (): Promise<boolean> => {
        let success = await this.trySelectContactOrEmailAddress(`${this.usersSearchInput.nativeElement.value}`)
        this.focusSearchInput()
        if (success) {
            this.clearSearchInput()
        }
        return false
    }

    protected onPreviousContactKey = () => {
        if (this.isReverseDirection) {
            this.targetNextContact()
        } else {
            this.targetPreviousContact()
        }
    }

    protected onNextContactKey = () => {
        if (this.isReverseDirection) {
            this.targetPreviousContact()
        } else {
            this.targetNextContact()
        }
    }

    protected onCloseSearchResultsKey = () => {
        this.clearSearchInput()
    }

    protected async trySelectContactOrEmailAddress(email: string): Promise<boolean> {
        if (Validations.isValidEmail(email)) {
            this.onEmailAddressSelected(email)
            return true
        } else {
            let targetedContact = await this.targetedContactSubject
            if (targetedContact) {
                this.onContactSelectedEvent(targetedContact)
                return true
            }
        }
        return false
    }

    public onContactSelectedEvent(contact: FirestoreUser | string) {
        if (contact) {
            /**
             * Emit user outside of component
             */
            this.onSelected.emit(contact)

            /**
             * Clearing criteria when user been selected
             */
            this.searchCriteriaStream.next('')
        }
    }

    protected onEmailAddressSelected(email: string) {

        if (!email) {
            return
        }

        /**
         * Trying to get clear email address from user input
         */
        email = email.trim()
                     .toLowerCase()
            /**
             * Removing escaped domain host
             */
                     .replace(/(%.+@)/, '@')
            /**
             * Removing additional characters
             */
                     .replace(/(\+.+@)/, '@')
            /**
             * Removing comments
             */
                     .replace(/(\(.+\))/, '')


        if (Validations.isValidEmail(email)) {
            /**
             * Emit event only if email address is correct
             */
            return this.onContactSelectedEvent(email)
        }

        this.snackbarManager.error(`Please enter valid email address`, SnackbarPosition.BottomLeft)
    }

    protected async targetNextContact() {
        let [searchResults, targetedContact] = await Promise.all([
            this.userProfilesSearchStream,
            this.targetedContactSubject,
        ])
        if (searchResults?.length) {

            if (targetedContact) {
                let targetedIndex = searchResults.findIndex(p => p.email === (targetedContact as PublicProfileData).email)
                if (targetedIndex !== -1) {
                    if (searchResults.length > 1) {
                        if (targetedIndex !== searchResults.length - 1) {
                            return this.targetedContactSubject.next(searchResults[targetedIndex + 1])
                        }
                    }
                }
            }
            return this.targetedContactSubject.next(searchResults[0])
        } else {
            this.targetedContactSubject.next(null)
        }
    }

    protected async targetPreviousContact() {
        let [searchResults, targetedContact] = await Promise.all([
            this.userProfilesSearchStream,
            this.targetedContactSubject,
        ])
        if (searchResults?.length) {
            if (targetedContact) {
                let targetedIndex = searchResults.findIndex(p => p.email === (targetedContact as PublicProfileData).email)
                if (targetedIndex !== -1) {
                    if (searchResults.length > 1) {
                        if (targetedIndex !== 0) {
                            return this.targetedContactSubject.next(searchResults[targetedIndex - 1])
                        }
                    }
                }
            }
            return this.targetedContactSubject.next(searchResults[searchResults.length - 1])
        } else {
            this.targetedContactSubject.next(null)
        }
    }

    public hideSearchResults() {
        this.clearSearchInput()
    }

    public focusSearchInput() {
        return this.usersSearchInput?.nativeElement?.focus()
    }

    protected clearSearchInput() {
        this.searchCriteriaStream.next(null)
        this.inputValue.emit(null)
        if (this.usersSearchInput) {
            this.usersSearchInput.nativeElement.value = ''
        }
    }
}
