<div id="ud-loading-overlay" style="height: 100%; width: 100%;"
    [ngClass]="{
        'dark': style === OverlayStyle.Dark,
        'light': style === OverlayStyle.Light,
        'transparent': style === OverlayStyle.Transparent
     }"
>
    <div class="app-loading">
        <ng-container *ngIf="overrideLogoUrl; else undockLogo">
            <img src="{{overrideLogoUrl}}">
        </ng-container>
        <ng-template #undockLogo>
            <ng-lottie width="300px" height="300px" [options]="options"></ng-lottie>
        </ng-template>
    </div>
</div>
