import { OnDestroy } from '@angular/core'

import {
    Aggregate,
    Collection,
    CollectionRef,
    CreatedDateField,
    Field,
    ID,
    UpdatedDateField,
} from '@typeheim/orm-on-fire'
import {
    DestroyEvent,
    LazyLoadStream,
} from '@typeheim/fire-rx'

import {
    Attachment,
    AttachmentScope,
    HasAttachments,
    HasEntityName,
    HasPrivateNotes,
    HasRecordings,
    HasSecrets,
    Model,
    PrivateNote,
    Recording,
    RecordingScope,
    Secret,
} from '@undock/core'
import {
    ConferenceMode,
    DockSharedAccessMode,
    DockType,
    MeetingMode,
} from '@undock/dock/meet/contracts'
import { DateRange } from '@undock/time/availability'
import {
    DockParticipant,
    DockParticipantScope,
} from '@undock/dock/meet/models/dock/dock-participant.model'
import { DockVisibility } from '@undock/dock/meet/contracts/dock/dock-visibility'


const DOCK_COLLECTION_NAME = 'dock'

@Aggregate({
    collection: DOCK_COLLECTION_NAME,
})
export class Dock implements Model,
                             OnDestroy,
                             HasSecrets,
                             HasEntityName,
                             HasRecordings,
                             HasAttachments,
                             HasPrivateNotes {

    /**
     * Used for `SharedCollectionEntity` linking
     */
    public readonly entityName = 'Dock'
    static readonly entityName = 'Dock'

    public static readonly BOOKING_CODE = 'bookingCode'
    public static readonly SHARED_ACCESS_SECRET = 'conferenceSharedAccess'
    public static readonly CONFERENCE_JOIN_PIN_CODE = 'conferenceJoinPinCode'
    public static readonly CONFERENCE_JOIN_PHONE_NUMBER = 'conferenceJoinPhoneNumber'
    public static readonly CONFERENCE_RECORDER_JOIN_CODE = 'conferenceRecorderJoinCode'

    public readonly recordingsStream: LazyLoadStream<Recording[]>
    public readonly attachmentsStream: LazyLoadStream<Attachment[]>
    public readonly participantsStream: LazyLoadStream<DockParticipant[]>

    public readonly bookingCodeStream: LazyLoadStream<string>
    public readonly sharedAccessSecretStream: LazyLoadStream<string>
    public readonly conferenceJoinPinCodeStream: LazyLoadStream<string>

    private readonly destroyedEvent = new DestroyEvent()

    @ID() readonly id: string

    /**
     * Contains an undockID of author user
     */
    @Field() authorId: string

    /**
     * Contains a firebaseID of author user
     */
    @Field() authorUId: string

    @Field() url: string
    @Field() note: string
    @Field() title: string

    @Field() dates: DateRange

    @Field() eventSchedule: EventSchedule

    /**
     * Selected duration in minutes
     */
    @Field() duration: number

    /**
     * Contains text for `InPerson` or conference url for remote meetings
     */
    @Field() location: string

    @Field() inPersonLocation: string

    @Field() inPersonLocationUrl: string

    /**
     * Used for only `MeetingMode.Video` and `MeetingMode.Broadcast`
     */
    @Field() isAudioOnly: boolean = false

    /** `Draft`, `Meeting`, `Instant`, `Document` */
    @Field() type: DockType = DockType.Meeting

    /** `Video`, `InPerson`, `Broadcast` */
    @Field() mode: MeetingMode = MeetingMode.Video

    /** `Room`, `Forum` and `Private` */
    @Field() conferenceMode: ConferenceMode = ConferenceMode.Room

    /**
     * `Participants` at default.
     *
     * `Connections` option is available only for Broadcast meetings
     */
    @Field() visibilityMode: DockVisibility = DockVisibility.Participants

    /** `Link`, `Connections`, `Participants` */
    @Field() sharedAccessMode: DockSharedAccessMode = DockSharedAccessMode.Link

    @CollectionRef(Secret) secrets: Collection<Secret>
    @CollectionRef(Recording) recordings: Collection<Recording>
    @CollectionRef(Attachment) attachments: Collection<Attachment>
    @CollectionRef(PrivateNote) privateNotes: Collection<PrivateNote>
    @CollectionRef(DockParticipant) participants: Collection<DockParticipant>

    @CreatedDateField() createdAt: Date
    @UpdatedDateField() updatedAt: Date

    public constructor() {
        this.recordingsStream = new LazyLoadStream<Recording[]>(
            () => this.recordings.all()
                      .filter(RecordingScope.initialized)
                      .stream()
                      .emitUntil(this.destroyedEvent),
        )

        this.attachmentsStream = new LazyLoadStream<Attachment[]>(
            () => this.attachments.all()
                      .filter(AttachmentScope.initialized)
                      .stream()
                      .emitUntil(this.destroyedEvent),
        )

        this.participantsStream = new LazyLoadStream<DockParticipant[]>(
            () => this.participants.all()
                      .filter(DockParticipantScope.initialized)
                      .stream()
                      .emitUntil(this.destroyedEvent),
        )

        this.bookingCodeStream = new LazyLoadStream<string>(
            () => this.secrets.filter(filter => filter.key.equal(Dock.BOOKING_CODE))
                      .map(secrets => secrets.length > 0 ? secrets[0].value : null)
                      .stream()
                      .emitUntil(this.destroyedEvent),
        )

        this.sharedAccessSecretStream = new LazyLoadStream<string>(
            () => this.secrets.filter(filter => filter.key.equal(Dock.SHARED_ACCESS_SECRET))
                      .map(secrets => secrets.length > 0 ? secrets[0].value : null)
                      .stream()
                      .emitUntil(this.destroyedEvent),
        )

        this.conferenceJoinPinCodeStream = new LazyLoadStream<string>(
            () => this.secrets.filter(filter => filter.key.equal(Dock.CONFERENCE_JOIN_PIN_CODE))
                      .map(secrets => secrets.length > 0 ? secrets[0].value : null)
                      .stream()
                      .emitUntil(this.destroyedEvent),
        )
    }

    public get isDraftType(): boolean {
        return this.type === DockType.Draft
    }

    public get isMeetingType(): boolean {
        return this.type === DockType.Meeting
    }

    public get isInstantType(): boolean {
        return this.type === DockType.Instant
    }

    public get isDocumentType(): boolean {
        return this.type === DockType.Document
    }

    /**
     * Converting proprietary Firestore dates to Date type
     */
    protected init() {
        // TODO: Finish this method
    }

    public ngOnDestroy() {
        this.destroyedEvent.emit()
    }

    @Field() partnerName?: string

    /**
     * Assigned phone number for the conference incoming calling
     *
     * @TODO: Move this field to the secrets sub-collection
     *
     * @deprecated
     */
    @Field() conferenceJoinPhoneNumber: string

    /**
     * IDs of connections participating in this meeting
     *
     * Used for searching meetings for specific connection
     */
    @Field() readonly connectionIds: Array<string> = []
}

export interface EventSchedule {
    isAllDay: boolean // whether event is all day event

    start?: Date // event start date

    end?: Date // event end date

    rRule?: string // iCal recurrency rule - https://datatracker.ietf.org/doc/html/rfc5545
}

export const DockCollection = Collection.of<Dock>(Dock)
