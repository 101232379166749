import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    Output,
} from '@angular/core'
import {
    ControlValueAccessor,
    FormBuilder,
    FormGroup,
    NG_VALUE_ACCESSOR,
} from '@angular/forms'
import { SwitchToggleComponent } from '@undock/common/ui-kit/ui/components'
import { OrganizationFormViewModel } from '@undock/organizations/services/organization-form.view-model'

@Component({
    selector: 'app-double-option-switch',
    templateUrl: 'double-option-switch.component.html',
    styleUrls: ['double-option-switch.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DoubleOptionSwitchComponent),
            multi: true,
        }
    ]
})
export class DoubleOptionSwitchComponent implements ControlValueAccessor{

    private onTouched = () => {}
    public onChange = (value) => {}

    private touched = false;
    private disabled = false;

    @Input() size: 'md' | 'lg' | 'xl' = 'lg'
    @Input() value: string
    @Input() options: Array<[any, string[]]>

    @Output() onValueChange = new EventEmitter<any>()

    public setValue(value: any) {
        this.markAsTouched()
        if (this.value != value) {
            this.value = value
            this.onChange(value)
            this.onValueChange.emit(value)
        }
    }

    public markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    public writeValue(value : any) {
        this.value = value
    }

    public registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    public  setDisabledState(disabled: boolean) {
        this.disabled = disabled
    }
}
