import {
    ModuleWithProviders,
    NgModule,
} from '@angular/core'
import {
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'

import { OverlayModule } from '@angular/cdk/overlay'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { ClipboardModule } from '@angular/cdk/clipboard'

import {
    MAT_SNACK_BAR_DEFAULT_OPTIONS,
    MatSnackBarModule,
} from '@angular/material/snack-bar'
import { MatMomentDateModule } from '@angular/material-moment-adapter'

import { TextMaskModule } from 'angular2-text-mask'
import { FluentNgModule } from '@typeheim/fluent-ng'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'

import {
    LottieCacheModule,
    LottieModule,
} from 'ngx-lottie'
import {
    defaultSimpleModalOptions,
    SimpleModalModule,
} from 'ngx-simple-modal'
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'
import { NgxPaginationModule } from 'ngx-pagination'
import { NgxColorsModule } from 'ngx-colors'
import { ImageCropperModule } from 'ngx-image-cropper'
import player from 'lottie-web/build/player/lottie_svg'

import { MaterialsModule } from '@undock/common/ui-kit/materials.module'
import { UndockThemeModule } from '@undock/common/undock-theme/undock-theme.module'

import {
    AddCalendarIconComponent,
    AuthEmptyStateIconComponent,
    AddPeopleIconComponent,
    AlignmentIconComponent,
    AllDayEventClockIconComponent,
    AllowedSitesIconComponent,
    AttachmentsIconComponent,
    AudioOffIconComponent,
    AudioOnIconComponent,
    AudioOnlyBroadcastIconComponent,
    AutoRescheduleIconComponent,
    AvatarGuestIconComponent,
    BackArrowIconComponent,
    BasketIconComponent,
    ExpandIconComponent,
    ShrinkIconComponent,
    CalendarIconComponent,
    CalendarNoTimeIconComponent,
    CalendarPersonIconComponent,
    CalendarProposeIconComponent,
    CalendarRequestedIconComponent,
    CalendarViewIconComponent,
    CameraOffIconComponent,
    CameraOnIconComponent,
    ChainLinkIconComponent,
    ChangeColorIconComponent,
    ChangeTextColorIconComponent,
    ChatIconComponent,
    CheckedCircleIconComponent,
    ChevronIconComponent,
    ChromeExtIconComponent,
    CloseIconComponent,
    CustomFormIconComponent,
    CommandsListIconComponent,
    CommandAfternoonsIconComponent,
    CommandCreatedIconComponent,
    CommandCustomRangeIconComponent,
    CommandEveningsIconComponent,
    CommandMarkAvailableIconComponent,
    CommandMarkBusyIconComponent,
    CommandMeetingIconComponent,
    CommandMorningsIconComponent,
    CommandPriorityIconComponent,
    CommandPromptIconComponent,
    CommandRescheduleIconComponent,
    CommandTimeBlockIconComponent,
    CompareCalendarIconComponent,
    ConferenceExitIconComponent,
    ConfSettingsIconComponent,
    ConnectCalendarIconComponent,
    CopyCodeIconComponent,
    CopyLinkIconComponent,
    CopyTimesIconComponent,
    CreateProposalIconComponent,
    DoorIconComponent,
    DiscoverIconComponent,
    DragNDropIconComponent,
    ElementStatusCheckedIconComponent,
    EventLabelAgendaIconComponent,
    EventLabelCommandIconComponent,
    EventLabelLocationIconComponent,
    EventLabelNotesIconComponent,
    EventLabelVideoIconComponent,
    ExpandArrowIconComponent,
    EyeCloseIconComponent,
    EyeOpenIconComponent,
    FeatureIconComponent,
    FolderIconComponent,
    ForumBroadcastIconComponent,
    GearIconComponent,
    GlobalIconComponent,
    GoogleIconComponent,
    GuardIconComponent,
    HistoryIconComponent,
    HourglassIconComponent,
    IconTemplateComponent,
    IndividualBroadcastIconComponent,
    InfoIconComponent,
    InPersonIconComponent,
    KeyIconComponent,
    LightningIconComponent,
    LiveBroadcastIconComponent,
    LockIconComponent,
    LogoUndockComponent,
    MeetingCancelledIconComponent,
    MeetingConfirmedIconComponent,
    MeetingSubmittedIconComponent,
    MenuHorizontalIconComponent,
    MenuVerticalIconComponent,
    MicrosoftIconComponent,
    NoProposalIconComponent,
    NoResultsIconComponent,
    NotesIconComponent,
    NotFoundIconComponent,
    OpenLinkIconComponent,
    ParticipantsIconComponent,
    PersonalizedFormIconComponent,
    PhoneIconComponent,
    PlayIconComponent,
    PlusIconComponent,
    PreferTimeAfternoonIconComponent,
    PreferTimeLateMorningIconComponent,
    PreferTimeMidIconComponent,
    PreferTimeMorningIconComponent,
    ClockIconComponent,
    QuotesIconComponent,
    RemoteIconComponent,
    ScheduleIconComponent,
    SearchIconComponent,
    SettingsGearIconComponent,
    SettingsIconComponent,
    SettingsPersonalizedFormEditFormIconComponent,
    ShareIconComponent,
    ShareOnIconComponent,
    ShareUnavailableIconComponent,
    ShareWidgetIconComponent,
    SidebarCloseIconComponent,
    SidebarOpenIconComponent,
    SignupMessageIconComponent,
    SlotHeldIconComponent,
    StatusInIconComponent,
    StatusOutIconComponent,
    TimeIconComponent,
    TuneUpIconComponent,
    UdWidgetIconComponent,
    UserDataIconComponent,
    UserIconComponent,
    UserProfileIconComponent,
    UsersIconComponent,
    WarningCalendarIconComponent,
    WarningIconComponent,
    WebGlobeIconComponent,
    LocationIconComponent,
    ShieldCheckIconComponent,
    EmailIconComponent,
    GoogleAuthIconComponent,
    LocationsEmptyStateIconComponent,
    EventLabelAudioIconComponent,
    CreditCardPlusIconComponent,
    EventPreferencesIconComponent,
    UserCheckIconComponent,
    ConfEmptyStateIconComponent,
    AgendaIconComponent,
    VerifiedCheckIconComponent,
    GmailIconComponent,
    TwitterIconComponent,
    FacebookIconComponent,
    LinkedInIconComponent,
    SpecialKeyIconComponent,
    RemoveIconComponent,
    PlanCategoryIconComponent,
    MinusCircleHollowComponent,
} from '@undock/common/ui-kit/ui/icons'

// Components
import {
    ArrowButtonComponent,
    ShortArrowButtonComponent,
    ColorSelectorComponent,
    ConfirmPopupComponent,
    DatePickerComponent,
    DateRangePickerComponent,
    DropdownButtonComponent,
    IconSelectButtonComponent,
    IconSelectorComponent,
    ImageCropperComponent,
    ImageViewerComponent,
    ImageViewerHostComponent,
    KeyLabelComponent,
    LoadingComponent,
    LoadingOverlayComponent,
    NavigationLoaderOverlayComponent,
    NotFoundScreen,
    NotFoundComponent,
    OnOffSelectButtonComponent,
    ParentDialogModalComponent,
    PhoneNumberInputComponent,
    SelectFilterComponent,
    SelectFilterInputComponent,
    SpinnerComponent,
    SwitchToggleComponent,
    TextSwitchToggleComponent,
    UrlInputComponent,
    ValuesSelectorComponent,
    TimeSliderComponent,
    AILoadingComponent,
    ImageUploadPreviewComponent,
    ImageUploadComponent,
} from '@undock/common/ui-kit/ui/components'

// Directives
import {
    ClickOutsideDirective,
    DropdownDirective,
    DropzoneDirective,
    EditableDirective,
    ElementHeightClassDirective,
    ImageDefaultDirective,
    InfiniteListDirective,
    InputAutoresizeDirective,
    LifecycleDirective,
    ObservablesDirective,
    RefreshOnAuthChangeDirective,
    RequireAuthDirective,
    ResizeObserverDirective,
    TextareaAutoresizeDirective,
} from '@undock/common/ui-kit/ui/directives'

// Providers
import { SnackbarManager } from '@undock/common/ui-kit/services/snackbar.manager'
import { ImageViewerService } from '@undock/common/ui-kit/services/image-viewer.service'
import { ConfirmPopupService } from '@undock/common/ui-kit/services/confirm-popup.service'

// Pipes
import {
    AsyncDefaultPipe,
    BooleanPipe,
    HourOfDayPipe,
    MomentPipe,
    MomentTimeZonePipe,
    MonthOfYearPipe,
    ObjectKeysPipe,
    RepeatElementPipe,
    SplitOnCapitalPipe,
} from '@undock/common/ui-kit/ui/pipes'
import { CurrencyMaskModule } from 'ng2-currency-mask'
import { TimeZoneIconComponent } from '@undock/common/ui-kit/ui/icons/time-zone/time-zone.component'
import { PinIconComponent } from '@undock/common/ui-kit/ui/icons/pin/pin.component'
import { CommandRunIconComponent } from '@undock/common/ui-kit/ui/icons/commands/command-run/command-run.component'
import { ClipboardIconComponent } from '@undock/common/ui-kit/ui/icons/clipboard/clipboard.component'
import { CleanIconComponent } from '@undock/common/ui-kit/ui/icons/clean'
import { RRuleToStringPipe } from '@undock/common/ui-kit/ui/pipes/rrule-to-string.pipe'
import { CreditCardLogoIconComponent } from '@undock/common/ui-kit/ui/icons/credit-card-logo/credit-card-logo-icon.component'
import { TimeZoneSelector2Component } from '@undock/common/ui-kit/ui/components/time-zone-selector/time-zone-selector-2.component'
import { PopupTriggerDirective } from '@undock/common/ui-kit/ui/directives/popup-trigger/popup-trigger.directive'
import { DollarIconComponent } from '@undock/common/ui-kit/ui/icons/dollar/dollar-icon.component'
import { LockMedievalIconComponent } from '@undock/common/ui-kit/ui/icons/lock-medieval/lock-medieval-icon'
import { PeopleIconComponent } from '@undock/common/ui-kit/ui/icons/people/people-icon.component'
import { PlansIconComponent } from '@undock/common/ui-kit/ui/icons/plans/plans-icon.component'
import { InfinityIconComponent } from '@undock/common/ui-kit/ui/icons/infinity/infinity-icon.component'
import { CalendarBasicIconComponent } from '@undock/common/ui-kit/ui/icons/calendar-basic/calendar-basic-icon.component'
import { WandIconComponent } from '@undock/common/ui-kit/ui/icons/wand/wand-icon.component'
import { ItemsListComponent } from '@undock/common/ui-kit/ui/icons/items-list/items-list.component'
import { ActiveIconComponent } from '@undock/common/ui-kit/ui/icons/active/active-icon.component'
import { CloseCircleComponent } from '@undock/common/ui-kit/ui/icons/close-circle/close-circle.component'
import { NoResultsLiteIconComponent } from '@undock/common/ui-kit/ui/icons/no-search-results-lite/no-results-lite.component'
import { ExpandElementIconComponent } from '@undock/common/ui-kit/ui/icons/expand-element/expand-element-icon.component'
import { TextIconComponent } from '@undock/common/ui-kit/ui/icons/text/text-icon.component'
import { PersonIconComponent } from '@undock/common/ui-kit/ui/icons/person/person-icon.component'
import { DurationIconComponent } from '@undock/common/ui-kit/ui/icons/duration/duration-icon.component'
import { AgendaExtendedIconComponent } from '@undock/common/ui-kit/ui/icons/agenda-extended/agenda-extended-icon.component'
import { BellIconComponent } from '@undock/common/ui-kit/ui/icons/bell/bell-icon.component'
import { ViewSwitchComponent } from '@undock/common/ui-kit/ui/components/view-switch/view-switch.component'
import { PlusSmallIconComponent } from '@undock/common/ui-kit/ui/icons/plus-small/plus-small-icon.component'
import { LockRoundedIconComponent } from '@undock/common/ui-kit/ui/icons/lock-rounded/lock-rounded-icon.component'
import { DoubleOptionSwitchComponent } from '@undock/common/ui-kit/ui/components/double-option-switch/double-option-switch.component'
import { ReservedSlotsHiddenIconComponent } from '@undock/common/ui-kit/ui/icons/reserved-slots-hidden/reserved-slots-hidden-icon.component'
import { ReservedSlotsShownIconComponent } from '@undock/common/ui-kit/ui/icons/reserved-slots-shown/reserved-slots-shown-icon.component'
import { BriefcaseIconComponent } from '@undock/common/ui-kit/ui/icons/briefcase/briefcase-icon.component'
import { AddNewCalendarIconComponent } from '@undock/common/ui-kit/ui/icons/add-calendar/add-calendar-icon.component'
import { AnalyticsIconComponent } from '@undock/common/ui-kit/ui/icons/analytics/analytics-icon.component'
import { BillingIconComponent } from '@undock/common/ui-kit/ui/icons/billing/billing-icon.component'
import { AtSignIconComponent } from '@undock/common/ui-kit/ui/icons/email/at-sign-icon.component'
import { CheckedHollowIconComponent } from '@undock/common/ui-kit/ui/components/checked-hollow/checked-hollow-icon.component'


/**
 * Use this array to declare and export the icon
 */
export const ICONS = [
    AgendaIconComponent,
    AgendaExtendedIconComponent,
    AddCalendarIconComponent,
    AuthEmptyStateIconComponent,
    AddPeopleIconComponent,
    AddNewCalendarIconComponent,
    AlignmentIconComponent,
    AllDayEventClockIconComponent,
    AllowedSitesIconComponent,
    AnalyticsIconComponent,
    AttachmentsIconComponent,
    AudioOffIconComponent,
    AudioOnIconComponent,
    AudioOnlyBroadcastIconComponent,
    AutoRescheduleIconComponent,
    AvatarGuestIconComponent,
    ActiveIconComponent,
    BackArrowIconComponent,
    BellIconComponent,
    BillingIconComponent,
    BriefcaseIconComponent,
    BasketIconComponent,
    ExpandIconComponent,
    CalendarIconComponent,
    CalendarNoTimeIconComponent,
    CalendarPersonIconComponent,
    CalendarProposeIconComponent,
    CalendarRequestedIconComponent,
    CalendarViewIconComponent,
    CalendarBasicIconComponent,
    CameraOffIconComponent,
    CameraOnIconComponent,
    ChainLinkIconComponent,
    ChangeColorIconComponent,
    ChangeTextColorIconComponent,
    ChatIconComponent,
    CheckedCircleIconComponent,
    CheckedHollowIconComponent,
    ChevronIconComponent,
    ChromeExtIconComponent,
    ClipboardIconComponent,
    CleanIconComponent,
    CloseIconComponent,
    CloseCircleComponent,
    CustomFormIconComponent,
    CommandsListIconComponent,
    CommandAfternoonsIconComponent,
    CommandCreatedIconComponent,
    CommandCustomRangeIconComponent,
    CommandEveningsIconComponent,
    CommandMarkAvailableIconComponent,
    CommandMarkBusyIconComponent,
    CommandMeetingIconComponent,
    CommandMorningsIconComponent,
    CommandPriorityIconComponent,
    CommandPromptIconComponent,
    CommandRescheduleIconComponent,
    CommandRunIconComponent,
    CommandTimeBlockIconComponent,
    CompareCalendarIconComponent,
    ConfEmptyStateIconComponent,
    ConferenceExitIconComponent,
    ConfSettingsIconComponent,
    ConnectCalendarIconComponent,
    CopyCodeIconComponent,
    CopyLinkIconComponent,
    CopyTimesIconComponent,
    CreateProposalIconComponent,
    CreditCardPlusIconComponent,
    CreditCardLogoIconComponent,
    DoorIconComponent,
    DollarIconComponent,
    DiscoverIconComponent,
    DurationIconComponent,
    EmailIconComponent,
    ElementStatusCheckedIconComponent,
    EventLabelAgendaIconComponent,
    EventLabelCommandIconComponent,
    EventLabelNotesIconComponent,
    EventLabelAudioIconComponent,
    EventLabelVideoIconComponent,
    AtSignIconComponent,
    EventLabelLocationIconComponent,
    EventPreferencesIconComponent,
    ExpandArrowIconComponent,
    ExpandElementIconComponent,
    EyeCloseIconComponent,
    EyeOpenIconComponent,
    FeatureIconComponent,
    FolderIconComponent,
    ForumBroadcastIconComponent,
    GearIconComponent,
    GlobalIconComponent,
    GoogleIconComponent,
    GoogleAuthIconComponent,
    GuardIconComponent,
    HistoryIconComponent,
    ReservedSlotsHiddenIconComponent,
    ReservedSlotsShownIconComponent,
    HourglassIconComponent,
    IconTemplateComponent,
    IndividualBroadcastIconComponent,
    InfoIconComponent,
    InPersonIconComponent,
    InfinityIconComponent,
    ItemsListComponent,
    LightningIconComponent,
    LiveBroadcastIconComponent,
    LocationsEmptyStateIconComponent,
    LockMedievalIconComponent,
    LockRoundedIconComponent,
    LockIconComponent,
    LogoUndockComponent,
    MeetingCancelledIconComponent,
    MeetingConfirmedIconComponent,
    MinusCircleHollowComponent,
    MeetingSubmittedIconComponent,
    MenuHorizontalIconComponent,
    MenuVerticalIconComponent,
    MicrosoftIconComponent,
    NoProposalIconComponent,
    NoResultsIconComponent,
    NoResultsLiteIconComponent,
    NotesIconComponent,
    NotFoundComponent,
    NotFoundIconComponent,
    OpenLinkIconComponent,
    ParticipantsIconComponent,
    PersonalizedFormIconComponent,
    PeopleIconComponent,
    PersonIconComponent,
    PlansIconComponent,
    PhoneIconComponent,
    PinIconComponent,
    PlanCategoryIconComponent,
    PlayIconComponent,
    PlusIconComponent,
    PlusSmallIconComponent,
    PreferTimeAfternoonIconComponent,
    PreferTimeLateMorningIconComponent,
    PreferTimeMidIconComponent,
    PreferTimeMorningIconComponent,
    KeyIconComponent,
    ClockIconComponent,
    LocationIconComponent,
    QuotesIconComponent,
    RemoteIconComponent,
    RemoveIconComponent,
    ScheduleIconComponent,
    SearchIconComponent,
    SettingsGearIconComponent,
    SettingsIconComponent,
    SettingsPersonalizedFormEditFormIconComponent,
    ShareIconComponent,
    ShareOnIconComponent,
    ShareUnavailableIconComponent,
    ShareWidgetIconComponent,
    ShrinkIconComponent,
    SidebarCloseIconComponent,
    SidebarOpenIconComponent,
    SignupMessageIconComponent,
    SlotHeldIconComponent,
    SpecialKeyIconComponent,
    StatusInIconComponent,
    StatusOutIconComponent,
    TimeIconComponent,
    TimeZoneIconComponent,
    TextIconComponent,
    TuneUpIconComponent,
    UdWidgetIconComponent,
    UserDataIconComponent,
    UserIconComponent,
    UserProfileIconComponent,
    UserCheckIconComponent,
    UsersIconComponent,
    VerifiedCheckIconComponent,
    WarningCalendarIconComponent,
    WarningIconComponent,
    DragNDropIconComponent,
    WebGlobeIconComponent,
    WandIconComponent,
    ShieldCheckIconComponent,
    GmailIconComponent,
    TwitterIconComponent,
    FacebookIconComponent,
    LinkedInIconComponent,
]

export function lottiePlayerFactory() {
    return player
}

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        CommonModule,

        // Angular CDK
        OverlayModule,
        DragDropModule,
        ClipboardModule,

        // Material UI
        MaterialsModule,
        MatSnackBarModule,
        MatMomentDateModule,

        FluentNgModule,
        UndockThemeModule,

        CKEditorModule,
        TextMaskModule,
        CurrencyMaskModule,
        ImageCropperModule,
        NgxSkeletonLoaderModule,
        NgxPaginationModule,
        NgxColorsModule,
        SimpleModalModule.forRoot({ container: document.body }, {
            ...defaultSimpleModalOptions,
            closeOnEscape: true,
            closeOnClickOutside: true,
            animationDuration: 400,
            autoFocus: true,
        }),
        LottieModule.forRoot({ player: lottiePlayerFactory }),
        LottieCacheModule.forRoot(),
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,

        OverlayModule,
        DragDropModule,
        ClipboardModule,

        MaterialsModule,
        MatSnackBarModule,
        MatMomentDateModule,

        FluentNgModule,
        UndockThemeModule,

        CKEditorModule,
        TextMaskModule,
        CurrencyMaskModule,
        ImageCropperModule,

        NgxSkeletonLoaderModule,
        NgxPaginationModule,
        NgxColorsModule,

        // Pipes
        MomentPipe,
        MomentTimeZonePipe,
        BooleanPipe,
        ObjectKeysPipe,
        AsyncDefaultPipe,
        RepeatElementPipe,
        HourOfDayPipe,
        MonthOfYearPipe,
        SplitOnCapitalPipe,
        RRuleToStringPipe,

        // Directives
        DropdownDirective,
        DropzoneDirective,
        EditableDirective,
        LifecycleDirective,
        ObservablesDirective,
        InfiniteListDirective,
        ImageCropperComponent,
        ClickOutsideDirective,
        ImageDefaultDirective,
        TextareaAutoresizeDirective,
        InputAutoresizeDirective,
        ElementHeightClassDirective,
        RequireAuthDirective,
        RefreshOnAuthChangeDirective,
        PopupTriggerDirective,
        ResizeObserverDirective,

        // Components
        DatePickerComponent,
        DateRangePickerComponent,
        NotFoundScreen,
        SpinnerComponent,
        KeyLabelComponent,
        UrlInputComponent,
        NotFoundComponent,
        ImageViewerComponent,
        ImageUploadPreviewComponent,
        ImageUploadComponent,
        SwitchToggleComponent,
        ImageDefaultDirective,
        ConfirmPopupComponent,
        ValuesSelectorComponent,
        IconSelectorComponent,
        ColorSelectorComponent,
        LoadingComponent,
        AILoadingComponent,
        LoadingOverlayComponent,
        ImageViewerHostComponent,
        PhoneNumberInputComponent,
        TextSwitchToggleComponent,
        ArrowButtonComponent,
        ShortArrowButtonComponent,
        DropdownButtonComponent,
        DoubleOptionSwitchComponent,
        ViewSwitchComponent,
        IconSelectButtonComponent,
        OnOffSelectButtonComponent,
        SelectFilterInputComponent,
        SelectFilterComponent,
        TimeSliderComponent,
        NavigationLoaderOverlayComponent,
        TimeZoneSelector2Component,

        LottieModule,
        LottieCacheModule,

        //Icons
        ...ICONS,
    ],
    declarations: [
        // Screens
        NotFoundScreen,

        // Directives
        DropdownDirective,
        DropzoneDirective,
        EditableDirective,
        LifecycleDirective,
        ObservablesDirective,
        InfiniteListDirective,
        ImageCropperComponent,
        ClickOutsideDirective,
        ImageDefaultDirective,
        TextareaAutoresizeDirective,
        InputAutoresizeDirective,
        ElementHeightClassDirective,
        RequireAuthDirective,
        RefreshOnAuthChangeDirective,
        PopupTriggerDirective,
        ResizeObserverDirective,

        // Pipes
        MomentPipe,
        MomentTimeZonePipe,
        BooleanPipe,
        ObjectKeysPipe,
        AsyncDefaultPipe,
        RepeatElementPipe,
        HourOfDayPipe,
        MonthOfYearPipe,
        SplitOnCapitalPipe,
        RRuleToStringPipe,

        // Components
        DatePickerComponent,
        DateRangePickerComponent,
        SpinnerComponent,
        NotFoundComponent,
        UrlInputComponent,
        KeyLabelComponent,
        ImageViewerComponent,
        ConfirmPopupComponent,
        SwitchToggleComponent,
        LoadingComponent,
        AILoadingComponent,
        LoadingOverlayComponent,
        ValuesSelectorComponent,
        IconSelectorComponent,
        ImageUploadPreviewComponent,
        ImageUploadComponent,
        ColorSelectorComponent,
        ImageViewerHostComponent,
        PhoneNumberInputComponent,
        ViewSwitchComponent,
        TextSwitchToggleComponent,
        ArrowButtonComponent,
        ShortArrowButtonComponent,
        DropdownButtonComponent,
        DoubleOptionSwitchComponent,
        IconSelectButtonComponent,
        OnOffSelectButtonComponent,
        SelectFilterInputComponent,
        SelectFilterComponent,
        LogoUndockComponent,
        ParentDialogModalComponent,
        NavigationLoaderOverlayComponent,
        TimeSliderComponent,
        TimeZoneSelector2Component,

        //Icons
        ...ICONS,
    ],
    providers: [
        SimpleModalModule,

        MomentTimeZonePipe,
        SplitOnCapitalPipe
    ],
    entryComponents: [
        ParentDialogModalComponent,
    ],
})
export class UiKitModule {
    static forRoot(): ModuleWithProviders<UiKitModule> {
        return {
            ngModule: UiKitModule,
            providers: [
                ImageViewerService,
                ConfirmPopupService,

                SnackbarManager,
                { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
            ],
        }
    }
}
