import {
    ModuleWithProviders,
    NgModule,
} from '@angular/core'

import { Config } from '@undock/core'
import { CoreModule } from '@undock/core/core.module'
import { Database } from './services/database.service'
import { CurrentUser } from '@undock/session/models/current-user'
import { UserSession } from '@undock/session/models/user-session'
import { FirebaseApp } from '@undock/session/contracts/firebase-app.token'
import { FirebaseAppFactory } from '@undock/session/factories/firebase-app.factory'


@NgModule({
    imports: [
        CoreModule,
    ],
})
export class SessionModule {
    public constructor(
        /**
         * Firestore DB initialization - must be here to init Firestore
         */
        database: Database,
    ) {}

    static forRoot(): ModuleWithProviders<SessionModule> {
        return {
            ngModule: SessionModule,
            providers: [
                Database,
                CurrentUser,
                UserSession,

                { provide: FirebaseApp, useFactory: FirebaseAppFactory, deps: [Config] },
            ],
        }
    }
}
