<div class="popup-container">
    <div class="content">
        <div class="content-message">
            <div class="message-text-box">
                <span>{{ config.title }}</span>
            </div>
        </div>

        <div class="content-message">
            <div class="message-text-box warning">
                <span>{{ config.description }}</span>
            </div>
        </div>
    </div>

    <div class="actions"
         [ngClass]="{
            'single-option': config.hideDiscardButton,
            'actions-vertical': config.actionButtonsStyle === 'vertical',
            'actions-horizontal': config.actionButtonsStyle === 'horizontal',
            'discard-button-link': config.discardButtonStyle === 'link'
         }"
    >
        <ng-container *ngIf="!config.hideDiscardButton">
            <button class="confirm-actions-button btn-secondary discard"
                    (click)="discard()"
            >{{ config.discardButtonLabel }}</button>
        </ng-container>

        <button class="confirm-actions-button btn-primary"
             [class.confirm]="config.highlightConfirmButton" (click)="confirm()"
        >{{ config.confirmButtonLabel }}</button>
    </div>
</div>
