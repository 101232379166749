<div class="organizations-container">
    <div class="header">
        <div class="label">
            Organizations
        </div>

        <div class="add-organization clickable" [routerLink]="['', 'organizations', 'new']">
            <app-plus-small-icon
                [height]="22" [width]="22" [color]="'#807A8E'"
                [matTooltip]="'New Organization'" [matTooltipPosition]="'right'"
            ></app-plus-small-icon>
        </div>
    </div>

    <div class="organizations-list"
         *ngIf="(organizations$ | stream) as organizations; else organizationsSkeletonTmpl"
    >
        <div class="organization-list-item"
             *ngFor="let organization of organizations"
             [routerLink]="['settings', 'organizations']"
             [matTooltip]="organization.name" [matTooltipPosition]="TooltipPosition.RIGHT"
        >
            <div class="logo">
                <img [src]="organization.logoUrl | imgThumbnail" [alt]="organization.name" />
            </div>

            <div class="name">
                {{ organization.name }}
            </div>
        </div>
    </div>

    <ng-template #organizationsSkeletonTmpl>
        <div class="organizations-list">
            <div class="organization-list-item">
                <div class="logo">
                    <ngx-skeleton-loader appearance="circle" [theme]="{width: '24px', height: '24px'}"></ngx-skeleton-loader>
                </div>
                <div class="name">
                    <ngx-skeleton-loader [theme]="{width: '100%', height: '20px', 'border-radius': '6px'}"></ngx-skeleton-loader>
                </div>
            </div>
            <div class="organization-list-item">
                <div class="logo">
                    <ngx-skeleton-loader appearance="circle" [theme]="{width: '24px', height: '24px'}"></ngx-skeleton-loader>
                </div>
                <div class="name">
                    <ngx-skeleton-loader [theme]="{width: '100%', height: '20px', 'border-radius': '6px'}"></ngx-skeleton-loader>
                </div>
            </div>
            <div class="organization-list-item">
                <div class="logo">
                    <ngx-skeleton-loader appearance="circle" [theme]="{width: '24px', height: '24px'}"></ngx-skeleton-loader>
                </div>
                <div class="name">
                    <ngx-skeleton-loader [theme]="{width: '100%', height: '20px', 'border-radius': '6px'}"></ngx-skeleton-loader>
                </div>
            </div>
            <div class="organization-list-item">
                <div class="logo">
                    <ngx-skeleton-loader appearance="circle" [theme]="{width: '24px', height: '24px'}"></ngx-skeleton-loader>
                </div>
                <div class="name">
                    <ngx-skeleton-loader [theme]="{width: '100%', height: '20px', 'border-radius': '6px'}"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </ng-template>
</div>
