import {
  ChangeDetectorRef,
  Directive,
  HostListener,
  Input,
} from '@angular/core'


@Directive({
    selector: 'img[default]',
    host: {
        '[attr.src]': 'src',
        '[class.default]': 'isDefaultImageApplied',
    },
})
export class ImageDefaultDirective {

    private _src: string

    @Input()
    public default: string

    public isDefaultImageApplied: boolean = false

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
    ) {}


    public get src(): string {
        return this._src
    }

    @Input()
    public set src(value: string) {
        this._src = value ?? ''
    }


    @HostListener('error')
    public onImageError() {
        this.src = this.default
        this.isDefaultImageApplied = true

        this.changeDetectorRef.detectChanges()
    }
}

