import { Injectable } from '@angular/core'
import {
    ReactiveStream,
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'
import { Memoize } from '../decorators'
import RecordRTC from 'recordrtc'


@Injectable()
export class RecorderService {

    protected recordRTC: RecordRTC

    @CompleteOnDestroy()
    protected mediaStreamSubject = new ValueSubject<MediaStream>(null)

    @CompleteOnDestroy()
    protected isRecordingRunningSubject = new ValueSubject<boolean>(false)

    @Memoize()
    public get mediaStreamStream(): ReactiveStream<MediaStream> {
        return this.mediaStreamSubject.asStream()
    }

    @Memoize()
    public get isRecordingRunningStream(): ReactiveStream<boolean> {
        return this.isRecordingRunningSubject.asStream()
    }

    public async startRecording(): Promise<void> {
        const stream = await this.getUserMediaStream()

        this.mediaStreamSubject.next(stream)
        this.isRecordingRunningSubject.next(true)

        this.recordRTC = new RecordRTC(stream, {
            type: 'video', disableLogs: true,
        })

        await this.recordRTC.startRecording()
    }

    public async stopRecording(): Promise<Blob> {
        if (this.recordRTC) {
            const stream = await this.mediaStreamSubject

            this.mediaStreamSubject.next(null)
            this.isRecordingRunningSubject.next(false)

            const blob = await new Promise(resolve => {
                this.recordRTC.stopRecording(() => {
                    resolve(this.recordRTC.getBlob())
                })
            })

            /**
             * Revoking all media stream tracks when the recording is stopped
             */
            stream.getTracks().forEach(track => track.stop())

            return blob as Blob
        }

        return null
    }

    public async cancelRecording(): Promise<void> {
        if (this.recordRTC) {
            const stream = await this.mediaStreamSubject

            this.recordRTC.stopRecording()
            this.isRecordingRunningSubject.next(false)

            /**
             * Revoking all media stream tracks when the recording is stopped
             */
            stream.getTracks().forEach(track => track.stop())
        }
    }


    protected getUserMediaStream(): Promise<MediaStream> {
        return navigator?.mediaDevices?.getUserMedia({
            audio: true, video: true,
        })
    }
}
