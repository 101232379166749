import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Optional,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core'
import { Router } from '@angular/router'
import { isNumber } from 'lodash'

import {
    combineLatest,
    map,
    shareReplay,
    takeUntil,
} from 'rxjs'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'
import {
    filter,
    pairwise,
    startWith,
    withLatestFrom,
} from 'rxjs/operators'

import { Api } from '@undock/api'
import { ConfirmPopupService } from '@undock/common/ui-kit'
import {
    KeyboardShortcut,
    UseKeyboardShortcuts,
} from '@undock/hotkeys/services/keyboard-shortcuts.decorator'
import {
    SnackbarManager,
    SnackbarPosition,
} from '@undock/common/ui-kit/services/snackbar.manager'
import { TimeCommandViewModel } from '@undock/time/prompt/states/time-command.view-model'
import {
    TimeCommandAction,
    TimeCommandActions,
    TimeCommandBlueprintEvent,
    TimeCommandBlueprintHold,
} from '@undock/api/scopes/nlp/routes/commands.route'
import { Clipboard } from '@angular/cdk/clipboard'
import {
    clone,
    DeviceUtil,
    Memoize,
} from '@undock/core'
import { CurrentUser } from '@undock/session'
import {
    CalendarGridDataSource,
    DashboardCalendarViewModel,
} from '@undock/dashboard/view-models'
import { CalendaringGridViewModel } from '@undock/common/calendar-grid/view-models/calendaring-grid.view-model'
import { TimeCommandBlueprintEventItemComponent } from '@undock/time/prompt/ui/components/time-command-blueprint-event-item/time-command-blueprint-event-item.component'
import { CalendarEventsStorage } from '@undock/calendar/services/calendar-events.storage'
import { GridDataSource } from '@undock/common/calendar-grid/contracts/grid-data-source'
import { CalendarGridEvent } from '@undock/timeline/services/timeline-events.manager'
import { UiTimelineEvent } from '@undock/dashboard/contracts'
import { IS_BETA_USER } from '@undock/feature-plans/tokens/is-beta-user'


@UseKeyboardShortcuts({
    preventDefault: false
})
@Component({
    selector: 'app-time-command-blueprint',
    templateUrl: 'edit-time-command-blueprint.component.html',
    styleUrls: ['edit-time-command-blueprint.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditTimeCommandBlueprintComponent {

    @Output() onEventSelected = new EventEmitter<TimeCommandBlueprintEvent>()
    @Output() onHoldSelected = new EventEmitter<TimeCommandBlueprintHold>()
    @Output() onEditNewEvent = new EventEmitter<TimeCommandBlueprintEvent>()

    public readonly TimeCommandActions = TimeCommandActions

    public readonly state = this.commandViewModel.state
    public readonly currentUserStream = this.currentUser.dataStream

    @CompleteOnDestroy()
    public readonly targetedEventIndexSubject = new ValueSubject<number>(null)

    @CompleteOnDestroy()
    public readonly isNonSuggestedEventsShowingSubject = new ValueSubject<boolean>(false)

    @CompleteOnDestroy()
    public readonly lastSelectAllActionSubject = new ValueSubject<'all' | 'none'>('none')

    @ViewChildren('itemElement')
    protected readonly eventItemElements: QueryList<TimeCommandBlueprintEventItemComponent>

    @EmitOnDestroy()
    protected readonly destroyEvent = new DestroyEvent()

    public constructor(
        @Inject(IS_BETA_USER)
        public readonly isBetaUser$: ReactiveStream<boolean>,
        public readonly deviceUtil: DeviceUtil,
        public readonly commandViewModel: TimeCommandViewModel,

        protected api: Api,
        protected router: Router,
        protected clipboard: Clipboard,
        protected currentUser: CurrentUser,
        protected snackbarManager: SnackbarManager,
        protected confirmPopupService: ConfirmPopupService,

        // dashboard calendar page context
        @Optional()
        protected readonly calendarEventsStorage: CalendarEventsStorage,
        @Optional() @Inject(GridDataSource)
        protected readonly gridDataSource: CalendarGridDataSource,
        @Optional() @Inject(CalendaringGridViewModel)
        protected readonly calendarGridViewModel: DashboardCalendarViewModel,
    ) {}

    @Memoize()
    public get eventsStream(): ReactiveStream<TimeCommandBlueprintEvent[]> {
        return new ReactiveStream<TimeCommandBlueprintEvent[]>(
            this.state.blueprintStream.pipe(
                map(blueprint => blueprint?.actions?.reduce(
                    (events, action) => [...events, ...(action.events ?? [])], [],
                )),
                takeUntil(this.destroyEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            )
        )
    }

    @Memoize()
    public get visibleEventsStream(): ReactiveStream<TimeCommandBlueprintEvent[]> {
        return new ReactiveStream<TimeCommandBlueprintEvent[]>(
            combineLatest([
                this.eventsStream,
                this.isNonSuggestedEventsShowingSubject,
                this.state.isTrainingModeStream
            ]).pipe(
            map(([events, isNonSuggestedEventsShowing, isTrainingMode]) =>
                isTrainingMode
                    ? events ?? []
                    : isNonSuggestedEventsShowing
                        ? events ?? []
                        : events?.filter(e => e.isSelected || e.isSuggested) ?? []
            ),
            takeUntil(this.destroyEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        ))
    }

    @Memoize()
    public get holdsStream(): ReactiveStream<TimeCommandBlueprintHold[]> {
        return new ReactiveStream<TimeCommandBlueprintHold[]>(
            this.state.blueprintStream.pipe(
                map(blueprint => blueprint?.actions?.reduce(
                    (holds, action) => [...holds, ...(action.holds ?? [])], [],
                )),
                takeUntil(this.destroyEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            )
        )
    }

    @Memoize()
    public get visibleHoldsStream(): ReactiveStream<TimeCommandBlueprintHold[]> {
        return new ReactiveStream<TimeCommandBlueprintHold[]>(
            combineLatest([
                this.holdsStream,
                this.isNonSuggestedEventsShowingSubject,
                this.state.isTrainingModeStream
            ]).pipe(
                map(([holds, isNonSuggestedEventsShowing, isTrainingMode]) =>
                    isTrainingMode
                        ? holds ?? []
                        : isNonSuggestedEventsShowing
                            ? holds ?? []
                            : holds?.filter(h => h.isSelected || h.isSuggested) ?? []
                ),
                takeUntil(this.destroyEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ))
    }

    @Memoize()
    public get visibleActionsEventsStream(): ReactiveStream<TimeCommandAction[]> {
        return new ReactiveStream<TimeCommandAction[]>(
            combineLatest([
                this.state.blueprintStream,
                this.isNonSuggestedEventsShowingSubject,
                this.state.isTrainingModeStream
            ]).pipe(
                map(([blueprint, isNonSuggestedEventsShowing, isTrainingMode]) => {
                    if (blueprint) {
                        if (isTrainingMode || isNonSuggestedEventsShowing) {
                            return blueprint.actions.sort((a, b) => a.action < b.action ? -1 : a.action > b.action ? 1 : 0)
                        } else {
                            let clonedActions = clone(blueprint.actions)
                            clonedActions.forEach(a => {
                                a.events = a.events.filter(e => e.isSelected || e.isSuggested)
                                a.holds = a.holds?.length ? a.holds.filter(h => h.isSelected || h.isSuggested) : []
                            })
                            return clonedActions.sort((a, b) => a.action < b.action ? -1 : a.action > b.action ? 1 : 0)
                        }
                    }
                    return []
                }),
                takeUntil(this.destroyEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ))
    }

    @Memoize()
    public get shouldShowConfirmButtonStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(this.visibleActionsEventsStream.pipe(
            map(actions => {
                if (actions.length) {
                    return actions.some(action =>
                        ![TimeCommandActions.ShareAvailability, TimeCommandActions.Answer, TimeCommandActions.ViewHolds, TimeCommandActions.Other]
                            .includes(action.action)
                    )
                }
                return false
            }),
            takeUntil(this.destroyEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        ))
    }

    @Memoize()
    public get shouldShowToggleNonSuggestedEventsButtonStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(this.visibleActionsEventsStream.pipe(
                map(actions => {
                    if (actions.length) {
                        return actions.some(action =>
                            [TimeCommandActions.Cancel, TimeCommandActions.Modify, TimeCommandActions.View]
                                .includes(action.action)
                        )
                    }
                    return false
                }),
                takeUntil(this.destroyEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ))
    }

    @Memoize()
    public get selectedEventsCountStream(): ReactiveStream<number> {
        return new ReactiveStream<number>(this.eventsStream.pipe(
            map(events => events?.length ? events.filter(e => e.isSelected).length : 0),
            takeUntil(this.destroyEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        ))
    }

    @Memoize()
    public get selectedHoldsCountStream(): ReactiveStream<number> {
        return new ReactiveStream<number>(this.holdsStream.pipe(
            map(holds => holds?.length ? holds.filter(h => h.isSelected).length : 0),
            takeUntil(this.destroyEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        ))
    }

    @KeyboardShortcut('Down')
    public async targetNextEvent() {
        let [events, targetedEventIndex] = await Promise.all([
            this.visibleEventsStream, this.targetedEventIndexSubject
        ])
        if (events?.length) {
            if (targetedEventIndex === null || targetedEventIndex === events.length-1) {
                this.targetedEventIndexSubject.next(0)
            } else {
                this.targetedEventIndexSubject.next(targetedEventIndex+1)
            }
        } else {
            this.targetedEventIndexSubject.next(null)
        }

        this.scrollSelectedEventItemIntoView()
    }

    @KeyboardShortcut('Up')
    public async targetPreviousEvent() {
        let [events, targetedEventIndex] = await Promise.all([
            this.visibleEventsStream, this.targetedEventIndexSubject
        ])
        if (events?.length) {
            if (targetedEventIndex === null || targetedEventIndex === 0) {
                this.targetedEventIndexSubject.next(events.length-1)
            } else {
                this.targetedEventIndexSubject.next(targetedEventIndex-1)
            }
        } else {
            this.targetedEventIndexSubject.next(null)
        }

        this.scrollSelectedEventItemIntoView()
    }

    public async targetFirstEvent() {
        let events = await this.visibleEventsStream
        if (events?.length) {
            return this.targetedEventIndexSubject.next(0)
        } else {
            this.targetedEventIndexSubject.next(null)
        }

        this.scrollSelectedEventItemIntoView()
    }

    @KeyboardShortcut('Enter')
    public async viewTargetedEvent() {
        if (await this.getActionForTargetedEvent() === TimeCommandActions.View) {
            let [events, targetedEventIndex] = await Promise.all([
                this.visibleEventsStream, this.targetedEventIndexSubject
            ])
            if (events?.length && targetedEventIndex !== null) {
                return this.onEventSelected.emit(
                    events.find(e => e.iCalUId === events[targetedEventIndex].iCalUId)
                )
            }
        }
    }

    @KeyboardShortcut('Space')
    public async toggleTargetedEvent() {
        if (await this.getActionForTargetedEvent() !== TimeCommandActions.View) {
            let [events, targetedEventIndex] = await Promise.all([
                this.visibleEventsStream, this.targetedEventIndexSubject
            ])
            if (events?.length && targetedEventIndex !== null) {
                return this.commandViewModel.toggleEventSelection(
                    events.find(e => e.iCalUId === events[targetedEventIndex].iCalUId)
                )
            }
        }
    }

    @KeyboardShortcut('Alt')
    public async toggleIsNonActiveEventsShowing() {
        if (await this.shouldShowToggleNonSuggestedEventsButtonStream) {
            let nonActiveVisible = await this.isNonSuggestedEventsShowingSubject
            this.isNonSuggestedEventsShowingSubject.next(
                !nonActiveVisible
            )
            setTimeout(() => this.targetFirstEvent())
        }
    }

    public async toggleEvent(event: TimeCommandBlueprintEvent) {
        return this.commandViewModel.toggleEventSelection(event)
    }

    public async toggleHold(hold: TimeCommandBlueprintHold) {
        return this.commandViewModel.toggleHoldSelection(hold)
    }

    public async toggleAllEvents() {
        let last = await this.lastSelectAllActionSubject
        if (last === 'none') {
            return this.commandViewModel.selectAllEvents()
        } else {
            return this.commandViewModel.clearResponse()
        }
    }

    public async toggleTrainingMode() {
        this.isNonSuggestedEventsShowingSubject.next(true)
        return this.commandViewModel.toggleTrainingMode()
    }

    public async downloadTrainingData() {
        return this.api.nlp.training.getTrainingData()
    }

    @KeyboardShortcut('Control.Enter, Command.Enter')
    public async executeCommand() {
        if (await this.state.isTrainingModeStream) {
            await this.commandViewModel.trainCommand(
                await this.state.blueprintStream
            )
            return this.isNonSuggestedEventsShowingSubject.next(true)
        }
        return this.commandViewModel.executeCommand(
            await this.state.blueprintStream
        )
    }

    public copyTextToClipboard(text: string) {
        this.clipboard.copy(text)
        this.snackbarManager.info("Copied to clipboard", SnackbarPosition.BottomCenter)
    }

    // Triggers schedule event action when schedule button is clicked
    public scheduleMeeting() {
         // TODO: editNewEvent using GPT's suggested time frame and duration, which should be stored in TimeCommandAction
         // TODO: prompt for timeframe and duration on backend
    
    }

    public async close() {
        return this.commandViewModel.clearResponse()
    }

    public async ngOnInit() {
        // Executed when running in dashboard context
        if (this.gridDataSource && this.calendarGridViewModel) {
            this.initializeDashboardCalendarFeatures()
        }
    }

    protected async getActionForTargetedEvent(): Promise<TimeCommandActions> {
        let [events, targetedEventIndex] = await Promise.all([
            this.visibleEventsStream, this.targetedEventIndexSubject
        ])
        if (events?.length && targetedEventIndex !== null) {
            let targetedEvent = events.find(e => e.iCalUId === events[targetedEventIndex].iCalUId)
            if (targetedEvent) {
                let blueprint = await this.state.blueprintStream
                for (let action of blueprint.actions) {
                    let ev = action.events.find(e => e.iCalUId === targetedEvent.iCalUId)
                    if (ev) {
                        return action.action
                    }
                }
                return null
            }
        }
    }

    protected scrollSelectedEventItemIntoView() {
        setTimeout(() => {
            if (this.eventItemElements?.length) {
                let selectedEl = this.eventItemElements.find(el => el.el.nativeElement.classList.contains('__targeted'))
                if (selectedEl) {
                    selectedEl.el.nativeElement.scrollIntoView({ block: 'nearest', inline: 'nearest' })
                }
            }
        })
    }

    /**
     * TODO: Move to a separate service
     * 1. Switch calendar to the selected event
     * 2. Create temporary events for blueprint events
     * 3. Highlight selected event in the calendar grid
     */
    protected initializeDashboardCalendarFeatures() {
        if (!this.calendarGridViewModel) {
            throw new Error(`CalendaringGridViewModel is missing`)
        }

        // Automatically target first action for blueprints
        this.state.blueprintStream.pipe(
            startWith(null),
            pairwise(),
            filter(([ prev, next ]) => {
                return next?.actions?.length &&
                    next.actions[0]?.id !== prev?.actions?.[0]?.id
            })
        ).subscribe(() => {
            this.targetedEventIndexSubject.next(0)
        })

        // Switch calendar view to the targeted blueprint event
        this.targetedEventIndexSubject.pipe(
            takeUntil(this.destroyEvent),
            withLatestFrom(
                this.visibleEventsStream,
                this.calendarGridViewModel.view$,
            ),
            map(([ targetedEventIdx, allEvents, view ]) => {
                return {
                    view: view,
                    targetedEvent: allEvents && isNumber(targetedEventIdx)
                        ? allEvents[targetedEventIdx]
                        : null,
                }
            }),
        ).subscribe(({ targetedEvent: event, view }) => {
            if (event) {
                if (event.schedule?.start) {
                    const start = new Date(
                        event.reschedule?.start ?? event.schedule?.start,
                    )
                    // Switch calendar grid viewed date
                    this.calendarGridViewModel.viewDate$.next(start)
                    // Set calendar grid time to show targeted event
                    this.calendarGridViewModel.setGridTime$.next({
                        time: start,
                        scrollTo: view === 'day' ? 'center' : 'start',
                    })
                }
            }
        })

        // Display blueprint events in the calendar grid
        this.state.blueprintStream.pipe(
            takeUntil(this.destroyEvent),
        ).subscribe(async blueprint => {

            // Reset dashboard calendar grid and exit
            if (!blueprint) {
                if (this.gridDataSource.isEditingAllowed$) {
                    this.gridDataSource.isEditingAllowed$.next(true)
                }
                this.calendarGridViewModel.viewDate$.next(new Date())
                this.calendarGridViewModel.temporaryEvents$.next([])
                this.calendarGridViewModel.overriddenEvents$.next([])
                return
            }

            // Generate calendar grid events for blueprint
            const gridTemporaryEvents = []
            const gridEventsToOverride = []

            for (let action of blueprint.actions) {
                // TODO: Rework loading events flow
                const needToLoadEvents = (
                    action.action === TimeCommandActions.Cancel ||
                    action.action === TimeCommandActions.Reschedule ||
                    action.action === TimeCommandActions.Modify
                )
                const { loadedEvents: calendarEvents } = needToLoadEvents
                    ? await this.calendarEventsStorage.getEventsForDateRange({
                        end: new Date(action.timeframe[0].end),
                        start: new Date(action.timeframe[0].start),
                    }) : { loadedEvents: [] }

                const eventsToBeApplied = action.events.filter(e => e.isSelected)

                // Handle action based on type
                switch (action.action) {
                    case TimeCommandActions.Block:
                    case TimeCommandActions.Schedule:

                        eventsToBeApplied.forEach(actionEvent => {
                            const id = actionEvent.meetingData ?
                                actionEvent.meetingData._id : actionEvent.iCalUId

                            gridTemporaryEvents.push(
                                this.gridDataSource.timelineEventToCalendarGridEvent({
                                    id: id,
                                    title: actionEvent.title,
                                    end: new Date(actionEvent.schedule.end).toISOString(),
                                    start: new Date(actionEvent.schedule.start).toISOString(),
                                    state: {
                                        isActive: true,
                                        isCurrent: true,
                                    },
                                } as UiTimelineEvent),
                            )
                        })
                        break

                    case TimeCommandActions.Cancel:
                    case TimeCommandActions.Reschedule:
                        // Generate grid override events for actions
                        eventsToBeApplied.forEach(actionEvent => {
                            const calendarEvent = clone(calendarEvents.find(calEvent => {
                                return calEvent.iCalUId === actionEvent.iCalUId
                            }))
                            if (calendarEvent) {
                                // Use new schedule for Reschedule action
                                if (actionEvent.reschedule) {
                                    calendarEvent.end = new Date(actionEvent.reschedule.end)
                                    calendarEvent.start = new Date(actionEvent.reschedule.start)
                                }

                                const gridEvent = this.gridDataSource
                                                       .timelineEventToCalendarGridEvent(
                                                           calendarEvent
                                                       ) as CalendarGridEvent<UiTimelineEvent>

                                // Update event meta for reschedule action
                                if (action.action === TimeCommandActions.Cancel) {
                                    gridEvent.meta.payload.state.isNext = true
                                    gridEvent.meta.payload.state.isDeclined = true
                                }

                                gridEventsToOverride.push(gridEvent)
                            }
                        })
                        break
                }
            }

            // Mark events to not open
            gridTemporaryEvents.forEach(e => e.meta.openedIn = 'time-command-blueprint')
            gridEventsToOverride.forEach(e => e.meta.openedIn = 'time-command-blueprint')

            if (this.gridDataSource.isEditingAllowed$) {
                this.gridDataSource.isEditingAllowed$.next(false)
            }
            this.calendarGridViewModel.temporaryEvents$.next(gridTemporaryEvents)
            this.calendarGridViewModel.overriddenEvents$.next(gridEventsToOverride)
        })
    }
}

