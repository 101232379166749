import { Injectable } from '@angular/core'

import {
    StateModel,
    StreamStore,
} from '@typeheim/fluent-states'
import { StatefulSubject } from '@typeheim/fire-rx'

import {
    SyncedCalendar,
    IntegrationsManager,
} from '@undock/integrations'
import { Api } from '@undock/api'
import {
    ArrayHelpers,
    LocalStorage,
} from '@undock/core'
import { CurrentUser } from '@undock/session'
import { CalendarSettings } from '@undock/api/scopes/calendar/routes/settings.route'


@Injectable()
export class CalendarSettingsStateModel extends StateModel<CalendarSettingsStore> {

    protected readonly store = new CalendarSettingsStore()

    public constructor(
        private api: Api,
        private currentUser: CurrentUser,
        private localStorage: LocalStorage,
        private integrationsManager: IntegrationsManager,
    ) {
        super()
        this.initialize().catch(error => {
            console.warn(`Cannot initialize CalendarSettingsStateModel`, error)
        })
    }

    protected async initialize() {
        const user = await this.currentUser.data
        if (!user.isGuest) {
            const cached = this.getCachedCalendarSetting(user._id)
            if (cached) {
                this.store.settingsStream.next(cached)
            }
        }
        return this.reload()
    }

    public async reload() {
        const user = await this.currentUser.data
        if (!user.isGuest) {
            const [ settings, integrations ] = await Promise.all([
                this.api.calendar.settings.getSettings(),
                this.integrationsManager.calendarsStream,
            ])

            this.prepareCalendarSettingsData(settings, integrations)

            this.store.settingsStream.next(settings)
            this.setCachedCalendarSettings(user._id, settings)
        } else {
            /**
             * Guest mock settings
             */
            this.store.settingsStream.next({
                calendars: [],
                showWeekends: false,
                showReservedSlots: false,
            })
        }
    }

    public async update(settings: CalendarSettings) {
        const user = await this.currentUser.data
        if (!user.isGuest) {
            this.store.settingsStream.next(settings)
            this.setCachedCalendarSettings(user._id, settings)
            return this.api.calendar.settings.update(settings)
        }
    }

    /**
     * Temporary solution for building missing settings
     */
    protected prepareCalendarSettingsData(
        settings: CalendarSettings, integrations: SyncedCalendar[],
    ) {
        /**
         * Filtering unique calendar settings
         */
        settings.calendars = ArrayHelpers.filterUniqueWithCache(
            settings.calendars, calSettings => calSettings.id,
        )

        for (let integration of integrations) {
            const targetSettings = settings.calendars.find(
                item => item.id === integration.email
            )

            if (targetSettings) {
                const subCalendarIds = integration.calendars
                                                  .filter(calendar => !calendar.isPrimary)
                                                  .map(calendar => calendar.calendarId)

                const settingsSubCalendarIds = targetSettings.subCalendars
                                                             .map(subCalendar => subCalendar.id)

                /**
                 * Sub-calendar ids to build the initial settings
                 */
                const missingSettingSubCalendarIds = ArrayHelpers.findArraysDifference(
                    subCalendarIds, ArrayHelpers.findArraysIntersection(
                        subCalendarIds, settingsSubCalendarIds,
                    ),
                )

                for (let calendarId of missingSettingSubCalendarIds) {
                    const targetSubCalendar = integration.calendars.find(
                        calendar => calendar.calendarId === calendarId
                    )

                    targetSettings.subCalendars.push({
                        id: targetSubCalendar.calendarId,
                        name: targetSubCalendar.title,
                        enabled: targetSubCalendar.isActive,
                        display: targetSubCalendar.isDisplayed,
                        visible: targetSubCalendar.isDisplayed,

                        icon: '',
                        color: '',
                    })
                }
            } else {
                console.warn(`Should build settings for ${integration.email}`)
            }
        }
    }

    protected getCachedCalendarSetting(userId: string): CalendarSettings {
        const cache = this.localStorage.getItem(`@undock[CalendarSettings][${userId}]`)
        if (cache) {
            return JSON.parse(cache)
        }
        return null
    }

    protected setCachedCalendarSettings(userId: string, settings: CalendarSettings) {
        this.localStorage.setItem(`@undock[CalendarSettings][${userId}]`, JSON.stringify(settings))
    }
}

export class CalendarSettingsStore extends StreamStore {
    public settingsStream = new StatefulSubject<CalendarSettings>()
}
