import { Injectable } from '@angular/core'

import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
} from '@typeheim/fire-rx'
import {
    tap,
    map,
    startWith,
    switchMap,
    takeUntil,
    shareReplay,
    distinctUntilChanged,
} from 'rxjs/operators'

import {
    Config,
    Memoize,
    AppEventsDispatcher,
} from '@undock/core'
import { Api } from '@undock/api'
import { CurrentUser } from '@undock/session'
import {
    Schedule,
    MeetingTypeScope,
    ScheduleType,
} from '@undock/dock/meet/models/schedule.model'
import { AccountCollection } from '@undock/user/models/account.model'
import { MeetingTypeFactory } from '@undock/dock/meet/models/factories/meeting-type.factory'
import { MeetingDurationOptionsProvider } from '@undock/dock/meet/services/data-providers/meeting-duration-options.provider'
import { UserLimitsProvider } from '@undock/feature-plans/services/user-limits.provider'
import {
    combineLatest,
    from,
} from 'rxjs'
import { SnackbarManager } from '@undock/common/ui-kit/services/snackbar.manager'
import { UserAnalyticsAttributesManager } from '@undock/integrations/services/analytics/user-analytics-attributes.manager'


@Injectable({ providedIn: 'root' })
export class MeetingTypesManager {

    @EmitOnDestroy()
    protected readonly destroyedEvent = new DestroyEvent()

    public constructor(
        protected api: Api,
        protected config: Config,
        protected currentUser: CurrentUser,
        protected snackbarManager: SnackbarManager,
        protected meetingTypeFactory: MeetingTypeFactory,
        protected userLimitsProvider: UserLimitsProvider,
        protected userAnalyticsManager: UserAnalyticsAttributesManager,
        protected meetingDurationOptionsProvider: MeetingDurationOptionsProvider,
    ) {}

    @Memoize()
    public get meetingTypesStream(): ReactiveStream<Schedule[]> {
        return new ReactiveStream<Schedule[]>(
            this.currentUser.uidStream.pipe(
                distinctUntilChanged(),
                switchMap(uid => {
                    return AccountCollection.one(uid)
                                            .collection(Schedule)
                                            .all()
                                            .filter(MeetingTypeScope.notRemoved)
                                            .stream()
                                            .emitUntil(this.destroyedEvent)
                }),

                /**
                 * Ensure data structure is correct
                 */
                map(schedules => {
                    return this.prepareSchedules(schedules)
                }),

                /**
                 * Triggers code generating build-in schedules (fail-safe option)
                 */
                tap(schedules => {
                    const isStandardScheduleExist = schedules.some(s => s.type === ScheduleType.Standard)
                        , isPersonalScheduleExist = schedules.some(s => s.type === ScheduleType.Personal)
                    if (!isStandardScheduleExist || !isPersonalScheduleExist) {
                        this.api.schedules.personal.ensureBuildInSchedulesCreated()
                            .catch(error => {
                                console.warn(`Cannot generate build-in schedules`, error)
                            })
                    }
                }),

                /**
                 * Sends analytics event
                 */
                tap(types =>
                    this.userAnalyticsManager.identify({
                        'Schedules Created Count': types?.length > 2 ? types.length : 0,
                        'Schedule Types': types ? types.length : 0,
                    })
                ),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get customSchedulesStream(): ReactiveStream<Schedule[]> {
        return new ReactiveStream(
            this.meetingTypesStream.pipe(
                map(schedules => {
                    return schedules.filter(
                        schedule => [
                            ScheduleType.CustomProfile,
                            ScheduleType.EmbedProfile,
                        ].includes(schedule.type)
                    ).sort((a, b) => {
                        /**
                         * To display enabled schedules on top
                         */
                        return Number(a.isDisabled) - Number(b.isDisabled)
                    }).sort((a, b) => {
                        /**
                         * To display all embed schedules at the end of the list
                         */
                        return Number(a.type === ScheduleType.EmbedProfile)
                             - Number(b.type === ScheduleType.EmbedProfile)
                    })
                }),
                takeUntil(this.destroyedEvent),
            )
        )
    }

    @Memoize()
    public get buildInSchedulesStream(): ReactiveStream<Schedule[]> {
        return new ReactiveStream(
            this.meetingTypesStream.pipe(
                map(schedules => {
                    return schedules.filter(
                        schedule => [
                            ScheduleType.Standard,
                            ScheduleType.Personal,
                        ].includes(schedule.type)
                    ).sort((a, b) => {
                        /**
                         * To display Standard schedule at the first place
                         */
                        return Number(b.type === ScheduleType.Standard)
                             - Number(a.type === ScheduleType.Standard)
                    })
                }),
                takeUntil(this.destroyedEvent),
            )
        )
    }

    @Memoize()
    public get isSchedulesLimitReached$(): ReactiveStream<boolean> {
        return new ReactiveStream(
            combineLatest([
                this.meetingTypesStream,
                from(this.userLimitsProvider.getSchedulesLimit()),
            ]).pipe(
                map(([schedules, limit]) => {
                    return schedules.filter(schedule => {
                        return !schedule.removed
                            && !schedule.isDisabled
                            && schedule.type === ScheduleType.CustomProfile
                    }).length >= limit
                }),
            )
        )
    }

    @Memoize()
    public get noMeetingTypesAddedStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(
            this.meetingTypesStream.pipe(
                map(types => types.length < 1),
                takeUntil(this.destroyedEvent),
            ),
        )
    }


    @Memoize()
    public get isAnyMeetingTypeAddedStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(
            this.meetingTypesStream.pipe(
                map(types => types.length > 0),
                takeUntil(this.destroyedEvent),
            ),
        )
    }

    public async getAllMeetingTypesByUserUId(uid: string): Promise<Schedule[]> {
        return this.prepareSchedules(
            await AccountCollection.one(uid)
                                   .collection(Schedule)
                                   .all()
                                   .get()
        )
    }

    public async getMeetingTypeById(id: string, userUId: string): Promise<Schedule> {
        return this.prepareSchedule(
            await AccountCollection.one(userUId)
                                   .collection(Schedule)
                                   .one(id)
                                   .get()
        )
    }

    public async getByIntegrationClientId(
        userUid: string, integrationClientId: string,
    ): Promise<Schedule> {
        /**
         * Returns first matching value
         */
        return this.prepareSchedule(
            (await AccountCollection.one(userUid)
                                    .collection(Schedule)
                                    .filter(filter => {
                                        filter.type.equal(ScheduleType.EmbedProfile)
                                        filter.integrationClientId.equal(integrationClientId)
                                        return filter
                                    })
                                    .filter(MeetingTypeScope.notRemoved)
                                    .get()
            )[0]
        )
    }


    public async createMeetingType(properties?: Partial<Schedule>, save = false): Promise<Schedule> {
        const [account, entity] = await Promise.all([
            this.currentUser.accountStream,
            this.meetingTypeFactory.create(properties),
        ])

        if (save) {
            await AccountCollection.one(account.id)
                                   .collection(Schedule)
                                   .save(entity)
        }

        return entity
    }


    public async saveMeetingType(entity: Schedule): Promise<void> {
        const account = await this.currentUser.accountStream
        await AccountCollection.one(account.id)
                               .collection(Schedule).save(entity)
    }

    public async deleteMeetingType(entity: Schedule): Promise<void> {
        const account = await this.currentUser.accountStream

        entity.removed = true

        return AccountCollection.one(account.id)
                                .collection(Schedule)
                                .save(entity)
    }

    public async setScheduleEnabled(entity: Schedule): Promise<void> {
        if (!await this.isSchedulesLimitReached$) {
            entity.isDisabled = false
            return this.saveMeetingType(entity)
        } else {
            this.snackbarManager.error(`Cannot enable schedule`)
        }
    }

    public async setScheduleDisabled(entity: Schedule): Promise<void> {
        entity.isDisabled = true
        return this.saveMeetingType(entity)
    }

    protected prepareSchedule(schedule: Schedule): Schedule {

        if (!schedule) {
            return schedule
        }

        /**
         * Setup default value for `allowedModes`
         */
        schedule.allowedModes =  schedule.allowedModes ?? []

        /**
         * Using legacy mode as the one is allowed
         */
        if (schedule.allowedModes.length === 0) {
            schedule.allowedModes.push(schedule.mode)
        }

        const defaultDurations = this.meetingDurationOptionsProvider
                                     .defaultMeetingDurationValues
        /**
         * Restore all default durations
         */
        defaultDurations.forEach(duration => {
            if (!schedule.availableDurationValues.hasOwnProperty(`${duration}`)) {
                schedule.availableDurationValues[duration] = false
            }
        })

        return schedule
    }

    protected prepareSchedules(schedules: Schedule[]): Schedule[] {
        return schedules.map(schedule => this.prepareSchedule(schedule))
    }
}
