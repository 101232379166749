<header>
    <div class="row">
        <ng-container
                *ngIf="state.selectedMeetingSlotStream | stream as slot else availabilitySlotIsNotSelectedTmpl"
        >
            <div class="selected-meeting-date-box"
                 [ngSwitch]="isPastDateUsedStream | stream"
            >
                <!--
                  /**
                   * Controls should be displayed only for non-past meetings
                   *      RESCHEDULE FOR PAST MEETINGS IS NOT AVAILABLE
                   */
                 -->
                <ng-container *ngSwitchCase="true">
                    <ng-container *ngIf="{
                        selectedName: state.selectedTimeZoneNameStream | stream
                    } as tz">
                        <div class="date-text date" (click)="switchToThePresentTimeMode()">
                            <span>{{ slot.start | momentTimeZone : tz.selectedName : "dddd, MMMM D" }}</span>
                        </div>

                        <div class="date-text time" (click)="switchToThePresentTimeMode()">
                            <span>{{ slot.start | momentTimeZone : tz.selectedName : "h:mm A" }}</span>

                            <span class="time-separator">-</span>

                            <span>{{ slot.end | momentTimeZone : tz.selectedName : "h:mm A" }}</span>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="false">
                    <ng-container *ngIf="{
                        browserTz: state.browserTimeZoneDataStream | stream,
                        selectedTz: state.selectedTimeZoneDataStream | stream,
                        isOwnerMode: isOwnerModeStream | stream
                    } as values">
                        <div class="date-text date">
                            <app-date-picker
                                    [dateFormat]="dateFormat | stream"
                                    [disabled]="!values.isOwnerMode"
                                    [date]="slot.start" (onDateSelected)="onCustomMeetingDateSelected($event)"
                            ></app-date-picker>
                        </div>

                        <div class="date-text time">
                            <app-meet-time-slot-selector
                                    [labelFormat]="'h:mmA'"
                                    [selectedTime]="slot.start"
                                    [step]="timeSlotSelectorStep"
                                    [disabled]="!values.isOwnerMode"
                                    [rangeStart]="customTimeRangeStartStream | stream"
                                    (onSlotSelected)="onCustomMeetingStartTimeSelected($event)"
                            ></app-meet-time-slot-selector>

                            <span class="time-separator">-</span>

                            <app-meet-time-slot-selector
                                    [labelFormat]="'h:mmA'"
                                    [selectedTime]="slot.end"
                                    [step]="timeSlotSelectorStep"
                                    [disabled]="!values.isOwnerMode"
                                    [rangeStart]="customTimeRangeStartStream | stream"
                                    (onSlotSelected)="onCustomMeetingEndTimeSelected($event)"
                            ></app-meet-time-slot-selector>
                        </div>

                        <app-icon-lightning *ngIf="slot?.best" class="best-datetime-used-icon"></app-icon-lightning>
                        <!--<div class="alt-timezone-label" *ngIf="tz.browser.zone !== tz.selected.zone">{{(tz.selected.label)}}</div>-->

                        <ng-container *ngIf="{
                            browserTz: state.browserTimeZoneDataStream | stream,
                            selectedTz: state.selectedTimeZoneDataStream | stream
                        } as values">
                            <app-time-time-zone-selector-2
                                    class="timezone-selector-new"
                                    [defaultSelect]="true"
                                    [selectedTimeZone]="values.selectedTz"
                                    [browserTimeZone]="values.browserTz"
                                    (onSelected)="onTimeZoneSelected($event)"
                            ></app-time-time-zone-selector-2>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>

        <ng-template #availabilitySlotIsNotSelectedTmpl>
            <ng-container [ngSwitch]="state.isAvailabilityLoadingStream | stream">
                <ng-container *ngSwitchCase="true">
                    <div class="selected-meeting-date-box">
                        <span class="date-text notice">Please wait. Availability is loading.</span>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="false">
                    <div class="selected-meeting-date-box">
                        <span class="date-text notice">No mutual availability found</span>
                    </div>
                </ng-container>
            </ng-container>
        </ng-template>

        <div class="toolbar-icons">
            <ng-container *ngIf="isOwnerModeStream | stream">
                <mat-slide-toggle *ngIf="isBetaUser$ | stream" [checked]="state.isRecurrentEventStream | stream" (toggleChange)="state.toggleRecurrenceMode()">
                    Recurring
                </mat-slide-toggle>

                <ng-container [ngSwitch]="isAvailabilitySelectorHiddenStream | stream | boolean">
                    <ng-container *ngSwitchCase="true">
                    <span class="icon-box" (click)="openAvailabilitySelector()">
                        <i class="material-icons-round">add</i>
                    </span>
                    </ng-container>

                    <ng-container *ngSwitchCase="false">
                    <span class="icon-box" (click)="closeAvailabilitySelector()">
                        <i class="material-icons-round">remove</i>
                    </span>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>

    <ng-container *ngIf="state.isCustomAvailableSlotUsedStream | stream">
        <div class="row">
            <!--<span class="custom-datetime-notice">Custom meeting date is used</span>-->
            <ng-container *ngIf="state.isCustomRangeTimeConflictsWithAvailability | stream">
                <span class="schedule-conflict-notice">Schedule Conflict</span>
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="state.isMeetingShouldBeRescheduledStream | stream">
        <div class="row">
            <span class="reschedule-notice">Meeting will be rescheduled</span>

            <div class="cancel-reschedule" (click)="cancelReschedule()">Cancel</div>
        </div>
    </ng-container>

    <ng-container *ngIf="state.isAvailabilityLoadingStream | stream">
        <div class="availability-loading-overlay"></div>
    </ng-container>
</header>

<main *ngIf="!(isAvailabilitySelectorHiddenStream | stream)">
    <ng-container *ngIf="state.isRecurrentEventStream | stream">
        <section class="recurrence-block">
            <span class="block-title">Recurring settings</span>

            <div class="recurrence-settings">
                <app-tags-selector
                        [multiple]="false"
                        [required]="true"
                        [options]="recurrenceOptions"
                        [selected]="state.recurringFrequencyStream | stream"
                        (tagClick)="handleRecurrenceOptionChange($event)"
                ></app-tags-selector>

                <mat-slide-toggle [checked]="state.limitRecurrentEventSeriesStream | stream" (toggleChange)="state.toggleRecurrenceLimit()">
                    Limit number of occurrences
                </mat-slide-toggle>

                <ng-container *ngIf="state.limitRecurrentEventSeriesStream | stream">
                    <input [ngModel]="state.numberOfEventOccurrencesStream | stream" (ngModelChange)="state.changeNumberOfEventOccurrences($event)"  class="events-limit-number" udInput type="number" spellcheck="false">
                </ng-container>
            </div>
        </section>

    </ng-container>

    <app-time-availability-day-browser
            [multiMode]="true"
            [availability]="state.displayAvailabilityStream | stream"
            [isAvailabilityLoading]="state.isAvailabilityLoadingStream | stream"
            [availabilityRangeStart]="state.displayAvailabilityRangeStartStream | stream"
            [selectedAvailabilityDayIndex]="selectedAvailabilityDayIndexStream | stream"
            [availabilityDaysCountToDisplay]="state.availabilityDaysCountToDisplayStream | stream"
            [selectedTimeZone]="state.selectedTimeZoneNameStream | stream"
            [enableDatePicker]="false"

            (onNextDaysClicked)="onGoToNextDaysClicked()"
            (onPrevDaysClicked)="onGoToPrevDaysClicked()"
            (onDayIndexSelected)="onAvailabilityDayIndexSelected($event)"
    ></app-time-availability-day-browser>

    <app-time-availability-multi-day-slot-selector
            [availabilitySet]="state.displayAvailabilityStream | stream"
            [availabilityDaysCountToDisplay]="state.availabilityDaysCountToDisplayStream | stream"
            [isAvailabilityLoading]="state.isAvailabilityLoadingStream | stream"
            [selectedAvailabilitySlot]="state.selectedAvailableSlotStream | stream"
            [selectedTimezone]="state.selectedTimeZoneNameStream | stream"

            (onAvailableSlotSelected)="selectAvailableSlot($event)"
    ></app-time-availability-multi-day-slot-selector>
</main>
