import { Injectable } from '@angular/core'

import { default as m } from 'moment'

import {
    of,
} from 'rxjs'
import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
} from '@typeheim/fire-rx'
import {
    map,
    shareReplay,
    takeUntil,
} from 'rxjs/operators'

import { Memoize } from '@undock/core'
import { TimezoneData } from '@undock/time/availability/contracts'
import { timeZoneToCityMap } from '@undock/core/utils/timezone-data-model'


/**
 * Root injectable
 */
@Injectable({
    providedIn: 'root'
})
export class BrowserTime {

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    @Memoize()
    public get timeZoneNameStream(): ReactiveStream<string> {
        return new ReactiveStream<string>(
            this.timeZoneDataStream.pipe(
                map(data => data.zone),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            )
        )
    }

    @Memoize()
    public get timeZoneDataStream(): ReactiveStream<TimezoneData> {
        const zone = m.tz.guess(true)
        return new ReactiveStream<TimezoneData>(
            of({
                zone: zone,
                label: m().tz(zone).format('z'),
                suggestedCity: timeZoneToCityMap[zone],
            }).pipe(
                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            )
        )
    }

    @Memoize()
    public get timeZoneLabelStream(): ReactiveStream<string> {
        return new ReactiveStream<string>(
            this.timeZoneDataStream.pipe(
                map(data => data.label),
                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }
}
