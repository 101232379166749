import { Injectable } from '@angular/core'
import { Clipboard } from '@angular/cdk/clipboard'

import {
    combineLatest,
    merge,
    Observable,
    tap,
    timer,
} from 'rxjs'
import {
    distinctUntilChanged,
    map,
    shareReplay,
    switchMap,
    takeUntil,
} from 'rxjs/operators'
import {
    AsyncStream,
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    StatefulSubject,
    ValueSubject,
} from '@typeheim/fire-rx'

import {
    compareDeeply,
    Config,
    Memoize,
} from '@undock/core'
import { Api } from '@undock/api'
import { User } from '@undock/user'
import { CurrentUser } from '@undock/session'

import moment from 'moment-timezone'
import {
    MeetingTypesManager,
    Schedule,
    ScheduleType,
} from '@undock/dock/meet'
import { PrivacyManager } from '@undock/user/services/privacy.manager'
import { ProfileLinksManager } from '@undock/profile/shared/services/profile-links.manager'
import { TimezoneData } from '@undock/time/availability'
import {
    SnackbarManager,
    SnackbarPosition,
} from '@undock/common/ui-kit/services/snackbar.manager'
import { BrowserTime } from '@undock/time/availability/services/browser-time.model'
import { PartnerChargeAccountInterface } from '@undock/api/scopes/charges/contracts/charge-account.interface'


export enum ProfileFacadeStatus {
    ProfileLoadingFailure = 'ProfileLoadingFailure',
    ProfileLoadingPending = 'ProfileLoadingPending',
    ProfileLoadingSucceed = 'ProfileLoadingSucceed',
    PrivacyRequestPending = 'PrivacyRequestPending',
}

@Injectable()
export class ProfileFacade {

    public readonly browserTimeZoneNameStream = this.browserTime.timeZoneNameStream
    public readonly browserTimeZoneDataStream = this.browserTime.timeZoneDataStream
    public readonly browserTimeZoneLabelStream = this.browserTime.timeZoneLabelStream

    public readonly visitorUserStream: ReactiveStream<User>
    public readonly currentlyViewedUserStream: ReactiveStream<User>
    public readonly selectedMeetingTypeStream: ReactiveStream<Schedule>

    public readonly isVisitorRegularUserStream: AsyncStream<boolean>
    public readonly isVisitorAnonymousUserStream: AsyncStream<boolean>

    @CompleteOnDestroy()
    private currentlyViewedUserSubject = new StatefulSubject<User>()

    @CompleteOnDestroy()
    private selectedScheduleSubject = new StatefulSubject<Schedule>()

    @CompleteOnDestroy()
    private appliedPrivacyUnlockCodeSubject = new ValueSubject<string>('')

    @CompleteOnDestroy()
    private selectedTimeZoneDataSubject = new StatefulSubject<TimezoneData>()

    @CompleteOnDestroy()
    private profileUserLocalTimeSubject = new StatefulSubject<moment.Moment>()

    @CompleteOnDestroy()
    private browserTimeZoneSubject = new StatefulSubject<{ zone: string, label: string }>()

    @CompleteOnDestroy()
    private isPrivacyUnlockRequestProcessingSubject = new ValueSubject<boolean>(false)

    @CompleteOnDestroy()
    private isProfileLoadingSubject = new ValueSubject<boolean>(true)

    @CompleteOnDestroy()
    private profileInitializationErrorSubject = new ValueSubject<Error>(null)


    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()


    public constructor(
        private api: Api,
        private config: Config,
        private clipboard: Clipboard,
        private browserTime: BrowserTime,
        private currentUser: CurrentUser,
        private privacyManager: PrivacyManager,
        private snackbarManager: SnackbarManager,
        private meetingTypesManager: MeetingTypesManager,
        private profileLinksManager: ProfileLinksManager,
    ) {
        this.visitorUserStream = this.currentUser.dataStream
        this.selectedMeetingTypeStream = this.selectedScheduleSubject.asStream()
        this.currentlyViewedUserStream = this.currentlyViewedUserSubject.asStream()
        this.isVisitorRegularUserStream = this.currentUser.isRegularUserStream
        this.isVisitorAnonymousUserStream = this.currentUser.isAnonymousUserStream
    }


    @Memoize()
    public get statusStream(): Observable<ProfileFacadeStatus> {
        return combineLatest([
            this.isProfileLoadingSubject,
            this.profileInitializationErrorSubject,
            this.isPrivacyUnlockRequestProcessingSubject,
        ]).pipe(
            map(sources => {
                const [isLoading, isErrorCaused, isPrivacyRequestProcessing] = sources

                if (isErrorCaused) {
                    return ProfileFacadeStatus.ProfileLoadingFailure
                }

                if (isLoading) {
                    return ProfileFacadeStatus.ProfileLoadingPending
                }

                if (isPrivacyRequestProcessing) {
                    return ProfileFacadeStatus.PrivacyRequestPending
                }

                return ProfileFacadeStatus.ProfileLoadingSucceed
            }),
        )
    }


    @Memoize()
    public get isViewingOwnProfileStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(
            combineLatest([
                this.currentUser.dataStream,
                this.currentlyViewedUserSubject,
            ]).pipe(
                takeUntil(this.destroyedEvent),
                map(sources => {
                    const [currentUserData, viewingProfile] = sources

                    return currentUserData ? currentUserData._id === viewingProfile._id : false
                }),
            ),
        )
    }

    @Memoize()
    public get isViewingProfilePrivateStream(): Observable<boolean> {
        return this.currentlyViewedUserSubject.pipe(
            takeUntil(this.destroyedEvent),
            map(
                profile => profile.settings.privateProfile ?? false,
            ),
        )
    }

    @Memoize()
    public get isPrivacyUnlockCodeAppliedStream(): Observable<boolean> {
        return this.appliedPrivacyUnlockCodeSubject.pipe(
            takeUntil(this.destroyedEvent),
            map(
                code => code && code.length > 0,
            ),
        )
    }

    @Memoize()
    public get isProfileAccessAllowedStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(
            combineLatest([
                this.isViewingOwnProfileStream,
                this.isViewingProfilePrivateStream,
                this.isPrivacyUnlockCodeAppliedStream,
            ]).pipe(
                map(sources => {
                    const [
                        viewingOwnProfile, viewingProfilePrivate, privacyCodeApplied,
                    ] = sources

                    return viewingOwnProfile || !viewingProfilePrivate || privacyCodeApplied
                }),
                takeUntil(this.destroyedEvent),
            ),
        )
    }

    @Memoize()
    public get allSchedulesOfViewedUserStream(): ReactiveStream<Schedule[]> {
        return new ReactiveStream(
            this.currentlyViewedUserStream.pipe(
                distinctUntilChanged(
                    (prev, next) => compareDeeply(prev, next, 'firebaseId'),
                ),
                switchMap(user => {
                    return this.meetingTypesManager
                               .getAllMeetingTypesByUserUId(user.firebaseId)
                }),
                map(schedules => {
                    /**
                     * Filtering schedules to remove soft-deleted entries
                     */
                    return schedules?.filter(
                        schedule => !schedule.removed && !schedule.isDisabled
                    ).sort((a, b) => {
                        /**
                         * To display all embed schedules at the end of the list
                         */
                        return Number(a.type === ScheduleType.Personal)
                             - Number(b.type === ScheduleType.Personal)
                    }).sort((a, b) => {
                        /**
                         * To display all embed schedules at the end of the list
                         */
                        return Number(a.type === ScheduleType.Standard)
                             - Number(b.type === ScheduleType.Standard)
                    }) ?? []
                }),
                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get standardScheduleOfViewedUserStream(): ReactiveStream<Schedule> {
        return new ReactiveStream(
            this.allSchedulesOfViewedUserStream.pipe(
                map(schedules => {
                    return schedules.find(
                        schedule => schedule.type === ScheduleType.Standard
                    )
                }),
                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            )
        )
    }

    @Memoize()
    public get publicMeetingTypesForViewedUserStream(): ReactiveStream<Schedule[]> {
        return new ReactiveStream<Schedule[]>(
            combineLatest([
                this.currentUser.dataStream,
                this.allSchedulesOfViewedUserStream,
            ]).pipe(
                map(([currentUser, schedules]) => {
                    schedules = schedules.filter(
                        // Remove expired schedules
                        schedule => !schedule.isExpired && (
                            // Remove Standard schedule from the list
                            schedule.type === ScheduleType.Personal ||
                            schedule.type === ScheduleType.CustomProfile
                        )
                    )

                    if (currentUser.isGuest) {
                        return schedules?.filter(
                            schedule => !schedule.isPrivate
                        ) ?? []
                    }

                    const userUId = currentUser.firebaseId
                    return schedules.filter(schedule => {
                        if (schedule.userAccessOverride.hasOwnProperty(userUId)) {
                            return schedule.userAccessOverride[userUId]
                        } else {
                            return !schedule.isPrivate
                        }
                    })
                }),
                takeUntil(this.destroyedEvent),
            )
        )
    }

    @Memoize()
    public get selectedPaymentGroupStream(): ReactiveStream<PartnerChargeAccountInterface> {
        return new ReactiveStream<PartnerChargeAccountInterface>(
            this.selectedMeetingTypeStream.pipe(
                switchMap(async (meetingType) => !!meetingType?.integrationClientId
                    ? await this.api.charge.charges.getChargeAccountGroupByIntegrationClientId(meetingType.integrationClientId)
                    : null),
                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get profileUserLocalTimeStream(): Observable<Date> {
        return combineLatest([
            this.currentlyViewedUserStream,
            /**
             * Time will be updated at each second
             */
            timer(0, 5000),
        ]).pipe(
            map(sources => {
                const [currentlyViewedUser] = sources

                /**
                 * If the user didn't initialized his time-zone we'll display time in local zone
                 */
                let timeZoneTime = currentlyViewedUser.lastTimeZone && currentlyViewedUser.lastTimeZone !== 'Space' ?
                    new Date().toLocaleString('en-US', { timeZone: currentlyViewedUser.lastTimeZone })
                    : new Date().toLocaleString('en-US')

                return new Date(timeZoneTime)
            }),
        )
    }

    @Memoize()
    public get selectedTimeZoneNameStream(): ReactiveStream<string> {
        return new ReactiveStream<string>(
            this.selectedTimeZoneDataStream.pipe(
                map(data => data.zone),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get selectedTimeZoneLabelStream(): ReactiveStream<string> {
        return new ReactiveStream<string>(
            this.selectedTimeZoneDataStream.pipe(
                map(data => data.label),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get selectedTimeZoneDataStream(): ReactiveStream<TimezoneData> {
        return new ReactiveStream<{ zone: string, label: string }>(
            merge(
                this.browserTimeZoneDataStream,
                this.selectedTimeZoneDataSubject,
            ).pipe(
                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    public async initialize(profileUrl: string): Promise<void> {
        this.isProfileLoadingSubject.next(true)

        try {
            this.currentlyViewedUserSubject.next(
                await this.loadUserByProfileUrl(profileUrl),
            )

            /**
             * Waiting for all schedules to load
             */
            await this.ensureBuildInSchedulesExistForViewedUser()

            this.isProfileLoadingSubject.next(false)
        } catch (e) {
            console.warn(`Unable to load profile`, e)
            this.profileInitializationErrorSubject.next(e)
        } finally {
            this.isProfileLoadingSubject.next(false)
        }
    }

    public async destroyActivePrivacyUnlockCode() {
        let code = await this.appliedPrivacyUnlockCodeSubject

        if (code) {
            await this.privacyManager.destroyPrivacyUnlockCode(code)
        }
    }

    public async tryToUnlockPrivateAccount(code: string) {
        this.isPrivacyUnlockRequestProcessingSubject.next(true)

        const profile = await this.currentlyViewedUserStream
        if (
            await this.privacyManager.isPrivacyUnlockCodeValid(code, profile.email)
        ) {
            this.appliedPrivacyUnlockCodeSubject.next(code)
        }

        this.isPrivacyUnlockRequestProcessingSubject.next(false)
    }

    public async generateAndCopyPrivateAccessProfileUrl(): Promise<void> {

        this.clipboard.copy(
            /**
             * Generating URL for current user profile with privacy unlock code
             */
            await this.profileLinksManager.getPrivateUrlForCurrentUserProfile(),
        )

        this.snackbarManager.success('Profile link copied', SnackbarPosition.BottomLeft)
    }

    /**
     * @throws {Error}
     */
    protected async loadUserByProfileUrl(profileUrl: string): Promise<User> {
        const currentUser = await this.currentUser.data

        let user: User
        if (profileUrl == currentUser.profileUrl || profileUrl === 'me') {
            user = currentUser
        } else {
            user = await this.api.user.profile.getByProfileUrl(profileUrl)
        }

        if (!user) {
            throw new Error(`User ${profileUrl} is not found`)
        }

        return user
    }

    public async selectScheduleByUrl(url: string) {
        let schedules = await this.allSchedulesOfViewedUserStream
        let targetSchedule = schedules.find(t => t.url.endsWith(url))
        if (targetSchedule) {
            return this.selectedScheduleSubject.next(targetSchedule)
        }

        this.snackbarManager.error(`Requested schedule is not available`)
    }

    public async selectScheduleById(meetingTypeId: string) {
        let schedules = await this.allSchedulesOfViewedUserStream
        let targetSchedule = schedules.find(t => t.id === meetingTypeId)
        if (targetSchedule) {
            return this.selectedScheduleSubject.next(targetSchedule)
        }
        this.snackbarManager.error(`Requested schedule is not available`)
    }

    public async selectStandardSchedule() {
        return this.selectScheduleById(ScheduleType.Standard)
    }

    public selectTimeZone(timeZone: TimezoneData) {
        this.selectedTimeZoneDataSubject.next(timeZone)
    }

    /**
     * Generates build-in schedules and then reloads the page (fail-safe option)
     */
    protected async ensureBuildInSchedulesExistForViewedUser() {
        const [ user, schedules ] = await Promise.all([
            this.currentlyViewedUserStream, this.allSchedulesOfViewedUserStream
        ])

        const isStandardScheduleExist = schedules.some(s => s.type === ScheduleType.Standard)
            , isPersonalScheduleExist = schedules.some(s => s.type === ScheduleType.Personal)

        if (!isStandardScheduleExist || !isPersonalScheduleExist) {
            try {
                await this.api.schedules.ensureBuildInSchedulesCreatedForUser(user._id)
                window.location.reload()
            } catch (error) {
                console.warn(`Cannot generate build-in schedules`, error)
            }
        }
    }
}
