import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core'
import {
    animate,
    style,
    transition,
    trigger,
} from '@angular/animations'

import {
    combineLatest,
    Observable,
} from 'rxjs'
import {
    DestroyEvent,
    EmitOnDestroy,
    StatefulSubject,
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'
import {
    map,
    shareReplay,
    takeUntil,
} from 'rxjs/operators'

import { Memoize } from '@undock/core'
import {
    AvailabilitySet,
    AvailabilitySlot,
} from '@undock/api/scopes/profile/contracts/availability'

import { default as moment } from 'moment'


@Component({
    selector: 'app-time-availability-slot-selector',
    templateUrl: 'availability-slot-selector.component.html',
    styleUrls: ['availability-slot-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('insertSlotTrigger', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateX(10px)' }),
                animate('300ms', style({ opacity: 1, transform: 'translateX(0)' })),
            ]),
        ]),
    ],
})
export class AvailabilitySlotSelectorComponent {

    @Output() readonly onAvailabilitySlotSelected = new EventEmitter<AvailabilitySlot>()


    @CompleteOnDestroy()
    private availabilitySubject = new StatefulSubject<AvailabilitySet[]>()

    @CompleteOnDestroy()
    private isAvailabilityLoadingSubject = new StatefulSubject<boolean>()

    @CompleteOnDestroy()
    private selectedAvailabilityDayIndexSubject = new StatefulSubject<number>()

    @CompleteOnDestroy()
    private selectedAvailabilitySlotSubject = new ValueSubject<AvailabilitySlot>(null)


    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()


    /**
     * -----------------------------------------------------------
     *                        Public API
     * -----------------------------------------------------------
     */

    @Input() set availabilitySet(value: AvailabilitySet[]) {
        this.availabilitySubject.next(value ?? [])
    }

    @Input() set isAvailabilityLoading(value: boolean) {
        this.isAvailabilityLoadingSubject.next(value ?? false)
    }

    @Input() set selectedAvailabilityDayIndex(value: number) {
        this.selectedAvailabilityDayIndexSubject.next(value ?? 0)
    }

    @Input() set selectedAvailabilitySlot(value: AvailabilitySlot) {
        this.selectedAvailabilitySlotSubject.next(value)
    }

    /**
     * -----------------------------------------------------------
     *                   Internal UI Streams
     * -----------------------------------------------------------
     */

    @Memoize()
    public get isAvailabilityLoadingStream(): Observable<boolean> {
        return this.isAvailabilityLoadingSubject
    }

    @Memoize()
    public get selectedDayAvailabilityStream(): Observable<AvailabilitySet> {
        return combineLatest([
            this.availabilitySubject,
            this.selectedAvailabilityDayIndexSubject,
        ]).pipe(
            map(([set, selectedDayIdx]) => {

                if (!set[selectedDayIdx]) {
                    return null
                }

                let availability = set[selectedDayIdx]

                availability.slots = availability.slots
                                                 .filter(slot => slot.type === 'slot')

                return availability
            }),

            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get morningAvailabilitySlotsStream(): Observable<AvailabilitySlot[]> {
        return this.selectedDayAvailabilityStream.pipe(
            map(availability => {
                return availability.slots.filter(slot => slot.hour < 11) as AvailabilitySlot[]
            }),

            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get eveningAvailabilitySlotsStream(): Observable<AvailabilitySlot[]> {
        return this.selectedDayAvailabilityStream.pipe(
            map(availability => {
                return availability.slots.filter(slot => slot.hour >= 16) as AvailabilitySlot[]
            }),

            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get afternoonAvailabilitySlotsStream(): Observable<AvailabilitySlot[]> {
        return this.selectedDayAvailabilityStream.pipe(
            map(availability => {
                return availability.slots.filter(slot => slot.hour >= 11 && slot.hour < 16) as AvailabilitySlot[]
            }),

            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get isMorningSlotsAvailableStream(): Observable<boolean> {
        return this.morningAvailabilitySlotsStream.pipe(
            map(slots => slots.length && slots.length > 0),

            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get isEveningSlotsAvailableStream(): Observable<boolean> {
        return this.eveningAvailabilitySlotsStream.pipe(
            map(slots => slots.length && slots.length > 0),

            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get isAfternoonSlotsAvailableStream(): Observable<boolean> {
        return this.afternoonAvailabilitySlotsStream.pipe(
            map(slots => slots.length && slots.length > 0),

            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get isSelectedDayHasAvailabilitySlotsStream(): Observable<boolean> {
        return this.selectedDayAvailabilityStream.pipe(
            map(availability => availability?.slots && availability.slots.length > 0),

            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }


    /**
     * -----------------------------------------------------------
     *                        Actions
     * -----------------------------------------------------------
     */

    public selectSlot(slot: AvailabilitySlot): void {
        this.onAvailabilitySlotSelected.next(slot)
    }

    public isAvailableSlotSelected(slot: AvailabilitySlot): boolean {
        const selectedSlot = this.selectedAvailabilitySlotSubject.value
        if (selectedSlot) {
            return moment(slot.timeStamp).isSame(selectedSlot.timeStamp)
        }

        return false
    }
}
