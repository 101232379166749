<!-- charge-empty-image -->
<svg [attr.width]="iconSize" [attr.height]="iconSize" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M35.4999 66.2667C52.4678 66.2667 66.2666 52.4896 66.2666 35.5332C66.2006 18.5105 52.4678 4.7334 35.4999 4.7334C18.466 4.7334 4.73328 18.5105 4.73328 35.4669C4.73328 52.4896 18.466 66.2667 35.4999 66.2667Z" fill="#252329"/>
    <path d="M35.5 56.8002C47.2633 56.8002 56.7999 47.2823 56.7999 35.5002C56.7999 23.7181 47.2213 14.2002 35.5 14.2002C23.7366 14.2002 14.2 23.7181 14.2 35.5002C14.2 47.2823 23.7366 56.8002 35.5 56.8002Z" fill="#1A191D"/>
    <path d="M26.98 32.0293V23.5093C26.98 18.8038 30.7945 14.9893 35.5 14.9893V14.9893V14.9893C40.2054 14.9893 44.02 18.8038 44.02 23.5093V32.0293" stroke="#3E3C42" stroke-width="4.26"/>
    <path d="M49.72 55.8135H21.28C19.558 55.8135 18.105 54.3215 18.105 52.4925V32.8644C18.105 31.0354 19.558 29.5435 21.28 29.5435H49.72C51.442 29.5435 52.895 31.0354 52.895 32.8644V52.4925C52.895 54.3215 51.442 55.8135 49.72 55.8135Z" fill="#5A5860" stroke="#5A5860" stroke-width="0.71"/>
    <circle cx="35.5" cy="40.549" r="3.55" fill="#3E3C42"/>
    <rect x="34.08" y="41.9688" width="2.84" height="4.97" rx="1.42" fill="#3E3C42"/>
</svg>

<div class="text-box">
    <h1>
        Upgrade to {{ planName }}
    </h1>

    <h5>{{ description }}</h5>
</div>

<button class="button clickable" (click)="navigateToTheMembershipPage()">
    {{ buttonText }}
</button>
