import {
    Aggregate,
    Collection,
    CollectionRef,
    CreatedDateField,
    EntityFilter,
    Field,
    ID,
    UpdatedDateField,
} from '@typeheim/orm-on-fire'

import {
    HasPrivateNotes,
    Model,
    PrivateNote,
} from '@undock/core'


export class ConnectionScope {
    public static ownConnections(userId: string) {
        return (connection: EntityFilter<Connection>) => {
            connection.membersIds.contain(userId)
        }
    }
}

export const CONNECTIONS_COLLECTION_NAME = 'connection'

export enum ConnectionType {
    /**
     * Means that members fully connected
     */
    Contact = 'contact',
    /**
     * Means that users have met before but didn't approve connection
     */
    NetworkMember = 'network-member'
}

@Aggregate({
    collection: CONNECTIONS_COLLECTION_NAME,
})
export class Connection implements Model, HasPrivateNotes {

    /**
     * Used for `SharedCollectionEntity` linking
     */
    public readonly entityName = 'Connection'


    @ID() readonly id: string

    /**
     * Contains FirebaseIds of connected users
     *
     * @description connection member id's for search purposes
     */
    @Field() membersIds: string[] = []

    @Field() lastMetDate: Date

    @Field() meetingsCount: number = 0

    @Field() connectionType: ConnectionType = ConnectionType.NetworkMember

    @CreatedDateField() createdAt: Date
    @UpdatedDateField() updatedAt: Date

    @CollectionRef(PrivateNote) privateNotes: Collection<PrivateNote>

    public getConnectedUid(ownId): string {
        // membersIds is always sorted the way that 0 is pointing to memberA
        if (this.membersIds[0] === ownId) {
            return this.membersIds[1]
        } else {
            return this.membersIds[0]
        }
    }

    /**
     * Converting proprietary Firestore dates to Date type
     */
    protected init() {
        if (this.lastMetDate) {
            if (typeof this.lastMetDate === 'string') {
                this.lastMetDate = new Date(this.lastMetDate)
            }

            if ('toDate' in this.lastMetDate) {
                // @ts-ignore
                this.lastMetDate = this.lastMetDate.toDate()
            }
        }
    }
}

export const ConnectionCollection: Collection<Connection> = Collection.of<Connection>(Connection)
