import { Injectable } from '@angular/core'
import { Config } from '@undock/core/models/config'
import { SSE } from 'sse.js'
import { AuthHeaders } from '@undock/api'
import {
    fromEventPattern,
    Observable,
    tap,
} from 'rxjs'
import { map } from 'rxjs/operators'
import { ServerSentEvent } from '@undock/core/contracts/server-sent-event/server-sent-event'
import { ServerSentEventType } from '@undock/core/contracts/server-sent-event/server-sent-event-type'


@Injectable({
    providedIn: 'root',
})
export class SseService {

    public constructor(
        private config: Config,
        private authHeaders: AuthHeaders
    ) { }

    public async stream<T>(url: string): Promise<Observable<ServerSentEvent<T>>> {
        let payload = null // future use?
        let method = Boolean(payload) ? 'POST' : 'GET'

        let eventSource = await this.buildEventSource(`${this.config.apiPlatformURL}${url}`, method, payload)
        if (eventSource) {

            eventSource.stream()

            let stream: Observable<ServerSentEvent<T>> = fromEventPattern(
                handler => eventSource.addEventListener('message', handler),
                handler => eventSource.removeEventListener('message', handler)
            ).pipe(
                map((event: any) => event?.data ? JSON.parse(event.data) as ServerSentEvent<T> : null),
                tap(event => {
                    if (event.type === ServerSentEventType.End) {
                        eventSource.close()
                    }
                })
            )

            eventSource.onerror = (event: any) => {
                console.log("Error streaming command updates:", event)
                eventSource.close()
            }

            return stream
        }

        return null
    }

    protected async buildEventSource(url: string, method: string, payload?: any): Promise<SSE> {

        const options = await this.buildOptions(method, payload)

        return new SSE(url, options)
    }

    protected async buildOptions(
        method: string,
        payload?: any,
    ): Promise<{
        payload: any
        method: string
        headers: string | { [key: string]: string }
    }> {
        let headers = await this.authHeaders.getFullyAuthenticatedRequest()
        return {
            payload: JSON.stringify(payload),
            method: method,
            headers: {
                'Authorization': headers.get('Authorization'),
                'Content-Type': headers.get('Content-Type')
            },
        };
    }
}
