import { Injectable } from '@angular/core'
import { Api } from '@undock/api'


export interface NavigationRoute {
    path: string[]
    keepUrl: boolean
}

@Injectable()
export class NavigationController {

    public constructor(
        private api: Api,
    ) {}

    public async getTargetRouteForPage(segments: string[]): Promise<NavigationRoute> {
        const handlesToCheck = [segments[0]]
        /**
         * For compatibility with dock.link
         */
        if (segments[0].startsWith('mi')) {
            /**
             * Should use handle without 'mi' prefix firstly
             * 'mi' prefix has been used for Docks handle routing
             */
            handlesToCheck.unshift(segments[0].substring(2))
        }

        /**
         * To process capitalized profile handles
         */
        if (segments[0][0] === segments[0][0].toUpperCase()) {
            /**
             * All profile handles stored in lowercase
             */
            handlesToCheck.push(segments[0].toLowerCase())
        }

        for (let handle of handlesToCheck) {
            try {
                const entity = await this.api.urlHandle.registry.getUrlHandle(handle)

                if (entity) {
                    switch (entity.ownerType) {
                        case 'Dock':
                        case 'CalendarEvent':
                            return { path: ['meet', 'join', entity.handle], keepUrl: false }

                        case 'Profile':
                            return { path: ['u', entity.handle, ...segments.slice(1)], keepUrl: true }

                        case 'Booking':
                            return { path: ['u', entity.ownerId, 'b', handle], keepUrl: true }

                        case 'Proposal':
                            return { path: ['proposal', 'respond', entity.ownerId], keepUrl: true }
                    }
                }
            } catch (error) {}
        }

        /**
         * Redirects to the NotFound page
         */
        return { path: ['404'], keepUrl: true }
    }
}
