/*****************************************************************************************************
 *
 * Undock Web App Angular Client Development Environment
 * Production Environment Configuration
 *
 *
 * This environment will overwrite the one in the default environment.ts during a production build
 * e.g. $ ng build -c next
 * or $ ng serve -c next
 * or $ ng serve:next
 *
 */
import { AppMode } from '@undock/core'
import { RxJSDebugLevel } from '@undock/core/contracts/rxjs-debug-level'

export const environment = {
    appMode: 'beta' as AppMode,
    prodMode: false,
    production: false,

    enableErrorTracking: true,
    logRocketId: 'oozzgi/undock',

    // App Config
    dockLink: 'https://next.undock.com/meet/join/',
    proposalLink: 'https://next.undock.com/',
    clientDomain: 'https://next.undock.com/',
    embedDomain: 'https://next.undock.com/',
    apiURL: 'https://undock-staging.appspot.com/',
    apiPlatformURL: 'https://api-next.undock.com/',
    requestFeatureURL: 'https://matter.undock.com/feature-requests',
    latestUpdatesUrl: 'https://undock.canny.io/changelog',
    privacyPolicyUrl: 'https://undock.com/privacypolicy',
    termsOfServiceUrl: 'https://undock.com/m/terms-of-use/',
    errorUrl: 'https://undock.getgist.help',
    debugMode: false,
    rxjsDebugLevel: RxJSDebugLevel.OFF,
    enableTracking: true,
    useExperimentalFeatures: true,
    useExperimentalPeoplePage: true,
    appVersion: 'ud00063',
    actualCalendarVersion: 3,

    // Analytics
    enableAnalytics: true,
    enableHeapAnalytics: true,
    enableGoogleAnalytics: false,
    enableGoogleTagManager: false,
    enableSegmentAnalytics: false,
    enableCannySDK: true,
    googleAnalyticsTrackingId: 'UA-250497264',
    googleTagManagerTrackingId: 'UA-160161596-3',
    segmentAnalyticsTrackingId: 's1RQYagBzsBeFjGAnLL2htcHTSqxdaeR',
    cannyAppId: '6071a6334c231e2b2f3ac332',
    // gistTourId: 283,
    // gistStepId: '283-step-c7e54ca8-1681415317897',

    // Integrations
    googleApiUrl: 'https://apis.google.com/js/api.js',
    googleApiClientUrl: 'https://accounts.google.com/gsi/client',
    googleClientId: '689053353097-tpf84rmqj0a96dhhjt95dubdi8ppeapu.apps.googleusercontent.com',
    googleContactsScope: 'https://www.googleapis.com/auth/contacts.readonly',
    googleDirectoryScope: 'https://www.googleapis.com/auth/directory.readonly',
    googleApiKey: 'AIzaSyDyGm-CcqXFIjCkRVwLO9aUJgtLnr26zeM',
    microsoftClientId: '0c78673b-03cf-4162-82b4-36006964084b',
    stripeApiKey: 'pk_live_UveFRFuYzOziBrtq4HXC3NGV',
    zoomCallbackPath: 'zoom-auth',

    gistAppId: null,
    usetifulToken: null,
    crispWebsiteId: null,

    firebase: {
        apiKey: 'AIzaSyAKyTiXqP8yospCSZtqR3XwsZfmkLO1YXk',
        authDomain: 'auth.next.undock.com',// experimental domain
        // authDomain: 'undock-platform.firebaseapp.com',
        databaseURL: 'https://undock-platform.firebaseio.com',
        projectId: 'undock-platform',
        storageBucket: 'undock-platform.appspot.com',
        messagingSenderId: '211620569159',
        appId: '1:211620569159:web:1c5b194f5d0d654b919162',
        measurementId: 'G-XNG2V8JJN0',
    },

    // Chrome Extension
    chromeExtURL: 'https://chrome.google.com/webstore/detail/undock-meetings/edpipfjenheecalaenoobkbnbdelhopm',
    chromeExtensionId: 'edpipfjenheecalaenoobkbnbdelhopm',
    schedulingWindowAllowedDomains: [
        'https://mail.google.com',
        'https://outlook.live.com',
        'https://outlook.office365.com',
        'https://outlook.office.com',
        'https://mail.superhuman.com',
        'http://localhost:4200/install',
        'http://localhost:4200/signup',
        'http://localhost:4201/install',
        'http://localhost:4201/signup',
        'https://dev.undock.com/install',
        'https://dev.undock.com/signup',
        'https://dev5.undock.com/install',
        'https://dev5.undock.com/signup',
        'https://next.undock.com/install',
        'https://next.undock.com/signup',
        'https://app.undock.com/install',
        'https://app.undock.com/signup',
        'https://io.undock.com/install',
        'https://io.undock.com/signup'
    ]
}
