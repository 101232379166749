import { Component } from '@angular/core'
import { AsyncStream } from '@typeheim/fire-rx'
import { ProfileFacade } from '@undock/profile/public/services/facade/profile.facade'

@Component({
    selector: 'app-not-found',
    templateUrl: 'not-found.component.html',
    styleUrls: ['not-found.component.scss'],
})
export class NotFoundComponent {

    public readonly isVisitorRegularUserStream: AsyncStream<boolean>

    constructor(
        private profileFacade: ProfileFacade,
    ) {
        this.isVisitorRegularUserStream = this.profileFacade.isVisitorRegularUserStream
    }
}
