import { Injectable } from '@angular/core'

import {
    ValueSubject,
    StatefulSubject,
} from '@typeheim/fire-rx'
import {
    State,
    StateModel,
    StreamStore,
} from '@typeheim/fluent-states'


class MeetingDockPageStore extends StreamStore {

    public readonly dockIdStream = new StatefulSubject<string>()

    public readonly isConfModeStream = new ValueSubject<boolean>(false)
    public readonly isLunchClubModeStream = new ValueSubject<boolean>(false)
    public readonly isConferenceActiveStream = new ValueSubject<boolean>(false)
    public readonly chatMessagesUnreadCountStream = new ValueSubject<number>(0)

    public readonly isChatDisplayedStream = new ValueSubject<boolean>(false)
    public readonly isQueueDisplayedStream = new ValueSubject<boolean>(false)
    public readonly isSharingModalOpenStream = new ValueSubject<boolean>(false)
    public readonly isRecordingsDisplayedStream = new ValueSubject<boolean>(false)
    public readonly isAttachmentsDisplayedStream = new ValueSubject<boolean>(false)
    public readonly isPrivateNotesDisplayedStream = new ValueSubject<boolean>(false)
    public readonly isParticipantsDisplayedStream = new ValueSubject<boolean>(false)
}

export type MeetingDockPageState = State<MeetingDockPageStore>

@Injectable()
export class MeetingDockPageStateModel extends StateModel<MeetingDockPageStore> {

    /**
     * Primary data storage
     */
    protected readonly store = new MeetingDockPageStore()

    private _dockIdentifierUsedForNavigation: string


    public switchToConfMode() {
        this.store.isConfModeStream.next(true)
    }

    public switchToDockMode() {
        this.store.isConfModeStream.next(false)
    }

    public switchToLunchClubMode() {
        this.store.isLunchClubModeStream.next(true)
    }

    public setConferenceRunning() {
        this.store.isConferenceActiveStream.next(true)
    }

    public setConferenceStopped() {
        this.store.isConferenceActiveStream.next(false)
    }

    public setUnreadCountOfChatMessages(value: number) {
        this.store.chatMessagesUnreadCountStream.next(value)
    }

    public toggleChat() {
        this.store.isChatDisplayedStream.next(
            !this.store.isChatDisplayedStream.value,
        )
    }

    public toggleQueue() {
        this.store.isQueueDisplayedStream.next(
            !this.store.isQueueDisplayedStream.value,
        )
    }

    public toggleRecordings() {
        this.store.isRecordingsDisplayedStream.next(
            !this.store.isRecordingsDisplayedStream.value,
        )
    }

    public togglePrivateNotes() {
        this.store.isPrivateNotesDisplayedStream.next(
            !this.store.isPrivateNotesDisplayedStream.value,
        )
    }

    public toggleParticipants() {
        this.store.isParticipantsDisplayedStream.next(
            !this.store.isParticipantsDisplayedStream.value,
        )
    }

    public toggleSharingModal() {
        this.store.isSharingModalOpenStream.next(
            !this.store.isSharingModalOpenStream.value
        )
    }

    public toggleChatAttachments() {
        this.store.isAttachmentsDisplayedStream.next(
            !this.store.isAttachmentsDisplayedStream.value,
        )
    }

    public closeChatIfOpen() {
        if (this.store.isChatDisplayedStream.value) {
            this.store.isChatDisplayedStream.next(false)
        }
    }

    public closeQueueIfOpen() {
        if (this.store.isQueueDisplayedStream.value) {
            this.store.isQueueDisplayedStream.next(false)
        }
    }

    public closeRecordingsIfOpen() {
        if (this.store.isRecordingsDisplayedStream.value) {
            this.store.isRecordingsDisplayedStream.next(false)
        }
    }

    public closeChatAttachmentsIfOpen() {
        if (this.store.isAttachmentsDisplayedStream.value) {
            this.store.isAttachmentsDisplayedStream.next(false)
        }
    }

    public closeParticipantsIfOpen() {
        if (this.store.isParticipantsDisplayedStream.value) {
            this.store.isParticipantsDisplayedStream.next(false)
        }
    }


    public setDockId(value: string) {
        this.store.dockIdStream.next(value)
    }

    /**
     * Current navigation supports both dock id and handle
     */
    public get dockIdentifierUsedForNavigation() {
        return this._dockIdentifierUsedForNavigation
    }

    /**
     * Current navigation supports both dock id and handle
     */
    public set dockIdentifierUsedForNavigation(value: string) {
        this._dockIdentifierUsedForNavigation = value
    }
}
