import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
    TemplateRef,
} from '@angular/core'
import { TimelineEvent } from '@undock/api/scopes/time/contracts/timeline-event'
import {
    AppEventsDispatcher,
    openLinkBlank,
} from '@undock/core'
import {
    TrackUserAnalyticsEvent,
    UserAnalyticsAction,
} from '@undock/integrations'
import { TimelineEventsManager } from '@undock/timeline/services/timeline-events.manager'
import { MeetingMode } from '@undock/dock/meet'

@Component({
    selector: 'app-dock-preview',
    templateUrl: 'item-view.component.html',
    styleUrls: ['item-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
// TODO: Move away from layout module
export class DockPreviewTileItemViewComponent {

    public readonly MeetingMode = MeetingMode

    @Input() event: TimelineEvent
    @Input() @HostBinding('class.next') isNext: boolean = false
    @Input() @HostBinding('class.first-active') isFirstActive: boolean = false
    @Input() @HostBinding('class.bordered') isActive = false
    @Input() canJoinMeeting: boolean = false
    @Input() timerText: string = ''

    @Input() @HostBinding('class.first') isFirst = false
    @Input() @HostBinding('class.last') isLast = false
    @Input() @HostBinding('class.edit-mode') isEditMode = false
    @Input() @HostBinding('class.no-border') disableBorder = false

    @Input() tabsTemplate: TemplateRef<HTMLElement>
    @HostBinding('class.meetingRequest') @Input() meetingRequest: boolean = false
    @HostListener('click', ['$event']) onClick($event) {
        this.navigateToDock($event)
    }
    @Output() action = new EventEmitter<string>()

    constructor(
        private timelineEventsManager: TimelineEventsManager,
        private eventsManager: AppEventsDispatcher,
    ) {}

    public navigateToDock($event) {
        if ($event) {
            $event.stopPropagation()
        }

        if (this.event?.dockKey || this.event?.dockId) {
            return this.timelineEventsManager.navigateToTheMeeting(
                this.event?.dockKey ?? this.event?.dockId
            )
        }
    }

    public async navigateToConference() {
        const { dockId, dockKey, conferenceLink } = this.event

        if (conferenceLink && conferenceLink?.length > 0) {
            openLinkBlank(conferenceLink)
        } else  if (dockId || dockKey) {
            await this.timelineEventsManager.navigateToTheConference(dockKey ?? dockId)
        }

        await this.eventsManager.dispatch(
            new TrackUserAnalyticsEvent(UserAnalyticsAction.MeetingJoinedFromTimeline)
        )
    }

}
