import {
    Collection,
    CollectionRef,
    CreatedDateField,
    Entity,
    EntityFilter,
    Field,
    ID,
    UpdatedDateField,
} from '@typeheim/orm-on-fire'
import {
    DestroyEvent,
    LazyLoadStream,
} from '@typeheim/fire-rx'

import {
    Attachment,
    SoftDelete,
    HasAttachments,
    AttachmentScope,
} from '@undock/core'
import {
    PublicProfileData,
    UserConferenceLinkType,
    UserAvailableMeetingLength,
    UserAvailableSlotPreferences,
} from '@undock/user'
import { FormConfig } from '@undock/common/form-creator/types'
import { MeetingMode } from '@undock/dock/meet/contracts/dock/meeting-mode'


export class MeetingTypeScope {
    public static private(filter: EntityFilter<Schedule>) {
        return filter.isPrivate.equal(true)
    }

    public static notRemoved(filter: EntityFilter<Schedule>) {
        return filter.removed.equal(false)
    }
}

export enum ScheduleType {
    /**
     * Pre-defined schedule types
     */
    Standard = 'Standard',
    Personal = 'Personal',

    /**
     * Default schedule type
     */
    CustomProfile = 'profile',

    /**
     * Schedule type for embed integrations
     */
    EmbedProfile = 'embed-profile',
}

export enum ScheduleAvailabilityMode {
    // Default mode. Use `availability` property
    Pattern = 'Pattern',
    // Use certain time-slots. Use `availableSlots` property
    AvailableSlots = 'AvailableSlots',
}

export interface SchedulePaymentSettings {
    requirePayment: boolean,
    rates: {
        15: number,
        30: number,
        60: number,
        120: number
    }
}

export interface ScheduleAgendaTemplate {
    title: string
    isEnabled: boolean
    description: string
}

export interface ScheduleBookingOptions {
    minScheduleNotice?: number
    maximumEventsPerDay?: number
    defaultEventDuration?: number
    maximumDistanceInFuture?: number
    createNonBlockingEvents?: boolean
    automaticallyAcceptEvents?: boolean
}

/**
 * Feature specs: https://docs.google.com/document/d/1bEFa1nigyE4LkKrBgvbyBM2N4fYsTTUrO1vNeCsvZIM/edit
 */
export enum SchedulingMode {
    /**
     * This mode combines the availability of all members added to the schedule
     * and displays only the time slots when all members are available simultaneously
     * @default
     */
    Pool = 'Pool',

    /**
     * This mode takes the individual availability of all members added
     * to the schedule and finds time slots when any single person is available.
     * When two or more people have the same time slot available,
     * the mode prioritizes the person who was not most recently scheduled.
     */
    Select = 'Select',

    /**
     *
     * This mode is based on group availability but prioritizes the person least recently scheduled.
     * The goal is to ensure fair distribution of scheduling opportunities among group members,
     * avoiding overburdening certain individuals.
     */
    RoundRobin = 'RoundRobin',
}

export interface ScheduleParticipant extends PublicProfileData {
    isScheduleOwner?: boolean
    // Date when the meeting was booked with this participant
    lastBookedWithAt?: Date
}


export const SCHEDULE_COLLECTION_NAME = 'meeting-type'

@Entity({
    collection: SCHEDULE_COLLECTION_NAME,
})
export class Schedule implements SoftDelete, HasAttachments {

    public readonly attachmentsStream: LazyLoadStream<Attachment[]>

    private readonly destroyedEvent = new DestroyEvent()

    @ID() id: string

    @Field() userId: string
    @Field() userUId: string
    /**
     *
     */
    @Field() syncedCalendarId?: string

    /**
     * Used only for EmbedProfile schedules
     */
    @Field() integrationClientId?: string

    /**
     * For SoftDelete implementation
     */
    @Field() removed: boolean = false
    @Field() isPrivate: boolean = false
    @Field() isDisabled: boolean = false

    @Field() url: string
    @Field() label: string
    @Field() description: string

    @Field() allowedModes: MeetingMode[] = []

    @Field() type = ScheduleType.CustomProfile
    @Field() schedulingMode = SchedulingMode.Pool
    @Field() availabilityMode = ScheduleAvailabilityMode.Pattern

    /**
     * Location is used for MeetingMode.InPerson types
     */
    @Field() location?: string

    @Field() participants: ScheduleParticipant[]

    /**
     * Should be the same as user availability from the settings at default
     */
    @Field() availability: UserAvailableSlotPreferences

    @Field() availableSlots: Array<{ timeStamp: string, durationMin: number }>

    /**
     * Should be the same as user availability from the settings at default
     */
    @Field() conferenceLinkType: UserConferenceLinkType = 'undock'

    /**
     * Should be used only if selected conferenceLinkType === 'custom'
     */
    @Field() customConferenceLink?: string

    @Field() bookingOptions?: ScheduleBookingOptions = {}

    /**
     * Should be the same as user availability from the settings at default
     */
    @Field() availableDurationValues: Record<UserAvailableMeetingLength, boolean>

    @Field() formConfig: FormConfig
    @Field() showFormConfig: boolean // @TODO:  Rename to `isFormEnabled`

    @Field() agendaTemplate: ScheduleAgendaTemplate
    @Field() paymentSettings: SchedulePaymentSettings

    @Field() defaultDuration: number
    @Field() autoAcceptEvents: boolean
    @Field() minScheduleNotice: number
    @Field() maxBookingDistance: number

    /**
     * The mapping between user uId's and allowance
     */
    @Field() userAccessOverride: Record<string, boolean> = {}

    @CollectionRef(Attachment) attachments: Collection<Attachment>

    @CreatedDateField() createdAt: Date
    @UpdatedDateField() updatedAt: Date

    public constructor() {
        this.attachmentsStream = new LazyLoadStream<Attachment[]>(
            () => this.attachments.all()
                      .filter(AttachmentScope.initialized)
                      .stream()
                      .emitUntil(this.destroyedEvent),
        )
    }

    public get isBuildIn() {
        return this.type === ScheduleType.Standard
            || this.type === ScheduleType.Personal
    }

    public get isExpired() {
        if (this.availabilityMode !== ScheduleAvailabilityMode.Pattern) {
            const nowMs = Date.now()
            return Array.isArray(this.availableSlots)
                ? !Boolean(
                    this.availableSlots.find(slot => {
                        const slotEndMs = new Date(slot.timeStamp).valueOf() + slot.durationMin * 60_000
                        return nowMs < slotEndMs
                    })
                )
                : true
        }
        return false
    }

    protected init() {
        if (Array.isArray(this.participants)) {
            for (let participant of this.participants) {
                if ('toDate' in participant) {
                    // @ts-ignore
                    participant.lastBookedWithAt = participant.lastBookedWithAt.toDate()
                }
                if (typeof participant.lastBookedWithAt === 'string') {
                    participant.lastBookedWithAt = new Date(participant.lastBookedWithAt)
                }
            }
        }
    }

    /**
     * To provide id to the JSON data
     */
    public toJSON(): Partial<Schedule> {
        return {
            id: this.id,
            userId: this.userId,
            userUId: this.userUId,
            syncedCalendarId: this.syncedCalendarId,
            integrationClientId: this.integrationClientId,
            removed: this.removed,
            isPrivate: this.isPrivate,
            isDisabled: this.isDisabled,
            url: this.url,
            label: this.label,
            description: this.description,
            allowedModes: this.allowedModes,
            type: this.type,
            schedulingMode: this.schedulingMode,
            availabilityMode: this.availabilityMode,
            location: this.location,
            participants: this.participants,
            availability: this.availability,
            availableSlots: this.availableSlots,
            conferenceLinkType: this.conferenceLinkType,
            customConferenceLink: this.customConferenceLink,
            bookingOptions: this.bookingOptions,
            availableDurationValues: this.availableDurationValues,
            formConfig: this.formConfig,
            showFormConfig: this.showFormConfig,
            agendaTemplate: this.agendaTemplate,
            paymentSettings: this.paymentSettings,
            defaultDuration: this.defaultDuration,
            autoAcceptEvents: this.autoAcceptEvents,
            minScheduleNotice: this.minScheduleNotice,
            maxBookingDistance: this.maxBookingDistance,
            userAccessOverride: this.userAccessOverride,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
        } as Schedule
    }

    /**
     * Please use `allowedModes` instead
     *
     * @deprecated
     */
    @Field() mode: Exclude<MeetingMode, MeetingMode.Broadcast>
}
