<div class="contacts-search-container"
     matAutocompleteOrigin #autocompleteOrigin="matAutocompleteOrigin"
>
    <input #usersSearchInput class="contact-search-input" type="text"
           tabindex="0"
           udInput [udInputType]="'search'"
           [placeholder]="placeholder"
           [class.remove-border]="!showBorder"
           [value]="searchCriteriaStream | stream"

           [matAutocomplete]="autocomplete"
           [matAutocompleteConnectedTo]="autocompleteOrigin"

           (click)="$event.stopPropagation()"
    />

    <mat-autocomplete [class.__hidden]="!showResultsPopup"
        #autocomplete="matAutocomplete"
        (optionSelected)="onContactSelectedEvent($event.option.value)"
    >
        <ng-container ngProjectAs="mat-option" *ngFor="let uid of usersUIDsSearchStream | stream">
            <ng-container *ngIf="profilesProvider.getProfileByUid(uid) | stream as profile else userIsLoadingTmpl">
                <mat-option [value]="profile"
                           [disabled]="disabledEmails && disabledEmails.includes(profile.email)"
                >
                    <div class="contacts-search__user-view"
                         [class.disabled]="disabledEmails && disabledEmails.includes(profile.email)">

                        <img default="/assets/img/placeholders/user.png"
                             [src]="profile.imageUrl | imgThumbnail" [alt]="profile.displayName">

                        <div class="user-information">
                            <span class="name">{{ profile.displayName }}</span>
                            <span class="email">{{ profile.email }}</span>
                        </div>
                    </div>
                </mat-option>
            </ng-container>

            <ng-template #userIsLoadingTmpl>
                <mat-option disabled="true">
                    <div class="contacts-search__user-view disabled">
                        <img src="/assets/img/placeholders/user.png" alt=""/>

                        <div class="user-information"><span class="email"> .... </span></div>
                    </div>
                </mat-option>
            </ng-template>
        </ng-container>
    </mat-autocomplete>
</div>
