import {
    Collection,
    CollectionRef,
    CreatedDateField,
    DocRef,
    Entity,
    Field,
    ID,
    Reference,
    UpdatedDateField,
} from '@typeheim/orm-on-fire'

import {
    Attachment,
    AttachmentScope,
    HasAttachments,
    SoftDelete,
} from '@undock/core'
import {
    FirestoreUser,
    PublicProfileData,
} from '@undock/user'
import { Dock } from '@undock/dock/meet/models/dock.model'
import { LazyLoadStream } from '@typeheim/fire-rx'


export const TOPIC_COLLECTION_NAME = 'topic'

@Entity({
    collection: TOPIC_COLLECTION_NAME,
})
export class Topic implements SoftDelete, HasAttachments {

    public readonly dockStream: LazyLoadStream<Dock>
    public readonly authorStream: LazyLoadStream<FirestoreUser>
    public readonly attachmentsStream: LazyLoadStream<Attachment[]>
    public readonly assignedUserStream: LazyLoadStream<FirestoreUser>

    @ID() id: string

    @Field() text: string
    @Field() complete: boolean

    /**
     * @description Used for Drag&Drop sorting
     */
    @Field() position: number

    /**
     * For soft-delete implementation
     */
    @Field() removed: boolean

    @Field() hasAttachments: boolean

    @CreatedDateField() createdAt: Date
    @UpdatedDateField() updatedAt: Date

    /**
     * Stores some properties of a user
     */
    @Field() authorData: PublicProfileData

    @Field() dockId: string
    @DocRef(Dock) dock: Reference<Dock>

    @Field() authorId: string
    @DocRef(FirestoreUser) author: Reference<FirestoreUser>

    @Field() completedById: string
    @DocRef(FirestoreUser) completedBy: Reference<FirestoreUser>

    @Field() assignedUserId: string
    @DocRef(FirestoreUser) assignedUser: Reference<FirestoreUser>

    @CollectionRef(Attachment) attachments: Collection<Attachment>

    /**
     * @description `isInitialized` is value which prevents
     *           getting updates from uncompleted documents
     */
    @Field() isInitialized: boolean


    public constructor() {
        this.dockStream = new LazyLoadStream<Dock>(
            () => this.dock.get(), // Dock should never change
        )

        this.authorStream = new LazyLoadStream<FirestoreUser>(
            () => this.author.get(), // Author should never change
        )

        this.attachmentsStream = new LazyLoadStream<Attachment[]>(
            () => this.attachments.all()
                      .filter(AttachmentScope.initialized)
                      .stream(),
        )

        this.assignedUserStream = new LazyLoadStream<FirestoreUser>(
            () => this.assignedUser.stream(),
        )
    }
}

export const TopicsCollection = Collection.of<Topic>(Topic)
