import {
    Inject,
    Injectable,
} from '@angular/core'

import 'firebase/auth'
import 'firebase/firestore'
import firebase from 'firebase/app'

import { OrmOnFire } from '@typeheim/orm-on-fire'
import { FirebaseApp } from '@undock/session/contracts/firebase-app.token'


@Injectable({
    providedIn: 'root',
})
export class Database {

    public constructor(
        @Inject(FirebaseApp) app: firebase.app.App,
    ) {
        this.initializeOrmOnFire(app)
    }

    protected initializeOrmOnFire(app: firebase.app.App): void {
        OrmOnFire.driver = app.firestore()
    }
}
