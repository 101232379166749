import { Injectable } from '@angular/core'
import { Clipboard } from '@angular/cdk/clipboard'

import {
    combineLatest,
    Observable,
} from 'rxjs'
import {
    debounceTime,
    map,
    shareReplay,
    switchMap,
    takeUntil,
    withLatestFrom,
} from 'rxjs/operators'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    StatefulSubject,
    ValueSubject,
} from '@typeheim/fire-rx'

import {
    clone,
    Memoize,
    Validations,
} from '@undock/core'
import {
    MeetingMode,
    MeetingTypesManager,
    Schedule,
    ScheduleAgendaTemplate,
    ScheduleAvailabilityMode,
    ScheduleBookingOptions,
    ScheduleParticipant,
    ScheduleType,
    SchedulingMode,
} from '@undock/dock/meet'
import { CurrentUser } from '@undock/session'
import {
    PublicProfileData,
    UserAvailableSlotPreferences,
} from '@undock/user'
import { ConfirmPopupService } from '@undock/common/ui-kit'
import {
    IntegrationsManager,
    SyncedCalendar,
} from '@undock/integrations'
import {
    UserAvailableMeetingLength,
    UserConferenceLinkType,
} from '@undock/api/scopes/user/contracts/user.interface'
import { ProfileLinksManager } from '@undock/profile/shared/services/profile-links.manager'
import { MeetingDurationOptionsProvider } from '@undock/dock/meet/services/data-providers/meeting-duration-options.provider'
import {
    SnackbarManager,
    SnackbarPosition,
} from '@undock/common/ui-kit/services/snackbar.manager'
import { FormConfig } from '@undock/common/form-creator/types'
import { MeetingDuration } from '@undock/time/availability/services/availability.service'
import { Api } from '@undock/api'
import { SettingsFacade } from '@undock/profile/settings/services/facade/settings.facade'
import { PartnerChargeAccountInterface } from '@undock/api/scopes/charges/contracts/charge-account.interface'


export enum ScheduleEditView {
    Default = 'Default',
    Calendar = 'Calendar',
}

@Injectable()
export class ScheduleEditViewModel {

    @CompleteOnDestroy()
    public readonly view$ = new ValueSubject(ScheduleEditView.Calendar)

    @CompleteOnDestroy()
    public readonly isRequestProcessing$ = new ValueSubject<boolean>(false)

    @CompleteOnDestroy()
    protected readonly saveTrigger$ = new StatefulSubject()

    @CompleteOnDestroy()
    protected readonly selectedEntity$ = new ValueSubject<Schedule>(null)

    @EmitOnDestroy()
    protected readonly destroyedEvent = new DestroyEvent()

    public constructor(
        protected api: Api,
        protected clipboard: Clipboard,
        protected currentUser: CurrentUser,
        protected settingsFacade: SettingsFacade,
        protected snackbarManager: SnackbarManager,
        protected confirmService: ConfirmPopupService,
        protected meetingTypesManager: MeetingTypesManager,
        protected profileLinksManager: ProfileLinksManager,
        protected integrationsManager: IntegrationsManager,
        protected meetingDurationOptionsProvider: MeetingDurationOptionsProvider,
    ) {
        // Auto-save
        this.saveTrigger$.pipe(
            takeUntil(this.destroyedEvent),
            debounceTime(1000),
            withLatestFrom(this.selectedSchedule$),
        ).subscribe(async ([, schedule]) => {
            if (schedule && schedule.id) {
                await this.saveSelectedMeetingType(true)
            }
        })
    }


    @Memoize()
    public get participantEmails$(): ReactiveStream<string[]> {
        return new ReactiveStream<string[]>(
            this.allParticipants$.pipe(
                map(
                    users => users.map(user => user.email),
                ),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get selectedSchedule$(): ReactiveStream<Schedule> {
        return this.selectedEntity$.asStream()
    }

    @Memoize()
    public get selectedPartnerPaymentAccount$(): ReactiveStream<PartnerChargeAccountInterface> {
        return new ReactiveStream<PartnerChargeAccountInterface>(this.selectedSchedule$.pipe(
            switchMap(async (meetingType) => !!meetingType?.integrationClientId
                ? await this.api.charge.charges.getChargeAccountGroupByIntegrationClientId(meetingType.integrationClientId)
                : null),

            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        ))
    }

    @Memoize()
    public get partnerFeePercentage$(): ReactiveStream<number> {
        return new ReactiveStream<number>(this.selectedPartnerPaymentAccount$.pipe(
            map(paymentGroup => !!paymentGroup ? paymentGroup.feePercentage / 100 : 0),
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true })
        ))
    }

    @Memoize()
    public get allParticipants$(): ReactiveStream<PublicProfileData[]> {
        return new ReactiveStream<PublicProfileData[]>(
            combineLatest([
                this.addedParticipantsData$,
                this.currentUser.publicProfileDataStream,
            ]).pipe(
                map(
                    ([addedParticipants, currentUser]) => [currentUser, ...addedParticipants],
                ),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get addedParticipantsData$(): Observable<ScheduleParticipant[]> {
        return this.selectedSchedule$.pipe(
            map(type => !!type ? type.participants : []),
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    public get allSchedules$(): ReactiveStream<Schedule[]> {
        return this.meetingTypesManager.meetingTypesStream
    }

    public get isAnyScheduleAdded$(): ReactiveStream<boolean> {
        return this.meetingTypesManager.isAnyMeetingTypeAddedStream
    }

    @Memoize()
    public get isCreationMode$(): ReactiveStream<boolean> {
        return new ReactiveStream(
            combineLatest([
                this.allSchedules$,
                this.selectedSchedule$,
            ]).pipe(
                map(
                    ([allTypes, selectedType]) => !allTypes.some(t => t.id === selectedType.id),
                ),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get isEmbedIntegrationMode$(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(
            combineLatest([
                this.selectedEntity$,
                this.integrationsManager.partnerIntegrationsStream.pipe(
                    map(
                        integrations => integrations.map(i => i.clientId),
                    ),
                    takeUntil(this.destroyedEvent),
                ),
            ]).pipe(
                map(sources => {
                    const [entity, partnerIntegrationIds] = sources

                    return entity.type === ScheduleType.EmbedProfile ||
                        partnerIntegrationIds.includes(entity.integrationClientId)
                }),
            ),
        )
    }

    public selectMeetingType(entity: Schedule) {
        this.selectedEntity$.next(entity)
    }

    public async createAndSelectNewMeetingType() {
        this.selectMeetingType(
            await this.meetingTypesManager.createMeetingType(),
        )
    }

    public async deleteMeetingType(entity: Schedule): Promise<void> {
        const confirmed = await this.confirmService.open({
            title: 'Are you sure you want to delete this Schedule?',
            description: 'This action could not to be undone',
            discardButtonLabel: 'CANCEL',
        })

        if (confirmed) {
            this.isRequestProcessing$.next(true)

            await this.meetingTypesManager.deleteMeetingType(entity)

            this.isRequestProcessing$.next(false)

            this.snackbarManager.success(`Schedule deleted successfully`)
        }
    }

    public async saveSelectedMeetingType(isAutoSave = false): Promise<void> {
        let entity = await this.selectedSchedule$

        if (!entity) {
            return
        }
        if (!isAutoSave) {
            this.isRequestProcessing$.next(true)
        }

        if (await this.isEntityValid(entity)) {
            const allEntities = await this.meetingTypesManager.meetingTypesStream

            /**
             * Searching for original meeting type entity
             */
            const originalEntity = allEntities.find(
                entity => entity.id ? entity.id === entity.id : false,
            )

            if (!originalEntity) {
                /**
                 * Creation mode. We should save entity we have
                 */
                await this.meetingTypesManager.saveMeetingType(entity)
            } else {
                /**
                 * Edit mode. We should copy changes and save original
                 */
                for (let key in entity) {
                    if (entity.hasOwnProperty(key)) {
                        originalEntity[key] = entity[key]
                    }
                }

                await this.meetingTypesManager.saveMeetingType(originalEntity)
            }

            if (!isAutoSave) {
                this.selectMeetingType(null)
            }
        }
        if (!isAutoSave) {
            this.isRequestProcessing$.next(false)
        }
    }


    public setAvailability(availability: UserAvailableSlotPreferences) {
        return this.updateScheduleData(schedule => {
            schedule.availability = availability
        })
    }

    public setAvailableSlots(availableSlots: Schedule['availableSlots']) {
        return this.updateScheduleData(schedule => {
            schedule.availableSlots = availableSlots
        })
    }

    public toggleAvailabilitySlotStatus(day: string, hour: number, minuteIndex: number) {
        return this.updateScheduleData(async schedule => {
            if (schedule.availability.hasOwnProperty(day)) {
                // If day is completely disabled, it won't have a map so need to initialize it to prevent errors
                if (!schedule.availability[day].map) {
                    schedule.availability[day].map = {}
                    schedule.availability[day].count = 0
                    schedule.availability[day].enabled = true
                }

                if (schedule.availability[day].map.hasOwnProperty(hour)) {
                    if (schedule.availability[day].map[hour][minuteIndex]) {
                        schedule.availability[day].map[hour][minuteIndex].available = !schedule.availability[day].map[hour][minuteIndex].available
                        schedule.availability[day].map[hour][minuteIndex].available ? schedule.availability[day].count++ : schedule.availability[day].count--
                    }
                    if (!schedule.availability[day].map[hour].some(min => min.available)) {
                        delete schedule.availability[day].map[hour]
                    }
                } else {
                    schedule.availability[day].map[hour] = [
                        { available: false, allowInPerson: false },
                        { available: false, allowInPerson: false },
                    ]
                    if (minuteIndex >= 0 && schedule.availability[day].map[hour].length - 1 >= minuteIndex) {
                        schedule.availability[day].map[hour][minuteIndex].available = true
                        schedule.availability[day].count++
                    }
                }

                if (schedule.availability[day].count === 0) {
                    schedule.availability[day].enabled = false
                    delete schedule.availability[day].map
                }

                // Temporary code // TODO: Remove this later
                if (schedule.type === ScheduleType.Standard) {
                    // Update the user setting for availability v1 compatibility
                    await this.settingsFacade.updateTimeProfile(schedule.availability)
                }
            }
        })
    }


    public setPrivacyStatus(isPrivate: boolean) {
        return this.updateScheduleData(async schedule => {
            if (await this.isEmbedIntegrationMode$) {
                // Embed integrations should always be private
                isPrivate = true
                this.snackbarManager.warning(`Embed integration schedules are always private.`)
            }
            schedule.isPrivate = isPrivate
        })
    }

    public setAllowedModes(modes: Exclude<MeetingMode, MeetingMode.Broadcast>[]) {
        return this.updateScheduleData(schedule => {
            schedule.allowedModes = modes
        })
    }

    public updateLabel(value: string) {
        return this.updateScheduleData(schedule => {
            schedule.label = value
        })
    }

    public setDescription(value: string) {
        return this.updateScheduleData(schedule => {
            schedule.description = value
        })
    }

    public setBookingOptions(options: ScheduleBookingOptions) {
        return this.updateScheduleData(schedule => {
            schedule.bookingOptions = options
        })
    }

    public updateUrl(value: string) {
        return this.updateScheduleData(schedule => {
            schedule.url = value
        })
    }

    public setAgendaTemplate(value: ScheduleAgendaTemplate) {
        return this.updateScheduleData(schedule => {
            schedule.agendaTemplate = value
        })
    }

    public setIsAgendaTemplateEnabled(isEnabled: boolean) {
        return this.updateScheduleData(schedule => {
            schedule.agendaTemplate.isEnabled = isEnabled
        })
    }

    public setDuration(enabled: boolean, interval: UserAvailableMeetingLength) {
        return this.updateScheduleData(async schedule => {
            if (enabled || this.getActiveDurationOptionsCount(schedule) > 1) {
                schedule.availableDurationValues = clone(schedule.availableDurationValues)
                schedule.availableDurationValues[interval] = enabled

                // Temporary code // TODO: Remove this later
                if (schedule.type === ScheduleType.Standard) {
                    // Update the user setting for availability v1 compatibility
                    await this.settingsFacade
                              .updateAvailableDurationValues(schedule.availableDurationValues)
                }
            } else {
                this.snackbarManager.warning('You cannot disable all intervals', SnackbarPosition.BottomLeft)
            }
        })
    }

    public addCustomDuration(interval: number, isChecked: boolean = true) {
        return this.updateScheduleData(schedule => {
            const durations = Object.getOwnPropertyNames(schedule.availableDurationValues)
            if (!durations.includes(`${interval}`)) {
                schedule.availableDurationValues = clone(schedule.availableDurationValues)
                schedule.availableDurationValues[interval] = isChecked
            }
        })
    }

    public removeCustomDuration(duration: number) {
        return this.updateScheduleData(async schedule => {
            // Default meeting duration cannot be removed
            if (
                schedule.availableDurationValues.hasOwnProperty(duration) &&
                !this.meetingDurationOptionsProvider.defaultMeetingDurationValues.includes(duration)
            ) {
                schedule.availableDurationValues = clone(schedule.availableDurationValues)

                delete schedule.availableDurationValues[duration]

                // At least one duration option should be selected
                if (this.getActiveDurationOptionsCount(schedule) === 0) {
                    await this.setDuration(true, Object.keys(schedule.availableDurationValues)[0] as any)
                }
            }
        })
    }

    public setLocation(value: string) {
        return this.updateScheduleData(schedule => {
            schedule.location = value
        })
    }

    public setFormConfig(value: FormConfig) {
        return this.updateScheduleData(schedule => {
            // If first form element was just added, toggle the form on
            if (!schedule.formConfig || schedule.formConfig.fields?.length === 0) {
                schedule.showFormConfig = true
            }
            schedule.formConfig = value
        })
    }

    public setCalendar(calendar: SyncedCalendar) {
        return this.updateScheduleData(schedule => {
            schedule.syncedCalendarId = calendar?._id
        })
    }

    public selectSchedulingMode(mode: SchedulingMode) {
        return this.updateScheduleData(schedule => {
            schedule.schedulingMode = mode
        })
    }

    public setAvailabilityMode(mode: ScheduleAvailabilityMode) {
        return this.updateScheduleData(schedule => {
            schedule.availabilityMode = mode
        })
    }

    public setConferenceLinkType(type: UserConferenceLinkType) {
        return this.updateScheduleData(schedule => {
            schedule.conferenceLinkType = type
        })
    }

    public setCustomConferenceLink(value: string) {
        return this.updateScheduleData(schedule => {
            schedule.customConferenceLink = value
        })
    }


    public addAdditionalParticipant(user: PublicProfileData): Promise<void> {
        return this.updateScheduleData(schedule => {
            if (!schedule.participants.some(({ email }) => email === user.email)) {
                schedule.participants = [...schedule.participants, user]
            }
        })
    }

    public toggleIsFormEnabled() {
        return this.updateScheduleData(schedule => {
            schedule.showFormConfig = !schedule.showFormConfig
        })
    }

    public removeAdditionalParticipant(user: PublicProfileData) {
        return this.updateScheduleData(schedule => {
            schedule.participants = [...schedule.participants].filter(p => p.email !== user.email)
        })
    }

    public async setPaymentRequired(value: boolean) {
        return this.updateScheduleData(schedule => {
            if (!schedule.paymentSettings) {
                schedule.paymentSettings = {
                    requirePayment: value,
                    rates: { 15: 0, 30: 0, 60: 0, 120: 0 }
                }
            } else {
                schedule.paymentSettings.requirePayment = value
            }
        })
    }

    public async setPaymentRateForDuration(rate: number, duration: MeetingDuration) {
        return this.updateScheduleData(schedule => {
            if (!schedule.paymentSettings) {
                schedule.paymentSettings = {
                    requirePayment: true,
                    rates: { 15: 0, 30: 0, 60: 0, 120: 0 }
                }
            }
            schedule.paymentSettings.rates[duration] = rate
        })
    }

    public async generateAndCopyMeetingTypeAccessUrl(meetingType: Schedule) {

        let link = meetingType.type === ScheduleType.Standard ?
            /**
             * Standard schedule should navigate to the profile page
             */
            await this.profileLinksManager.getPrivateUrlForCurrentUserProfile() :
            await this.profileLinksManager.generatePrivateAccessUrlForMeetingType(meetingType.url)

        this.clipboard.copy(link)
        this.snackbarManager.success('Schedule link copied', SnackbarPosition.BottomLeft)
    }

    /**
     * Returns amount of active duration options
     */
    private getActiveDurationOptionsCount(schedule: Schedule): number {
        if ('_id' in schedule.availableDurationValues) {
            delete schedule.availableDurationValues['_id']
        }

        return Object.entries(schedule.availableDurationValues)
                     .filter(([value, isActive]) => isActive).length
    }

    /**
     * @TODO: Rework validation approach
     */
    protected async isEntityValid(targetEntity: Schedule): Promise<boolean> {

        const isEntityValid = Validations.isNotEmptyString(targetEntity.label) && Validations.isNotEmptyString(targetEntity.url)

        if (!isEntityValid) {
            this.snackbarManager.warning('Schedule must have a valid label', SnackbarPosition.BottomLeft)

            return false
        }

        const allEntities = await this.meetingTypesManager.meetingTypesStream

        const isTitleOrUrlDuplicates = allEntities.some(
            entity => entity.id !== targetEntity.id && (entity.label === targetEntity.label || entity.url === targetEntity.url),
        )

        if (isTitleOrUrlDuplicates) {
            this.snackbarManager.warning('Schedule label and url must be unique', SnackbarPosition.BottomLeft)

            return false
        }

        return true
    }

    protected async updateScheduleData(cb: (s: Schedule) => Promise<any> | any) {
        const schedule = await this.selectedSchedule$
        if (schedule) {
            await cb(schedule)
            this.saveTrigger$.next()
            this.selectedEntity$.next(schedule)
        }
    }
}
