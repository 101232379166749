import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core'
import {
    DestroyEvent,
    EmitOnDestroy,
} from '@typeheim/fire-rx'
import { Router } from '@angular/router'
import { TimeCommandViewModel } from '@undock/time/prompt/states/time-command.view-model'
import { Observable } from 'rxjs'
import {
    map,
    take,
    takeUntil,
} from 'rxjs/operators'
import { TimeCommandActions } from '@undock/api/scopes/nlp/routes/commands.route'

@Component({
    selector: 'app-time-command-executed-confirmation',
    templateUrl: 'time-command-executed-confirmation.component.html',
    styleUrls: ['time-command-executed-confirmation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeCommandExecutedConfirmationComponent {

    public readonly commandSumamryStream: Observable<string>

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    constructor(
        public commandViewModel: TimeCommandViewModel,
        protected router: Router,
    ) {
        this.commandSumamryStream = this.commandViewModel.state.blueprintStream.pipe(
            take(1),
            map(blueprint => {
                let scheduled = 0, rescheduled = 0, canceled = 0

                let summary = ''
                for (let i=0; i<blueprint.actions.length; i++) {
                    let count = blueprint.actions[i].events.filter(e => e.isSelected).length
                    switch (blueprint.actions[i].action) {
                        case TimeCommandActions.Schedule:
                            scheduled += count
                            break
                        case TimeCommandActions.Reschedule:
                            rescheduled += count
                            break
                        case TimeCommandActions.Cancel:
                            canceled += count
                            break
                    }
                }
                if (scheduled > 0) {
                    summary += `Scheduled ${scheduled} ${scheduled === 1 ? 'event' : 'events'}${rescheduled > 0 || canceled > 0 ? ', ' : ''}`
                }
                if (rescheduled > 0) {
                    summary += `Rescheduled ${rescheduled} ${rescheduled === 1 ? 'event' : 'events'}${canceled > 0 ? ', ' : ''}`
                }
                if (canceled > 0) {
                    summary += `Canceled ${canceled} ${canceled === 1 ? 'event' : 'events'}`
                }

                return summary
            })
        )
    }

    public async close() {
        return this.commandViewModel.clearConfirmation()
    }

}
