<div class="switch-container clickable"
     [ngClass]="{
         lg: size === 'lg',
         md: size === 'md',
         xl: size === 'xl'
    }"
>
    <ng-container *ngFor="let option of options">
        <div class="switch-option" [class.__target]="option[0] === value" (click)="setValue(option[0])">
            <div class="label">
                {{option[1][0]}}
            </div>
            <div class="description" *ngIf="option[1][1]">
                {{option[1][1]}}
            </div>
        </div>
    </ng-container>
</div>
