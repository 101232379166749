import { TemplateRef } from '@angular/core'

import {
    State,
    StreamStore,
} from '@typeheim/fluent-states'
import {
    StatefulSubject,
    ValueSubject,
} from '@typeheim/fire-rx'

import {
    UiTimelineEvent,
    UpdateEventsStorageResult,
} from '@undock/dashboard/contracts'
import { DashboardView } from '@undock/timeline/contracts/dashboard-view-types'


export class DashboardStore extends StreamStore {
    public readonly dashboardView = new StatefulSubject<DashboardView>()
    public readonly headerControlsTmpl = new StatefulSubject<TemplateRef<any>>()
}

export declare type DashboardState = State<DashboardStore>
