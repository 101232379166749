import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core'
import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
} from '@typeheim/fire-rx'
import {
    fromEvent,
    Observable,
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    map,
    takeUntil,
} from 'rxjs'
import {
    SearchInputFocusType,
} from '@undock/time/plans/services/states/plans-search.state-model'
import {
    TimeSearchState,
    TimeSearchStateModel,
} from '@undock/time/prompt/states/time-search.state-model'
import {
    isEmptyString,
    Memoize,
} from '@undock/core'
import { TimeCommandViewModel } from '@undock/time/prompt/states/time-command.view-model'
import { UserAppUsageRegistry } from '@undock/user/services/analytics/app-usage.registry'
import {
    UserLimitsProvider,
    UserLimitType,
} from '@undock/feature-plans/services/user-limits.provider'


@Component({
    selector: 'app-time-prompt-input',
    templateUrl: './time-prompt-input.component.html',
    styleUrls: ['./time-prompt-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimePromptInputComponent implements AfterViewInit {

    public readonly searchState: TimeSearchState

    private readonly searchDebounceTime = 500

    @ViewChild('searchInput')
    private searchInput: ElementRef<HTMLInputElement>

    @Input() allowVoiceActivation: boolean = false
    @Input() placeholder: string = 'Talk to your calendar (e.g. cancel my meetings next Friday afternoon)'

    @Output() onSubmitPromptPrevented = new EventEmitter<void>()
    @Output() onCreateNewPlanWithTitle = new EventEmitter<string>()

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    constructor(
        public viewModel: TimeCommandViewModel,
        public searchStateModel: TimeSearchStateModel,
        protected appUsageRegistry: UserAppUsageRegistry,
        protected userLimitsProvider: UserLimitsProvider,
    ) {
        this.searchState = this.searchStateModel.state
    }

    ngAfterViewInit(): void {
        this.subscribeToSearchInputKeyEventStream()
        this.subscribeToSearchInputFocusEventStream()
    }

    @Memoize()
    public get isSubmitPromptAllowedStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(combineLatest([
            this.appUsageRegistry.streamCurrentUsageValue(UserLimitType.PromptsCount),
            this.userLimitsProvider.getPromptsLimit()
        ]).pipe(
            map(([count, limit]) => Math.max(limit-count, 0) > 0),
            takeUntil(this.destroyedEvent)
        ))
    }

    public async submitPrompt() {
        if (await this.isSubmitPromptAllowedStream) {
            let text = this.searchInput?.nativeElement.value
            if (!isEmptyString(text)) {
                await this.viewModel.submitPrompt(text)

                if (this.searchInput) {
                    this.searchInput.nativeElement.blur()
                }
            }
        } else {
            this.onSubmitPromptPrevented.emit()
        }
    }

    public async tryCreateNewPlan(title: string) {
        this.onCreateNewPlanWithTitle.emit(title)
        this.clearSearchInput()
    }

    public focusSearchInput() {
        return this.searchInput?.nativeElement?.focus()
    }

    public clearSearchInput() {
        this.searchStateModel.clearSearch()
        if (this.searchInput) {
            this.searchInput.nativeElement.value = ''
        }
    }

    protected subscribeToSearchInputKeyEventStream() {
        fromEvent<KeyboardEvent>(
            this.searchInput.nativeElement, 'keydown',
        ).pipe(
            map(() => this.searchInput.nativeElement.value),
            distinctUntilChanged(),
            takeUntil(this.destroyedEvent),
        ).subscribe(criteria => {
            this.searchStateModel.setCriteria(criteria)
            this.viewModel.clearResponse()
        })

        this.searchState.searchCriteriaStream.pipe(
            debounceTime(this.searchDebounceTime),
            takeUntil(this.destroyedEvent),
        ).subscribe(criteria => {
            return this.searchStateModel.searchAll(criteria)
        })
    }

    protected subscribeToSearchInputFocusEventStream() {
        const searchFocusEventStream = fromEvent<KeyboardEvent>(
            this.searchInput.nativeElement, 'focus',
        ).pipe(
            takeUntil(this.destroyedEvent),
        )

        searchFocusEventStream.subscribe()

        const searchFocusStream = searchFocusEventStream.pipe(
            map((event) => event),
            takeUntil(this.destroyedEvent),
        )

        searchFocusStream.subscribe(event => {
            this.searchStateModel.setSearchInputFocusType(event.type as SearchInputFocusType)
        })
    }
}
