import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core'
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
} from '@angular/forms'
import { AbstractInput } from '../abstract-input/abstract-input'

import {
    format,
    isValidNumber,
} from 'libphonenumber-js'


@Component({
    selector: 'app-phone-number-input',
    template: `
        <input udInput
               #phoneNumberInput placeholder="{{placeholder}}" spellcheck="false"
               [(ngModel)]="value" [textMask]="{mask: numberEntryMask, guide: false}"
               [disabled]="disabled"
               [style.width]="width"
               
               (ngModelChange)="onChange($event); onTouched()"
        />
    `,
    styleUrls: [`phone-number-input.component.scss`],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PhoneNumberInputComponent),
            multi: true,
        }
    ]
})
export class PhoneNumberInputComponent implements OnInit,
                                                  OnChanges,
                                                  AbstractInput,
                                                  ControlValueAccessor {

    public onChange = (v) => {}
    public onTouched = () => {}

    private initialNumber: string

    @Input() placeholder: string = '(555) 555-5555'
    @Input() width: string = '100%'

    @Input() value: string = ''
    @Input() disabled: boolean = false

    @Output() numberChange = new EventEmitter<string>()
    @Output() invalidNumber = new EventEmitter<string>()

    public numberEntryMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]


    public ngOnInit() {
        this.initialNumber = this.value
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            if (changes.number) {
                if (changes.number.currentValue && this.verifyValue(changes.number.currentValue)) {
                    this.value = this.formatInput(changes.number.currentValue)
                } else {
                    this.invalidNumber.emit(changes.number.currentValue)
                    this.value = ''
                }
            }
        }
    }


    public registerOnChange(fn: any) {
        this.onChange = fn
    }

    public registerOnTouched(fn: any) {
        this.onTouched = fn
    }

    public writeValue(value: any) {
        this.value = value
    }

    public setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled
    }


    public tryEmitValue(value: string) {
        if (this.verifyValue(value)) {
            let v = this.formatOutput(value)
            this.numberChange.emit(v)
            this.initialNumber = value
        } else {
            this.invalidNumber.emit(value)
            this.value = this.initialNumber
        }
    }

    public formatInput(input: string): string {
        return format(input, 'US', 'National')
    }

    public formatOutput(value: string): string {
        return format(value, 'US', 'International').replace(/\s+/g, '')
    }

    public verifyValue(value: string): boolean {
        return isValidNumber(value, 'US')
    }
}
