import { Router } from '@angular/router'
import { Injectable } from '@angular/core'

import {
    combineLatest,
    Observable,
    timer,
} from 'rxjs'
import {
    delay,
    distinctUntilChanged,
    map,
    pairwise,
    shareReplay,
    startWith,
    switchMap,
    take,
    takeUntil,
} from 'rxjs/operators'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'

import {
    ArrayHelpers,
    compareDeeply,
    Memoize,
    Moment,
} from '@undock/core'
import { Api } from '@undock/api'
import {
    DateRange,
    MomentRange,
} from '@undock/time/availability'
import { CurrentUser } from '@undock/session'
import {
    CalendarEvent,
    CalendarEventCollection,
    CalendarEventType,
    CalendarEventVisibility,
} from '@undock/calendar/models/calendar-event.model'
import { IntegrationsManager } from '@undock/integrations'
import { ConnectionsFacade } from '@undock/people/services/facades/connections.facade'
import { CalendarEvent as AngularCalendarEvent } from 'angular-calendar'
import {
    FocusedTimelineItem,
    TimelineDirection,
    TimelineEvent,
    TimelineEventConfig,
    TimelineItem,
    TimelineItemMap,
    TimelineItemStateDataSource,
} from '@undock/api/scopes/time/contracts/timeline-event'
import { SidebarState } from '@undock/common/layout/states/sidebar.state'
import { GridDataSource } from '@undock/common/calendar-grid/contracts/grid-data-source'
import { CalendarSettingsStateModel } from '@undock/timeline/states/calendar.settings.state'

export enum CalendarGridEventType {
    Event = 'event',
}

// TODO: Move into calendar-ui module
export type CalendarGridEvent<T = TimelineEvent> = AngularCalendarEvent<{
    payload: T,
    type?: string,
}>

export interface TimelineLoadingState {
    firstEvent: TimelineItem
    lastEvent: TimelineItem
    hasHistory: boolean
    hasFuture: boolean
}

@Injectable()
export class TimelineEventsManager implements GridDataSource {

    private debugMode = false

    @CompleteOnDestroy()
    public isProcessingSubject = new ValueSubject<boolean>(false)

    @CompleteOnDestroy()
    private readonly forceReloadTimelineSubject = new ValueSubject(null)

    @CompleteOnDestroy()
    public readonly visibleTimelineItemsSubject = new ValueSubject<TimelineItem[]>([])

    @CompleteOnDestroy()
    public readonly resetTimelinePositionSubject = new ValueSubject(null)

    @CompleteOnDestroy()
    public readonly hasHistory = new ValueSubject(true)

    @CompleteOnDestroy()
    public readonly hasFuture = new ValueSubject(true)

    @CompleteOnDestroy()
    public readonly focusedTimelineItem = new ValueSubject<FocusedTimelineItem>({ id: null, tabIndex: 0 })

    @CompleteOnDestroy()
    public displayedEvents$ = new ValueSubject<CalendarGridEvent[]>([])

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    private readonly DEFAULT_DATE_RANGE: DateRange = {
        start: Moment().startOf('day').add(-2, 'week').toDate(),
        end: Moment().endOf('day').add(2, 'week').toDate(),
    }

    private _nextEvent: TimelineEvent

    public constructor(
        private api: Api,
        private router: Router,
        private currentUser: CurrentUser,
        private sidebarState: SidebarState,
        private connections: ConnectionsFacade,
        private integrationsManager: IntegrationsManager,
        private calendarSettings: CalendarSettingsStateModel,
    ) {
        this.sidebarState.locationChangesStream.subscribe(location => {
            if (location === 'timeline') {
                this.resetTimelinePosition()
            }
        })

        this.visibleTimelineItemsSubject.subscribe(visibleItems => {
            const focusedItem = this.focusedTimelineItem.getValue()
            if (focusedItem) {
                if (!visibleItems.find(item => item.id === focusedItem.id)) {
                    this.focusedTimelineItem.next({ id: null, tabIndex: 0 })
                }
            }
        })

        this.nextEventStream.subscribe(nextEvent => {
            this._nextEvent = nextEvent
        })

        if (this.debugMode) {
            this.actualTimelineStream.subscribe(v => console.log('actualTimelineStream', v))
            this.dashboardItemsStream.subscribe(v => console.log('dashboardItemsStream', v))
            // this.timelineCalendarEventsStream.subscribe(v => console.log('timelineCalendarEventsStream', v))
        }

        let actualScopeEventIDs = []
        this.actualTimelineStream
            .pipe(
                distinctUntilChanged((prev, next) => compareDeeply(prev, next)),
                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            )
            .subscribe(actualEvents => {
                const loadedEvents = this.loadedEvents.getValue()
                const actualDockIDs = actualEvents.map(({ uid: dockId }) => dockId)
                const deletedDockIDs = actualScopeEventIDs.filter(dockId => !actualDockIDs.includes(dockId))
                actualScopeEventIDs = actualDockIDs
                this.mergeLoadedEvents(actualEvents, loadedEvents.filter(({ uid: dockId }) => deletedDockIDs.includes(dockId)))
            })

        if (this.debugMode) {
            this.isInitialized.subscribe(v => console.log('isInitialized', v))
            this.eventsHistoryPage.subscribe(v => console.log('eventsHistoryPage', v))
            this.eventsFuturePage.subscribe(v => console.log('eventsFuturePage', v))
            this.eventsFuturePaginationStream.subscribe(v => console.log('eventsFuturePaginationStream', v))
            this.eventsHistoryPaginationStream.subscribe(v => console.log('eventsHistoryPaginationStream', v))
        }

        this.loadedEvents.pipe(
            distinctUntilChanged(),
            map((items) => items.map(item => TimelineEventsManager.convertToCalendarGripEvent(item))),
        ).subscribe( data => {
            this.displayedEvents$.next(data)
        } )

    }


    /** region Single Meeting Handling */
    public async createNewMeeting(options = {}): Promise<any> {
        return this.router.navigate(['/', 'meet', 'new'], { queryParams: options })
    }

    private async handleMeetingRequest(eventId: string, method: 'accept' | 'decline'): Promise<boolean> {
        this.isProcessingSubject.next(true)
        let result = false
        try {
            switch (method) {
                case 'accept' :
                    await this.api.meet.requests.acceptFromEvent(eventId)
                    break
                case 'decline' :
                    await this.api.meet.requests.declineFromEvent(eventId)
                    break
            }
            this.forceReloadTimelineSubject.next()
            result = true
        } catch (error) {
            console.warn(`Error during ${'accept' === method ? 'accepting' : 'declining'} meeting request`, error)
        } finally {
            this.isProcessingSubject.next(false)
        }
        return result
    }

    public async acceptMeetingRequest(event: TimelineEvent): Promise<boolean> {
        return await this.handleMeetingRequest(event.id, 'accept')
    }

    public async declineMeetingRequest(event: TimelineEvent): Promise<boolean> {
        return await this.handleMeetingRequest(event.id, 'decline')
    }

    /** @deprecated */
    public async rescheduleEvent(event: CalendarGridEvent, newData) {
        throw new Error('Do not use TimelineEventsManager::rescheduleEvent')
    }

    public async navigateToTheMeeting(dockIdOrHandle: string): Promise<boolean> {
        return this.router.navigate(['meet', dockIdOrHandle])
    }

    public async navigateToTheConference(dockIdOrHandle: string): Promise<boolean> {
        return this.router.navigate(['meet', dockIdOrHandle, 'room'])
    }

    public focusOn(item: FocusedTimelineItem): void {
        this.debugLog('focusOn', item)
        if (item.id !== this._nextEvent?.id) {
            this.focusedTimelineItem.next(item)
        }
    }

    /** endregion */

    private readonly apiChunkSize = 25
    @CompleteOnDestroy()
    private loadedEvents = new ValueSubject<TimelineEvent[]>([])
    @CompleteOnDestroy()
    private readonly eventsHistoryPage = new ValueSubject<number>(0)
    @CompleteOnDestroy()
    private readonly eventsFuturePage = new ValueSubject<number>(0)
    private readonly loadedDatesScope: DateRange = this.DEFAULT_DATE_RANGE
    private noHistory = false
    private noFuture = false

    public async fetch(start: Date, end: Date): Promise<any> {
        return this
            .loadEventsFromRange({ start, end })
            .then(events => {
                if (Array.isArray(events)) {
                    return events.map((item) => TimelineEventsManager.convertToCalendarGripEvent(item))
                }
                return []
            })
    }

    private mergeLoadedEvents(events: TimelineEvent[], sourceData?: TimelineEvent[]): void {
        if (!sourceData) {
            sourceData = this.loadedEvents.getValue()
        }
        // updated values comes first
        let result = [...events, ...sourceData]
        const unique = {}
        const distinct = []
        for (let i = 0; i < result.length; i++) {
            const event = result[i]
            const { uid: dockId } = event
            if (!unique[dockId]) {
                distinct.push(event)
                unique[dockId] = 1
            }
        }
        result = distinct.sort((a, b) => Moment(a.start).isBefore(Moment(b.start)) ? -1 : 1)
        if (!compareDeeply(sourceData, result)) {
            this.loadedEvents.next(result)
        }
    }

    @Memoize()
    private get actualTimelineStream(): Observable<TimelineEvent[]> {
        return this.updateTimelineTimerStream.pipe(
            switchMap(() => this.loadEventsFromRange(this.DEFAULT_DATE_RANGE, true)),
            distinctUntilChanged((prev, next) => compareDeeply(prev, next)),
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get isInitialized(): Observable<boolean> {
        return this.actualTimelineStream.pipe(
            map(() => true),
            startWith(false),
        )
    }

    @Memoize()
    private get calendarsConfig(): Observable<Record<string, TimelineEventConfig>> {
        return this.calendarSettings.state.settingsStream.pipe(
            map(config => {
                const result = {};
                (config.calendars || []).forEach(cal => {
                    result[cal.id] = {
                        icon: cal.icon,
                        color: cal.color,
                        display: cal.display,
                    };
                    (cal.subCalendars || []).forEach(subCal => {
                        result[subCal.id] = {
                            icon: subCal.icon,
                            color: subCal.color,
                            display: subCal.display,
                        }
                    })
                })
                return result
            }),
        )
    }

    @Memoize()
    private get dashboardItemsStream(): Observable<TimelineEvent[]> {
        return this.actualTimelineStream.pipe(
            take(1),
            switchMap(() => this.loadedEvents.pipe(
                distinctUntilChanged((prev, next) => compareDeeply(prev, next)),
                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ) )
        )
    }

    /*
    @Memoize()
    public get timelineLoadReady(): Observable<boolean> {
        return combineLatest([
            this.actualTimelineStream.pipe( take(1) ),
            this.eventsHistoryPaginationStream.pipe( take(1 ) ),
            this.eventsFuturePaginationStream.pipe( take(1 ) ),
        ]).pipe(
            take(1),
            map( ([a, h, f]) => {
                console.log({a,h,f, result: Boolean(a && h && f)})
                return Boolean(a && h && f)
            } )
        )
    }
    */

    @Memoize()
    private get timelineRawClusterStream(): Observable<{ dayMap: TimelineItemMap, regularEvents: TimelineItem[], allDayEvents: TimelineItem[], allDayEventsMap: TimelineItemMap }> {
        return this.dashboardItemsStream.pipe(
            distinctUntilChanged((prev, next) => compareDeeply(prev, next)),
            map(items => {
                const dayMap = {}
                const allDayEventsMap = {}
                const todayStartMoment = Moment().startOf('day')

                const reserveDate = (date, targetMap, item?) => {
                    const blankItem = { date, id: date, blankDay: true }
                    const existDay = targetMap[date]
                    if (!existDay || existDay[0]?.blankDay) {
                        targetMap[date] = [item || blankItem]
                    } else if (item) {
                        targetMap[date].push(item)
                    }
                }

                for (let dayShift = 0; dayShift < 14; dayShift++) {
                    const dayDateString = Moment().add(dayShift, 'day').format('YYYY-MM-DD')
                    reserveDate(dayDateString, dayMap)
                }

                items.forEach(timelineEvent => {
                    if (!timelineEvent) {
                        return
                    }

                    let { allDay, start, end, id } = timelineEvent

                    if (!start) {
                        return
                    }

                    let eventStart
                    let eventEnd

                    if (allDay) {
                        // fix timezone shift on all-day event
                        eventStart = Moment(start).add(12, 'hour').startOf('day')
                        eventEnd = eventStart.clone()
                        if (end) {
                            eventEnd.add(Moment(end).diff(Moment(start)) / 1000 - 1, 'second')
                        } else {
                            eventEnd.add(1, 'day')
                        }
                        eventEnd.add(-1, 'second')
                    } else {
                        eventStart = Moment(start)
                        eventEnd = end ? Moment(end) : eventStart.clone().add(1, 'hour').add(-1, 'second')
                    }

                    const eventDayString = eventStart.format('YYYY-MM-DD')
                    // const eventEndString = eventEnd.format('YYYY-MM-DD')

                    const clusterItem = {
                        id,
                        date: eventDayString,
                        isToday: todayStartMoment.format('YYYY-MM-DD') === eventDayString,
                        isOver: eventEnd.isBefore(Moment()),
                        timelineEvent,
                    } as TimelineItem

                    if (allDay) {
                        let tmpDate = eventStart.clone()
                        let i = 0
                        while (tmpDate.isSameOrBefore(eventEnd, 'date') && i < 365) {
                            const date = tmpDate.format('YYYY-MM-DD')
                            reserveDate(date, dayMap)
                            reserveDate(date, allDayEventsMap, { ...clusterItem, id: [clusterItem.id, date].join('_') })
                            tmpDate = tmpDate.add(1, 'day')
                            i++
                            if (365 == i) {
                                console.error('All-Day Event last more than 1 year, check it boundaries please')
                            }
                        }
                    } else {
                        reserveDate(eventDayString, dayMap, clusterItem)
                    }
                })

                let timelineCluster = []

                let dateGroups = Object.entries(dayMap)
                dateGroups = dateGroups.sort(([date1], [date2]) => date1 > date2 ? 1 : -1)
                for (const [date, items] of dateGroups) {
                    const dayItems = items as TimelineItem[]
                    const dayOpeningTimelineItem = dayItems[0]
                    if (dayOpeningTimelineItem) {
                        dayOpeningTimelineItem.allDayEvents = allDayEventsMap[date] || []
                        if (!dayOpeningTimelineItem.blankDay) {
                            dayOpeningTimelineItem.startOfDay = true
                            dayOpeningTimelineItem.eventsCount = dayItems.length
                        }
                    }
                    if (dayItems.length) {
                        dayItems[dayItems.length - 1].endOfDay = true
                    }
                    timelineCluster = timelineCluster.concat(dayItems)
                }

                /*this.debugLog({
                    dayMap,
                    regularEvents: timelineCluster,
                    allDayEvents: Object.values(allDayEventsMap),
                    allDayEventsMap,
                })*/

                return {
                    dayMap,
                    regularEvents: timelineCluster,
                    allDayEvents: Object.values(allDayEventsMap),
                    allDayEventsMap,
                }
            }),
        )
    }

    @Memoize()
    public get timelineClusterStream(): Observable<TimelineItem[]> {
        return this.timelineRawClusterStream.pipe(
            map(({ regularEvents }) => regularEvents),
        )
    }

    public static convertToCalendarGripEvent(event: TimelineEvent): CalendarGridEvent {
        const { id, dockId, start, end, title, allDay, isOrganizer } = event
        const startDate = Moment(start).toDate()
        const endDate = Moment(end || Moment(start).endOf('day')).toDate()

        return {
            id: dockId || id, // for backward compatibility
            allDay,
            title,
            start: startDate,
            end: endDate,
            meta: {
                type: CalendarGridEventType.Event,
                dock: event,
                payload: event,
            },
            /*
                color?: EventColor;
                actions?: EventAction[];
                cssClass?: string;
            */
            draggable: isOrganizer,
            resizable: {
                afterEnd: isOrganizer,
                beforeStart: isOrganizer,
            },
        } as CalendarGridEvent
    }

    @Memoize()
    public get timelineBoundaries(): ReactiveStream<{ start: TimelineEvent, end: TimelineEvent }> {
        return new ReactiveStream<{ start: TimelineEvent; end: TimelineEvent }>(
            this.timelineClusterStream.pipe(
                map((items) => {
                    return {
                        start: items[0],
                        end: items[items.length - 1],
                    }
                }),
            ),
        )
    }

    @Memoize()
    public get timelineItemState(): ReactiveStream<TimelineItemStateDataSource> {
        return new ReactiveStream(combineLatest([
            this.currentEventStream,
            this.lastCurrentEventStream,
            this.nextEventStream,
            this.focusedTimelineItem,
            timer(0, 5000),
        ]).pipe(
            map(([currentEvents, lastCurrentEvent, nextEvent, focusedTimelineItem]) => ({
                currentEvents,
                lastCurrentEvent,
                nextEvent,
                focusedTimelineItem,
            })),
        ))
    }

    @Memoize()
    public get currentEventStream(): Observable<TimelineEvent[]> {
        return combineLatest([
            this.actualTimelineStream,
            timer(0, 1000),
        ]).pipe(
            map(([events]) => (events || []).filter(event => event && !event.allDay && Moment().isBetween(Moment(event.start), Moment(event.end)))),
            distinctUntilChanged((prev, next) => compareDeeply(prev, next)),
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get firstCurrentEventStream(): Observable<TimelineEvent> {
        return this.currentEventStream.pipe(
            map(events => events[0] || null),
            // distinctUntilChanged((prev, next) => compareDeeply(prev, next)),
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get lastCurrentEventStream(): Observable<TimelineEvent> {
        return this.currentEventStream.pipe(
            map(events => events.find(item => Moment(item.end).isSame(Moment.max(events.map(event => Moment(event.end)))))),
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get nextEventStream(): Observable<TimelineEvent> {
        return combineLatest([
            this.dashboardItemsStream,
            timer(0, 1000),
        ]).pipe(
            map(([events]) => events.find(event => !event.allDay && Moment().isBefore(Moment(event.start)))),
            distinctUntilChanged(
                (prev, next) => next?.dockId === prev?.dockId,
            ),
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get firstEventStream(): Observable<TimelineItem> {
        return combineLatest([
            this.timelineClusterStream,
            //timer(0, 1000),
        ]).pipe(
            map(([timelineItems]) => timelineItems[0]),
            distinctUntilChanged((prev, next) => compareDeeply(prev, next)),
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get lastEventStream(): Observable<TimelineItem> {
        return combineLatest([
            this.timelineClusterStream,
            //timer(0, 1000),
        ]).pipe(
            map(([timelineItems]) => [...timelineItems].pop()),
            distinctUntilChanged((prev, next) => compareDeeply(prev, next)),
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get meetingStartsInText(): Observable<null | string> {
        return combineLatest([
            this.nextEventStream,
            timer(0, 5000),
        ])
            .pipe(
                map(([nextEvent]) => {
                    if (nextEvent) {
                        const { start } = nextEvent
                        const startsInLessThan12Hours = Moment(start).isBetween(Moment(), Moment().add(12, 'hours'))
                        if (startsInLessThan12Hours) {
                            return Moment(start).fromNow()
                        }
                    }
                    return null
                }),
                takeUntil(this.destroyedEvent),
            )
    }

    @Memoize()
    public get updateTimelineTimerStream(): Observable<void> {
        return combineLatest([
            this.forceReloadTimelineSubject,
            timer(0, 60_000),
        ]).pipe(
            map(() => null),
            takeUntil(this.destroyedEvent),
        )
    }

    @Memoize()
    public get timelineLoadingStateStream(): ReactiveStream<TimelineLoadingState> {
        return new ReactiveStream(
            combineLatest([
                this.firstEventStream,
                this.lastEventStream,
                this.hasFuture,
                this.hasHistory,
                timer(0, 100),
            ]).pipe(
                map(([firstEvent, lastEvent, hasFuture, hasHistory]) => ({ firstEvent, lastEvent, hasFuture, hasHistory })),
            ),
        )
    }

    @Memoize()
    public get pendingBroadcastsStream(): ReactiveStream<CalendarEvent[]> {
        return new ReactiveStream<CalendarEvent[]>(
            combineLatest([
                this.currentUser.dataStream,
                this.contactScopedBroadcastsStream,
                this.participantScopedBroadcastsStream,
            ]).pipe(
                map(sources => {
                    /**
                     * Merging all events into one array
                     */
                    let userId = sources[0].firebaseId
                    let events = [...sources[1], ...sources[2]]

                    /**
                     * Casting proprietary firebase Timestamps format to Date
                     */
                    events.forEach(event => {
                        if (event.dates.end && 'toDate' in event.dates.end) {
                            event.dates.end = (event.dates.end as any).toDate() as Date
                        }

                        if (event.dates.start && 'toDate' in event.dates.start) {
                            event.dates.start = (event.dates.start as any).toDate() as Date
                        }
                    })

                    /**
                     * Removing current user broadcasts
                     */
                    events = events.filter(e => e.authorId !== userId)

                    events.sort((a, b) => {
                        return a.dates.start.valueOf() - b.dates.start.valueOf()
                    })

                    return events
                }),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    protected get contactScopedBroadcastsStream(): ReactiveStream<CalendarEvent[]> {
        return new ReactiveStream<CalendarEvent[]>(
            combineLatest([
                this.currentUser.dataStream,
                this.connections.connectionsStream,
            ]).pipe(
                map(([user, connections]) => {
                    /**
                     * Firebase doesn't support IN filter more than 10 items
                     */
                    return ArrayHelpers.splitArrayToChunks([
                        user.firebaseId, ...connections.map(
                            c => c.getConnectedUid(user.firebaseId),
                        ),
                    ], 10)
                }),
                switchMap(uidChunks => {
                    return Promise.all(
                        uidChunks.map(connectedUsersUIDs => {
                            return CalendarEventCollection.filter(
                                filter => filter.authorId.in(connectedUsersUIDs),
                            ).filter(
                                filter => filter.type.equal(CalendarEventType.Broadcast),
                            ).filter(
                                filter => filter.visibility.equal(CalendarEventVisibility.Connections),
                            ).filter(
                                filter => filter.dates.field('end').greaterThen(new Date()),
                            ).orderBy(
                                'dates.end', 0,
                            ).limit(50).get()
                        }),
                    )
                }),
                map((eventChunks) => {
                    /**
                     * Merging chunks
                     */
                    return eventChunks.reduce((carry, item) => {
                        return carry.concat(item)
                    }, [])
                }),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    protected get participantScopedBroadcastsStream(): ReactiveStream<CalendarEvent[]> {
        return new ReactiveStream<CalendarEvent[]>(
            this.currentUser.dataStream.pipe(
                switchMap(user => {
                    return CalendarEventCollection.filter(
                        filter => filter.type.equal(CalendarEventType.Broadcast),
                    ).filter(
                        filter => filter.participantIds.contain(user.firebaseId),
                    ).filter(
                        filter => filter.visibility.equal(CalendarEventVisibility.Participants),
                    ).filter(
                        filter => filter.dates.field('end').greaterThen(new Date()),
                    ).orderBy(
                        'dates.end', 0,
                    ).limit(50).get()
                }),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    public reloadTimeline(): void {
        this.forceReloadTimelineSubject.next()
    }

    public resetTimelinePosition(target?): void {
        this.resetTimelinePositionSubject.next(target)
    }

    private dateRangeToMomentRange(range: DateRange): MomentRange {
        return {
            start: Moment(range.start),
            end: Moment(range.end),
        }
    }

    private getAlreadyLoadedEventsByRange(range: DateRange): TimelineEvent[] {
        return this.loadedEvents.getValue().filter(item => {
            const { allDay, start, end } = item
            const eventStartInRange = Moment(start).isBetween(Moment(range.start), Moment(range.end), undefined, '[]')
            const rangeStartInsideAllDayEvent = allDay && Moment(range.start).isBetween(Moment(start), Moment(end), undefined, '[]')
            return eventStartInRange || rangeStartInsideAllDayEvent
        })
    }

    private updateLoadedRange(range?: DateRange) {
        const { start: loadedStart, end: loadedEnd } = this.dateRangeToMomentRange(this.loadedDatesScope)
        if (range) {
            const { start, end } = this.dateRangeToMomentRange(range)

            if (loadedStart.isAfter(start)) {
                this.loadedDatesScope.start = start.toDate()
            }
            if (loadedEnd.isBefore(end)) {
                this.loadedDatesScope.end = end.toDate()
            }
        } else {
            const loadedEvents = [...this.loadedEvents.getValue()]
            const firstEvent = loadedEvents.shift()
            const lastEvent = loadedEvents.pop()
            if (firstEvent && Moment(firstEvent).isBefore(loadedStart)) {
                this.loadedDatesScope.start = Moment(firstEvent.start).toDate()
            }
            if (lastEvent && Moment(lastEvent).isAfter(loadedEnd)) {
                this.loadedDatesScope.end = Moment(lastEvent.start).toDate()
            }
        }
    }

    private prepareRange(range: DateRange): DateRange | boolean {
        let { start, end } = this.dateRangeToMomentRange(range)
        const { start: loadedStart, end: loadedEnd } = this.dateRangeToMomentRange(this.loadedDatesScope)

        if ((this.noHistory && start.isBefore(loadedStart) || (this.noFuture && end.isAfter(loadedEnd)))) {
            // nothing to load
            return false
        }

        if (start.isSameOrAfter(loadedStart)) {
            start = loadedEnd
        }

        if (end.isSameOrBefore(loadedEnd)) {
            end = loadedStart
        }

        if (start === loadedEnd && end === loadedStart) {
            return true // both dates are in loaded range
        } else if (!start) {
            start = loadedEnd
        } else if (!end) {
            end = loadedStart
        }

        return {
            start: start.toDate(),
            end: end.toDate(),
        }
    }

    public async loadEventsFromRange(range: DateRange, ignoreHistory = false): Promise<TimelineEvent[]> {
        /**
         * Should load events only for regular users
         */
        if (await this.currentUser.isRegularUser) {
            const requestRange = ignoreHistory ? range : this.prepareRange(range)
            switch (true) {
                case true === requestRange:
                    return this.getAlreadyLoadedEventsByRange(range)
                case false === requestRange:
                    return []
                default: {
                    const { start, end } = requestRange as DateRange
                    this.updateLoadedRange(requestRange as DateRange)
                    return this.api.time.events
                               .getTimelineEventsByDateRange(start, end)
                               .then(result => {
                                   if (result.length) {
                                       this.mergeLoadedEvents(result)
                                   }
                                   return result
                               })
                }
            }
        }
        return []
    }

    @Memoize()
    public get eventsHistoryPaginationStream(): Observable<TimelineEvent[]> {
        return this.isInitialized.pipe(
            switchMap(isReady => !isReady ? [] :
                this.eventsHistoryPage
                    .pipe(
                        distinctUntilChanged(),
                        switchMap(async (page) => {
                            const result = await this.loadHistoryPrevPage(page)
                            const hasHistory = result.length === this.apiChunkSize
                            this.hasHistory.next(hasHistory)
                            this.noHistory = !hasHistory
                            this.debugLog('loadHistoryPrevPage', { page, hasHistory, result })
                            await this.mergeLoadedEvents(result)
                            this.updateLoadedRange()
                            return result
                        }),
                    ),
            ),
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get eventsFuturePaginationStream(): Observable<TimelineEvent[]> {
        return this.isInitialized.pipe(
            switchMap(isReady => !isReady ? [] :
                this.eventsFuturePage
                    .pipe(
                        distinctUntilChanged(),
                        switchMap(async (page) => {
                            const result = await this.loadFutureNextPage(page)
                            const hasFuture = result.length === this.apiChunkSize
                            this.hasFuture.next(hasFuture)
                            this.noFuture = !hasFuture
                            this.debugLog('loadFutureNextPage', { page, hasFuture, result })
                            await this.mergeLoadedEvents(result)
                            this.updateLoadedRange()
                            return result
                        }),
                    ),
            ),
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    public async loadHistory() {
        if (this.noHistory) {
            return
        }
        const currentPageNumber = await this.eventsHistoryPage
        this.eventsHistoryPage.next(currentPageNumber + 1)
        this.debugLog(`loadHistory: ${currentPageNumber} -> ${currentPageNumber + 1}`)
    }

    public async loadFuture() {
        if (this.noFuture) {
            return
        }
        const currentPageNumber = await this.eventsFuturePage
        this.eventsFuturePage.next(currentPageNumber + 1)
        this.debugLog(`loadFuture: ${currentPageNumber} -> ${currentPageNumber + 1}`)
    }

    private async loadFutureNextPage(page) {
        if (this.noFuture) {
            return []
        }
        return this.loadEventsChunk(page, TimelineDirection.Future)
    }

    private async loadHistoryPrevPage(page) {
        if (this.noHistory) {
            return []
        }
        return this.loadEventsChunk(page, TimelineDirection.History)
    }

    private async loadEventsChunk(page: number, direction: TimelineDirection) {
        let date
        switch (direction) {
            case TimelineDirection.History :
                date = this.loadedDatesScope.start
                break
            case TimelineDirection.Future :
                date = this.loadedDatesScope.end
                break
        }
        date = Moment(date).toDate()
        this.debugLog('loadEventsChunk', { date, page, direction })

        /**
         * Should load events only for regular users
         */
        if (await this.currentUser.isRegularUser) {
            const events = await this.api.time.events
                                     .getTimelineEventsChunkFromDate(date, direction, this.apiChunkSize, page)
            return events || []
        }

        return []
    }

    private debugLog(...args): void {
        if (this.debugMode) {
            console.log.apply(null, ['[TimelineEventsManager]', ...args])
        }
    }

}
