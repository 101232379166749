import { Injectable } from '@angular/core'

import {
    combineLatest,
    Observable,
} from 'rxjs'
import {
    distinctUntilChanged,
    map,
    shareReplay,
    switchMap,
    takeUntil,
} from 'rxjs/operators'
import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    StatefulSubject,
    CompleteOnDestroy,
} from '@typeheim/fire-rx'

import { User } from '@undock/user'
import { CurrentUser } from '@undock/session'
import { PrivateNote } from '@undock/core/models'
import { Memoize } from '@undock/core/decorators'
import { HasPrivateNotes } from '@undock/core/contracts'
import {
    Collection,
    EntityStream,
} from '@typeheim/orm-on-fire'


@Injectable()
export class PrivateNotesManager {

    @CompleteOnDestroy()
    private readonly sourceSubject = new StatefulSubject<HasPrivateNotes>()

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    public constructor(
        protected currentUser: CurrentUser,
    ) {}

    @Memoize()
    public get privateNotesStream(): ReactiveStream<string> {
        return new ReactiveStream<string>(
            this.privateNotesEntityStream.pipe(
                map(
                    entity => entity.text ?? '',
                ),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get isPrivateNotesFilledStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(
            this.privateNotesStream.pipe(
                map(
                    notes => notes && notes.length > 0,
                ),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    protected get privateNotesEntityStream(): ReactiveStream<PrivateNote> {
        return new ReactiveStream<PrivateNote>(
            combineLatest([
                this.sourceSubject.pipe(
                    distinctUntilChanged((prev, next) => prev.id === next.id),
                ),
                this.currentUser.dataStream.pipe(
                    distinctUntilChanged((prev, next) => prev._id === next._id),
                ),
            ]).pipe(
                switchMap(([source, user]) => {
                    /**
                     * Returns stream of PrivateNote entity
                     */
                    return this.getOrCreatePrivateNoteEntityStream(source, user)
                }),
                /**
                 * Switches to the PrivateNote entity stream
                 */
                switchMap(stream => stream),
            ),
        )
    }

    public async updatePrivateNoteText(text: string) {
        const [source, entity] = await Promise.all([
            this.sourceSubject, this.privateNotesEntityStream,
        ])

        entity.text = text

        return source.privateNotes.save(entity)
    }

    /**
     * The main initialization entry point
     */
    public setPrivateNotesSource(source: HasPrivateNotes) {
        this.sourceSubject.next(source)
    }

    /**
     * Returns promise with Observable of PrivateNote entity
     */
    protected async getOrCreatePrivateNoteEntityStream(source: HasPrivateNotes, user: User): Promise<Observable<PrivateNote>> {
        const { stream } = await PrivateNotesManager.getOrCreateEntityForUser(
            user, source.privateNotes, source.id, source.entityName,
        )
        return stream.emitUntil(this.destroyedEvent).asObservable()
    }



    public static async getOrCreateEntityForUser(
        user: User,
        collection: Collection<PrivateNote>,
        ownerEntityId: string, ownerEntityName: string,
    ): Promise<{ stream: EntityStream<PrivateNote> }> {
        /**
         * Firebase id should be used
         */
        const userId = user.firebaseId

        /**
         * This ID should be used for all private-note entities
         */
        const modernId = `${ownerEntityName}|${ownerEntityId}|${user.firebaseId}`

        let [modernEntity, legacyEntity] = await Promise.all([
            collection.one(modernId).get(),
            collection.one(`${user._id}`).get(),
        ])

        if (!modernEntity) {
            modernEntity = await collection.new(modernId)

            modernEntity.userId = userId
            modernEntity.userUId = user.firebaseId ?? ''
            modernEntity.ownerId = ownerEntityId
            modernEntity.ownerType = ownerEntityName

            await collection.save(modernEntity)
        }

        if (legacyEntity) {
            modernEntity.text = legacyEntity.text

            /**
             * Remove legacy one to use only modern approach
             */
            collection.remove(legacyEntity)

            await collection.save(modernEntity)
        }

        return { stream: collection.one(modernId).stream() }
    }
}
