import { Injectable } from '@angular/core'
import { HttpHeaders } from '@angular/common/http'

import { AuthManager } from '@undock/auth'


@Injectable()
export class AuthHeaders {
    public constructor(
        private authManager: AuthManager,
    ) {}

    public async getFullyAuthenticatedRequest(): Promise<HttpHeaders> {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await this.authManager.accessTokenStream}`,
        })
    }
}
