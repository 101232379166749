import { Router } from '@angular/router'
import {
    Injectable,
} from '@angular/core'

import 'firebase/auth'
import firebase from 'firebase/app'

import {
    AsyncStream,
    DestroyEvent,
    EmitOnDestroy,
    ReactivePromise,
} from '@typeheim/fire-rx'
import { Subscription } from 'rxjs'
import {
    debounceTime,
    filter,
    takeUntil,
} from 'rxjs/operators'
import {
    AnonymousAuth,
    AuthProviders,
    PasswordAuth,
    TokenAuth,
} from '@typeheim/fire-auth'
import { CredentialAuth } from '@typeheim/fire-auth/src/AuthManager'

import { Config } from '@undock/core/models'
import { AuthSession } from '@undock/auth/services/firebase/auth-session.service'
import { FirebaseAuthManager } from '@undock/auth/services/firebase/firebase-auth.manager'
import {
    DeviceUtil,
    ExtConnector,
} from '@undock/core'


@Injectable({
    providedIn: 'root',
})
export class AuthManager {

    public readonly accessTokenStream: AsyncStream<string>
    public readonly authUserStream: AsyncStream<firebase.User>
    public readonly isLoggedInStream: AsyncStream<boolean>
    public readonly isRegularUserStream: AsyncStream<boolean>
    public readonly isAnonymousUserStream: AsyncStream<boolean>
    public readonly idTokenStream: AsyncStream<firebase.auth.IdTokenResult>


    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()


    public constructor(
        protected config: Config,
        protected router: Router,
        protected deviceUtil: DeviceUtil,
        protected authSession: AuthSession,
        protected firebaseAuth: FirebaseAuthManager,
        protected extensionConnector: ExtConnector,
    ) {
        this.authUserStream = this.authSession.userStream
        this.idTokenStream = this.authSession.idTokenStream
        this.isLoggedInStream = this.authSession.isLoggedInStream
        this.accessTokenStream = this.authSession.accessTokenStream
        this.isRegularUserStream = this.authSession.isLoggedInStream
        this.isAnonymousUserStream = this.authSession.isAnonymousStream

        this.initialize()
    }

    /**
     * Navigates the user to the login page
     */
    public async login(redirectPath = ''): Promise<void> {
        await this.router.navigate(['/login'], { queryParams: { redirectPath } })
    }

    /**
     * Navigates the user to the signup page
     */
    public async signUp(redirectPath = ''): Promise<void> {
        await this.router.navigate(['/signup'], { queryParams: { redirectPath } })
    }

    /**
     * Redirects user to logout page and keeps redirectPath for login
     *
     * @TODO: Maybe we should proceed with direct logout and redirect to the login page?
     */
    public async logout(redirectPath = ''): Promise<void> {
        await this.router.navigate(['/logout'], { queryParams: { redirectPath } })
    }


    public async signOut(): Promise<void> {
        await this.firebaseAuth.signOut()
        await this.extensionConnector.signOutExt()
    }

    public async sendResetPasswordEmail(email: string): Promise<void> {
        return this.firebaseAuth.sendPasswordResetEmail(email)
    }

    public async signInWithGoogle() {
        let googleProvider = AuthProviders.Google
        googleProvider.setCustomParameters({ prompt: 'select_account' })
        const auth = this.firebaseAuth.throughProvider(googleProvider)
        return this.isPopupMode() ? auth.signInWithPopup() : auth.signInWithRedirect()
    }

    public async signInWithCredential(cred) {
        return this.firebaseAuth.signIn(new CredentialAuth(cred))
    }

    public async getRedirectResult() {
        return this.firebaseAuth.getRedirectResult()
    }

    public async signInAnonymously(): Promise<firebase.auth.UserCredential> {
        return this.firebaseAuth.signIn(new AnonymousAuth(null, null))
    }

    public async signInWithMicrosoft() {
        const auth = this.firebaseAuth.throughProvider(
            new firebase.auth.OAuthProvider('microsoft.com'),
        )
        return this.isPopupMode() ? auth.signInWithPopup() : auth.signInWithRedirect()
    }

    public async signInWithEmailAndPassword(email: string, password: string): Promise<firebase.auth.UserCredential> {
        return this.firebaseAuth.signIn(new PasswordAuth(email, password))
    }

    public signInWithMobileToken(token: string): ReactivePromise<firebase.auth.UserCredential> {
        return this.firebaseAuth.signIn(new TokenAuth(token.replace(/[\n\r\s]/g, '')))
    }

    public async signUpWithEmailAndPassword(email: string, password: string): Promise<firebase.auth.UserCredential> {
        return this.firebaseAuth.createUserWithEmailAndPassword(email, password)
    }

    protected initialize() {
        this.subscribeToIdTokenStream()
    }

    protected subscribeToIdTokenStream(): Subscription {
        return this.authSession.idTokenStream.pipe(
            debounceTime(100),
            takeUntil(this.destroyedEvent),
            /**
             * State should be not empty
             */
            filter(state => Boolean(state)),
        ).subscribe(payload => {
            if (payload && payload.token) {
                /**
                * Sends ID Token to extension so the extension can log in if needed
                */
                this.extensionConnector.sendIdTokenToExt(payload.token)
            }
        })
    }

    /**
     * Some browsers don't support redirect auth
     *  | Safari 16.1+ on macOS
     *  | iOS 16.1+
     *  | Firefox 109+
     * https://firebase.google.com/docs/auth/web/redirect-best-practices#web-version-8
     */
    protected isPopupMode() {
        return this.deviceUtil.isAppleDevice || this.deviceUtil.isFirefox ||
            this.router.parseUrl(this.router.url).queryParamMap.has('force_popup_mode')
    }
}
