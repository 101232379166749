import { ApiRoute } from '@undock/api'
import { InitializeSubscriptionRequest } from '@undock/api/scopes/subscriptions/requests/initialize-subscription.request'
import { SubscriptionInterface } from '@undock/api/scopes/subscriptions/contracts/subscriptions.interface'
import { SubscriptionAccountDetailsInterface } from '@undock/api/scopes/subscriptions/contracts/subscription-account-details.interface'
import { BankAccountDetails } from '@undock/api/scopes/charges/routes/charges.route'
import { CancelSubscriptionRequest } from '@undock/api/scopes/subscriptions/requests/cancel-subscription.request'

export class SubscriptionsRoute extends ApiRoute {

    public init() {
        this.route = 'integrations/subscriptions'
    }

    public getSubscriptionAccountDetails(): Promise<SubscriptionAccountDetailsInterface> {
        return this.get<SubscriptionAccountDetailsInterface>()
                   .endpoint(`details`)
    }

    public setPaymentMethodAsDefaultById(id: string): Promise<BankAccountDetails> {
        return this.post<BankAccountDetails>()
                   .endpoint(`payment-method/default/${id}`)
    }

    public removePaymentMethodById(id: string): Promise<BankAccountDetails> {
        return this.delete<BankAccountDetails>()
                   .endpoint(`payment-method/${id}`)
    }

    public initializeSubscription(request: InitializeSubscriptionRequest): Promise<SubscriptionInterface> {
        return this.post<SubscriptionInterface>()
                   .withData(request)
                   .endpoint(`init`)
    }

    // public confirmSubscription(request: ConfirmChargeRequest): Promise<ChargeInterface> {
    //     return this.post<ChargeInterface>()
    //                .withData(request)
    //                .endpoint(`confirm`)
    // }

    public cancelSubscription(request: CancelSubscriptionRequest): Promise<SubscriptionInterface> {
        return this.post<SubscriptionInterface>()
                   .withData(request)
                   .endpoint(`cancel`)
    }

}


export enum CreditCardType {
    Amex = 'amex',
    Visa = 'visa',
    MasterCard = 'mastercard',
    Discover = 'discover'
}

export enum SubscriptionInvoiceStatus {
    Pending = 'Pending',
    Paid = 'Paid',
    Void = 'Void'
}

export interface SubscriptionInvoice {
    id: string,
    label: string,
    status: SubscriptionInvoiceStatus,
    amount: number
    date: Date
}
