import {
    Component,
    ChangeDetectionStrategy,
} from '@angular/core'


@Component({
    selector: 'app-shield-check-icon',
    templateUrl: 'shield-check-icon.component.html',
    styleUrls: ['shield-check-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShieldCheckIconComponent {}
