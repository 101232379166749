import {
    Collection,
    CreatedDateField,
    DocRef,
    Entity,
    EntityStream,
    Field,
    ID,
    Reference,
    UpdatedDateField,
} from '@typeheim/orm-on-fire'

import {
    Memoize,
    SoftDelete,
} from '@undock/core'
import { FirestoreUser } from '@undock/user'
import { Dock } from '@undock/dock/meet/models/dock.model'

export const ACTION_ITEMS_COLLECTION_NAME = 'actionItem'

@Entity({
    collection: ACTION_ITEMS_COLLECTION_NAME,
})
export class ActionItem implements SoftDelete {
    @ID() id: string

    @Field() text: string

    @Field() weight: number
    @Field() position: number

    @Field() complete: boolean

    /**
     * For soft-delete implementation
     */
    @Field() removed: boolean

    @CreatedDateField() createdAt: Date
    @UpdatedDateField() updatedAt: Date


    @Field() dockId: string
    @DocRef(Dock) dock: Reference<Dock>

    @Field() authorId: string
    @DocRef(FirestoreUser) author: Reference<FirestoreUser>

    @Field() completedById: string
    @DocRef(FirestoreUser) completedBy: Reference<FirestoreUser>

    @Field() assignedUserId: string
    @DocRef(FirestoreUser) assignedUser: Reference<FirestoreUser>

    /**
     * @description `isInitialized` is value which prevents
     *           getting updates from uncompleted documents
     */
    @Field() isInitialized: boolean


    @Memoize()
    public get dockStream(): EntityStream<Dock> {
        return this.dock.stream()
    }

    @Memoize()
    public get authorStream(): EntityStream<FirestoreUser> {
        return this.author.stream()
    }

    @Memoize()
    public get assignedUserStream(): EntityStream<FirestoreUser> {
        return this.author.stream()
    }
}

export const ActionItemsCollection = Collection.of<ActionItem>(ActionItem)
