<section class="draft-participants-list">
    <div class="participants-header">
        <app-user-contacts-search *ngIf="!readonlyMode"
                                  [showBorder]="false"
                                  [placeholder]="'Email or name...'"
                                  [disabledEmails]="participantEmailsStream | stream"

                                  (onSelected)="onContactSelected($event)"
        >
        </app-user-contacts-search>

        <app-icon-participants *ngIf="showIcon"></app-icon-participants>
    </div>

    <ng-container *ngFor="let participant of allParticipantsStream | stream">
        <div class="participant-list-item"
             [ngClass]="{
                'role-owner': participant.role   === DockParticipantRole.Owner,
                'role-member': participant.role  === DockParticipantRole.Member,
                'role-visitor': participant.role === DockParticipantRole.Visitor,

                'status-denied': participant.status   === DockParticipantStatus.Denied,
                'status-pending': participant.status  === DockParticipantStatus.Pending,
                'status-accepted': participant.status === DockParticipantStatus.Accepted
             }"
             [ngSwitch]="participant.userData.isUndockUser"
        >
            <ng-container *ngSwitchCase="true">
                <ng-container *ngIf="profilesProvider.getProfileById(participant.userId) | stream as profile">
                    <div class="profile-image-box">
                        <img [matTooltip]="profile.displayName" [matTooltipPosition]="TooltipPosition.BOTTOM"
                             [alt]="profile.email" [src]="profile.imageUrl | imgThumbnail"
                             default="/assets/img/placeholders/user.png"
                        >
                    </div>

                    <div class="display-name-box">
                        <span>
                            {{ profile.displayName ? profile.displayName : profile.email }} {{ profile.removed ? '[Inactive]' : '' }}
                        </span>
                    </div>

                    <div *ngIf="!readonlyMode" class="participant-actions">
                        <div class="delete-button"
                             *ngIf="canDeleteParticipant(participant) | stream"
                             (click)="removeParticipantByEmail(participant.userData.email)"
                        >
                            <i class="material-icons">close</i>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container
                    *ngSwitchCase="false" [ngTemplateOutlet]="participantUserIsLoadingOrNotDefinedTmpl"
            ></ng-container>

            <ng-template #participantUserIsLoadingOrNotDefinedTmpl>
                <div class="profile-image-box">
                    <img class="participants-list__participant-icon"
                         [src]="participant?.userData?.imageUrl | imgThumbnail" [alt]="participant?.userData?.email"
                         default="/assets/img/placeholders/user.png"
                         [matTooltip]="participant?.userData?.displayName || participant?.userData?.email"
                         [matTooltipPosition]="TooltipPosition.BOTTOM"
                    >
                </div>

                <div class="display-name-box">
                    <span>
                        {{ participant?.userData?.displayName || participant?.userData.email }}
                    </span>
                </div>

                <div *ngIf="!readonlyMode" class="participant-actions">
                    <div class="delete-button"
                         *ngIf="canDeleteParticipant(participant) | stream"
                         (click)="removeParticipantByEmail(participant.userData.email)"
                    >
                        <i class="material-icons">close</i>
                    </div>
                </div>
            </ng-template>

            <ng-container *ngIf="isParticipantMarkedToDelete(participant) | stream">
                <div class="marked-to-delete-overlay">
                    <span (click)="removeParticipantFromDeleteList(participant)">Cancel</span>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngFor="let profile of pendingParticipantsStream | stream">
        <div class="participant-list-item pending">
            <div class="profile-image-box">
                <img [src]="profile.imageUrl | imgThumbnail" [alt]="profile.displayName"
                     default="/assets/img/placeholders/user.png"
                     [matTooltip]="profile?.displayName || profile.email"
                     [matTooltipPosition]="TooltipPosition.BOTTOM"/>
            </div>

            <div class="display-name-box">
                <span>{{ profile.displayName }}</span>
            </div>

            <div class="participant-actions">
                <div class="delete-button" (click)="removeParticipantByEmail(profile.email)">
                    <i class="material-icons">close</i>
                </div>
            </div>
        </div>
    </ng-container>
</section>
